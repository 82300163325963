// eslint-disable-next-line import/named
import { getInternalId } from '../../components/ui/databinding/databinding';
import { ViewBase } from '../../components/ui/view-base';

export class PageView extends ViewBase {
  protected internalId: string;

  constructor() {
    super();

    this.internalId = getInternalId();
  }
}
