import { getApiFactory } from '../../../api/api-injector';
import {
  InputIssuePurchaseOrder,
  InputIssueQuote,
  InputPreviewQuoteShopDrawing
} from '../../../api/dealer-api-interface-clientagent';
import { WaitPatientlySaving } from '../../../components/ui/modal-saving';
import { tlang } from '@softtech/webmodule-components';

export class V6SupplierQuoteBase {
  static async generateShopDrawing(branchId, quoteId, projectId: string): Promise<boolean> {
    const waiting = new WaitPatientlySaving(() => tlang`Requesting %%shopdrawing%%`);
    try {
      const input: InputPreviewQuoteShopDrawing = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId
      };
      const result = await getApiFactory().clientAgent().previewQuoteShopDrawing(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }

  static async issueQuote(branchId, quoteId, projectId): Promise<boolean> {
    const waiting = new WaitPatientlySaving(() => tlang`Issuing %%quote%%`);
    try {
      const input: InputIssueQuote = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId
      };
      const result = await getApiFactory().clientAgent().issueQuote(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }

  static async issuePurchaseOrder(branchId, quoteId, projectId, purchaseOrderId: string): Promise<boolean> {
    const waiting = new WaitPatientlySaving(() => tlang`Issuing %%quote%%`);
    try {
      const input: InputIssuePurchaseOrder = {
        branchId: branchId,
        projectId: projectId,
        quoteId: quoteId,
        purchaseOrderId: purchaseOrderId
      };
      const result = await getApiFactory().clientAgent().issuePurchaseOrder(input);
      return result !== null;
    } finally {
      await waiting.hideModal();
    }
  }
}
