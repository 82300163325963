import { ProjectDataEntryView, ProjectView, ProjectViewOptions } from '../../../projects/views/project-view';
import { FranchiseeProjectDetailView } from './franchisee-project-detail-view';
import { ClientApi } from '../../../api/client-api';
import { getApiFactory } from '../../../api/api-injector';
import { ProjectResourceView } from '../../../projects/views/project-resource-view';
import { FranchiseeProjectResourceView } from './franchisee-project-resource-view';
import { cache } from '../../cache/cache-registry';
import { createNewQuote, getSupplier } from '../../quotes/quote-creator';
import { emptyGuid } from '../../../api/guid';
import {
  ProjectState,
  ProjectStateChangeReason,
  Resource,
  ResourceType
} from '../../../api/dealer-api-interface-project';
import { getQuoteContainerManager } from '../../quotes/quote-ui-adapter';
import { fireQuickErrorToast, fireQuickSuccessToast } from '../../../toast-away';
import { tlang } from '@softtech/webmodule-components';
import { launchQuote } from '../../../quotes/ui/launcher';
import { userDataStore } from '../../common/current-user-data-store';
import { FranchiseeQuoteContainerManager } from '../../quotes/data/franchisee-quote-manager';
import { QuoteState, QuoteType } from '../../../api/dealer-api-interface-quote';
import { DevelopmentError } from '../../../development-error';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { PurchaseOrderState } from '../../../api/dealer-api-interface-franchisee';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { ProjectDocumentView } from '../../../projects/views/project-document-view';
import { FranchiseeProjectDocumentView } from './franchisee-project-document-view';
import { PaymentProfileCacheData } from '../../cache/cache-data';
import { NullPromise } from '@softtech/webmodule-data-contracts';
import { getProjectStateChangeReasonsForState } from '../../cache/project-state-change-reason-cache';
import { displaySupplierTACNotApprovedMsg } from '../../../v6config/supplier-offline';

export class FranchiseeProjectDEView extends ProjectDataEntryView {
  clientApi: ClientApi = getApiFactory().client();
  private projectCache = cache().project;
  private clientCache = cache().client;
  private clientTypeCache = cache().paymentProfile;
  private contactCache = cache().contact;

  protected projectDetailViewFactory(_options: ProjectViewOptions) {
    return new FranchiseeProjectDetailView({
      projectManager: this.projectContainerManager
    });
  }

  protected projectResourceViewFactory(): ProjectResourceView {
    return new FranchiseeProjectResourceView({
      projectManager: this.projectContainerManager,
      quoteCache: cache().quote,
      purchaseOrderCache: cache().purchaseOrder,
      canClose: async () => {
        if (await this.canClose()) {
          await this.tryClose();
          return true;
        }
        return false;
      }
    });
  }

  protected projectDocumentsViewFactory(): ProjectDocumentView {
    return new FranchiseeProjectDocumentView({
      projectManager: this.projectContainerManager,
      canClose: async () => {
        if (await this.canClose()) {
          await this.tryClose();
          return true;
        }
        return false;
      }
    });
  }

  protected async internalCreateQuote(): Promise<boolean> {
    //REFACTOR - remove business logic from UI, add to container manager and parameterize
    //ie qcm.createProjectQuote()
    const supplier = await getSupplier();
    if (isEmptyOrSpace(supplier.supplierId)) return false;

    if (!userDataStore.supplierTACApproved(supplier.supplierId)) {
      await displaySupplierTACNotApprovedMsg(supplier, true);
      return false;
    }
    const quoteContainer = await createNewQuote({
      supplierId: supplier.supplierId,
      owner: userDataStore.defaultBranch.id
    });
    if (quoteContainer) {
      const quoteManager = getQuoteContainerManager(quoteContainer) as FranchiseeQuoteContainerManager;
      quoteManager.quote.state = QuoteState.Active;
      quoteManager.generateQuoteNumberOnSave();

      const branchQuote = quoteManager.branchQuote;
      branchQuote.clientId = this.projectContainerManager.project.clientId;
      branchQuote.clientName = this.clientCache.getLocal(branchQuote.clientId)?.displayValue ?? '';
      branchQuote.clientTypeId = this.projectContainerManager.project.clientTypeId;
      branchQuote.clientTypeName = this.clientTypeCache.getLocal(branchQuote.clientTypeId)?.displayValue ?? '';
      branchQuote.contactId = this.projectContainerManager.project.contactId;
      branchQuote.contactName = this.contactCache.getLocal(branchQuote.contactId)?.displayValue ?? '';

      const paymentProfile = await cache().paymentProfile.get(branchQuote.clientTypeId);
      if (paymentProfile && paymentProfile.data) {
        const profile = paymentProfile.data as PaymentProfileCacheData;
        //Update Terms
        if (quoteManager.quote.quoteType == QuoteType.Quote)
          quoteManager.quote.termsAndConditions = profile.paymentProfile.quoteTerms;
        else quoteManager.quote.termsAndConditions = profile.paymentProfile.estimateTerms;

        quoteManager.quotePrice.marginPercentage = profile.paymentProfile.margin;
      }

      const updatedQuote = await quoteManager.saveQuote(true);
      if (updatedQuote) {
        const resource = await this.projectApi.createProjectResourceReference({
          resourceId: quoteContainer.quoteId,
          projectId: this.projectContainerManager.projectId,
          typeOf: ResourceType.Quote,
          parentResourceId: emptyGuid,
          parentTypeOf: ResourceType.None
        });
        if (resource) {
          await Promise.all([
            this.projectCache.updateLocal(this.projectContainerManager.projectId),
            cache().projectResourceLink.updateLocal(this.projectContainerManager.projectId),
            cache().projectResourceLink.updateLocal(quoteContainer.quoteId)
          ]);
          fireQuickSuccessToast(tlang`Created %%quote%% for ${this.projectContainerManager.projectTitle}`);
          return launchQuote(quoteContainer.quoteId);
        }
      }
      // if we get here A) The quote update has failed and B) the project-quote link wasn't created
      fireQuickErrorToast(tlang`Unable to create %%quote%% for ${this.projectContainerManager.projectTitle}`);
      // if we were unable to create the project link, remove the alternative quote
      return await quoteManager.deleteQuote();
    }
    fireQuickErrorToast(tlang`Unable to create %%quote%%`);
    return false;
  }

  protected async canCreateQuote(): Promise<boolean> {
    const project = this.projectContainer.project;
    if (!project) {
      throw new DevelopmentError(tlang`%%project%% container is empty`);
    }
    const isProjectActive = project.state != ProjectState.Cancelled && project.state != ProjectState.Completed;
    if (!isProjectActive) return false;
    const errors = this.getValidationErrors();

    if (errors.length != 0) {
      await showValidations(errors);
      return false;
    }

    return true;
  }

  protected async setProjectState(projectState: ProjectState): Promise<boolean> {
    // force a reload of the project to get updated quote/purchase order resources
    this.projectContainer.project = null;
    await this.projectContainerManager.needsProject();

    const errors: string[] = [];
    const stateText = projectState == ProjectState.Cancelled ? 'cancel' : 'complete';
    const quoteResources = (await this.internalLoadResources(ResourceType.Quote, this.quoteCache)) ?? [];
    const orderResources =
      (await this.internalLoadResources(ResourceType.PurchaseOrder, this.purchaseOrderCache)) ?? [];

    if (projectState == ProjectState.Cancelled) {
      errors.push(...this.internalValidateProjectCancel(quoteResources, orderResources));
    } else if (projectState == ProjectState.Completed) {
      errors.push(...this.internalValidateProjectComplete(quoteResources, orderResources));
    }

    if (errors.length > 0) {
      await showValidations(
        errors,
        () => tlang`Unable to ${stateText} %%project%% as one or more items have not been completed.`
      );
      return false;
    } else {
      return super.setProjectState(projectState);
    }
  }

  protected async internalLoadResources(
    resourceType: ResourceType,
    objectCache: DataCacheGeneric
  ): Promise<Resource[] | undefined> {
    const items = this.projectContainer.resources?.filter(x => x.typeOf == resourceType);
    if (items) {
      const keys = items.map(x => x.resourceId ?? emptyGuid) ?? [];

      //if there are any other callbacks we need to do, add them to the
      //all array which will let them run at the same time
      await Promise.all([objectCache.preFetch(keys)]);
    }
    return items;
  }

  protected internalValidateProjectCancel(quoteResources: Resource[], orderResources: Resource[]): string[] {
    const errors: string[] = [];

    /*
         Cancel Requirements
         - Only Accepted, Rejected, Cancelled and Lapsed quotes
         - Only Cancelled purchase orders
         */
    let hasAccepted = false;
    quoteResources.forEach(resource => {
      const quoteSummary = this.quoteCache.getLocalData(resource.resourceId)?.quoteSummary;
      if (quoteSummary) {
        if (this.isQuoteStateOutstanding(quoteSummary.state)) {
          errors.push(
            tlang`%%quote%% '${quoteSummary.title}' is still outstanding (Currently ${QuoteState[quoteSummary.state]}).`
          );
        } else if (quoteSummary.state == QuoteState.Approved) {
          //TODO# we may test for more than the accepted state with new supplier workflow
          hasAccepted = true;
        }
      }
    });
    // probably should never reach here, but just in case...
    if (hasAccepted) {
      errors.push(tlang`Cannot cancel a %%project%% with accepted %%quote%%s`);
    }

    orderResources.forEach(resource => {
      const orderSummary = this.purchaseOrderCache.getLocalData(resource.resourceId)?.purchaseOrder;
      if (
        orderSummary &&
        orderSummary.state != PurchaseOrderState.Completed &&
        orderSummary.state != PurchaseOrderState.Cancelled
      ) {
        errors.push(
          tlang`%%purchase-order%% '${orderSummary.title}' has not been completed (Currently ${
            PurchaseOrderState[orderSummary.state]
          }).`
        );
      }
    });

    return errors;
  }

  protected internalValidateProjectComplete(quoteResources: Resource[], orderResources: Resource[]): string[] {
    const errors: string[] = [];
    /*
         Complete Requirements
         - Only Accepted, Rejected, Cancelled and Lapsed quotes
         - Only Completed and Cancelled purchase orders
         */
    quoteResources.forEach(resource => {
      const quoteSummary = this.quoteCache.getLocalData(resource.resourceId)?.quoteSummary;
      if (quoteSummary && this.isQuoteStateOutstanding(quoteSummary.state)) {
        errors.push(
          tlang`%%quote%% '${quoteSummary.title}' has not been completed (Currently ${QuoteState[quoteSummary.state]}).`
        );
      }
    });

    orderResources.forEach(resource => {
      const orderSummary = this.purchaseOrderCache.getLocalData(resource.resourceId)?.purchaseOrder;
      if (
        orderSummary &&
        orderSummary.state != PurchaseOrderState.Completed &&
        orderSummary.state != PurchaseOrderState.Cancelled
      ) {
        errors.push(
          tlang`%%purchase-order%% '${orderSummary.title}' has not been completed (Currently ${
            PurchaseOrderState[orderSummary.state]
          }).`
        );
      }
    });

    return errors;
  }

  protected isQuoteStateOutstanding(quoteState: QuoteState): boolean {
    return (
      quoteState != QuoteState.Approved &&
      quoteState != QuoteState.Cancelled &&
      quoteState != QuoteState.Rejected &&
      quoteState != QuoteState.Lapsed
    );
  }

  protected async getProjectReasons(projectState: ProjectState): NullPromise<ProjectStateChangeReason[]> {
    const franchiseeId = userDataStore.franchisee.id;
    const stateReasons = await getProjectStateChangeReasonsForState(projectState, franchiseeId);
    if (stateReasons) {
      const displayOrder = stateReasons.presentation.itemDisplayOrder;
      const sortedItems: ProjectStateChangeReason[] = [];
      displayOrder.forEach(elementId => {
        const item = stateReasons.stateChangeReasons.find(item => item.id === elementId);
        if (item) {
          sortedItems.push(item);
        }
      });
      return sortedItems;
    }
    return null;
  }
}

export class FranchiseeProjectView extends ProjectView {
  protected createView(): ProjectDataEntryView {
    return new FranchiseeProjectDEView(this.options, this);
  }
}
