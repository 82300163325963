import { customElement, property, query, state } from 'lit/decorators.js';
import { LitElementBase } from '../../../components/litelement-base';
import { FormInputImageFileUploadView } from '../../../components/FormInputView';
import {
  AddConversationAttachment,
  BranchQuoteSupport,
  BranchQuoteSupportStatus,
  QuoteItemConversation,
  QuoteItemConversationType,
  ViewConversationAttachment,
  ViewConversationEntry
} from '../../../api/dealer-api-interface-franchisee';
import { currentUserClaims, supplierHasFullPrivelages } from '../../../components/currentuser-claims';
import { DataBinding } from '../../../components/ui/databinding/databinding';
import {
  DataTracker,
  DynamicValueBinder,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../../components/ui/databinding/data-tracker';
import { getApiFactory } from '../../../api/api-injector';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { DevelopmentError } from '../../../development-error';
import { emptyGuid, newGuid } from '../../../api/guid';
import { html, PropertyValueMap, TemplateResult } from 'lit';
import { QuoteItemContainer } from '../../../quotes/data/quote-item-container';
import { money } from '../../../components/currency-formatter';
import { tlang } from '@softtech/webmodule-components';
import { InformationDispatcher } from '../../../components/ui/information-dispatcher';
import { SaveWorkflowModal } from '../../../components/save-workflow';
import { V6SSIProcessor } from '../../../quotes/data/v6/v6-ssi-processor';
import { flagInSet, isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import {
  ConversationEntryUpdateEvent,
  CreateConversationEntry,
  WebModuleConversationEntryEditorView
} from '../../conversation/wm-conversation-entry';
import {
  ConversationActions,
  WebModuleBranchQuoteConversationView
} from '../../conversation/wm-branchquote-conversation';
import { compare } from '../../../components/clone';
import { AskConfirmation, buttonsContinueCancel } from '../../../components/ui/modal-confirmation';
import { getQuoteSupplierDisplayName } from '../../../quotes/data/quoteSupplierProvider';
import { information } from '../../../components/ui/modal-option';
import { stringsAsMarkdownList } from '../../../components/markdown';
import { InputUpdateEtoItemsV2, InputUpdateQuoteItems } from '../../../api/dealer-api-interface-quote';
import { getSupportStatusBadge } from '../../conversation/conversation-helper';
import { updateFreehandPrice } from '../data/v6/freehand-pricing';
import { cache } from '../../cache/cache-registry';

export interface ETOEditorSavedEventData {
  quoteItemContainer: QuoteItemContainer;
  branchQuoteSupport: BranchQuoteSupport;
  masterDocument: ViewConversationEntry;
  quoteItemConversationLink: QuoteItemConversation;
}

@customElement('wmview-franchisee-eto-editor')
export class WebModuleFranchiseeETOEditor extends LitElementBase {
  isEditingDraft: any;

  protected _originalsupplierGrossSingleUnitCost?: number;
  protected _originalQty?: number;
  protected _originalThumbnail?: string;

  protected dataBinding = new DataBinding(this);
  protected dataTracker = new DataTracker(this.dataBinding);

  protected franchiseeApi = getApiFactory().franchisee();
  protected claims = currentUserClaims();

  protected conversationEntryId: string = newGuid();

  protected quoteApi = getApiFactory().quote();

  protected _masterDocumentConversationId: string = newGuid();

  @property() branchQuoteSupport?: BranchQuoteSupport;
  @property() quoteManager?: FranchiseeQuoteContainerManager;
  @property() isNewETO = true;
  @property() quoteItemConversationLink?: QuoteItemConversation | null;
  @property() private _quoteItemContainer?: QuoteItemContainer | undefined;
  @property() private _masterDocument?: ViewConversationEntry | undefined;

  @query('#conversation') conversation?: WebModuleBranchQuoteConversationView;
  @query('#masterdocument') masterDocumentEditor?: WebModuleConversationEntryEditorView;
  @query('#thumbnail') protected thumbNailEditor?: FormInputImageFileUploadView;

  @state() protected addNoteTemplate?: TemplateResult;
  @state() protected editNoteTemplate?: TemplateResult;
  @state() protected conversationActions?: ConversationActions;
  public get readonly() {
    return super.readonly || this.forceReadonly;
  }
  constructor() {
    super();
    this.prepareBindings();
  }

  get isEditorVisible() {
    return this.addNoteTemplate !== undefined || this.editNoteTemplate !== undefined;
  }

  public get quoteItemContainer(): QuoteItemContainer | undefined {
    return this._quoteItemContainer;
  }

  public set quoteItemContainer(value: QuoteItemContainer | undefined) {
    const resetValues = !value || !this._quoteItemContainer || this._quoteItemContainer?.item.id !== value?.item.id;
    this._quoteItemContainer = value;
    if (resetValues) {
      this.backupPriceDetails();
    }
  }

  private backupPriceDetails() {
    this._originalsupplierGrossSingleUnitCost = this._quoteItemContainer?.price.supplierGrossSingleUnitCost;
    this._originalQty = this._quoteItemContainer?.item.quantity;
    this._originalThumbnail = this._quoteItemContainer?.item.virtualThumbnailPath;
  }

  public get masterDocument(): ViewConversationEntry | undefined {
    return this._masterDocument;
  }

  public set masterDocument(value: ViewConversationEntry | undefined) {
    this._masterDocument = value;
  }

  get qcm(): FranchiseeQuoteContainerManager {
    if (!this.quoteManager) throw new DevelopmentError('missing quote manager');
    return this.quoteManager;
  }

  get forceReadonly() {
    return (this.quoteManager?.isReadonly() ?? true) || this.ticketClosed;
  }

  //this is both conversation and entryid
  get masterDocumentConversationId() {
    return this.masterDocument?.conversationId ?? this._masterDocumentConversationId;
  }

  get quoteId(): string {
    return this.qcm.quoteId;
  }

  get quoteSetId() {
    return this.qcm.quote.quoteSetId;
  }

  get branchQuoteSupportId(): string {
    return this.branchQuoteSupport?.id ?? this.quoteItemContainer?.item.id ?? emptyGuid;
  }

  get quoteItemId() {
    return this.quoteItemContainer?.item.id ?? emptyGuid;
  }

  get hasDataChanged(): boolean {
    if (!this.quoteItemContainer) return false;

    // When it is a new item we return true as there is no backup item, and the supplier thumbnail nor master document
    // has not been uploaded yet.
    if (this.isNewETO) return true;

    return (
      this.masterDocument?.lastModified === '' ||
      this.thumbNailEditor?.isValueChanged ||
      this.qcm.changedItem(this.quoteItemContainer.item.id)
    );
  }

  protected get priceOrQtyChanged() {
    if (!this.quoteItemContainer) return false;
    if (this.isNewETO) return true;
    return (
      this._originalsupplierGrossSingleUnitCost !== this.quoteItemContainer.price.supplierGrossSingleUnitCost ||
      this._originalQty !== this.quoteItemContainer.item.quantity
    );
  }

  protected get ticketClosed() {
    return (
      !this.branchQuoteSupport ||
      flagInSet(this.branchQuoteSupport.status, BranchQuoteSupportStatus.Cancelled | BranchQuoteSupportStatus.Resolved)
    );
  }

  async getResolveIssues(): Promise<string[]> {
    const result = this.getValidationErrors();

    if (!this.quoteItemContainer) throw new DevelopmentError('ETO must have item');

    if (isEmptyOrSpace(this.quoteItemContainer.item.description))
      result.push(tlang`%%special-item%% Reference must be set`);

    if (this.quoteItemContainer.item.quantity < 1) result.push(tlang`Quantity must be valid`);
    if (this.quoteItemContainer.item.quantity % 1 !== 0) result.push(tlang`Quantity must be valid whole number`);

    if (isEmptyOrSpace(this.thumbNailEditor?.value)) result.push(tlang`Thumbnail must be uploaded`);

    if ((this.masterDocument?.attachments.length ?? 0) < 1) result.push(tlang`Shop Drawing file must be included`);

    return result;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];
    this.dataTracker.applyChangeToValue();
    if (!this.quoteItemContainer) throw new DevelopmentError('ETO QuoteItemContainer must exist');

    if (isEmptyOrSpace(this.quoteItemContainer.item.title))
      errors.push(tlang`Please add a title to describe this %%special-item%%`);

    const quantity = this.quoteItemContainer?.item.quantity ?? 0;
    if (quantity <= 0) {
      errors.push(tlang`Please provide a quantity greater than 0`);
    }
    if (quantity % 1 !== 0) {
      errors.push(tlang`!!special-item!! Quantity must be a whole number`);
    }

    return errors;
  }

  prepareForSave() {
    this.dataTracker.applyChangeToValue();
  }

  async save(): Promise<boolean> {
    if (!this.quoteItemContainer) return false;

    if (!this.hasDataChanged) return true;

    const qm = this.qcm;
    const informationDispatcher = new InformationDispatcher();

    const setInfo = async (s: string, header = 'Preparing to save') => {
      await informationDispatcher.setInformation(`# ${header}

                + ${s}`);
    };

    await setInfo(tlang`getting updates from %%supplier%%`);

    const saveModal = new SaveWorkflowModal(
      tlang`Saving ${this.quoteItemContainer.item.title}`,
      informationDispatcher,
      3
    );

    const modalState = await saveModal.show();
    await modalState.onShow;

    try {
      await setInfo(tlang`Requesting Updates from %%supplier%%`);
      //process ssi with new information
      //build an ssi processor that will build the quote exluding the deleted item, so that we get data
      //based on its non-existence

      const ssiProcessor = new V6SSIProcessor(qm);
      //we need to go and get the correct price and store it in the item
      //before we run ssi data collection.
      if (this.priceOrQtyChanged) {
        if (
          !(await updateFreehandPrice(
            this.qcm.quote.supplierId,
            this.qcm.quote.quoteOwnerId,
            this.quoteItemContainer.item,
            this.quoteItemContainer.price
          ))
        ) {
          await information(tlang`Save cancelled, could not communicate with Server`);
          return false;
        }
      }
      const ssiInputs = this.priceOrQtyChanged ? await ssiProcessor.processSSI() : undefined;

      await setInfo(tlang`%%supplier%% processing complete. 
                
                + sending updates to server`);

      if (this.isNewETO) {
        if (!(await this.createNewItem(ssiInputs))) return false;
      } else {
        if (!(await this.saveExistingItem(ssiInputs))) return false;
      }

      this.backupPriceDetails();
    } finally {
      saveModal?.done();
      await saveModal?.hideModal();
    }

    return true;
  }

  async createNewItem(ssiInputs: InputUpdateQuoteItems | undefined): Promise<boolean> {
    if (!this.quoteItemContainer) return false;
    const thumbnail = this.thumbNailEditor?.isValueChanged ? await this.thumbNailEditor?.base64Value() : '';

    const thumbnailExtension = thumbnail !== '' ? this.thumbNailEditor?.fileExtension : undefined;
    const result = await this.qcm.createQuoteItemEx(this.quoteItemContainer, thumbnail, ssiInputs, thumbnailExtension);
    if (!this.qcm.lastSaveSuccessful) return false;

    if (result) {
      await this.thumbNailEditor?.ClearFiles();
      this.isNewETO = false;
      this.quoteItemContainer = result;
      await this.assignBranchQuoteSupport();
      this.dispatchSavedEvent();
      return true;
    }
    return false;
  }

  async assignBranchQuoteSupport() {
    if (!this.quoteManager) throw new DevelopmentError('ETO QuoteManager must be assigned');
    if (!this.quoteItemContainer) throw new DevelopmentError('ETO QuoteItemcontainer must be assigned');
    if (!this.branchQuoteSupport) throw new DevelopmentError('ETO branchQuoteSupport must be assigned');
    if (!isEmptyOrSpace(this.branchQuoteSupport.recordVersion))
      throw new DevelopmentError('ETO branchQuoteSupport already exists');
    if (!this.masterDocument) throw new DevelopmentError('ETO MasterDocument must be assigned');
    const api = this.franchiseeApi;
    const attachments = this.masterDocumentEditor?.allAttachmentsAsInputs ?? [];
    const branchSupportSubject = `${this.quoteItemContainer.item.title} - ${this.quoteItemContainer.item.description}`;
    const result = await api.addBranchQuoteSupport({
      masterDocumentConversationEntry: {
        attachments: attachments,
        id: this.masterDocument.id,
        text: this.masterDocument.text,
        users: []
      },
      branchQuoteId: this.qcm.quoteId,
      conversationId: this.branchQuoteSupport.conversationId,
      conversationEntry: null, //we may have already created the first one.
      id: this.branchQuoteSupport.id,
      status: this.branchQuoteSupport.status,
      subject: branchSupportSubject,
      type: this.branchQuoteSupport.type
    });
    if (!result) return false;
    this.branchQuoteSupport = result.branchQuoteSupport;
    this.masterDocument = result.masterDocument ?? undefined;

    //if we are creating the support ticket, then this is the very first referrer to the ticket
    //so we can create it sharing the key.
    this.quoteItemConversationLink = await api.quoteItemConversationLink({
      quoteId: this.quoteManager?.quoteId,
      conversationId: this.quoteItemContainer.item.id,
      quoteItemId: this.quoteItemContainer.item.id,
      type: QuoteItemConversationType.EngineeredToOrder
    });
    return true;
  }

  async revertChanges(): Promise<boolean> {
    if (!this.quoteItemContainer) return true;
    this.quoteItemContainer =
      this.qcm.restoreQuoteItemFromBackup(this.quoteItemContainer.item.id) ?? this.quoteItemContainer;

    await this.thumbNailEditor?.ClearFiles();

    this.dataTracker.resetEditorValue();
    return true;
  }

  prepareBindings() {
    const addDynamicMoney = (
      fieldName: string,
      getter: EventValueGetter,
      setter: EventValueSetter,
      readonly?: () => boolean
    ) => {
      this.dataTracker.addBinding(
        new DynamicValueBinder(FieldType.money, false, getter, setter, readonly),
        fieldName,
        fieldName,
        FieldType.money,
        false
      );
    };

    this.dataTracker.addObjectBinding(() => this.quoteItemContainer?.item, 'title', 'title', FieldType.string, false);

    this.dataTracker.addObjectBinding(
      () => this.quoteItemContainer?.item,
      'comment',
      'comment',
      FieldType.string,
      true
    );

    this.dataTracker.addObjectBinding(
      () => this.quoteItemContainer?.item,
      'description',
      'description',
      FieldType.string,
      false
    );
    this.dataTracker.addObjectBinding(
      () => this.quoteItemContainer?.item,
      'quantity',
      'quantity',
      FieldType.float,
      false
    );

    addDynamicMoney(
      'supplierGrossSingleUnitCost',
      () => this.quoteItemContainer?.price.supplierGrossSingleUnitCost ?? 0,
      () => {
        console.log('singleUnitCost value set');
      },
      () => true
    );
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('keyup', this.eventKeyup);
    this.addEventListener('blur', this.eventBlur);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('keyup', this.eventKeyup);
    this.removeEventListener('blur', this.eventBlur);
  }

  public branchSupportActionsTemplate(): unknown {
    const a = this.conversationActions;
    if (!a) return html``;
    return html`${a.postToSupplier(true)}${a.reactivate(true)}${a.resolve(true)}`;
  }

  conversationNoteHeader(): unknown {
    let title = tlang``;
    if (this.isEditorVisible) {
      const editCommentHeader = this.isEditingDraft ? tlang`Edit Draft Comments` : tlang`Edit Comments`;
      title = this.isNewETO
        ? tlang`%%support-initial-topic%%`
        : this.addNoteTemplate !== undefined
          ? tlang`Add Comments`
          : editCommentHeader;
    }
    return html`<h2 class="main-header-style">${title}</h2>`;
  }

  conversationNoteTemplates(): unknown {
    if (this.addNoteTemplate !== undefined) return this.addNoteTemplate;
    if (this.editNoteTemplate !== undefined) return this.editNoteTemplate;
    return html``;
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (this.conversation) this.conversationActions = this.conversation.actionsTemplate();
  }

  protected quantityCost(): number {
    try {
      const qty = (this.dataTracker.getEditorValue('quantity') as number) ?? 0;
      const unitCost = (this.dataTracker.getEditorValue('supplierGrossSingleUnitCost') as number) ?? 0;
      return money(qty * unitCost, 2);
    } catch {
      return this.quoteItemContainer?.price.quantityCost ?? 0;
    }
  }

  protected triggerEvent = (e: Event) => {
    if (!this.quoteItemContainer) return;
    const elem = e.target as HTMLInputElement;
    const qtyCost = this.quantityCost();
    const unitCost = this.dataTracker.getEditorValue('supplierGrossSingleUnitCost') as number;
    const qty = this.dataTracker.getEditorValue('quantity') as number;

    if (elem.id == this.dataTracker.binder.field('supplierGrossSingleUnitCost')) {
      //Set the unit cost. This is also an item being sent to the supplier, so we need to set the supplier unit cost.
      //the single unit cost needs to be force refreshed from v6
      this.quoteItemContainer.price.singleUnitCost = 0;
      this.quoteItemContainer.price.supplierGrossSingleUnitCost = unitCost;
    }

    if (money(qty * unitCost, 2) !== qtyCost) {
      this.requestUpdate();
    }
  };

  protected eventBlur = (e: Event) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    const elem = e.target as HTMLInputElement;
    if (
      elem.id == this.dataTracker.binder.field('quantity') ||
      elem.id == this.dataTracker.binder.field('supplierGrossSingleUnitCost')
    )
      this.triggerEvent(e);
  };

  protected eventKeyup = (e: KeyboardEvent) => {
    if (!(e.target instanceof HTMLInputElement)) return;
    const elem = e.target as HTMLInputElement;
    const event = e;
    console.log(`${elem.id} ${elem.value}`);
    if (
      elem.id == this.dataTracker.binder.field('quantity') ||
      elem.id == this.dataTracker.binder.field('supplierGrossSingleUnitCost')
    )
      this.triggerEvent(event);
  };

  protected eventNewConversationEntry = (e: CustomEvent<{ enabled: boolean; editor: TemplateResult }>) => {
    this.isEditingDraft = false;
    if (e.detail.enabled) {
      this.addNoteTemplate = e.detail.editor;
      this.editNoteTemplate = undefined;
    } else this.addNoteTemplate = undefined;
  };

  protected eventConversationSaved = async (
    e: CustomEvent<{
      updateEvent: ConversationEntryUpdateEvent;
      showMessage: boolean;
      completionPromise: Promise<void>;
    }>
  ) => {
    if (this.isNewETO) {
      e.detail.showMessage = false;
      this.prepareForSave();
      e.detail.completionPromise = (async () => {
        await this.save();
      })();
    }
  };

  protected eventConversationCreated = async (
    e: CustomEvent<{
      updateEvent: CreateConversationEntry;
      showMessage: boolean;
      completionPromise: Promise<void>;
    }>
  ) => {
    if (this.isNewETO) {
      e.detail.showMessage = false;
      this.prepareForSave();
      e.detail.completionPromise = (async () => {
        await this.save();
      })();
    }
  };

  protected eventConversationChanged = async (
    e: CustomEvent<{
      branchQuoteSupport: BranchQuoteSupport;
    }>
  ) => {
    this.branchQuoteSupport = e.detail.branchQuoteSupport;
    this.prepareForSave();
    if (this.hasDataChanged) await this.save();
    else this.dispatchSavedEvent();
  };

  protected eventConversationChanging = async (
    e: CustomEvent<{
      branchQuoteSupport: BranchQuoteSupport;
      status: BranchQuoteSupportStatus;
      allowChange: Promise<boolean>;
      validations: string[];
    }>
  ) => {
    let issues: string[] = [];
    e.detail.allowChange = (async () => {
      let allowChange = false;
      let errors: string[] = [];

      switch (e.detail.status) {
        case BranchQuoteSupportStatus.Cancelled:
          allowChange = await AskConfirmation(
            tlang`${'ref:cancel-eto-notification'}
                ## Cancellation

                this does not delete the %%special-item%% from the %%quote%% if you wish to do so you must delete
                the %%special-item%% manually. 
                
                Deleting the %%special-item%% will not delete the 
                associated %%support-request%%, but it cannot be relinked

                you may %%bqs-reactivate%% at any time
                `,
            buttonsContinueCancel(),
            undefined,
            tlang`Continue %%special-item%% !!bqs-cancel!!`
          );
          break;
        case BranchQuoteSupportStatus.Resolved:
          issues = await this.getResolveIssues();
          if (issues.length > 0) {
            await information(
              tlang`${'ref:resolve-eto-notification'}
                    ## Validation Issues
                    
                    Please correct the following issues before resolving this %%special-item%%

                    ${stringsAsMarkdownList(issues)}


                    `,
              tlang`%%special-item%% Validation Issues`
            );
            allowChange = false;
          } else allowChange = true;
          break;
        case BranchQuoteSupportStatus.New:
          errors = this.getValidationErrors();
          if (this.branchQuoteSupport) {
            if (this.conversation?.results?.conversationEntries.length == 0 ?? 0) {
              const quoteSummary = (await cache().quote.getData(this.branchQuoteSupport.branchQuoteId))?.quoteSummary;
              const supplierName = await getQuoteSupplierDisplayName(quoteSummary?.supplierId);
              // message specified via PBI 218417
              errors.push(tlang`${'ref:eto-missing-support-initial-topic-msg'}Please provide a detailed %%support-initial-topic%% before submitting this 
                                            ticket to ${supplierName}`);
            }
          }
          if (errors.length != 0) {
            e.detail.validations.push(...errors);
            allowChange = false;
          }
          allowChange = true;
          break;
        default:
          allowChange = true;
      }

      if (allowChange) {
        this.prepareForSave();
        await this.save();
      }

      return allowChange;
    })();
  };

  protected eventEditConversationEntry = (
    e: CustomEvent<{
      enabled: boolean;
      editor: TemplateResult;
      entry: ViewConversationEntry;
    }>
  ) => {
    this.isEditingDraft = false;
    if (e.detail.enabled) {
      this.isEditingDraft = e.detail.entry.draft;
      this.editNoteTemplate = e.detail.editor;
      this.addNoteTemplate = undefined;
    } else this.editNoteTemplate = undefined;
  };

  protected render(): unknown {
    //the readonly state of the object is not 100% reliable. we need to check many states.
    const readonly = this.forceReadonly;
    const forms = new FormInputAssistant(this.dataTracker, readonly);

    const alwaysShowEditor = !readonly && this.branchQuoteSupport && !this.ticketClosed;

    const conversation = this.branchQuoteSupport
      ? html`
          <div class="border-top border-secondary mt-2 ">
            <wmview-branchquote-conversation
              .alwaysShowNewNoteEditor="${alwaysShowEditor}"
              .readonly="${this.quoteManager?.isReadonly() ?? true}"
              .showHeader="${false}"
              .useExternalEditing="${true}"
              id="conversation"
              .branchQuoteSupport="${this.branchQuoteSupport}"
              .emptyNote=""
              .supplierName=${this.quoteManager?.supplierName}
              @wm-event-new-entry="${this.eventNewConversationEntry}"
              @wm-event-edit-entry="${this.eventEditConversationEntry}"
              @wm-event-conversation-saved="${this.eventConversationSaved}"
              @wm-event-conversation-created="${this.eventConversationCreated}"
              @wm-event-changed="${this.eventConversationChanged}"
              @wm-event-changing="${this.eventConversationChanging}"
            ></wmview-branchquote-conversation>
          </div>
        `
      : html``;

    const src = isEmptyOrSpace(this.quoteItemContainer?.item.virtualThumbnailPath)
      ? ''
      : this.quoteApi.api.fullUrl(`api/file/${this.quoteItemContainer?.item.virtualThumbnailPath}`);

    const isSupplier = this.claims.isAgent;
    const dealerAccess = !isSupplier || (isSupplier && supplierHasFullPrivelages());

    const eventAttachmentClicked = (e: CustomEvent<{ filename: string }>) => {
      const attachment = this.masterDocument?.attachments.find(x => x.filename === e.detail.filename);
      if (attachment && !isEmptyOrSpace(attachment.publicUrl)) window.open(attachment.publicUrl, '_blank');
    };

    const eventMasterDocument = async (
      e: CustomEvent<{
        attachments: AddConversationAttachment[];
        conversationAttachments: ViewConversationAttachment[];
      }>
    ) => {
      if (this.masterDocument) {
        if (!compare(this.masterDocument.attachments, e.detail.conversationAttachments)) {
          this.masterDocument.lastModified = '';
          this.masterDocument.attachments = e.detail.conversationAttachments;
        }
      }
    };

    const masterDocumentDropZoneTemplate = html` <div class="eto-master-document">
      <wmview-conversation-entry-editor
        id="masterdocument"
        @wm-event-attachmentclicked="${eventAttachmentClicked}"
        .allowDownloadLinks="${true}"
        .attachmentsCaption="${tlang`Shop Drawing`}"
        .maxFiles="${1}"
        .readonly="${readonly || !isSupplier}"
        .forceUpdate="${true}"
        .attachmentsOnly="${true}"
        .autoSaveWhenAttachmentsOnly="${false}"
        .conversationEntry="${this.masterDocument}"
        .showHeader="${false}"
        @wm-event-attachment-uploaded="${eventMasterDocument}"
      ></wmview-conversation-entry-editor>
    </div>`;

    return html`
      <div class="eto-main form-two-col ">
        <div class="row">
          <div class="eto-left form-column">
            <div class="main-header d-flex justify-content-between  border-bottom border-secondary mb-2">
              <h2 class="main-header-style border-0">
                ${tlang`%%special-item%%`}
                ${getSupportStatusBadge(this.branchQuoteSupport?.status ?? BranchQuoteSupportStatus.Draft)}
              </h2>
            </div>

            <form class="special-item-editor-container ">
              <div class="row">
                <div class="col-6 custom-item-props">
                  ${dealerAccess
                    ? forms.textRequired('title', tlang`Title`, 120)
                    : forms.textReadonly('title', tlang`Title`)}
                  ${dealerAccess ? forms.float('quantity') : forms.floatReadonly('quantity')}
                  ${isSupplier
                    ? forms.textRequired('description', tlang`%%supplier%% Reference`, 120)
                    : forms.textReadonly('description', tlang`%%supplier%% Reference`)}
                  ${isSupplier
                    ? forms.money('supplierGrossSingleUnitCost', tlang`%%supplier%% Unit Price`, 2)
                    : forms.moneyReadonly('supplierGrossSingleUnitCost', tlang`%%supplier%% Unit Price`, 2)}
                </div>
                <div class="col-3 upload-zone">
                  <bs-form-image-upload
                    id="thumbnail"
                    .resize="${true}"
                    .showFileInput="${false}"
                    .isThumbnail="${true}"
                    .displayType="${1}"
                    .resizeWidth="${600}"
                    .resizeHeight="${500}"
                    .value="${src}"
                    .clickCaption="${isSupplier ? tlang`Choose file` : tlang`Supplier to upload`}"
                    data-label="${tlang`Thumbnail`}"
                    .readonly="${!isSupplier || readonly}"
                  ></bs-form-image-upload>
                </div>
                <div class="col-3 upload-zone">${masterDocumentDropZoneTemplate}</div>
                <div class="col-12 custom-item-notes">
                  ${forms.note(
                    'comment',
                    tlang`Notes`,
                    250,
                    tlang`This detail is published on %%quote%% Reports and submitted to the Manufacturer with the %%purchase-order%%`,
                    tlang`250 Character Limit`
                  )}
                </div>
              </div>
            </form>
            <div class="conversation-note">${this.conversationNoteHeader()} ${this.conversationNoteTemplates()}</div>
          </div>
          <div class="eto-right form-column">
            <div class="conversation-header-block">
              <h2 class="main-header-style border-0">${tlang`Comments`}</h2>
              ${this.conversationActions?.attachmentsOnlySwitch()}
            </div>
            <div class="eto-edit-conversation">${conversation}</div>
          </div>
        </div>
      </div>
    `;
  }

  private async saveExistingItem(ssiInputs: InputUpdateQuoteItems | undefined): Promise<boolean> {
    if (!this.quoteItemContainer) return false;
    const thumbnail = this.thumbNailEditor?.isValueChanged ? await this.thumbNailEditor?.base64Value() : '';
    const thumbnailExtension = thumbnail !== '' ? this.thumbNailEditor?.fileExtension : undefined;

    const result = await this.qcm.saveAndUpdateQuoteItem(
      this.quoteItemContainer,
      thumbnail,
      ssiInputs,
      thumbnailExtension
    );

    if (!this.qcm.lastSaveSuccessful) return false;

    await this.thumbNailEditor?.ClearFiles();

    const itemsToUpdate = await this.franchiseeApi.getAllLinkedQuoteItemsForConversation({
      conversationId: this.branchQuoteSupport?.conversationId ?? emptyGuid
    });

    await UpdateEtoOnQuoteItems({
      quoteItemIds: itemsToUpdate?.quoteItemIds.filter(x => x != result.item.id) ?? [],
      price: result.price,
      title: result.item.title,
      thumbnail: result.item.virtualThumbnailPath,
      reference: result.item.description
    });

    this.quoteItemContainer = result;
    await this.updateMasterDocument();
    await this.createOrUpdateBranchQuoteSupport();

    this.dispatchSavedEvent();
    return true;
  }

  private async updateMasterDocument() {
    await this.masterDocumentEditor?.post();
  }

  private dispatchSavedEvent() {
    this.dispatchCustom('saved', {
      quoteItemContainer: this.quoteItemContainer,
      branchQuoteSupport: this.branchQuoteSupport,
      masterDocument: this.masterDocument,
      quoteItemConversationLink: this.quoteItemConversationLink
    });
  }

  private async createOrUpdateBranchQuoteSupport() {
    if (!this.branchQuoteSupport) throw new DevelopmentError('ETO BranchQuoteSupport must be assigned');
    if (isEmptyOrSpace(this.branchQuoteSupport.recordVersion)) {
      return await this.assignBranchQuoteSupport();
    } else await this.updateBranchQuoteSupport();
    return true;
  }

  private async updateBranchQuoteSupport() {
    const branchSupportSubject = `${this.quoteItemContainer?.item.title} - ${this.quoteItemContainer?.item.description}`;
    if (this.branchQuoteSupport)
      if (this.branchQuoteSupport?.subject !== branchSupportSubject) {
        this.branchQuoteSupport.subject = branchSupportSubject;
        this.branchQuoteSupport = (
          await getApiFactory()
            .franchisee()
            .updateBranchQuoteSupport({
              branchQuoteId: this.quoteId,
              masterDocumentConversationId: this.masterDocument?.id ?? null,
              branchQuoteSupportId: this.branchQuoteSupportId,
              subject: branchSupportSubject,
              status: null,
              quoteItemIdToDetach: null,
              conversationEntries: null
            })
        )?.branchQuoteSupport;
      }
  }
}

async function UpdateEtoOnQuoteItems(changes: InputUpdateEtoItemsV2): Promise<void> {
  const _api = getApiFactory().quote();

  await _api.updateEtoItemsV2(changes);
}
