import { html, nothing } from 'lit';
import { until } from 'lit/directives/until.js';
import { property, state } from 'lit/decorators.js';
import { PageBase } from './helpers/page-base';
import { tlang } from '@softtech/webmodule-components';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { isEmptyOrSpace, validId } from '../../components/ui/helper-functions';
import { NullPromise } from '../../null-promise';
import { showDevelopmentError } from '../../development-error';
import { DataEntryViewController, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { DataEntryOwner } from '../../components/ui/DataEntryOwner';
import { goStaticURL } from '../../components/ui/resource-resolver';

import { ResourceLocker } from '../common/resource-lock';
import { disposeOf } from '../../components/dispose';
import { lockUIandExecute } from '../../ui-lock';

export class AppResourcePage extends PageBase implements DataEntryOwner {
  internalId: string = getInternalId();
  @state()
  protected modalEditor: ModalViewBase | null = null;
  protected lock?: ResourceLocker;
  @property({ type: Number })
  protected renderCount = 0;

  private resourceChangeEvent = async (e: Event) => {
    e.stopImmediatePropagation();
    await this.resourceRefreshing();
    await this.dispose();
    await this.allowEnter();
    await this.setActiveTabByHash();
  };

  protected async resourceRefreshing() {
    //throw new Error('Method not implemented.');
  }
  constructor() {
    super();
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('resource-changed', this.resourceChangeEvent);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('resource-changed', this.resourceChangeEvent);
  }

  protected get resourceId(): string {
    const l = this.location;
    const p = l.params as any;
    return p.id ?? '';
  }

  protected get view(): DataEntryViewController | null {
    //return this.modalEditor?.view ?? null;
    return this.modalEditor?.viewController ?? null;
  }

  private get elementId(): string {
    return `resourceeditor-${this.internalId}`;
  }

  async createModalEditor(_id: string): NullPromise<ModalViewBase> {
    throw new Error('Method not implemented.');
  }

  async allowEnter(): Promise<boolean> {
    if (!validId(this.resourceId)) return false;

    return (
      (await lockUIandExecute(async () => {
        return await this.acquireResource(this.resourceId);
      })) ?? false
    );
  }

  async tryClose(): Promise<boolean> {
    const result = (await this.modalEditor?.tryClose()) ?? true;
    if (result) {
      const url = (await this.view?.getClosePageAction())?.url ?? '/';
      goStaticURL(url);
    }
    return result;
  }

  async forceClose(): Promise<boolean> {
    const url = (await this.view?.getClosePageAction())?.url ?? '/';
    goStaticURL(url);
    return true;
  }

  async getClosePageAction() {
    return (await this.view?.getClosePageAction())?.template ?? html`${nothing}`;
  }
  protected forcedReadonly = false;
  protected forcedReadonlyMsg = '';
  render() {
    if (!this.loggedIn) return html``;
    const nameTemplate = isEmptyOrSpace(this.lock?.lockOwnerEmail)
      ? this.lock?.lockOwnerName
      : html`<a href="mailto:${this.lock?.lockOwnerEmail}">${this.lock?.lockOwnerName}</a>`;
    const isUnknownOwner = isEmptyOrSpace(this.lock?.lockOwnerName) && !this.lock?.isLockOwner;
    const lockMsg = this.forcedReadonly
      ? html`${tlang`Readonly`} ${this.forcedReadonlyMsg}`
      : this.lock?.isLockOwner
        ? tlang`Editing Mode`
        : isUnknownOwner
          ? html`${tlang`Readonly`}`
          : html`${tlang`Readonly - Currently in use by `}${nameTemplate}`;
    const lockMsgClass =
      this.lock?.isLockOwner && !this.forcedReadonly ? tlang`resource-lock-editing` : tlang`resource-lock-locked`;
    const lockedUserTemplate = html`<div class="badge resource-lock-owner ${lockMsgClass}">
      <i class="fa-solid fa-user-lock"></i>
      <span class="ms-1">${lockMsg}</span>
    </div>`;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">
          <div class="header">
            <h2 class="title">${until(this.view?.getTitle(), tlang`Loading`)}${lockedUserTemplate}</h2>
            ${until(this.getClosePageAction(), '')}
          </div>
          ${this.view?.ui}
        </div>
      </div>
    `;
  }

  async userReset() {
    await this.dispose(); //override
  }

  async dispose(): Promise<void> {
    try {
      await super.dispose();
      await disposeOf(this.view);
      this.modalEditor = null;
    } catch {
      //
    }
    try {
      await this.lock?.release();
      this.lock = undefined;
    } catch {
      //
    }
  }

  protected createLock(_id: string): ResourceLocker | undefined {
    return undefined;
  }

  protected async canLeavePage(): Promise<boolean> {
    return this.modalEditor?.canClose() ?? true;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  protected async afterUserConnected() {
    if (!this.lock) if (!(await this.allowEnter())) await this.forceClose();
  }

  private async acquireResource(id: string): Promise<boolean> {
    this.lock = this.createLock(id);
    if (!this.lock) return false;

    if (await this.lock.lock()) {
      try {
        this.modalEditor = await this.createModalEditor(id);
      } catch (error) {
        await this.lock.release();
        await showDevelopmentError(error as Error);
        return false;
      }
      if (this.view) {
        this.view.owner = this;
        this.view.onRender = async () => {
          this.renderCount++;
        };
        return true;
      } else await this.lock.release();
    }
    return false;
  }

  protected async awaken() {
    await this.setActiveTabByHash();
    await super.awaken();
  }

  private async setActiveTabByHash() {
    await this.view?.setActiveTabByHash();
  }
}
