import { tlang } from '@softtech/webmodule-components';
import { Snippet } from '@softtech/webmodule-data-contracts';
import { PropertyValues, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ViewDailyRevenue } from '../../../api/dealer-api-interface-quote';
import { localDateTimeToServer, serverDateTimeToLocalDateTime } from '../../../components/datetime-converter';
import { ChartDataXY, ChartSeriesXY } from '../data/chart-data-types';
import { DashboardWidget } from './dashboard-widget';
import { TimelineWidgetModal } from './timeline-widget-modal';
import { issuedQuotesCache } from '../data/issued-quotes-cache';
import { DateRange } from './dashboard-filter';

export const ApexCharts = (globalThis as any).ApexCharts;

@customElement('issued-quotes-revenue-widget')
export class GeneratedQuotesWidget extends DashboardWidget {
  chart: any | null = null;

  @property() dateRange?: DateRange;
  @property() branchId?: string;
  @property() userId?: string;

  private _cache = issuedQuotesCache();

  protected async refreshData(): Promise<ViewDailyRevenue[]> {
    if (this.dateRange) {
      const startDate = localDateTimeToServer(this.dateRange.startDate);
      const endDate = localDateTimeToServer(this.dateRange.endDate);
      const result = await this._cache?.getData(startDate, endDate, this.branchId, this.userId);
      if (result) {
        return result.revenueSummary;
      }
    }
    return [];
  }

  protected firstUpdated(): void {
    const options = {
      chart: {
        type: 'area',
        height: '100%',
        selection: {
          enabled: false
        },
        toolbar: {
          tools: {
            download: false,
            customIcons: [
              {
                icon: '<i class="fa fa-plus-circle fa-lg"></i>',
                index: 0,
                title: 'Zoom',
                class: 'custom-icon',
                click: (chart: any, options: any, e: any) => this.onChartClick(e, chart, options)
              }
            ]
          }
        },
        zoom: {
          enabled: false
        }
      },
      stroke: {
        width: 1
      },
      dataLabels: {
        enabled: false
      },
      legend: { show: false },
      tooltip: {
        enabled: false
      },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0
          }
        }
      },
      series: [
        {
          name: tlang`%%quote%% Revenue`,
          data: []
        }
      ],
      xaxis: {
        type: 'datetime'
      },
      yaxis: {
        labels: {
          formatter: (value: number) => this.getYAxisLabel(value)
        }
      },
      noData: {
        text: 'Loading...'
      }
    };

    this.chart = new ApexCharts(this.querySelector('.quote-issued-revenue'), options);
    this.chart?.render();
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    this.updateData();
  }

  private getTitle(): string {
    return tlang`!!quote!! Issued - ${this.dateRange?.label}`;
  }

  protected getHeader(): Snippet {
    return this.getTitle();
  }

  protected getBody(): Snippet {
    return html`<div class="quote-issued-revenue"></div>`;
  }

  protected getYAxisLabel(value: number): string {
    return `$${Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)}`;
  }

  protected async onChartClick(_event: any, _chartContext: any, config: any) {
    const series = config.config.series as ChartSeriesXY[];
    const modal = new TimelineWidgetModal(this.getTitle(), series);
    await modal.showModal();
  }

  protected updateData(): void {
    const formatDate = (s: string) => {
      let dt = serverDateTimeToLocalDateTime(s);
      dt = dt.set({ hour: 0, minute: 0 });
      return dt.toMillis();
    };

    this.refreshData().then(results => {
      const chartData: ChartDataXY[] = [];
      results.map(r =>
        chartData.push({
          x: formatDate(r.date),
          y: r.dailyRevenue
        })
      );
      this.chart.updateSeries(
        [
          {
            name: tlang`%%quote%% Revenue`,
            data: chartData
          }
        ],
        true
      );
    });
  }
}
