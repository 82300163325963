// eslint-disable-next-line import/named

import { customElement, query } from 'lit/decorators.js';
import { AppIndex, MenuItem } from '../components/app-index';
import { appConfig, appConfigMenuPageItems } from './app-config';
import { attachRouter, setRoutes } from '../components/router';
import { getApi, getApiFactory, setApiFactory, setApiInjector } from '../api/api-injector';
import { ServiceResponseInvalid, ServiceResponseType, SupplierInfo } from '@softtech/webmodule-data-contracts';
import { DealerApiCommunications, setDealerTokenProvider } from '../api/dealer-api-communications';
import { QuoteApiImpl } from '../api/quote-api-impl';
import { BlobApiImpl } from '../api/blob-api-impl';
import { DealerClientApi } from '../api/client-api-implementation';
import { ProjectApiImplementation } from '../api/project-api-implementation';
import { DealerFranchiseeApi } from '../api/franchisee-api-implementation';
import { PaymentProfileApiImplementation } from '../api/payment-profile-api-implementation';
import { iconRegistry } from '../components/ui/icons/register-icons';
import { PurchaseOrderApiImplementation } from '../api/purchase-order-api-implementation';
import { userDataStore } from './common/current-user-data-store';
import { setErrorDialogEventHandler, showError } from '../components/ui/show-error';
import {
  registerComponents,
  setModalScreenFactory,
  registerIconLibrary,
  strUserConnectFailed,
  tlang,
  addCurrentUserEventListener,
  getCurrentUser,
  setAfterApplyUserEvent,
  UserPublicInfo,
  userSecurity,
  claimIdentity,
  setUserClaims,
  removeCurrentUserEventListener
} from '@softtech/webmodule-components';
import { getQuoteSuppliers, QuoteSupplier, setQuoteSupplierProviderCallback } from '../quotes/quote-service';
import {
  addV6BootLoaderEvent,
  clearDealerVersion,
  getQuoteSupplierFromApi,
  v6BootLoader,
  v6BootLoaderVersionCheck,
  v6Config,
  v6ConfigActiveVersionStr,
  v6SupportsVersion,
  v6VersionMap
} from '../v6config/v6config';
import { DevelopmentError, showDevelopmentError } from '../development-error';
import { setBeforeConstructionEvent } from '../async-constructor';
import { runGlobalInit } from '../components/app-global-init';
import { setCacheRegistry } from './cache/cache-registry';
import { ClientCache } from './cache/client-cache';
import { ContactReferenceCache } from './cache/contact-reference-cache';
import { PaymentProfileCache } from './cache/payment-profile-cache';
import { ProjectCache, ProjectPermanentDocumentCache } from './cache/project-cache';
import { QuoteCache } from './cache/quote-cache';
import { ResourcesCache } from './cache/resources-cache';
import { UserProfileCache } from './cache/user-profile-cache';
import { PurchaseOrderCache } from './cache/purchase-order-cache';
import { ClientPrimaryContactCache } from './cache/client-primarycontact-cache';
import { ProjectResourceLinkCache } from './cache/project-resource-link';
import {
  setQuoteProviderDataFactory,
  setQuoteProviderDataValidateAndUpgradeEvent
} from '../quotes/data/quote-provider-data';
import {
  createFranchiseeQuoteProviderData,
  validateAndUpdateFranchiseeQuoteProviderData
} from './quotes/data/franchisee-quote-provider-data';
import { displayAllError } from '../components/ui/modal-errorhandler';
import { EventSnippet } from '../components/ui/events';
import { SupplierApiImplementation } from '../api/supplier-api-implementation';
import { html, TemplateResult } from 'lit';

import { DealerClientAgentApi } from '../api/clientagent-api-implementation';
import { getUserLock } from './common/optimistic-user-lock';
import { Branch, ConnectedState } from '../api/dealer-api-interface-franchisee';

import { information } from '../components/ui/modal-option';

import { NFRCStandardv1Calculation } from '../nfrc/nfrc-standard-v1';
import { addURLResolvers, goStaticURL, ResolveURLEntry } from '../components/ui/resource-resolver';
import { displaySupplierTACNotApprovedMsg, supplierOnline } from '../v6config/supplier-offline';
import { emptyGuid } from '../api/guid';
import { QuoteStateChangeReasonCache } from './cache/quote-state-change-reason-cache';
import { addOrReplaceNFRCCalculation } from '../nfrc/nfrc-calculation';
import { ProjectStateChangeReasonCache } from './cache/project-state-change-reason-cache';

import { PurchaseOrderStateChangeReasonCache } from './cache/purchase-order-state-change-reason-cache';
import { modalScreenFactoryImpl } from '../components/ui/modal-factory';
import {
  clearCurrentUserFromSession,
  saveCurrentUserIntoSession,
  setUserReloadEvent,
  verifySession
} from '../components/user-session-verifier';
import { webcomponentRegistry } from '../components/ui/webcomponent-registry';
import { registerComponentsLocal } from '../components/ui/templatecontrols/component-registry';
import { franchiseeComponentRegistry } from './componentRegistry';
import { setSupplierInformationCache } from '../quotes/data/quoteSupplierProvider';
import { getPageController } from './pages/helpers/page-base';
import { QuoteItemConversationCache } from './cache/quoteitemconversation-cache';
import { lockUIandExecute } from '../ui-lock';
import { currentUserClaims } from '../components/currentuser-claims';
import { SupplierTermsAndConditionsModal } from './supplier/terms-and-conditions-modal';
import { UserApiImplementation } from '../api/user-api-implementation';
import { footerBrandLogoUrl, isTestSite } from '../components/domain';
import { responseHandler } from '../api/api-response-handler';

let currentSupplierNames = '';
let _v6SupplierVersion = '';
let _v6FrameConfigVersion = '';

function reload() {
  //if this flag exists then we have forced a logoff as part of a PAT login process, so we do not want to redirect or do anything
  if (sessionStorage.getItem('dealer-pat-login')) return;

  // Take us to the home page. this will clear any validations
  // or other issues
  // the home page has code to trigger login if required and
  // page reloading
  goStaticURL('/login');
}
async function connectUserAfterLogin() {
  const reset = async () => {
    userDataStore.clear();

    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    reload();
  };
  const user = getCurrentUser();
  if (user !== null) {
    if (!(await verifySession())) {
      await reset();
      return;
    }

    //DO NOT MAKE ANY CALLS THAT NEED AUTHORIZATION BEFORE CONNECTING THE USER
    //Make any api calls or anything else here that are necessary to be used for the current user
    try {
      /* //loadcoredetails will do this
            const valid =
                await DealerApiCommunications.verifyUserAndSetSecurityClaims(
                    'api/LicenseServer/verifyuser'
                );
*/
      const state = await getApi().post<ConnectedState>('api/Franchisee/ConnectUser', {});
      if (!state?.connected) throw new Error(strUserConnectFailed);

      //this will update the user claims security before we rebind the router.
      await userDataStore.loadCoreDetailsAfterLogin();
      saveCurrentUserIntoSession();
    } catch (e) {
      await showDevelopmentError(e as Error);
      await reset();
    }
    await reloadSuppliersAfterLogin();
  } else {
    clearCurrentUserFromSession();
    localStorage.removeItem('PAT-in-use');
    userDataStore.clear();
    reload();
    //we always want to login again
  }
}
function rebindRouter() {
  const config = appConfig();
  setRoutes(config.routes);
  addURLResolvers(
    config.routes
      .filter(x => x.resolveUrl !== undefined)
      .map(x => {
        return x.resolveUrl as ResolveURLEntry;
      })
  );
}

// this is our concrete top level application, everything else should be generic
@customElement('franchisee-app-index')
export class FranchiseeAppIndex extends AppIndex {
  // Do not remove below line as this will stop icons from working on our site.
  protected icons = iconRegistry;
  protected webcomponents = webcomponentRegistry;
  protected franchiseeComponents = franchiseeComponentRegistry;
  @query('#main-body')
  private mainBody!: HTMLElement;

  async userStateChanged(_user: UserPublicInfo | null) {
    if (getCurrentUser() !== null) {
      await this.updateUIAspectsAfterLogin();
    } else this.userDisplayName = '';
  }
  constructor() {
    super();
    rebindRouter();

    registerIconLibrary('system', {
      resolver: name => `/assets/icons/${name}.svg`
    });

    registerIconLibrary('bootstrap', {
      resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/icons/${name}.svg`
    });

    registerIconLibrary('fa', {
      resolver: name => {
        const filename = name.replace(/^fa[rbs]-/, '');
        let folder = 'regular';
        if (name.substring(0, 4) === 'fas-') folder = 'solid';
        if (name.substring(0, 4) === 'fab-') folder = 'brands';
        return `https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.5.2/svgs/${folder}/${filename}.svg`;
      },
      mutator: svg => svg.setAttribute('fill', 'currentColor')
    });

    this.userDisplayName = getCurrentUser()?.friendlyName;
  }
  generateBuildNumberText(): TemplateResult {
    const copyEvent = async () => {
      function parts(val: string[], from, count: number): string {
        //Supplier Ver "10.0.13.31.30.380481012"
        return val.slice(from, from + count).join('.');
      }

      function supplierDetails(suppinfo: SupplierInfo) {
        const nums = suppinfo.version.split('.');
        return `
### ${tlang`%%supplier%% "${suppinfo.description}`}"

+ ${tlang`Macros "${parts(nums, 0, 3)}`}"
+ ${tlang`DB Instance Version "${parts(nums, 3, 1)}`}"
+ ${tlang`DB Pricing Version "${parts(nums, 4, 1)}`}"
+ ${tlang`V6"${parts(nums, 5, 1)}`}"

------------------------------------------------------

            `;
      }
      const v6Suppliers = (await v6Config().suppliers(true)) ?? [];
      const text = `# Dealer's Module (SoftTech dealer CPQ)
      
+ ${tlang`Location`} "${window.location.hostname}"     
+ ${tlang`Dealer Build "${this.buildNumber}`}"
+ ${tlang`%%frame%% Config"${_v6FrameConfigVersion}`}"

## Supplier Details
${v6Suppliers.map(x => supplierDetails(x)).join('\n')}

            `;
      navigator?.clipboard?.writeText(text);
      await information(text, tlang`Copied to clipboard`);
      //fireQuickInformationToast(tlang`Version Details have been copied to the clipboard`, 6000);
    };

    function labelSpan(label: string, value: string) {
      return html`<span class="fw-bolder me-2">${label}</span><span class="me-2">${value}</span>`;
    }
    return html`${labelSpan(
        tlang`Build`,
        `${this.buildNumber ?? ''} | ${_v6SupplierVersion} | ${_v6FrameConfigVersion} `
      )} <span class="ms-2 me-2"><icon-copy @click=${copyEvent}></icon-copy></span>`;
  }
  connectedCallback(): void {
    super.connectedCallback();
    addCurrentUserEventListener(this);
    setUserReloadEvent(async () => {
      const user = getCurrentUser();
      if (user) {
        await userDataStore.reLoadFranchisee();
        await this.updateUIAspectsAfterLogin();
      } else {
        userDataStore.clear();
      }

      this.requestUpdate();
    });
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    removeCurrentUserEventListener(this);
  }
  private async updateUIAspectsAfterLogin() {
    await userDataStore.loadCoreDetailsAfterLogin();
    this.updateLogoTemplate();
    this.userDisplayName = getCurrentUser()?.friendlyName;
    this.requestUpdate();
  }
  userNameMenuContent(): unknown {
    return html`<i class="fa-solid fa-user me-1"></i>${this.userDisplayName?.trim()}`;
  }

  aboveUserNameTemplate(): unknown {
    const branchName = userDataStore.loaded ? userDataStore.defaultBranch.name.trim() : '';

    const menu = userDataStore.loaded
      ? html`
          <div class="dropdown current-user">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="current-user-menu-link"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="fa-solid fa-building me-1"></i>${branchName}</a
            >
            <ul class="dropdown-menu" aria-labelledby="current-user-menu-link">
              ${this.customBranchMenuElements()}
            </ul>
          </div>
        `
      : html``;

    return menu;
  }
  supplierFooterTemplate(): TemplateResult {
    return isTestSite()
      ? html`<h1>TEST ENVIRONMENT</h1>`
      : html`<img src="${footerBrandLogoUrl()}" alt="Supplier Branded logo" />`;
  }
  globalSupportInformation(): string | TemplateResult {
    const cu = getCurrentUser();
    const cc = currentUserClaims();
    if (cc.isCynclyStaff) {
      return cc.isAgent // cyncly staff can log in as a dealer or supplier depending on support needed
        ? `CYNCLY Support AS SUPPLIER connected to "${
            (cu as any).tenantName
          }", Using Supplier(s) ${currentSupplierNames}`
        : `CYNCLY Support AS DEALER connected to "${(cu as any).tenantName}", Using Supplier ${currentSupplierNames}`;
    } else if (cc.isAgent) {
      return tlang`%%Supplier%% Support Connected to "${(cu as any).tenantName}"`;
    }
    return '';
  }
  customUserMenuElements(): TemplateResult {
    return html``;
  }
  customBranchMenuElements(): TemplateResult {
    const doNothing = (e: Event) => {
      e.stopImmediatePropagation();
      e.preventDefault();
    };
    const switchBranchEvent = (branch: Branch) => () => {
      this.changeBranch(branch);
    };
    const branchTemplate = (branch: Branch) => {
      const isSelected = branch.id === userDataStore.defaultBranch.id;
      const iconClass = isSelected ? 'fa-solid fa-caret-right text-primary fa-xl' : 'fa-solid fa-caret-right fa-xl';
      const spanClass = isSelected ? 'dropdown-item fw-bolder' : 'dropdown-item';
      return html`
        <li>
          <span @click=${switchBranchEvent(branch)} class=${spanClass}><i class=${iconClass}></i> ${branch.name}</span>
        </li>
      `;
    };
    const switchBranchTemplate = () => {
      return userDataStore
        .getUserBranches()

        .map(x => branchTemplate(x));
    };
    if (!getCurrentUser() || !userDataStore.loaded) return html``;
    if (userSecurity().claimIsTrue(claimIdentity.allBranches) && userDataStore.getUserBranches().length > 1) {
      return html`
        <li>
          <h1 class="dropdown-item">${tlang`!!branch!!`}</h1>
        </li>
        ${switchBranchTemplate()}
        <li class="divider m-1"></li>
      `;
    } else
      return html`<li>
          <h1 class="dropdown-item">${tlang`!!branch!!`}</h1>
        </li>
        <li>
          <span @click=${doNothing} class="dropdown-item"
            ><i class="fa-solid fa-caret-right text-primary"></i>
            ${userDataStore.defaultBranch.name}
          </span>
        </li>
        <li class="divider m-1"></li>`;
  }
  async changeBranch(branch: Branch) {
    await lockUIandExecute(async () => {
      if (!(await getPageController().pagesCanClose())) return;
      userDataStore.defaultBranch = branch;
      await userDataStore.setV6OverrideKey();

      //the line below is executed in the line above
      //await getQuoteSuppliers(true);
      await getPageController().disposeAndRefresh();
      this.requestUpdate();
      //goStaticURL('');
    });
  }
  async disconnectUser() {
    await getPageController().disposeAndRefresh();
    try {
      await getApi().post<ConnectedState>('api/Franchisee/DisconnectUser', {});
      setUserClaims({});
    } catch {
      console.log(`Error disconnecting user`);
    }
    return true;
  }
  protected getMenuItems(): Array<MenuItem> {
    return appConfigMenuPageItems();
  }

  protected firstUpdated() {
    attachRouter(this.mainBody);
    let interval;
    const verifyUser = async () => {
      clearInterval(interval);
      await verifySession();
      interval = setInterval(verifyUser, 500);
    };
    interval = setInterval(verifyUser, 500);
    if (isTestSite() && !document.body.classList.contains('test-only-ui')) document.body.classList.add('test-only-ui');
  }

  public updateLogoTemplate() {
    const api = getApi();
    const logoURl = isTestSite()
      ? api.fullUrl(`api/file/${userDataStore.franchisee.negativeLogoVirtualPath}`)
      : api.fullUrl(`api/file/${userDataStore.franchisee.positiveLogoVirtualPath}`);
    this.logoTemplate = userDataStore.franchisee.positiveLogoVirtualPath
      ? html`<img src=${logoURl} crossorigin="anonymous" class="img-fluid" alt="Brand Image" width="90" />`
      : html` <svg width="1" height="1"></svg>`;
  }
}

async function processSupplierTAC(suppliers: QuoteSupplier[]) {
  for (let i = 0; i < suppliers.length; i++) {
    const supplier = suppliers[i];
    const tacApproved = userDataStore.supplierTACApproved(supplier.supplierId);
    if (!tacApproved) {
      const claims = currentUserClaims();
      if (claims.isAgent || claims.isCynclyStaff || claims.trueClaim('non-tenant-user'))
        await displaySupplierTACNotApprovedMsg(supplier);
      else await approveTACforSupplier(supplier);
    }
  }
}

async function reloadSuppliersAfterLogin() {
  clearDealerVersion();
  await v6BootLoader();
  applyQuoteSupplierProviderCallback();
  const suppliers = await getQuoteSuppliers(true);
  await processSupplierTAC(suppliers);
  currentSupplierNames = suppliers.map(x => `[${x.description}]`).join(', ');
}
function applyQuoteSupplierProviderCallback() {
  let _suppliers: QuoteSupplier[] | null = null;
  setQuoteSupplierProviderCallback(async (refreshList: boolean) => {
    const bootloader = await v6BootLoader();
    if (bootloader) {
      const v6Suppliers = (await v6Config().suppliers(refreshList)) ?? [];
      _v6SupplierVersion = v6Suppliers.length === 1 ? v6Suppliers[0].version : '';
      _v6FrameConfigVersion = v6ConfigActiveVersionStr();
      const firstSupplier = v6Suppliers[0];
      if (v6SupportsVersion(v6VersionMap.hasInstanceIDInSettings)) {
        await v6BootLoaderVersionCheck(firstSupplier);
      }
      if (v6Suppliers.find(x => !x.online) === undefined) supplierOnline();
      return v6Suppliers.map(s => {
        const qs: QuoteSupplier = {
          supplierId: s.id,
          description: s.description,
          online: s.online
        };
        return qs;
      });
    } else {
      if (_suppliers !== null && !refreshList) return _suppliers;
        _suppliers = (await getQuoteSupplierFromApi()) ?? [];
        if (_suppliers.find(x => !x.online) === undefined) supplierOnline();
      return _suppliers;
    }
  });
}
const afterV6BootLoader = async (): Promise<void> => {
  if (!userDataStore.loaded) throw new DevelopmentError('cant perform bootloading without user data');
  await userDataStore.setV6OverrideKey();
  await getQuoteSuppliers(true);
};
(function () {
  globalThis.dealerPrepUserData = async updateNote => {
    try {
      updateNote('Initialize Frame Configuration Service');
      addV6BootLoaderEvent(afterV6BootLoader);
      await v6BootLoader();
      applyQuoteSupplierProviderCallback();
      //we want to enforce that the system the user connects to is fully up to date. this is a
      //simple call to the v6 systems to check their version, and will ensure the system resets info
      //as needed. This is also required for the quotes page, so we know if we are a multi supplier view or not
      updateNote('Fetching Supplier Information');
      if (userDataStore.loaded) {
        const suppliers = await getQuoteSuppliers(true);
        await processSupplierTAC(suppliers);
        currentSupplierNames = suppliers.map(x => `[${x.description}]`).join(', ');
      }
    } catch {
      console.log('could not load suppliers');
    }
  };

  globalThis.dealerBuildApplicationBindings = async () => {
    registerComponents();
    registerComponentsLocal();
    setModalScreenFactory(modalScreenFactoryImpl);
    setAfterApplyUserEvent(connectUserAfterLogin);

    setSupplierInformationCache({
      getSupplierDisplayName: async (supplierId: string) => {
        const suppliers = await getQuoteSuppliers(false);
        return suppliers.find(x => x.supplierId === supplierId)?.description ?? tlang`%%supplier%%`;
      }
    });
    setDealerTokenProvider(() => getUserLock());

    const errorDialogEventHandler = async (item: ServiceResponseInvalid | Error, title: EventSnippet) =>
      await displayAllError(title, item);
    setErrorDialogEventHandler(errorDialogEventHandler);

    //Bind data provider information inot the generic
    setQuoteProviderDataFactory(createFranchiseeQuoteProviderData);
    setQuoteProviderDataValidateAndUpgradeEvent(validateAndUpdateFranchiseeQuoteProviderData);

    addOrReplaceNFRCCalculation(new NFRCStandardv1Calculation());
    //any class using the async construct pattern will be assurred of a valid login
    //before the afterconstruction is called.
    //the new workflow does not require this anymore
    setBeforeConstructionEvent(async () => await userDataStore.loadCoreDetails());

    setApiFactory({
      quote: () => new QuoteApiImpl(getApi()),
      blob: () => new BlobApiImpl(getApi()),
      client: () => new DealerClientApi(getApi()),
      project: () => new ProjectApiImplementation(getApi()),
      franchisee: () => new DealerFranchiseeApi(getApi()),
      paymentProfile: () => new PaymentProfileApiImplementation(getApi()),
      purchaseOrder: () => new PurchaseOrderApiImplementation(getApi()),
      supplier: () => new SupplierApiImplementation(getApi()),
      clientAgent: () => new DealerClientAgentApi(getApi()),
      user: () => new UserApiImplementation(getApi())
    });

    let _commSingleton: DealerApiCommunications | undefined;
    const apiInjecterEvent = () => {
      if (!_commSingleton)
        _commSingleton = new DealerApiCommunications('', responseHandler, () => {
          //Redirect to home page, next query will force a login to occur
          window.location.href = '/login';
        });
      return _commSingleton;
    };

    //Dependency inject an api for the entire application
    setApiInjector(apiInjecterEvent);
    setCacheRegistry(() => {
      const api = getApi();
      return {
        client: new ClientCache(api),
        quoteItemConversation: new QuoteItemConversationCache(api),
        contact: new ContactReferenceCache(api),
        primaryContact: new ClientPrimaryContactCache(api),
        paymentProfile: new PaymentProfileCache(api),
        project: new ProjectCache(api),
        projectPermanentDocuments: new ProjectPermanentDocumentCache(api),
        quote: new QuoteCache(api),
        resource: new ResourcesCache(api),
        userProfile: new UserProfileCache(api),
        purchaseOrder: new PurchaseOrderCache(api),
        projectResourceLink: new ProjectResourceLinkCache(api),
        quoteStateReason: new QuoteStateChangeReasonCache(api),
        projectStateReason: new ProjectStateChangeReasonCache(api),
        purchaseOrderStateReason: new PurchaseOrderStateChangeReasonCache(api)
      };
    });

    setQuoteSupplierProviderCallback(async (_refreshList: boolean) => {
      return [
        {
          supplierId: emptyGuid,
          description: tlang`Loading`,
          online: false
        }
      ];
    });

    window.addEventListener('unhandledrejection', function (event) {
      if (event.reason.message.includes('Vaadin')) {
        return;
      }
      console.error(event.reason.stack);
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
      showError(
        {
          responseType: ServiceResponseType.Error,
          responseTypeCaption: tlang`unhandled error`,
          responseError: {
            message: event.reason.message,
            stackTrace: event.reason.stackTrace
          }
        },
        () => tlang`Unhandled Error inside a promise occurred`
      );
    });

    runGlobalInit();
  };
})();

async function approveTACforSupplier(supplier: QuoteSupplier) {
  const r = await getApiFactory().supplier().getSupplierTAC({ supplierId: supplier.supplierId });
  if (!r) {
    await information(tlang`The %%supplier%% (${supplier.description}) Terms and Conditions cannot be retrieved at this time. Please try refreshing this page. 
        Contact support if no progress is made.`);
    return false;
  }
  const modal = new SupplierTermsAndConditionsModal(supplier, r?.supplierTermsAndConditionsOfUse);
  await modal.showModal();
  return modal.ok;
}
