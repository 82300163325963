import { css, html, LitElement, nothing } from "lit";
import { state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { customElement } from "lit/decorators.js";
import { WebmoduleIcon } from "@softtech/webmodule-components";

declare global {
  interface DocumentEventMap {
    'webmodule-auto-save': CustomEvent<AutoSaveNotification>;
  }
}

export class AutoSaveNotifier {
  private static instance: AutoSaveNotifier;

  private constructor() { }

  public static getInstance(): AutoSaveNotifier {
    if (!AutoSaveNotifier.instance) {
      AutoSaveNotifier.instance = new AutoSaveNotifier();
    }
    return AutoSaveNotifier.instance;
  }

  public triggerAutoSave(type: "busy" | "success" | "error", timeout = 2000) {
    const event = new CustomEvent<AutoSaveNotification>('webmodule-auto-save', {
      detail: {
        type: type,
        timeout: timeout
      },
      bubbles: true,
      composed: true
    });
    document.dispatchEvent(event);
  }

  public triggerAutoSaveBusy() {
    this.triggerAutoSave("busy");
  }

  public triggerAutoSaveSuccess() {
    this.triggerAutoSave("success");
  }

  public triggerAutoSaveError() {
    this.triggerAutoSave("error", 2000);
  }
}

export const autoSaveNotifier = AutoSaveNotifier.getInstance();

export interface AutoSaveNotification
{
  type: "busy" | "success" | "error" | null;
  timeout?:number;
}

//TODO: Once styling is done, move to lit components repo
@customElement('webmodule-auto-save-indicator')
export class WebmoduleAutoSaveIndicator extends LitElement {
  static styles = css`
    :host {
      display: inline-block;
      position: relative;
    }

    .indicator {
      display: none;
      font-size: 18px;
    }

    .indicator--visible {
      display: inline-block;
    }

    .indicator--busy{
      color: var(--bs-info);
    }
    
    .indicator--success{
      color: var(--bs-success);
    }

    .indicator--danger{
      color: var(--bs-danger);
    }
  `;

  static dependencies = {
    'webmodule-icon': WebmoduleIcon
  }

  @state()
  private _visible = false;

  @state()
  private _type: "busy" | "success" | "error" | null;

  constructor() {
    super();
    this._visible = false;
    this._type = "success"
  }

  //TODO: Should we respond to the event here, or rather have the parent element set properties to render.
  connectedCallback() {
    super.connectedCallback();
    document.addEventListener('webmodule-auto-save', this.handleAutoSave.bind(this));
  }

  disconnectedCallback() {
    document.removeEventListener('webmodule-auto-save', this.handleAutoSave.bind(this));
    super.disconnectedCallback();
  }

  handleAutoSave(event: CustomEvent<AutoSaveNotification>)  {
    this._type = event.detail.type;
    this._visible = true;

    const timeout = event.detail.timeout;

    setTimeout(() => {
      this._visible = false;
    }, timeout ?? 1000);
  }

  render() {
    let icon = html`${nothing}`;

    switch (this._type) {
      case 'busy':
        icon = html`<webmodule-icon library="system" name="saving-in-progress"></webmodule-icon>`;
        break;
      case 'success':
        icon = html`<webmodule-icon library="system" name="saved"></webmodule-icon>`;
        break;
      case 'error':
        icon = html`<webmodule-icon library="system" name="error-save"></webmodule-icon>`;
        break;
    }

    return html`
      <div class="${classMap({
        indicator: true,
        'indicator--visible': this._visible,
        'indicator--busy': this._type === 'busy',
        'indicator--success': this._type === 'success',
        'indicator--danger': this._type === 'error'
      })}">
        ${icon}
      </div>
    `;
  }
}