import { tlang } from '@softtech/webmodule-components';
import { AskConfirmation, buttonsContinueCancel } from '../../../../components/ui/modal-confirmation';
import { QuoteItemFrameViewForV6 } from '../../../../quotes/v6/v6-quote-item-frame-view';
import { V6FranchiseeQuoteProviderData } from '../../data/franchisee-quote-provider-data';
import { clone, cloneOrUndefined, compare } from '../../../../components/clone';
import { QuoteItemViewOptions } from '../../../../quotes/views/quote-item-view';
import { QuoteItemProviderData } from '../../../../api/dealer-api-interface-quote';
import { V6PickerFamilyButton } from '@softtech/webmodule-data-contracts';
import { DevelopmentError } from '../../../../development-error';

export class FranchiseeQuoteItemFrameViewForV6 extends QuoteItemFrameViewForV6 {
  private readonly originalFrameData?: QuoteItemProviderData;

  constructor(options: QuoteItemViewOptions, supplierSelector?: unknown) {
    super(options);

    this.originalFrameData = cloneOrUndefined(options.quoteItemContainer?.data);
    if (supplierSelector) {
      if (supplierSelector['family'] == undefined)
        throw new DevelopmentError(`${JSON.stringify(supplierSelector)} is not a valid V6PickerFamilyButton`);
      this.framePicker = clone(supplierSelector as V6PickerFamilyButton);
    }
  }

  protected getQuoteIGUs(): unknown {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.igu ?? [];
  }

  protected getQuoteDefaults(): unknown {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.defaultOptions ?? [];
  }

  protected async canSaveQuoteItem(): Promise<boolean> {
    if (this.quoteItemContainer) {
      if (this.quoteItemContainer.price.supplierPriceAdjustment == 0) return true;

      if (compare(this.quoteItemContainer.data, this.originalFrameData)) return true;

      const result = await AskConfirmation(
        tlang`${'ref:quote-item-discard-supplier-price-adjustment'}
          ## This item has a %%supplier%% price adjustment applied.
  
          If you edit the frame design this price adjustment will be discarded and you 
          will need to apply for a new price review through a Support Request ticket.`,
        buttonsContinueCancel(),
        undefined,
        tlang`%%supplier%% Price Adjustment`
      );

      if (result) {
        this.quoteItemContainer.price.supplierPriceAdjustment = 0;
        return true;
      } else return false;
    }
    return true;
  }
}
