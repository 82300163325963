// noinspection HtmlUnknownAttribute
// eslint-disable-next-line import/named
import { TemplateResult, html } from 'lit';
import { MenuIconOption, PageControl, PageControlOptions, PageManager } from '../../components/ui/page-control';
import { EventSnippet, PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { PaymentProfileDetailView } from './payment-profile-detail-view';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { DataEntryPageControlView, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { PaymentProfileContainer, PaymentProfileContainerManager } from '../data/payment-profile-container-manager';
import { constructAsync } from '../../async-constructor';
import { allowPageControlTabChange, isAutoSaving } from '../../components/save-workflow';
import { validId } from '../../components/ui/helper-functions';
import { buttonsSaveCancel } from '../../components/ui/modal-confirmation';

export interface PaymentProfileViewOptions {
  paymentProfile: PaymentProfileContainer;
  title: EventSnippet;
}

export function getMarginLabel(min = 0, max = 100)
{
  return min != 0 || max != 100
      ? tlang`Margin (${min}% - ${max}%)`
      : tlang`Margin (%)`;
}

export class PaymentProfileDataEntryView extends DataEntryPageControlView {
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  paymentProfile: PaymentProfileContainer;
  paymentProfileManager: PaymentProfileContainerManager;
  protected readonly title: EventSnippet;
  protected detailView: PaymentProfileDetailView | null = null;

  constructor(options: PaymentProfileViewOptions) {
    super();
    this.title = options.title;

    this.paymentProfile = options.paymentProfile;
    this.paymentProfileManager = this.paymentProfileContainerManagerFactory();
  }

  /**
   * inherited;
   * @returns
   */
  protected async bodyTemplate(): PromiseTemplate {
    this.buildPaymentProfileActionMenu();
    return super.bodyTemplate();
  }

  /**
   * inherited;
   * @returns
   */
  public async afterConstruction() {
    await this.paymentProfileManager.needsPaymentProfile();
    this.detailView = await this.paymentProfileDetailViewFactory();

    await this.detailView.render();

    //this will create the pagecontrol
    await super.afterConstruction();
    this.buildPaymentProfileActionMenu();
  }

  createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      if (this.detailView) pages.push(this.detailView.createPageManager());
      return pages;
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  /**
   * inherited;
   * @returns
   */
  public internalDataChanged(): boolean {
    return this.paymentProfileManager.changed();
  }

  /**
   * inherited;
   * @returns
   */
  protected async internalSaveData(): Promise<boolean> {
    return await this.paymentProfileManager.save(isAutoSaving());
  }
  protected isNew(): boolean {
    return !validId(this.paymentProfileManager.container.paymentProfileId);
  }
  /**
   * inherited;
   * @returns
   */
  public isReadonly(): boolean {
    return this.paymentProfileManager.isReadonly();
  }

  /**
   * inherited;
   * @returns
   */
  protected getValidationErrors() {
    const errors = this.detailView?.getValidationErrors() ?? [];
    return errors;
  }

  /**
   * inherited;
   * @returns
   */
  public async prepareForSave(): Promise<void> {
    if (this.isReadonly()) return;

    this.detailView?.prepareForSave();
  }

  /**
   * inherited;
   * @returns
   */
  public async getTitle(): PromiseSnippet {
    return this.title();
  }

  /**
   * inherited;
   * @returns
   */
  async allowPageSwitch(): Promise<boolean> {
    return await allowPageControlTabChange(this.getAutoSavePromptOptions());
  }

  protected async paymentProfileDetailViewFactory() {
    return await constructAsync(
      new PaymentProfileDetailView(this, {
        paymentProfileManager: this.paymentProfileManager
      })
    );
  }

  protected paymentProfileContainerManagerFactory(): PaymentProfileContainerManager {
    return new PaymentProfileContainerManager(this.paymentProfile, this.title, this.paymentProfileApi);
  }

  private buildPaymentProfileActionMenu() {
    const menuIcons: MenuIconOption[] = [];

    const save = {
      event: async () => await this.saveAndClose(),
      caption: () => tlang`Save %%payment-profile%%`
    };

    if (!isAutoSaving()) menuIcons.push(save);

    this.pageControl.setMenuIcons(menuIcons);
  }

  public async saveAndClose(): Promise<boolean> {
    if (this.isReadonly()) return false;

    const result = await this.performAutoSave();
    if (result) {
      return this.tryClose();
    }
    return false;
  }
}

export class PaymentProfileView extends ModalViewBase {
  view: PaymentProfileDataEntryView | null = null;
  options: PaymentProfileViewOptions;

  constructor(options: PaymentProfileViewOptions) {
    super();
    this.options = options;
  }
  /**
   * inherited
   * @returns
   */
  protected async getTitle(): PromiseSnippet {
    return this.view?.getTitle() ?? '';
  }
  /**
   * inherited
   * @returns
   */
  protected modalSize(): string {
    return 'modal-lg';
  }
  /**
   * inherited
   * @returns
   */
  protected footerTemplate(): TemplateResult | null {
    var buttons = buttonsSaveCancel();
    return this.createConfirmCancelButtons(buttons.ok, () => this.view?.saveAndClose(), buttons.cancel);
  }

  /**
   * inherited
   * @returns
   */
  async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }
  protected createView(): PaymentProfileDataEntryView {
    return new PaymentProfileDataEntryView(this.options);
  }
  async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
    this.view.onRender = async () => await this.render();
    await this.view.render();
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html`${this.view?.ui}`;
  }
  protected renderCloseButtonTemplate(): boolean {
    return false;
  }
}
