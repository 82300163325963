// eslint-disable-next-line import/named
import { html } from 'lit';
import {
  CurrencyTypeOption,
  FranchiseeConfiguration,
  InputUpdateFranchiseeConfiguration,
  TaxRate
} from '../../api/dealer-api-interface-franchisee';
import { fireQuickSuccessToast } from '../../toast-away';
import { showDevelopmentError } from '../../development-error';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { DataTracker, DynamicValueBinder, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { getCurrentUser, tlang, userSecurity } from '@softtech/webmodule-components';
import { DataProvider, ProviderBackup } from '../../components/clone';
import { isAutoSaving } from '../../components/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { localDateTimeToServer } from '../../components/datetime-converter';
import { emptyGuid, newGuid } from '../../api/guid';

export class FranchiseeConfigurationView extends PageControlTabWithIndependantSaving {
  private backup: ProviderBackup<InputUpdateFranchiseeConfiguration>;

  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private maxTaxRowCount: number;

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  public isReadonly(): boolean {
    return !this.isAdmin;
  }

  constructor(franchiseeConfiguration: DataProvider<InputUpdateFranchiseeConfiguration>) {
    super();
    this.pageFragment = 'configuration';
    this.backup = new ProviderBackup<InputUpdateFranchiseeConfiguration>(franchiseeConfiguration);

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataBinding.allowMissingElements = true; // skip taxes
    this.dataTracker = new DataTracker(this.dataBinding);

    this.maxTaxRowCount = this.taxRates.length;

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.backup.item.franchiseeConfiguration),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('unitType', FieldType.int, false);
    addField('currencyTypeOption', FieldType.int, false);

    for (let irate = 0; irate < this.maxTaxRowCount; irate++) this.addTaxRate(irate);
  }

  get config(): FranchiseeConfiguration {
    return this.backup.item.franchiseeConfiguration;
  }
  get taxRates(): TaxRate[] {
    return this.backup.item.taxRates;
  }

  addTaxRate(index: number) {
    const fieldName = `taxRate_name_${index}`;
    const fieldRate = `taxRate_rate_${index}`;
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.float,
        false,
        () => {
          if (index >= this.taxRates.length) return 0;
          return this.taxRates[index].rate;
        },
        value => {
          if (index >= this.taxRates.length) return;
          this.taxRates[index].rate = (value as number) ?? 0;
        },
        () => false
      ),
      fieldRate,
      fieldRate,
      FieldType.float,
      false
    );
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.string,
        false,
        () => {
          if (index >= this.taxRates.length) return '';
          return this.taxRates[index].name;
        },
        value => {
          if (index >= this.taxRates.length) return;
          this.taxRates[index].name = (value as string) ?? '';
        },
        () => false
      ),
      fieldName,
      fieldName,
      FieldType.string,
      false
    );
  }

  validateTaxRate(taxRate: TaxRate, index: number, errors: string[]) {
    if (isEmptyOrSpace(taxRate.name)) errors.push(tlang`Please enter a %%tax%% Name in position ${index + 1}`);

    if (taxRate.rate < 0) errors.push(tlang`Please provide a non negative %%tax%% Rate on "${taxRate.name}"`);
  }
  getValidationErrors(): string[] {
    const errors: string[] = [];

    if (!this.config) {
      errors.push('FranchiseeConfiguration is null');
      return errors;
    }

    const currencyTypeOption = this.config.currencyTypeOption;
    const quoteType = this.config.quoteType;

    if (!currencyTypeOption) errors.push(tlang`Please select a Currency`);

    if (!quoteType) errors.push(tlang`Please select a %%quote%% Type`);

    this.taxRates.forEach((rate, index) => this.validateTaxRate(rate, index, errors));
    return errors;
  }
  public async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }
  public internalDataChanged(): boolean {
    return this.backup.changed;
  }

  public resetEditControls() {
    if (this.dataTracker.modified) {
      this.dataTracker.resetEditorValue();
    }
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('Saving Franchisee Configuration');
    if (!this.config) {
      await showDevelopmentError(`franchisee-configuration-view, franchiseeConfiguration is null`);
      return false;
    }

    const result = await this.backup.commitChanges();
    if (result?.franchiseeConfiguration) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Configuration saved`);
      return true;
    }
    return false;
  }
  async onEnter(): Promise<void> {
    this.backup.reset();
    await this.render();
  }
  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Configuration`;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.isReadonly());
    const addTaxEvent = () => {
      this.taxRates.push({
        dateCreated: localDateTimeToServer(new Date()),
        dateModified: localDateTimeToServer(new Date()),
        franchiseeId: this.config.id,
        id: newGuid(),
        modifiedUserId: getCurrentUser()?.id ?? emptyGuid,
        name: '',
        rate: 0,
        position: this.taxRates.length,
        recordVersion: ''
      });
      var currentTaxCount = this.taxRates.length;
      if (currentTaxCount > this.maxTaxRowCount) {
        this.addTaxRate(this.maxTaxRowCount);
        this.maxTaxRowCount = currentTaxCount;
      }
      this.render();
    };
    const taxRowsTemplate = () => {
      const rowTemplate = (index: number) => {
        const fieldName = `taxRate_name_${index}`;
        const fieldRate = `taxRate_rate_${index}`;
        const removeTaxEvent = () => {
          this.taxRates.splice(index, 1);
          this.render();
        };
        const deleteBtnTemplate = () =>
          index === 0
            ? html``
            : html` <button type="button" @click=${removeTaxEvent} class="multi-action-btn btn-circle">
                <img class="icon  action-delete" src="/assets/icons/bin.svg" />
              </button>`;

        return html` <div class="row tax-row">
          <div>${forms.textRequired(fieldName, tlang`%%tax%% Name`, 60)}</div>
          <div>
            <div class="row">
              <div class="col-12">${forms.floatRequired(fieldRate, tlang`%%tax%% Rate %`)}</div>
              <div class="col-1 tax-row-delete">${deleteBtnTemplate()}</div>
            </div>
          </div>
        </div>`;
      };

      return this.taxRates.map((_x, index) => rowTemplate(index));
    };
    const addTaxButtonTemplate = () => {
      return html` <div class="row">
        <div class="col-2 mb-3">
          <button class="btn btn-primary" ?disabled=${this.isReadonly()} @click=${addTaxEvent}>
            ${tlang`Add %%tax%%`}
          </button>
        </div>
      </div>`;
    };
    return html` <div>
      <form id="configurationSettingsForm" class="form-two-col">
        <h2>${tlang`System Defaults`}:</h2>
        <div class="row">
          <div>${forms.enumPicker('currencyTypeOption', CurrencyTypeOption, tlang`Currency`)}</div>
        </div>
        <h2>${tlang`Financial Defaults`}:</h2>
        ${taxRowsTemplate()} ${addTaxButtonTemplate()}
      </form>
    </div>`;
  }
}
