import { BaseModal, tlang } from '@softtech/webmodule-components';
import { QuoteSupplier } from '../../quotes/quote-service';
import { TemplateResult, html } from 'lit';
import { asMarkdownTemplate } from '../../components/markdown';
import { getApiFactory } from '../../api/api-injector';
import { InputUpdateFranchiseeProperties } from '../../api/dealer-api-interface-franchisee';
import { lockUIandExecute } from '../../ui-lock';

export class SupplierTermsAndConditionsModal extends BaseModal {
  supplier: QuoteSupplier;
  ok = false;
  tac: string;
  constructor(supplier: QuoteSupplier, tac: string) {
    super();
    this.supplier = supplier;
    this.tac = tac;
  }
  async canClose(): Promise<boolean> {
    return this.ok;
  }
  modalSize = 'modal-xl terms-of-use-modal';

  get isFooterVisible(): boolean {
    return true;
  }
  eventAccept = async (_e: Event) => {
    const input: InputUpdateFranchiseeProperties = {
      properties: {}
    };
    const key = `supplier-${this.supplier.supplierId}-tac`;
    input.properties[`supplier-${this.supplier.supplierId}-tac`] = 'true';
    await lockUIandExecute(async () => {
      const r = await getApiFactory().franchisee().updateFranchiseePropreties(input);
      if (r) this.ok = r.properties[key] === 'true';
      window.location.reload();
    });
  };
  async title(): Promise<string | TemplateResult> {
    return tlang`Terms and Conditions for ${this.supplier.description}`;
  }
  footerTemplate(): TemplateResult | null | undefined {
    return html`<button class="btn btn-primary" @click=${this.eventAccept}>${tlang`Accept`}</button>`;
  }
  async bodyTemplate(): Promise<TemplateResult> {
    const tacTemplate = asMarkdownTemplate(this.tac, true);
    return html`<div>
      <h3>${tlang`The following terms must be accepted to use this software`}</h3>
      ${tacTemplate}
    </div>`;
  }
}
