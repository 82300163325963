import { NullPromise } from '../null-promise';
import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { ClientAgentApi } from './clientagent-api';
import {
  InputGenerateReport,
  InputIssuePurchaseOrder,
  InputIssueQuote,
  InputPreviewQuoteShopDrawing,
  ResultVoid
} from './dealer-api-interface-clientagent';

export class DealerClientAgentApi implements ClientAgentApi {
  apiPath = 'api/Queue/';

  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }

  async previewQuoteShopDrawing(input: InputPreviewQuoteShopDrawing): NullPromise<ResultVoid> {
    return await this.api.post<ResultVoid>(`${this.apiPath}PreviewQuoteShopDrawing`, input);
  }

  async issueQuote(input: InputIssueQuote): NullPromise<ResultVoid> {
    return await this.api.post<ResultVoid>(`${this.apiPath}IssueQuote`, input);
  }

  async issuePurchaseOrder(input: InputIssuePurchaseOrder): NullPromise<ResultVoid> {
    return await this.api.post<ResultVoid>(`${this.apiPath}IssuePurchaseOrder`, input);
  }

  async generateReport(input: InputGenerateReport): NullPromise<ResultVoid> {
    return await this.api.post<ResultVoid>(`${this.apiPath}GenerateReport`, input);
  }
}
