import { html, TemplateResult } from 'lit';
import { QuoteItemPrice, QuotePrice } from '../../api/dealer-api-interface-quote';
import { clone, compare } from '../../components/clone';
import { isAutoSaving } from '../../components/save-workflow';
import { checkValidations, inRange } from '../../components/ui/data-entry-screen-helpers';
import {
  DataTracker,
  DynamicValueBinder,
  EventValue,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { ModalDialog } from '../../components/ui/modal-base';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { PricingStrategy } from './pricing-strategy';
import { money } from '../../components/currency-formatter';
import { StockLookupViewExtra } from '../data/quote-container';
import { currentUserClaims } from '../../components/currentuser-claims';

export const addDynamicMoney = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.money, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.money,
    true
  );
};

export const addDynamicFloat = (
  dataTracker: DataTracker,
  fieldName: string,
  getter: EventValueGetter,
  setter: EventValueSetter,
  readonly?: () => boolean
) => {
  dataTracker.addBinding(
    new DynamicValueBinder(FieldType.float, true, getter, setter, readonly),
    fieldName,
    fieldName,
    FieldType.float,
    true
  );
};

export class QuoteItemPriceAdjustment extends ModalDialog {
  public ok = false;
  protected quoteItemPrice: QuoteItemPrice;
  protected dataTracker: DataTracker;
  protected quotePrice: QuotePrice;
  protected originalQuoteItemPrice: QuoteItemPrice;
  protected forceReadonly: boolean;
  protected quantity: number;
  protected buyInCost: StockLookupViewExtra[] | undefined;
  protected claims = currentUserClaims();

  constructor(
    quotePrice: QuotePrice,
    quoteItemPrice: QuoteItemPrice,
    quantity: number,
    forceReadonly: boolean,
    buyInCost: StockLookupViewExtra[] | undefined
  ) {
    super();
    this.forceReadonly = forceReadonly;
    this.quantity = quantity;
    this.quotePrice = quotePrice;
    this.buyInCost = buyInCost;
    this.quoteItemPrice = clone(quoteItemPrice);
    this.originalQuoteItemPrice = quoteItemPrice;
    this.dataTracker = new DataTracker(new DataBinding(this.ui));

    addDynamicFloat(
      this.dataTracker,
      'quantity',
      () => {
        return this.quantity;
      },
      (_value: EventValue) => {
        console.log('Quantity is readonly');
      },
      () => false
    );

    addDynamicFloat(
      this.dataTracker,
      'marginPercentage',
      () => {
        return this.quoteItemPrice.marginPercentage ?? this.quotePrice.marginPercentage;
      },
      (value: EventValue) => {
        if (value === this.quotePrice.marginPercentage) this.quoteItemPrice.marginPercentage = null;
        else this.quoteItemPrice.marginPercentage = value as number;
      },
      () => false
    );

    addDynamicMoney(
      this.dataTracker,
      'subTotal',
      () => this.quoteItemPrice.calculatedNetSellingPrice,
      (_value: EventValue) => {
        console.log('suppilerCost readonly');
      },
      () => true
    );

    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'priceAdjustment',
      'priceAdjustment',
      FieldType.money,
      false
    );
    this.dataTracker.addObjectBinding(
      () => this.quoteItemPrice,
      'calculatedGrossSellingPrice',
      'calculatedGrossSellingPrice',
      FieldType.money,
      false
    );
  }

  override async afterConstruction(): Promise<void> {
    $(this.ui).on('keyup', 'input', (e: Event) => {
      const elem = e.target as HTMLInputElement;
      const event = e as KeyboardEvent;
      if (
        elem.id == this.dataTracker.binder.field('priceAdjustment') ||
        elem.id == this.dataTracker.binder.field('marginPercentage')
      )
        if (event.code === 'Enter') this.triggerDealerPricingEvent(e);
    });

    $(this.ui).on('blur', 'input', (e: Event) => {
      const elem = e.target as HTMLInputElement;
      if (
        elem.id == this.dataTracker.binder.field('priceAdjustment') ||
        elem.id == this.dataTracker.binder.field('marginPercentage')
      )
        this.triggerDealerPricingEvent(e);
    });
  }

  async canClose(): Promise<boolean> {
    if (isAutoSaving()) {
      if (!(await this.isValid())) return false;
      this.save();
    }
    return true;
  }

  getValidationErrors(): string[] {
    const result: string[] = [];
    if (!inRange(this.quoteItemPrice.marginPercentage, 0, 99))
      result.push(tlang`Please set a valid margin from 0 to 99`);
    return result;
  }

  getMarginLabel(): string {
    return tlang`Margin (default ${this.quotePrice.marginPercentage.toString()}%)`;
  }

  protected triggerDealerPricingEvent = (e: Event) => {
    const elem = e.target as HTMLInputElement;
    if (elem.id == this.dataTracker.binder.field('priceAdjustment')) {
      const p = this.quoteItemPrice;
      const value = (this.dataTracker.getEditorValue('priceAdjustment') as number) ?? 0;
      if (p.priceAdjustment === value) return;
      console.log('PriceAdjustment Changing');
      p.priceAdjustment = value;
      p.calculatedNetSellingPrice = money(
        PricingStrategy.default.GetMarginPriceWithAdjustment(
          p.quantityCost,
          p.marginPercentage ?? this.quotePrice.marginPercentage,
          null
        ),
        2
      );
      p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);
      this.render();
    } else if (elem.id == this.dataTracker.binder.field('marginPercentage')) {
      const p = this.quoteItemPrice;
      //anything over 100 will be treated as 100.
      //100 will result in an infinity error, which will throw a validation on trying to close.
      let marginPerc: number | null =
        (this.dataTracker.getEditorValue('marginPercentage') as number) ?? this.quotePrice.marginPercentage;
      if (marginPerc === this.quotePrice.marginPercentage) marginPerc = null;

      if (p.marginPercentage === marginPerc) return;

      p.marginPercentage = marginPerc;
      //we need to do this because lit doesnt recognize the change when we want empty string to default
      this.dataTracker.setEditorValue('marginPercentage', marginPerc);

      p.calculatedNetSellingPrice = money(
        PricingStrategy.default.GetMarginPriceWithAdjustment(
          p.quantityCost,
          Math.min(marginPerc ?? this.quotePrice.marginPercentage, 100),
          null
        ),
        2
      );
      p.calculatedGrossSellingPrice = money(p.calculatedNetSellingPrice + p.priceAdjustment, 2);
      this.render();
    }
    else if (elem.id == this.dataTracker.binder.field('calculatedGrossSellingPrice')) {
      const p = this.quoteItemPrice;
      const sellingPrice = (this.dataTracker.getEditorValue('calculatedGrossSellingPrice') as number) ?? 0;
      if (p.calculatedGrossSellingPrice === sellingPrice) return;

      const priceAdjustment = sellingPrice - p.calculatedNetSellingPrice;
      p.priceAdjustment = money(priceAdjustment, 2);
      this.render();
    }
  };

  protected async isValid(): Promise<boolean> {
    this.prepareForSave();
    return await checkValidations(this.getValidationErrors());
  }

  protected async getTitle(): PromiseSnippet {
    return html`${tlang`Line Item Price`}`;
  }

  protected modalSize(): string {
    return 'modal-md';
  }

  protected prepareForSave() {
    if (this.forceReadonly) return;
    this.dataTracker.applyChangeToValue();
  }

  protected save() {
    this.ok = !compare(this.originalQuoteItemPrice, this.quoteItemPrice);
    Object.assign(this.originalQuoteItemPrice, this.quoteItemPrice);
  }

  protected footerTemplate(): TemplateResult {
    const saveEvent = async () => {
      if (await this.isValid()) {
        this.save();
        await this.hideModal();
      }
    };
    const closeEvent = () => this.hideModal();

    return this.forceReadonly
      ? html` <button @click="${closeEvent}" class="btn btn-secondary">Close</button>`
      : html`
          <button @click=${closeEvent} class="btn btn-secondary">Close</button>
          <button @click=${saveEvent} class="btn btn-primary">Save</button>
        `;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    return html`
      <form class="line-item-price-adjustment form-one-col">
        <div class="row">
          <h2>${tlang`%%franchisee%%`}</h2>
          <div class="form-column">
            ${forms.intReadonly('quantity', tlang`Quantity`)}
            ${forms.float('marginPercentage', this.getMarginLabel())}
            ${forms.moneyReadonly('subTotal', tlang`Sub Total`)}
            ${forms.money('priceAdjustment', tlang`Price Adjustment`)}
            ${forms.money('calculatedGrossSellingPrice', tlang`Sell Price`)}
          </div>
        </div>
      </form>
    `;
  }
}
