import { PaymentProfileApi } from '../../api/payment-profile-api';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { EventSnippet } from '../../components/ui/events';
import { EventPaymentProfileOpen } from '../data/events';
import { PaymentProfileSummary, ResultBrowsePaymentProfileSummary } from '../../api/dealer-api-interface-franchisee';
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { isMultiSupplier } from '../../quotes/quote-service';
import { getQuoteSupplierDisplayName } from '../../quotes/data/quoteSupplierProvider';

//This base class can be refactored out and more generic
interface PaymentProfileSummaryTableBaseOptions {
  title: EventSnippet;
  supplierId: string | null;
}

class PaymentProfileSummaryTableBase extends DataTableWrapper<PaymentProfileSummary> {
  title: EventSnippet;
  api: PaymentProfileApi = getApiFactory().paymentProfile();
  filter: string | null;
  supplierId: string | null = null;

  constructor(options: PaymentProfileSummaryTableBaseOptions) {
    super();
    this.title = options.title;
    this.filter = null;
    this.supplierId = options.supplierId;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PaymentProfileSummary>> {
    const results = await this.api.browsePaymentProfileSummary({
      branchId: userDataStore.defaultBranch.id,
      sortField: request.sortField,
      sortAsc: !request.sortAsc,
      filter: this.filter,
      requestPage: {
        pageSize: request.pageSize,
        pageIndex: request.pageIndex
      },
      supplierId: this.supplierId
    });
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };

    await Promise.all(this.getPreFetched(results));

    return results.paymentProfileSummary;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  useAutoWidthColumns(): boolean {
    return true;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    this.filter = _searchTerm;
  }

  getColumns(): any[] {
    const columns: any[] = [
      {
        title: tlang`%%payment-profile%%`,
        width: '500px',
        data: 'name',
        className: 'payment-profile-name'
      },
      {
        title: tlang`Margin`,
        width: '500px',
        data: 'margin',
        className: 'payment-profile-margin'
      }
    ];

    if (isMultiSupplier())
      columns.push({
        title: tlang`%%supplier%%`,
        width: '500px',
        data: 'supplierId',
        className: 'payment-profile-supplier',
        render: (value: PaymentProfileSummary) => {
          return getQuoteSupplierDisplayName(value.supplierId);
        }
      });

    return columns;
  }

  protected getPreFetched(_results: ResultBrowsePaymentProfileSummary): Promise<void>[] {
    return [];
  }
}

interface PaymentProfileSummaryTableOptions extends PaymentProfileSummaryTableBaseOptions {
  openPaymentProfileEvent: EventPaymentProfileOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}

export class PaymentProfileSummaryTable extends PaymentProfileSummaryTableBase {
  openPaymentProfileEvent: EventPaymentProfileOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;

  constructor(options: PaymentProfileSummaryTableOptions) {
    super(options);
    this.openPaymentProfileEvent = options.openPaymentProfileEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%payment-profile%%`,
        width: '500px',
        data: 'id',
        className: 'payment-profile-name',
        render: (data: string, _type: string, row: PaymentProfileSummary) => {
          return `<a class="payment-profile-link" data-paymen-profile-id=${data} href="#">${row.name}</a>`;
        }
      },
      {
        title: tlang`Margin`,
        width: '500px',
        data: 'margin',
        className: 'payment-profile-margin'
      }
    ];
  }

  bindClickEvents($dataTable: any) {
    //Data tables uses JQuery click events to bind things. they don't have any other
    //special handler.
    $dataTable.on(
      'click',
      '.payment-profile-link',
      this.eventHandler(async (data: PaymentProfileSummary) => {
        await this.openPaymentProfile(data);
      })
    );
  }

  private async openPaymentProfile(paymentProfileSummary: PaymentProfileSummary): Promise<void> {
    await this.openPaymentProfileEvent?.(paymentProfileSummary);
  }
}

export interface PaymentProfileSummaryPickerOptions extends PaymentProfileSummaryTableBaseOptions {
  onPaymentProfileSelected?: (paymentProfileSummary: PaymentProfileSummary) => void;
}

export class PaymentProfileSummaryPickerBrowser extends PaymentProfileSummaryTableBase {
  onPaymentProfileSelected?: (paymentProfileSummary: PaymentProfileSummary) => void;

  constructor(options: PaymentProfileSummaryPickerOptions) {
    super(options);
    this.onPaymentProfileSelected = options.onPaymentProfileSelected;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%payment-profile%%`,
        width: '500px',
        data: 'name'
      },
      {
        title: tlang`Margin`,
        width: '500px',
        data: 'margin'
      }
    ];
  }

  bindClickEvents($dataTable: any) {
    //Data tables uses JQuery click events to bind things. they don't have any other
    //special handler.

    // bind to all the quote-link classes to implement the quote open event
    $dataTable.on(
      'click',
      'tr',
      this.eventHandler(async (data: PaymentProfileSummary) => {
        if (this.onPaymentProfileSelected) this.onPaymentProfileSelected?.(data);
      })
    );
  }
}
