import { BaseModal, getCurrentUser, tlang } from '@softtech/webmodule-components';
import { TemplateResult, html } from 'lit';
import { asMarkdownTemplate } from '../../components/markdown';
import { information } from '../../components/ui/modal-option';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';

export enum QuoteTechnicalReviewQuery {}
export class QuoteTechnicalReviewDialog extends BaseModal {
  poNumberRequired = false;
  forceIssueOrder = false;
  supplierName: string;
  orderNumber = '';
  allowSupplierToIssue = false;
  ok = false;
  constructor(poNumberRequired: boolean, forceIssueOrder: boolean, supplierName: string, orderNumber: string) {
    super();
    this.poNumberRequired = poNumberRequired;
    this.forceIssueOrder = forceIssueOrder;
    this.supplierName = supplierName;
    this.orderNumber = orderNumber;
  }

  private async validateNumber() {
    if (this.poNumberRequired && isEmptyOrSpace(this.orderNumber)) {
      const user = getCurrentUser();
      const dealerName = (user as any).tenantName ?? tlang`%%franchisee%%`;

      await information(tlang`${dealerName} Order No is required`);
      return false;
    }
    return true;
  }
  private yesEvent = async () => {
    if (!(await this.validateNumber())) {
      return;
    }
    this.allowSupplierToIssue = true;
    this.ok = true;
    this.hideModal();
  };
  private noEvent = async () => {
    if (!(await this.validateNumber())) {
      return;
    }
    this.ok = true;
    this.hideModal();
  };

  async canClose() {
    return true;
  }
  footerTemplate(): TemplateResult | null | undefined {
    return this.forceIssueOrder
      ? html`<button class="btn btn-primary" @click=${this.yesEvent}>${tlang`Send`}</button>`
      : html`<button class="btn btn-secondary" @click=${this.noEvent}>${tlang`No thanks`}</button
          ><button class="btn btn-primary" @click=${this.yesEvent}>${tlang`Yes`}</button>`;
  }
  get isFooterVisible(): boolean {
    return true;
  }
  private eventPO = (e: Event) => {
    this.orderNumber = (e.currentTarget as HTMLInputElement).value;
  };

  async title(): Promise<string | TemplateResult> {
    return tlang`Technical Review`;
  }
  async bodyTemplate(): Promise<TemplateResult> {
    const user = getCurrentUser();
    const mandatory = this.poNumberRequired ? html`<span class="text-danger "><sup>*</sup></span>` : html``;
    const dealerName = (user as any).tenantName ?? tlang`%%franchisee%%`;
    const verbage = this.forceIssueOrder
      ? tlang`${'ref:technical-review-direct-to-supplier'}
            This %%quote%% will now be submitted to **${this.supplierName}** for a 
            %%technical-review%%.

            *If approved without changes, the %%purchase-order-abrev%% will be dispatched to* **${this.supplierName}**

            `
      : tlang`${'ref:technical-review-with-approval'}
            This %%quote%% will now be submitted to **${this.supplierName}** for a 
            %%technical-review%%.

            If approved without changes, would you like **${this.supplierName}** to !!purchase-order-state-issued!! the %%purchase-order-abrev%% on your behalf? If not, a !!purchase-order-state-draft!! 
            %%purchase-order-abrev%% will be generated for you to !!purchase-order-state-issued!!.
`;
    return html`<div class="me-2">${asMarkdownTemplate(verbage, true)}</div>
      <div>
        <label>${dealerName} Order No: ${mandatory}</label
        ><input
          id="ponumber"
          type="text"
          maxlength="200"
          .value=${this.orderNumber}
          @changed=${this.eventPO}
          @blur=${this.eventPO}
        />
      </div>`;
  }
}
