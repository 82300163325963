import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ProjectListView } from '../../projects/views/project-list-view';
import { PageBase } from './helpers/page-base';
import { FranchiseeProjectListView } from '../projects/views/franchisee-project-list-view';
import { getInternalId } from '../../components/ui/databinding/databinding';
import { tlang } from '@softtech/webmodule-components';
import { userDataStore } from '../common/current-user-data-store';
import { cache } from '../cache/cache-registry';
import { disposeOf } from '../../components/dispose';

@customElement('app-projects')
export class AppProjectsPage extends PageBase {
  @state()
  projectListView: ProjectListView | null = null;
  internalId: string = getInternalId();

  constructor() {
    super();
  }

  private get elementId(): string {
    return `project-page-${this.internalId}`;
  }

  protected async afterUserConnected() {
    this.projectListView = this.projectViewFactory();
    await this.projectListView.render();
  }
  async dispose() {
    await super.dispose();
    await disposeOf(this.projectListView);
    this.projectListView = null;
  }
  render() {
    if (!this.loggedIn) return html``;
    return html`
      <div id=${this.elementId} class="page-content">
        <div class="page-container">${this.projectListView?.ui}</div>
      </div>
    `;
  }

  meta() {
    return {
      title: tlang`%%project%% Summary`,
      description: 'SoftTech dealer CPQ'
    };
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private projectViewFactory() {
    //change this to a subclass which has branch details, or otherwise.. ie FranchiseeBranchQuoteListView
    return new FranchiseeProjectListView({
      projectOwnerId: async () => {
        await userDataStore.loadCoreDetails();
        //this will need to be changed to a franchisee branch that the user belongs to
        if (!userDataStore.defaultBranch) return '';
        return userDataStore.defaultBranch.id;
      },
      quoteCache: cache().quote,
      purchaseOrderCache: cache().purchaseOrder,
      clientCache: cache().client,
      userProfileCache: cache().userProfile,
      paymentProfileCache: cache().paymentProfile,
      contactCache: cache().contact
    });
  }
}
