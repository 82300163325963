import { ModalDialog } from './modal-base';
import { PromiseSnippet } from './events';

export type EventPickerSelect<e> = (selected: e | null) => void;
export type EventPickerCancel = () => void;

export interface ModalPickerDialogOptions<e> {
  onSelect?: EventPickerSelect<e>;
  onCancel?: EventPickerCancel;
  title?: string;
}

export class ModalPickerDialog<e> extends ModalDialog {
  onSelect?: EventPickerSelect<e>;
  onCancel?: EventPickerCancel;
  selected: e | null = null;
  //configuration for operation and callbacks
  options: ModalPickerDialogOptions<e>;
  protected browserClass = 'modal-base-picker';

  constructor(options: ModalPickerDialogOptions<e>) {
    super();
    this.onSelect = options.onSelect;
    this.onCancel = options.onCancel;
    this.selected = null;
    this.options = options;
  }

  protected modalSize(): string {
    return 'modal-xl';
  }

  protected async getTitle(): PromiseSnippet {
    return this.options.title ?? 'Please select from below list';
  }

  protected async select(forceSelection?: e | null): Promise<void> {
    if (this.onSelect) {
      const selection = forceSelection === undefined ? this.selected : forceSelection;

      if (selection !== undefined) this.onSelect(selection);
    }

    if (this.onCancel && forceSelection) this.onCancel();

    await this.hideModal();
  }

  protected cancel() {
    this.hideModal();
    if (this.onCancel) this.onCancel();
  }
}
