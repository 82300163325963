// eslint-disable-next-line import/named
import { html } from 'lit';
import { ClientApi } from '../../../api/client-api';
import { ContactSummaryTable } from '../../../clients/views/contact-list-table';
import { PageControl, PageControlOptions, PageManager } from '../../../components/ui/page-control';
import { IconRefresh } from '../../../components/ui/icons/icon-refresh';
import { ViewContactSummary } from '../../../api/dealer-api-interface-client';
import { ContactView, ContactViewOptions } from '../../../clients/views/contact-view';
import { PaymentProfileView, PaymentProfileViewOptions } from '../../../franchisee/view/payment-profile-view';
import { PaymentProfileSummaryTable } from '../../../franchisee/view/payment-profile-list-table';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import { PaymentProfileSummary } from '../../../api/dealer-api-interface-franchisee';
import { FranchiseeClientView } from './franchisee-client-view';
import { getInternalId } from '../../../components/ui/databinding/databinding';
import { tlang } from '@softtech/webmodule-components';
import { PromiseTemplate, Snippet } from '../../../components/ui/events';
import { getApiFactory } from '../../../api/api-injector';
import { ClientSummaryTable } from './franchisee-client-list-table';
import { ContactContainer, createNewContact } from '../../../clients/data/contact-container-manager';
import { createNewClient } from '../data/franchisee-client-container';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { cache } from '../../cache/cache-registry';
import { ClientViewOptions } from '../../../clients/views/client-view';
import {
  createNewPaymentProfile,
  PaymentProfileContainer
} from '../../../franchisee/data/payment-profile-container-manager';
import { ViewBase } from '../../../components/ui/view-base';
import { clientContainerManagerFactory } from '../client-ui-adaptor';
import { singleSupplierId } from '../../../quotes/quote-service';
import { v6BootLoaderWithFailMsg } from '../../../v6config/v6config';
import { userDataStore } from '../../common/current-user-data-store';

export type ClientViewFactory = (options: ClientViewOptions) => Promise<FranchiseeClientView>;
export type ContactViewFactory = (options: ContactViewOptions) => Promise<ContactView>;
export type PaymentProfileViewFactory = (options: PaymentProfileViewOptions) => Promise<PaymentProfileView>;

export interface FranchiseeClientListViewOptions {
  clientOwnerId: string;
  clientViewFactory: ClientViewFactory;
  contactViewFactory: ContactViewFactory;
  paymentProfileFactory: PaymentProfileViewFactory;
}

export class FranchiseeClientListView extends ViewBase {
  tables: (ClientSummaryTable | ContactSummaryTable | PaymentProfileSummaryTable)[];
  clientOwnerId: string;
  clientApi: ClientApi = getApiFactory().client();
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  elementId: string;
  paymentProfileCache: DataCacheGeneric = cache().paymentProfile;
  clientCache: DataCacheGeneric = cache().client;
  pageControl: PageControl;
  contactViewFactory: ContactViewFactory;
  clientViewFactory: ClientViewFactory;
  paymentProfileFactory: PaymentProfileViewFactory;
  addButtonTitle: Snippet;

  constructor(options: FranchiseeClientListViewOptions) {
    super();
    this.elementId = `client-list-view-${getInternalId()}`;
    this.clientOwnerId = options.clientOwnerId;
    this.clientViewFactory = options.clientViewFactory;
    this.contactViewFactory = options.contactViewFactory;
    this.paymentProfileFactory = options.paymentProfileFactory;

    this.tables = this.initializeTables();
    this.addButtonTitle = this.tables[0].addButtonTitle();

    this.pageControl = this.createPageControl();
  }

  createPageControl(): PageControl {
    const getInitialPageManagers = (): PageManager[] => {
      return this.tables.map(table => {
        const pm: PageManager = {
          caption: table.title,
          canClose: () => Promise.resolve(false),
          canLeave: () => Promise.resolve(true),
          hasDelete: () => false,
          onEnter: async () => {
            this.addButtonTitle = table.addButtonTitle();
            table.render();
          },
          content: () => {
            return table.ui;
          },
          data: table,
          pageFragment: table.pageFragment
        };
        return pm;
      });
    };

    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: [
        {
          event: async () => {
            const activePage = this.pageControl.activePage;

            if (activePage) {
              const table = activePage.data as ClientSummaryTable | ContactSummaryTable | PaymentProfileSummaryTable;
              await table.refreshData();
            }

            return true;
          },
          caption: () => html`${new IconRefresh()}`
        }
      ],
      plusPage: {
        caption: () => this.addButtonTitle,
        event: async () => {
          const activePage = this.pageControl.activePage;
          if (!(await userDataStore.singleSupplierTACApproved())) return false;

          if (activePage?.data instanceof ContactSummaryTable) {
            await this.addContact();
          }

          if (activePage?.data instanceof ClientSummaryTable) {
            await this.addClient();
          }

          if (activePage?.data instanceof PaymentProfileSummaryTable) {
            await this.addPaymentProfile();
          }

          return true;
        }
      },
      pageInitializer: () => getInitialPageManagers()
    };
    return new PageControl(options);
  }

  async addPaymentProfile(): Promise<boolean> {
    //payment profiles are reliant on supplier information
    if (!(await v6BootLoaderWithFailMsg())) {
      return false;
    }
    const paymentProfileContainer = await createNewPaymentProfile();
    if (paymentProfileContainer) {
      const title = tlang`Add %%payment-profile%%`;

      const qv = await this.paymentProfileFactory({
        title: () => title,
        paymentProfile: paymentProfileContainer
      });

      await qv.showModal();

      await this.refreshPaymentProfileList();

      return true;
    }
    return false;
  }

  async addClient(): Promise<boolean> {
    const clientContainer = await createNewClient(this.clientOwnerId);
    if (clientContainer) {
      const title = tlang`Add %%client%%`;
      const qm = clientContainerManagerFactory(clientContainer);
      const qv = await this.clientViewFactory({
        viewTitle: () => title,
        clientContainerManager: qm
      });

      await qv.showModal();

      await this.refreshContactList();
      await this.refreshClientList();

      return true;
    }
    return false;
  }

  async addContact(): Promise<boolean> {
    const contactContainer = await createNewContact();
    if (contactContainer) {
      const title = tlang`Add %%contact%%`;
      const qv = await this.contactViewFactory({
        title: () => title,
        contact: contactContainer
      });
      await qv.showModal();

      await this.refreshContactList();
      await this.refreshClientList();

      return true;
    }
    return false;
  }

  private async refreshClientList() {
    const tables = this.tables.filter(x => x instanceof ClientSummaryTable);

    for (const x of tables) {
      if (x.dataTable) await x.refreshData();
    }
  }

  private async refreshContactList() {
    const tables = this.tables.filter(x => x instanceof ContactSummaryTable);

    for (const x of tables) {
      if (x.dataTable) await x.refreshData();
    }
  }

  private async refreshPaymentProfileList() {
    const tables = this.tables.filter(x => x instanceof PaymentProfileSummaryTable);

    for (const x of tables) {
      if (x.dataTable) await x.refreshData();
    }
  }

  protected async template(): PromiseTemplate {
    return html` <div id=${this.elementId} class="page-content">${this.pageControl.ui}</div>`;
  }

  private initializeTables(): (ClientSummaryTable | ContactSummaryTable | PaymentProfileSummaryTable)[] {
    return [
      new ClientSummaryTable({
        title: () => tlang`!!client!!`,
        openContactEvent: async (contactSummary: ViewContactSummary) => await this.openContact(contactSummary),
        addButtonTitle: () => tlang`Create %%client%%`,
        pageFragment: 'clients'
      }),
      new ContactSummaryTable({
        title: () => tlang`!!contact!!`,
        openContactEvent: async (contactSummary: ViewContactSummary) => await this.openContact(contactSummary),
        addButtonTitle: () => tlang`Create %%contact%%`,
        pageFragment: 'contacts'
      }),
      new PaymentProfileSummaryTable({
        title: () => tlang`!!payment-profile!!`,
        openPaymentProfileEvent: async (paymentProfileSummary: PaymentProfileSummary) =>
          await this.openPaymentProfile(paymentProfileSummary),
        addButtonTitle: () => tlang`Create %%payment-profile%%`,
        pageFragment: 'paymentProfile',
        supplierId: singleSupplierId()
      })
    ];
  }

  private async openPaymentProfile(paymentProfileSummary: PaymentProfileSummary) {
    const modalTitle = tlang`Edit ${paymentProfileSummary.name}`;

    const qv = await this.paymentProfileFactory({
      title: () => modalTitle,
      paymentProfile: new PaymentProfileContainer(paymentProfileSummary.id, null)
    });
    await qv.showModal();

    await this.refreshPaymentProfileList();
  }

  private async openContact(contactSummary: ViewContactSummary) {
    const modalTitle = tlang`Edit ${contactSummary.name}`;

    const qv = await this.contactViewFactory({
      title: () => modalTitle,
      contact: new ContactContainer(contactSummary.id, null, contactSummary.isPrimary)
    });
    await qv.showModal();

    await this.refreshContactList();
    await this.refreshClientList();
  }
}
