import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '@softtech/webmodule-data-contracts';
import { PaymentProfileSummary } from '../../../api/dealer-api-interface-franchisee';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import {
  PaymentProfileSummaryPickerBrowser,
  PaymentProfileSummaryPickerOptions
} from '../../../franchisee/view/payment-profile-list-table';
import {
  PaymentProfileBrowserPicker,
  PaymentProfilePickerOptions
} from '../../../franchisee/view/payment-profile-picker';

interface FranchiseePaymentProfilePickerOptions extends PaymentProfilePickerOptions {
  supplierId: string;
}

export async function FranchiseePaymentProfilePicker(
  api: PaymentProfileApi,
  supplierId: string
): NullPromise<PaymentProfileSummary> {
  return new Promise<PaymentProfileSummary | null>((resolve, reject) => {
    const picker = new FranchiseePaymentProfileBrowserPicker({
      onSelect: (selected: PaymentProfileSummary | null) => {
        resolve(selected);
      },
      onCancel: () => reject(),
      title: tlang`Select a %%payment-profile%%`,
      api: api,
      supplierId: supplierId
    });
    picker.showModal();
  });
}

export class FranchiseePaymentProfileBrowserPicker extends PaymentProfileBrowserPicker {
  constructor(options: FranchiseePaymentProfilePickerOptions) {
    super(options);
  }

  protected paymentProfilePickerBrowserFactory(
    options: FranchiseePaymentProfilePickerOptions
  ): PaymentProfileSummaryPickerBrowser {
    return new FranchiseePaymentProfileSummaryPickerBrowser({
      title: () => options.title ?? '',
      onPaymentProfileSelected: paymentProfileSummary => this.select(paymentProfileSummary),
      supplierId: options.supplierId
    });
  }
}

export class FranchiseePaymentProfileSummaryPickerBrowser extends PaymentProfileSummaryPickerBrowser {
  constructor(options: PaymentProfileSummaryPickerOptions) {
    super(options);
  }
}
