import { ClientDataEntryView, ClientView, ClientViewOptions } from '../../../clients/views/client-view';
import { FranchiseeClientDetailView } from './franchisee-client-detail-view';
import { showValidations } from '../../../components/ui/modal-validationhandler';
import { DataEntryOwner } from '../../../components/ui/DataEntryOwner';
import { PromiseSnippet } from '../../../components/ui/events';

export class FranchiseeClientDataEntryView extends ClientDataEntryView {
  constructor(options: ClientViewOptions, owner: DataEntryOwner) {
    super(options, owner);
  }

  protected clientDetailViewFactory(): FranchiseeClientDetailView {
    return new FranchiseeClientDetailView(this, {
      clientContainerManager: this.clientContainerManager
    });
  }
  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    if (errors.length != 0) {
      await showValidations(errors);
      return false;
    }
    return true;
  }
}

export class FranchiseeClientView extends ClientView {
  options: ClientViewOptions;

  constructor(options: ClientViewOptions) {
    super(options);
    this.options = options;
  }
  protected async getTitle(): PromiseSnippet {
    return this.view?.getTitle() ?? '';
  }

  protected createView(): ClientDataEntryView {
    return new FranchiseeClientDataEntryView(this.options, this);
  }
}
