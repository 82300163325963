/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  V6ConfigEntryPoint,
  V6Configurator,
  V6DataUtilities,
  V6QuoteItemEditorServices,
  v6FrameUserMode
} from '@softtech/webmodule-data-contracts';

import { DevelopmentError } from '../development-error';
import { showError } from '../components/ui/show-error';
import { modalScreenFactoryImpl } from '../components/ui/modal-factory';
import { lockUIandExecute } from '../ui-lock';
import { tlang, userSecurity } from '@softtech/webmodule-components';
import { isEmptyOrSpace } from '../components/ui/helper-functions';
import {
  displayFrameConfigLoadErrorMsg,
  displaySupplierOffLineMsg,
  supplierOnline,
  wasSupplierOffline
} from './supplier-offline';
import { QuoteSupplier } from '../quotes/quote-service';
import { information } from '../components/ui/modal-option';
import { DealerApiCommunications } from '../api/dealer-api-communications';
import { responseHandler } from '../api/api-response-handler';

function versionToInteger(ver: string): number {
  const pieces = ver.substring(1).split('.');
  pieces[0] = pieces[0].padEnd(3, '0');
  pieces[1] = pieces[1].padEnd(4, '0');
  pieces[2] = pieces[2].padStart(5, '0');
  return parseInt(pieces.join(''));
}

export enum V6PickerDisplayType {
  description = 0,
  code = 1,
  codeDescription = 2
}
export interface V6SupplierOptions {
  isMetric: boolean;
  online: boolean;
  version: string;
  configVersion: string;
  instanceId: string;
  includeShipping: boolean;
  useQuoteGlass: boolean;
  buyIn: boolean;
  nfrc: boolean;
  widthBeforeHeight: boolean;
  useServerSideItems: boolean;
  isPurchaseOrderIssuedImmediately: boolean;
  isPurchaseOrderNumberMandatory: boolean;
  allowPickupFromSupplier: boolean;
  pickupFromSupplierAddress: string;

  pickerIguDisplayType: V6PickerDisplayType;
  pickerIguQuoteDisplayType: V6PickerDisplayType;
  pickerFrameDisplayType: V6PickerDisplayType;
  pickerHideIguThumbnails: boolean;
  pickerHideIguQuoteThumbnails: boolean;
  pickerHideFrameThumbnails: boolean;
}

export const v6VersionMap = {
  hasBeforeAfterApplyQuoteItemEvents: versionToInteger('v1.0.17'),
  hasQuoteOwnerLevelSettings: versionToInteger('v1.0.52'),
  hasInstanceIDInSettings: versionToInteger('v1.0.54'),
  hasNewFinishPicker: versionToInteger('v1.0.55'),
  hasMultipleFrameAdd: versionToInteger('v1.0.55')
};

export function v6SupportsVersion(version: number): boolean {
  return globalV6Version >= version;
}
export interface IV6ConfigManager {
  ver: (version: string) => V6ConfigEntryPoint | undefined;
  lastLoaded?: V6ConfigEntryPoint;
  activeVersion?: V6ConfigEntryPoint;
}

export function v6ConfigManager(): IV6ConfigManager | undefined {
  return globalThis.v6ConfigManager as IV6ConfigManager;
}

export function v6ConfigSupplierOptions(supplierId: string): V6SupplierOptions {
  const s = v6Config().getSupplier(supplierId);
  function str2bool(value: string, defaultValue = false): boolean {
    const strvalue = s.options?.[value];
    if (strvalue === undefined) return defaultValue;
    return strvalue === 'true';
  }
  function str2Number(value: string, defaultValue = 0): number {
    const strvalue = s.options?.[value];
    if (strvalue === undefined) return defaultValue;
    const v = parseInt(strvalue);
    if (isNaN(v)) return defaultValue;
    else return v;
  }
  const hasInstanceId = v6SupportsVersion(v6VersionMap.hasInstanceIDInSettings);
  return {
    configVersion: hasInstanceId ? s.configVersion ?? '' : '',
    instanceId: hasInstanceId ? s.instanceId ?? '' : '',
    buyIn: str2bool('buyIn'),
    includeShipping: str2bool('includeShipping', true),
    isMetric: s.isMetric,
    nfrc: str2bool('nfrc'),
    online: s.online,
    useQuoteGlass: str2bool('useQuoteGlass'),
    allowPickupFromSupplier: str2bool('allowPickupFromSupplier'),
    pickupFromSupplierAddress: s.options?.['pickupFromSupplierAddress'] ?? '',
    useServerSideItems: str2bool('useServerSideItems'),
    version: s.version,
    widthBeforeHeight: str2bool('widthBeforeHeight', true),
    isPurchaseOrderIssuedImmediately: str2bool('isPurchaseOrderIssuedImmediately', false),
    isPurchaseOrderNumberMandatory: str2bool('isPurchaseOrderNumberMandatory', false),
    pickerFrameDisplayType: str2Number('pickerFrameDisplayType', V6PickerDisplayType.description),
    pickerHideFrameThumbnails: str2bool('pickerHideFrameThumbnails', false),
    pickerIguDisplayType: str2Number('pickerIguDisplayType', V6PickerDisplayType.description),
    pickerIguQuoteDisplayType: str2Number('pickerIguQuoteDisplayType', V6PickerDisplayType.description),
    pickerHideIguQuoteThumbnails: str2bool('pickerHideIguQuoteThumbnails', false),
    pickerHideIguThumbnails: str2bool('pickerHideIguThumbnails', false)
  };
}

export function isValidV6ConfigVersion(): boolean {
  const manager = v6ConfigManager();
  if (!manager) return false;

  if (!manager.activeVersion) return false;
  return true;
}

function validateConfigVerison(version?: string) {
  const manager = v6ConfigManager();
  if (!manager) throw new DevelopmentError('V6Config Bootloader has not been run');
  if (!version) {
    if (!manager.activeVersion)
      throw new DevelopmentError('V6Config Bootloader does not have an active version loaded');
  } else if (!manager.ver(version))
    throw new DevelopmentError(
      `V6Config Bootloader does not have an active version ${version} loaded. call v6BootLoader`
    );
}

let globalV6Version = 0;
let globalV6VersionStr = '0.0.0';
export function v6ConfigActiveVersion(): number {
  return globalV6Version;
}
export function v6ConfigActiveVersionStr(): string {
  return globalV6VersionStr;
}

export function v6ConfigEntryPoint(version?: string): V6ConfigEntryPoint {
  validateConfigVerison(version);
  if (!version)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return v6ConfigManager()!.activeVersion!;
  return v6ConfigManager()!.ver(version)!;
}

export function v6Config(version?: string): V6Configurator {
  return v6ConfigEntryPoint(version).config;
}

export function v6Util(version?: string): V6DataUtilities {
  return v6ConfigEntryPoint(version).util;
}

export function v6Editors(version?: string): V6QuoteItemEditorServices {
  return v6ConfigEntryPoint(version).editors;
}

export interface V6MacroVersion {
  version: string;
  configVersion?: string;
  instanceId: string;
}

let _v6commSingleton: DealerApiCommunications | undefined;
const v6ApiInjecterEvent = () => {
  if (!_v6commSingleton)
    _v6commSingleton = new DealerApiCommunications(globalThis.dealerConfiguration.v6apiHost, responseHandler, () => {
      //Redirect to home page, next query will force a login to occur
      window.location.href = '/login';
    });
  return _v6commSingleton;
};
async function getDealerMacroVersion(): Promise<V6MacroVersion | undefined> {
  //this is configured as an anonymous call so we dont need a login to get this data.
  const comms = v6ApiInjecterEvent();
  const r = await comms.post<unknown>(`api/v6/configitem/suppliers`, {});
  if (!r) return undefined;
  const res: V6MacroVersion = {
    instanceId: (r as any)?.items[0].settings.instanceId ?? '',
    version: (r as any)?.items[0].settings.version ?? '',
    configVersion: (r as any)?.items[0].settings.configVersion ?? ''
  };
  return res;
}

export async function getQuoteSupplierFromApi(): Promise<QuoteSupplier[] | undefined> {
  //this is configured as an anonymous call so we dont need a login to get this data.
  const comms = v6ApiInjecterEvent();
  const r = await comms.post<unknown>(`api/v6/configitem/suppliers`, {});
  return (r as any)?.items.map(x => {
    const qs: QuoteSupplier = {
      supplierId: x.id,
      description: x.name,
      online: x.online
    };
    return qs;
  });
}

let dealerVersion: V6MacroVersion | undefined = undefined;
const v6VersionResponses: { dealerVersion: string; v6Version: string }[] = [];
export function clearDealerVersion() {
  dealerVersion = undefined;
  globalV6Version = 0;
  globalV6VersionStr = '0.0.0';
  _displayOfflineMsg = true;
}

export async function getRequiredV6ConfigVersionFromGlobal(dealerVersion: string): Promise<string> {
  let v6Version = v6VersionResponses.find(x => x.dealerVersion == dealerVersion)?.v6Version;
  if (!v6Version) {
    const v6VersionResponse = await fetch(
      `https://softtech-webmodule-dealer-macro-endpoint.azurewebsites.net/api/GetV6ConfigUIVersion?v6version=${dealerVersion}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        },
        method: 'get'
      }
    );
    v6Version = 'v1.0.5';
    v6Version = await v6VersionResponse.text();
    v6VersionResponses.push({
      dealerVersion: dealerVersion,
      v6Version: v6Version
    });
  }
  if (!v6Version.startsWith('v')) v6Version = `v${v6Version}`;
  return v6Version;
}

// eslint-disable-next-line no-var -- var declared as it is replaced by
declare var __localdebug__: string;

export interface V6FrameConfigVersion {
  api: number;
  major: number;
  minor: number;
}

export function activeV6FrameConfigVer(): V6FrameConfigVersion {
  const v = activeV6FrameConfigVersion() ?? 'v0.0.0';
  const values = v.substring(1).split('.');
  return {
    api: parseInt(values[0]),
    major: parseInt(values[1]),
    minor: parseInt(values[2])
  };
}

export function activeV6FrameConfigVersion() {
  let rKey: string | undefined = undefined;
  if (!v6ConfigManager()) return undefined;
  const active = v6ConfigManager()?.activeVersion;
  (v6ConfigManager() as any).versions.forEach((value, key) => {
    if (value === active) rKey = key;
  });
  return rKey;
}
/**
 *
 * @param requiredDealerVersion
 * @param configVersion
 * @returns the correct version to load, either the specific one specified, or load the global one
 */
async function getRequiredV6ConfigVersion(requiredDealerVersion: string, configVersion?: string) {
  const wantedVersion = configVersion ?? '';
  return isEmptyOrSpace(wantedVersion)
    ? await getRequiredV6ConfigVersionFromGlobal(requiredDealerVersion)
    : wantedVersion;
}

export type V6BootLoaderEvent = () => Promise<void>;
let _events: V6BootLoaderEvent[] = [];
export function addV6BootLoaderEvent(aEvent: V6BootLoaderEvent) {
  _events.push(aEvent);
}
export function remV6BootLoaderEvent(aEvent: V6BootLoaderEvent) {
  _events = _events.filter(x => x !== aEvent);
}

export async function v6BootLoaderWithFailMsg(): Promise<boolean> {
  _displayOfflineMsg = true;
  return await v6BootLoader();
}

export async function v6BootLoaderVersionCheck(newdealerVersion: V6MacroVersion | null | undefined): Promise<boolean> {
  if (
    dealerVersion &&
    newdealerVersion &&
    (newdealerVersion.version !== dealerVersion.version ||
      newdealerVersion.configVersion !== dealerVersion.configVersion)
  ) {
    await information(
      tlang`${'ref:supplier-system-update'}The %%supplier%% has updated their systems and the page requires a force reload now to update the systems`,
      tlang`Force Reload Required`
    );
    window.location.reload();
    return false;
  }
  return true;
}
let _displayOfflineMsg = true;
export async function v6BootLoader(): Promise<boolean> {
  //if requiredDealerVersion is empty, then we want the current default
  if (wasSupplierOffline() && dealerVersion) {
    const newdealerVersion = await getDealerMacroVersion();
    if (!v6BootLoaderVersionCheck(newdealerVersion)) return false;
  }

  if (dealerVersion === undefined) {
    console.log('request dealer version from server');
    dealerVersion = await getDealerMacroVersion();
  }

  //this gets us to the v6 dealer version such as "4.0.3.?????????????"
  const requiredDealerVersion = dealerVersion?.version ?? '';

  if (isEmptyOrSpace(requiredDealerVersion)) {
    dealerVersion = undefined;
    if (_displayOfflineMsg) {
      _displayOfflineMsg = false;
      await displaySupplierOffLineMsg();
    }

    return false;
  }

  console.log(`V6 ${dealerVersion?.version} ${dealerVersion?.configVersion}`);
  const usedebugv6 = __localdebug__;
  const isLocalV6 = usedebugv6 == 'true';
  let v6Version = 'v1.0.0';
  v6Version = isLocalV6
    ? activeV6FrameConfigVersion() ?? ''
    : await getRequiredV6ConfigVersion(requiredDealerVersion, dealerVersion?.configVersion);

  globalV6Version = v6Version === '' ? 0 : versionToInteger(v6Version);
  globalV6VersionStr = v6Version;
  //this will fail if no loader happened, of if the version isn't loaded
  if (v6ConfigManager()?.ver(v6Version)) return true;

  const activeVersion = activeV6FrameConfigVersion();
  if (activeVersion !== undefined && activeVersion !== v6Version) {
    //we need to force a reload of the system;
    await information(tlang`%%frame%% configuration requires a page reload`, tlang`Page reload required`);
    _displayOfflineMsg = true;
    window.location.reload();
    return false;
  }

  if (isLocalV6 && v6ConfigManager()?.activeVersion) return true;
  //if we are not asking to load a specific version of v6, then we will determine and load the most active version
  const v6Script = document.createElement('script');

  const v6Source = isLocalV6
    ? `./v6/v6config-entrypoint.js`
    : `https://webmodule-softtech.azureedge.net/v6config/${v6Version}/v6config-entrypoint.js`;

  let completed: string | undefined;
  v6Script.onload = async () => {
    _displayOfflineMsg = true;
    const versionToUse = isLocalV6 ? activeV6FrameConfigVersion() ?? 'v0.0.0' : v6Version;
    globalV6Version = versionToInteger(versionToUse);
    globalV6VersionStr = versionToUse;
    supplierOnline();
    const v6Events = (await v6ConfigEntryPoint(versionToUse))?.events;
    if (!v6Events) {
      console.log(`V6 Config ${v6Version} did not load correctly`);
      completed = 'false';
      return;
    }

    v6Events.setApiInjector(v6ApiInjecterEvent);
    v6Events.setErrorDialogEventHandler(showError);
    //lang is managed globally now
    //v6Events.setLangHandler(tlang, lang);
    v6Events.setModalScreenFactory(modalScreenFactoryImpl);
    v6Events.setUILockAndExecuteEvent(lockUIandExecute);

    v6Events.setUserMode(userSecurity().isPowerUser() ? v6FrameUserMode.powerUser : v6FrameUserMode.standardUser);
    //
    completed = 'true';
  };
  v6Script.setAttribute('src', v6Source);
  document.body.appendChild(v6Script);
  //this section will handle global stuff for v6

  const result = await new Promise<boolean>(resolve => {
    const interval = setInterval(() => {
      if (completed) {
        clearInterval(interval);
        resolve(completed == 'true');
      }
    }, 100);
  });
  if (result) {
    for (let i = 0; i < _events.length; i++) await _events[i]();
  }
  if (!result && _displayOfflineMsg) {
    await displayFrameConfigLoadErrorMsg();
  }
  return result;
}
