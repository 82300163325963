import {
  ResultGetFranchisee,
  InputUpdateFranchisee,
  InputUpdateFranchiseeConfiguration,
  ResultGetBranches,
  RequestGetBranch,
  ResultGetBranch,
  InputUpdateBranch,
  ResultUpdateBranch,
  ResultUpdateFranchisee,
  ResultUpdateFranchiseeConfiguration,
  ResultGetFranchiseeConfiguration,
  InputCreateBranchQuote,
  ResultCreateBranchQuote,
  ResultUpdateBranchQuote,
  InputUpdateBranchQuote,
  InputGetActiveUserInformation,
  ResultGetActiveUserInformation,
  RequestGetBranchQuotes,
  ResultGetBranchQuotes,
  ResultDeleteBranchQuote,
  InputDeleteBranchQuote,
  InputLockResource,
  ResultLockResource,
  InputUnLockResource,
  ResultUnLockResource,
  ResultGetStock,
  RequestGetStock,
  InputUpdateStock,
  ResultUpdateStock,
  RequestLookupStock,
  ResultLookupStock,
  Branch,
  InputCreateBranch,
  InputAddBranchQuoteSupport,
  ResultBranchQuoteSupport,
  InputUpdateBranchQuoteSupport,
  RequestGetBranchQuoteSupport,
  ResultGetBranchQuoteSupport,
  RequestConversation,
  ResultConversation,
  InputAddConversationEntry,
  ResultAddConversationEntry,
  InputUpdateConversationEntry,
  ResultUpdateConversationEntry,
  InputResolveBranchQuoteSupport,
  ResultResolveBranchQuoteSupport,
  InputQueueCleanupConversationAttachments,
  ResultQueueCleanupConversationAttachments,
  InputQuoteItemConversation,
  QuoteItemConversation,
  RequestGetAllLinkedQuoteItemsForConversation,
  ResultGetAllLinkedQuoteItemsForConversation,
  InputUpdateFranchiseeProperties,
  ResultUpdateFranchiseeProperties,
  RequestGetPurchaseOrderUserIdsByBranchId,
  ResultGetPurchaseOrderUserIdsByBranchId,
  InputDuplicateConversation,
  ResultDuplicateConversation,
  InputDuplicateBranchQuoteSupport,
  ResultDuplicateBranchQuoteSupport
} from './dealer-api-interface-franchisee';
import { NullPromise } from '../null-promise';
import { FranchiseeApi } from './franchisee-api';
import { ApiCommunications } from '@softtech/webmodule-data-contracts';
import { tenantId } from '@softtech/webmodule-components';

export class DealerFranchiseeApi implements FranchiseeApi {
  franchiseeApiPath = 'api/Franchisee/';
  conversationApiPath = 'api/Conversation/';
  api: ApiCommunications;

  constructor(api: ApiCommunications) {
    this.api = api;
  }
  async lockResource(input: InputLockResource): NullPromise<ResultLockResource> {
    return await this.api.post<ResultLockResource>(`${this.franchiseeApiPath}ResourceLock/Lock`, input);
  }
  async unlockResource(input: InputUnLockResource): NullPromise<ResultUnLockResource> {
    return await this.api.post<ResultLockResource>(`${this.franchiseeApiPath}ResourceLock/UnLock`, input);
  }

  async getBranchQuotes(input: RequestGetBranchQuotes): NullPromise<ResultGetBranchQuotes> {
    return await this.api.post<ResultGetBranchQuotes>(`${this.franchiseeApiPath}GetBranchQuotes`, input);
  }
  async getActiveUserInformation(input: InputGetActiveUserInformation): NullPromise<ResultGetActiveUserInformation> {
    return await this.api.post<ResultGetActiveUserInformation>(
      `${this.franchiseeApiPath}GetActiveUserInformation`,
      input
    );
  }

  async getFranchisee(): NullPromise<ResultGetFranchisee> {
    return await this.api.post<ResultGetFranchisee>(`${this.franchiseeApiPath}GetFranchisee`, {});
  }

  async getFranchiseeConfiguration(): NullPromise<ResultGetFranchiseeConfiguration> {
    return await this.api.post<ResultGetFranchiseeConfiguration>(
      `${this.franchiseeApiPath}GetFranchiseeConfiguration`,
      {}
    );
  }

  async updateFranchisee(body: InputUpdateFranchisee): NullPromise<ResultUpdateFranchisee> {
    return await this.api.post<ResultUpdateFranchisee>(`${this.franchiseeApiPath}UpdateFranchisee`, body);
  }
  async updateFranchiseePropreties(
    body: InputUpdateFranchiseeProperties
  ): NullPromise<ResultUpdateFranchiseeProperties> {
    return await this.api.post<ResultUpdateFranchiseeProperties>(
      `${this.franchiseeApiPath}UpdateFranchiseeProperties`,
      body
    );
  }

  async updateFranchiseeConfiguration(
    body: InputUpdateFranchiseeConfiguration
  ): NullPromise<ResultUpdateFranchiseeConfiguration> {
    return await this.api.post<ResultUpdateFranchiseeConfiguration>(
      `${this.franchiseeApiPath}UpdateFranchiseeConfiguration`,
      body
    );
  }

  async getBranches(): NullPromise<ResultGetBranches> {
    return await this.api.post<ResultGetBranches>(`${this.franchiseeApiPath}GetBranches`, {});
  }

  async getBranch(body: RequestGetBranch): NullPromise<ResultGetBranch> {
    return await this.api.post<ResultGetBranch>(`${this.franchiseeApiPath}GetBranch`, body);
  }

  async updateBranch(body: InputUpdateBranch): NullPromise<Branch> {
    return (await this.api.post<ResultUpdateBranch>(`${this.franchiseeApiPath}UpdateBranch`, body))?.branch ?? null;
  }

  async createBranch(body: InputCreateBranch): NullPromise<Branch> {
    return (await this.api.post<ResultUpdateBranch>(`${this.franchiseeApiPath}CreateBranch`, body))?.branch ?? null;
  }

  createBrandingLogoImagePath(fileName: string, extension: string): string {
    const date = new Date().getTime();
    return `T${tenantId()}/services/franchisee/logo/${date}/${fileName}.${extension}`;
  }

  async createBranchQuote(input: InputCreateBranchQuote): NullPromise<ResultCreateBranchQuote> {
    return await this.api.post<ResultCreateBranchQuote>(`${this.franchiseeApiPath}CreateBranchQuote`, input);
  }
  async updateBranchQuote(input: InputUpdateBranchQuote): NullPromise<ResultUpdateBranchQuote> {
    return await this.api.post<ResultUpdateBranchQuote>(`${this.franchiseeApiPath}UpdateBranchQuote`, input);
  }
  async deleteBranchQuote(input: InputDeleteBranchQuote): NullPromise<ResultDeleteBranchQuote> {
    return await this.api.post<ResultUpdateBranchQuote>(`${this.franchiseeApiPath}DeleteBranchQuote`, input);
  }

  getFranchiseeImagePath(fileName: string, extension: string): string {
    return this.api.fullUrl(`api/file/T${tenantId()}/services/franchisee/images/${fileName}.${extension}`);
  }

  async getStock(input: RequestGetStock): NullPromise<ResultGetStock> {
    return await this.api.post<ResultGetStock>(`${this.franchiseeApiPath}GetStock`, input);
  }

  async updateStock(input: InputUpdateStock): NullPromise<ResultUpdateStock> {
    return await this.api.post<ResultUpdateStock>(`${this.franchiseeApiPath}UpdateStock`, input);
  }

  async lookupStock(input: RequestLookupStock): NullPromise<ResultLookupStock> {
    return await this.api.post<ResultLookupStock>(`${this.franchiseeApiPath}GetLookupStock`, input);
  }

  async addBranchQuoteSupport(input: InputAddBranchQuoteSupport): NullPromise<ResultBranchQuoteSupport> {
    return await this.api.post<ResultBranchQuoteSupport>(`${this.conversationApiPath}addBranchQuoteSupport`, input);
  }
  async updateBranchQuoteSupport(input: InputUpdateBranchQuoteSupport): NullPromise<ResultBranchQuoteSupport> {
    return await this.api.post<ResultBranchQuoteSupport>(`${this.conversationApiPath}updateBranchQuoteSupport`, input);
  }

  async getBranchQuoteSupport(input: RequestGetBranchQuoteSupport): NullPromise<ResultGetBranchQuoteSupport> {
    return await this.api.post<ResultGetBranchQuoteSupport>(`${this.conversationApiPath}getBranchQuoteSupport`, input);
  }
  async getConversation(input: RequestConversation): NullPromise<ResultConversation> {
    return await this.api.post<ResultConversation>(`${this.conversationApiPath}getConversation`, input);
  }
  async addConversationEntry(input: InputAddConversationEntry): NullPromise<ResultAddConversationEntry> {
    return await this.api.post<ResultAddConversationEntry>(`${this.conversationApiPath}addConversationEntry`, input);
  }
  async duplicateConversation(input: InputDuplicateConversation): NullPromise<ResultDuplicateConversation> {
    return await this.api.post<ResultDuplicateConversation>(`${this.conversationApiPath}DuplicateConversation`, input);
  }
  async duplicateBranchQuoteSupport(
    input: InputDuplicateBranchQuoteSupport
  ): NullPromise<ResultDuplicateBranchQuoteSupport> {
    return await this.api.post<ResultDuplicateBranchQuoteSupport>(
      `${this.conversationApiPath}DuplicateBranchQuoteSupport`,
      input
    );
  }

  async updateConversationEntry(input: InputUpdateConversationEntry): NullPromise<ResultUpdateConversationEntry> {
    return await this.api.post<ResultUpdateConversationEntry>(
      `${this.conversationApiPath}updateConversationEntry`,
      input
    );
  }
  async resolveBranchQuoteSupport(input: InputResolveBranchQuoteSupport): NullPromise<ResultResolveBranchQuoteSupport> {
    return await this.api.post<ResultResolveBranchQuoteSupport>(
      `${this.conversationApiPath}ResolveBranchQuoteSupport`,
      input
    );
  }

  async queueCleanupConversationAttachments(input: InputQueueCleanupConversationAttachments): Promise<void> {
    await this.api.post<ResultQueueCleanupConversationAttachments>(
      `${this.conversationApiPath}QueueCleanupConversationAttachments`,
      input
    );
  }
  async quoteItemConversationLink(input: InputQuoteItemConversation): NullPromise<QuoteItemConversation> {
    return await this.api.post<QuoteItemConversation>(`${this.conversationApiPath}quoteItemConversationLink`, input);
  }

  async getAllLinkedQuoteItemsForConversation(
    input: RequestGetAllLinkedQuoteItemsForConversation
  ): NullPromise<ResultGetAllLinkedQuoteItemsForConversation> {
    return await this.api.post<ResultGetAllLinkedQuoteItemsForConversation>(
      `${this.conversationApiPath}GetAllLinkedQuoteItemsForConversation`,
      input
    );
  }
  get getConversationAttachmentUploadURL(): string {
    return this.api.fullUrl(`${this.conversationApiPath}UploadAttachment`);
  }

  async getPurchaseOrderUserIdsByBranchId(
    input: RequestGetPurchaseOrderUserIdsByBranchId
  ): NullPromise<ResultGetPurchaseOrderUserIdsByBranchId> {
    return this.api.post<ResultGetPurchaseOrderUserIdsByBranchId>(
      `${this.franchiseeApiPath}GetPurchaseOrderUserIdsByBranchId`,
      input
    );
  }
}
