import {
  Address,
  ResultBrowseClientSummary,
  ViewClientSummary,
  ViewContactSummary
} from '../../../api/dealer-api-interface-client';
import { emptyGuid } from '../../../api/guid';
import { DataCache } from '../../../cache/data-cache';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { EventContactOpen } from '../../../clients/data/events';
import { ClientSummaryTableBase, ClientSummaryTableBaseOptions } from '../../../clients/views/client-list-table';
import { EventSnippet } from '../../../components/ui/events';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { resolveURL } from '../../../components/ui/resource-resolver';
import { tlang } from '@softtech/webmodule-components';
import { ClientCacheData } from '../../cache/cache-data';
import { cache } from '../../cache/cache-registry';
import { resourceClient } from '../ui/launcher';

interface ClientSummaryTableOptions extends ClientSummaryTableBaseOptions {
  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  pageFragment: string;
}

//TODO - Client Type should not be here... this is the generic layer, which has no idea of the meaning of a client type
//even though its passing in a generic datacache, it is still kind of incorrect. there should be a subclass of this in
//franchisee-dealer, or just move this there.
//this subclass should then override fetch from server, and make a second call to get the FranchiseeClients
//which would include the payment profile ids, which would then mean i dont need the hack to include a client
//cache in a client table.
export class ClientSummaryTable extends ClientSummaryTableBase {
  paymentProfileCache: DataCacheGeneric = cache().paymentProfile;

  openContactEvent: EventContactOpen;
  addButtonTitle: EventSnippet;
  clientCache: DataCache<ClientCacheData> = cache().client;
  pageFragment: string;

  constructor(options: ClientSummaryTableOptions) {
    super(options);

    this.openContactEvent = options.openContactEvent;
    this.addButtonTitle = options.addButtonTitle;
    this.pageFragment = options.pageFragment;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%client%%`,
        width: '250px',
        data: 'id',
        className: 'client-name',
        render: (data: string, _type: string, row: ViewClientSummary) => {
          return `<a class="client-link" data-client-id=${data} href="${resolveURL(resourceClient, row.id)}">${row.name}</a>`;
        }
      },
      {
        title: tlang`Primary %%contact%%`,
        width: '250px',
        data: 'primaryContactName',
        className: 'client-contact-name',
        render: (data: string, _type: string, row: ViewClientSummary) => {
          if (!data) return '';
          return `<a class="contact-link" data-contact-id=${row.primaryContactId} href="#">${data}</a>`;
        }
      },
      {
        title: tlang`%%contact%% Email`,
        width: '250px',
        orderable: false,
        data: 'primaryContactEmail',
        className: 'client-contact-email',
        render: (value: string) => {
          return `${value}`;
        }
      },
      {
        title: tlang`Address`,
        width: '400px',
        data: 'physicalAddress',
        orderable: false,
        className: 'client-address',
        render: (value: Address) => {
          return `${[value.line1, value.locality, value.region, value.postcode, value.country]
            .filter(Boolean)
            .join(', ')}`;
        }
      },
      {
        title: tlang`%%payment-profile%%`,
        width: '200px',
        orderable: false,
        data: 'id',
        className: 'client-client-type',
        render: (value: string) => `${this.getClientDefaultPaymentProfile(value)}`
      }
    ];
  }

  bindClickEvents($dataTable: any) {
    // bind to all the quote-link classes to implement the quote open event
    $dataTable.on(
      'click',
      '.contact-link',
      this.eventHandler(async (data: ViewClientSummary) => {
        await this.openContact({
          id: data.primaryContactId ?? emptyGuid,
          name: data.primaryContactName ?? '',
          clientId: data.id,
          clientName: data.name,
          title: '',
          email: '',
          isPrimary: false,
          mobile: null
        });
      })
    );
  }

  async doPreFetching(results: ResultBrowseClientSummary) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const clientKeys = results.clientSummary.results!.map(x => x.id ?? '');
    //pump all the clients into a local cache if not there already.
    await this.clientCache.preFetch(clientKeys);
    await super.doPreFetching(results);
  }

  protected getPreFetched(results: ResultBrowseClientSummary): Promise<void>[] {
    const preFetchedList = super.getPreFetched(results);
    const profileKeys = results.clientSummary.results?.map(x => {
      const cacheItem = this.clientCache.getLocal(x.id)?.data;
      const fclient = cacheItem?.franchiseeClient;
      return fclient?.paymentProfileId ?? emptyGuid;
    });
    preFetchedList.push(this.paymentProfileCache.preFetch(profileKeys));
    return preFetchedList;
  }

  private async openContact(viewContactSummary: ViewContactSummary): Promise<void> {
    await this.openContactEvent?.(viewContactSummary);
  }

  private getClientDefaultPaymentProfile(value: string) {
    if (isEmptyOrSpace(value)) return '';
    //prefetch should already have been called
    const paymentProfileId = this.clientCache.getLocalData(value)?.franchiseeClient?.paymentProfileId;

    return this.paymentProfileCache.getLocal(paymentProfileId)?.displayValue ?? '';
  }
}
