/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Branch, Franchisee } from '../../api/dealer-api-interface-franchisee';
// eslint-disable-next-line import/named
import { html } from 'lit';
import { tlang, userSecurity } from '@softtech/webmodule-components';
import '../../components/ui/maps/google-place-autocomplete';
import '../../components/ui/maps/google-map';
import { getApiFactory } from '../../api/api-injector';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import { userDataStore } from '../../dealer-franchisee/common/current-user-data-store';
import { refreshButton } from '../../components/ui/command-action';

import { createBranch } from '../components/franchisee-branches-editor';

export class FranchiseeInformationView extends PageControlTabWithIndependantSaving {
  private branches: Branch[] = [];
  constructor() {
    super();
    this.pageFragment = 'information';
  }

  get franchisee(): Franchisee {
    return userDataStore.franchisee;
  }

  get isAdmin() {
    return userSecurity().isAdmin() || userSecurity().isSupplier();
  }

  public isReadonly(): boolean {
    return !this.isAdmin;
  }

  buttonMenu() {
    const createBranchEvent = () => {
      this.createBranch();
    };
    return html` <button class="btn btn-primary " ?disabled=${this.isReadonly()} @click=${createBranchEvent}>
        ${tlang`Create %%branch%%`}</button
      >${refreshButton(() => this.reloadBranches())}`;
  }

  async createBranch() {
    const b = await createBranch();

    if (b) await this.reloadBranches();
  }
  async reloadBranches() {
    this.branches = (await getApiFactory().franchisee().getBranches())?.branches ?? [];
    this.render();
  }
  public async afterConstruction(): Promise<void> {
    await this.reloadBranches();
  }
  public internalDataChanged(): boolean {
    return false;
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  public async prepareForSave(): Promise<void> {
    //
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected async refreshData() {
    await this.render();
  }

  protected getCaption(): Snippet {
    return tlang`%%franchisee%% !!branch!!`;
  }

  protected async internalSaveData(): Promise<boolean> {
    return true;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const branchChangedEvent = (e: CustomEvent<{ branch: Branch }>) => {
      e.stopImmediatePropagation();
      this.reloadBranches();
    };

    return html`
      <div class="row ">
        <h1 class="text-primary">${this.franchisee.name}</h1>
        <webmodule-franchisee-branches-editor
          .data=${this.branches}
          @wm-be-changed=${branchChangedEvent}
          .readonly=${this.isReadonly()}
        >
        </webmodule-franchisee-branches-editor>
      </div>
    `;
  }
}
