// eslint-disable-next-line import/named
import { getApiFactory } from '../../api/api-injector';
import { BlobApi } from '../../api/blob-api';
import { QuoteApi } from '../../api/quote-api';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { tlang } from '@softtech/webmodule-components';
import { showDevelopmentError } from '../../development-error';
import { QuoteContainerManager } from '../data/quote-container';
import { QuoteItemContainer } from '../data/quote-item-container';
import { QuoteItemPriceAdjustment as QuoteItemPriceAdjustmentDialog } from './quote-item-price-adjust-view';
import { html } from 'lit';
import { base64ToObject } from '../../blob/converters';
import { constructAsync } from '../../async-constructor';
import { QuoteItemPriceAdjustmentSupplier } from './quote-item-price-adjust-supplier-view';
import { isFrame } from '../data/quote-helper-functions';

export type EventQuoteItemView = (quoteItemContainer: QuoteItemContainer) => Promise<void>;

export interface QuoteItemViewOptions {
  quoteManager: QuoteContainerManager;
  quoteItemContainer: QuoteItemContainer | null;
  supplierId: string;
}

//base class to manage the intricacies of quoteitems that may be v6 or otherwise
export class QuoteItemView extends PageControlTabWithIndependantSaving {
  quoteItemContainer: QuoteItemContainer | null;
  quoteApi: QuoteApi = getApiFactory().quote();
  blobApi: BlobApi = getApiFactory().blob();
  supplierId: string;
  quoteManager: QuoteContainerManager;

  constructor(options: QuoteItemViewOptions) {
    super();
    this.quoteItemContainer = options.quoteItemContainer;
    this.supplierId = options.supplierId;
    this.quoteManager = options.quoteManager;
  }

  protected _readyToEdit = true;

  //true then this page is not valid, and should be assumed to be cancelled
  public get readyToEdit(): boolean {
    return this._readyToEdit;
  }

  public get hasPropertyDialog(): boolean {
    return true;
  }

  public get hasModalEditDialog(): boolean {
    return false;
  }

  //this should be called immediately after the constructor and if the return result is not

  discardLabel(): unknown {
    return tlang`Discard Changes`;
  }

  public async saveQuoteItem(): Promise<boolean> {
    return await this.allowPageSwitch();
  }

  async prepareEditor(): Promise<void> {
    this._readyToEdit = true;
  }

  public isTab(): boolean {
    return false;
  }

  public async executeModalEditDialog(): Promise<void> {
    await showDevelopmentError(tlang`Quote Item Modal Editor is not implemented yet`);
  }

  public async executePropertyDialog(): Promise<void> {
    if (!this.quoteItemContainer) return;
    const qic = this.quoteItemContainer;

    const dlg = isFrame(qic.item)
      ? await constructAsync(
          new QuoteItemPriceAdjustmentSupplier(
            this.quoteManager.quotePrice,
            this.quoteItemContainer.price,
            this.quoteItemContainer.item.quantity,
            this.quoteManager.isReadonly(),
            this.quoteItemContainer.buyInData
              ? base64ToObject(this.quoteItemContainer.buyInData) ?? undefined
              : undefined
          )
        )
      : await constructAsync(
          new QuoteItemPriceAdjustmentDialog(
            this.quoteManager.quotePrice,
            this.quoteItemContainer.price,
            this.quoteItemContainer.item.quantity,
            this.quoteManager.isReadonly(),
            this.quoteItemContainer.buyInData
              ? base64ToObject(this.quoteItemContainer.buyInData) ?? undefined
              : undefined
          )
        );

    await dlg.showModal();
    if (dlg.ok) {
      //price has been updated by modal
      this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItemPrice(this.quoteItemContainer.price);
    }
  }

  public getImg(): string {
    return '';
  }

  protected abandonAndCloseButton() {
    if (this.isReadonly()) return html``;
    return html` <button @click="${() => this.abandonAndClose()}" class="btn secondary-btn">
      ${this.discardLabel()}
    </button>`;
  }
}
