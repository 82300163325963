// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { PromiseSnippet, PromiseTemplate } from '../components/ui/events';
import { ModalDialog } from '../components/ui/modal-base';
import { showError } from '../components/ui/show-error';
import { WaitPatientlyLoading } from '../components/ui/modal-loading';
import { tlang } from '@softtech/webmodule-components';
import { TimedTrigger } from '../timed-trigger';

import {
  FamilyResourceTag,
  ResourceFamily,
  ResourceFamilyEndpoint,
  ResourceFamilyResults
} from './family-resource-picker-data';
import { repeat } from 'lit/directives/repeat.js';
import { TemplateResultEvent } from '../components/ui/common-types';

const displayWithFamilyTiles = true;

//const displayResourceWhenDisplayingFamilyTiles = true;

export interface GraphicalFamilyResourcePickerOptions {
  //will pick and trigger the event on click, rather than on select button
  pickImmediately?: boolean;
  show?: {
    selectButton?: boolean;
    indicator?: boolean;
    cancelButton?: boolean;
    tree?: boolean;
    filter?: boolean; //true if undefined
  };
  title?: string;
  resourceName?: string;
  settingsName?: string;
  hideImages?: boolean;
  sideBar?: TemplateResultEvent;
  extraFamily?: ResourceFamilyResults;
  modalSize?: string;
}

const storage = {
  history: 'history',
  favourites: 'favourites',
  family: 'family',
  extra: 'extra'
};

//a view object that supports searching and selecting information for a specific kind of resource
//using a filter. used as an abstract base class
export class GraphicalFamilyResourcePicker extends ModalDialog {
  names = {
    history: tlang`History`,
    favourites: tlang`Favourites`
  };
  //the means to get the data from somewhere.
  comms: ResourceFamilyEndpoint;
  //the currently selected item, either as a picker, or a browser highlighted row
  selected: FamilyResourceTag | null;
  //configuration for operation and callbacks
  options: GraphicalFamilyResourcePickerOptions;
  //the result set which is going to be rendered.
  lastResult: ResourceFamilyResults | null;
  extraResult: ResourceFamilyResults | null;
  activeFamily: ResourceFamily | null;
  timedTrigger: TimedTrigger;
  history: ResourceFamily;
  favourites: ResourceFamily;
  sideBar: TemplateResult | null;
  allFrameLoading = false;
  delayedRenderInterval: NodeJS.Timeout | null = null;
  //the filter used when filtering the local data
  private _filter = '';

  constructor(comms: ResourceFamilyEndpoint, options: GraphicalFamilyResourcePickerOptions) {
    super();
    this.comms = comms;
    this.ui.className += ' v6-family-resource-picker';
    this.options = options;
    this.lastResult = null;
    this.activeFamily = null;
    this.selected = null;
    this.history = { name: this.names.history, id: -1, libId: -1, items: [], families: [] };
    this.favourites = { name: this.names.favourites, id: -1, libId: -1, items: [], families: [] };
    this.sideBar = options.sideBar?.() ?? null;
    this.extraResult = options.extraFamily ?? null;
    this.loadHistoryItems();
    this.loadFavourites();

    //fire a change event only when the text triger fires
    const triggerEvent = e => {
      this.filterChangeEvent(e);
    };
    //set up a trigger on the filter that fires after 1second of idle time, or if a change event occurs
    this.timedTrigger = new TimedTrigger(1000, triggerEvent);
  }

  //update the filter from the UI and re-render the view
  public setFilter(filter: string) {
    this._filter = filter;
    this.render(); //no wait
  }

  getHistoryTemplate(): TemplateResult {
    const history = (index: number): TemplateResult => {
      if (index >= this.history.items.length) return html``;
      return html` <div class="row">${this.itemHistoryTemplate(this.history.items[index])}</div>`;
    };

    return html` <div class="">
      <hr class="dropdown-divider" />
      <div class="">
        <ol>
          ${this.createFamilyLI(this.history, this.names.history)}
        </ol>
      </div>
      ${this.history.items.slice(0, 5).map((_f, i) => {
        return history(i);
      })}
    </div>`;
  }

  getFavouritesTemplate(): TemplateResult {
    return html` <div class="">
      <hr class="dropdown-divider" />
      <div class="">
        <ol>
          ${this.createFamilyLI(this.favourites, this.names.favourites)}
        </ol>
      </div>
    </div>`;
  }

  createChildOL(family: ResourceFamily) {
    if (!family || family.families.length === 0) return html``;
    return html`
      <ol>
        ${family.families.filter(nf => this.familyItemCount(nf) > 0).map(nf => this.createFamilyLI(nf))}
      </ol>
    `;
  }

  //filter results by text

  createFamilyLI(family: ResourceFamily | undefined, title?: string) {
    const frameClickEvent = (e: Event, resourceFamily: ResourceFamily) => {
      e.preventDefault();
      this.setActiveFamily(resourceFamily);
    };
    if (!family) return html``;
    const badgeClass = 'qty-badge';
    const badge =
      family.items.length > 0
        ? html`<span class=${badgeClass}
            >(${family.items.length})<span class="visually-hidden">unread messages</span></span
          >`
        : html``;

    const frameClick = (e: Event) => frameClickEvent(e, family);
    if (family === this.activeFamily) {
      return html` <li class="v6-family-navigation-active">
        <a class="position-relative" @click=${frameClick}>${title ?? family.name}${badge}</a>
        ${this.createChildOL(family)}
      </li>`;
    } else {
      return html` <li class="v6-family-navigation">
        <a class="position-relative" href="#" @click=${frameClick}>${title ?? family.name}${badge}</a>
        ${this.createChildOL(family)}
      </li>`;
    }
  }

  getFamilyTreeViewTemplateContent(): TemplateResult {
    const caption = this.hasFamilies() ? tlang`Categories` : tlang`Items`;
    return html` <div class="">
      <ol>
        ${this.createFamilyLI(this.lastResult?.family, caption)}
      </ol>
    </div>`;
  }

  getFamilyTileTemplate(family: ResourceFamily): TemplateResult {
    const familyClick = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      this.setActiveFamily(family);
    };
    const imgSrc = this.comms.getFamilyImageUrl(this.comms.getResourceClass(), family.libId ?? 0, family.id ?? 0);
    return html`
            <div class="col-4  graphical-picker-card-family-container">
                <div class="card shadow" @click=${familyClick}>
                    <div class="card-body">
                        <img src=${imgSrc} class="card-img-top graphical-picker-family-img" alt="...">
                        <p class="card-title fw-bold">${family.name}</>
                    </div>
                </div>
            </div>`;
  }

  getSideBarTemplate(): TemplateResult | null {
    return this.sideBar;
  }

  getVisibleItems(): FamilyResourceTag[] {
    const results: FamilyResourceTag[] = [];

    function loadResults(family: ResourceFamily | null) {
      if (family) {
        results.push(...family.items);
        family.families.forEach(f => loadResults(f));
      }
    }

    const matchFilter = (item: FamilyResourceTag) => {
      const filter = this._filter.toLowerCase() ?? '';
      if (filter === '') return true;
      return item.code.toLowerCase().includes(filter) || item.description?.toLowerCase().includes(filter);
    };

    loadResults(this.activeFamily);
    return results.filter(item => matchFilter(item));
  }

  deleteHistoryItem(item: FamilyResourceTag) {
    this.history.items = this.history.items.filter(x => x.objectReference !== item.objectReference);
    localStorage.setItem(this.getSettingName(storage.history), JSON.stringify(this.history));
    this.render(); //no wait
  }

  deleteFavouriteItem(item: FamilyResourceTag) {
    this.favourites.items = this.favourites.items.filter(x => x.objectReference !== item.objectReference);
    localStorage.setItem(this.getSettingName(storage.favourites), JSON.stringify(this.favourites));
    this.render(); //no wait
  }

  addFavourite(item: FamilyResourceTag) {
    if (!this.favourites.items.some(x => x.objectReference === item.objectReference)) {
      this.favourites.items.push(item);
      localStorage.setItem(this.getSettingName(storage.favourites), JSON.stringify(this.favourites));
      this.render(); //no wait
    }
  }

  getItemImageResourceClass(item: FamilyResourceTag) {
    return item.resourceClassId ?? this.comms.getResourceClass();
  }

  findResourceTag(objectReference: string | undefined): FamilyResourceTag | null {
    let result: FamilyResourceTag | null = null;
    if (!objectReference) return null;

    function loadResults(family: ResourceFamily | null | undefined) {
      if (result) return;
      if (family) {
        const match = family.items.filter(x => x.objectReference === objectReference);
        if (match.length > 0) {
          result = match[0];
          return;
        }
        family.families.forEach(f => {
          if (!result) loadResults(f);
        });
      }
    }

    loadResults(this.lastResult?.family);
    return result;
  }

  public async onShowModal() {
    await this.render();
    if (!this.lastResult) {
      //we are not awaiting this promise. its set and forget
      await this.refreshData();
    }
  }

  public async hideModal() {
    if (this.delayedRenderInterval) clearTimeout(this.delayedRenderInterval);
    await super.hideModal();
  }

  // Alexey: <p class="filter-title mb-0">Filter:</p> removed No needed p element
  protected filterTemplate(): TemplateResult {
    const resetEvent = this.timedTrigger.getResetEvent();
    const triggerEvent = this.timedTrigger.getTriggerEarlyEvent();
    if (this.options.show?.filter !== undefined && !this.options.show?.filter) return html``;
    return html` <div class="row form-group filter-wrapper">
      <div class="col-1 filter-text">Filter:</div>
      <div class="col-4 filter-input">
        <input
          class="form-control"
          placeholder="Text Search"
          @oninput=${resetEvent}
          @blur=${triggerEvent}
          @keyup=${resetEvent}
        />
      </div>
    </div>`;
  }

  protected setActiveFamily(resourceTag: ResourceFamily | null) {
    this.activeFamily = resourceTag;
    if (this.activeFamily) localStorage.setItem(this.getSettingName(storage.family), this.familyToString(resourceTag));
    else localStorage.removeItem(this.getSettingName(storage.family));

    //delay rendering row items over families to force loading in a good order
    const hasFamilyTileTemplate =
      this._filter === '' &&
      this.hasFamilies() &&
      displayWithFamilyTiles &&
      this.activeFamily &&
      this.activeFamily.families.length > 0;
    this.allFrameLoading = !hasFamilyTileTemplate;

    this.render(); // no wait

    //delay image fetching on anything except the family images to prioritize them
    if (!this.allFrameLoading)
      this.delayedRenderInterval = setTimeout(() => {
        this.allFrameLoading = true;
        this.render(); // no wait
      }, 1500);
  }

  //this is the primary template for the data table, inclusive of pagination
  protected async bodyTemplate(): PromiseTemplate {
    if (!this.lastResult) {
      return html` <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`;
    }

    const treeContent = (): TemplateResult => {
      if (this.options.show?.tree) {
        return html` <div class="col-3 graphical-picker-tree">
          ${this.getExtraFamilyTreeviewTemplateContent()} ${this.getFamilyTreeViewTemplateContent()}
          ${this.getFavouritesTemplate()} ${this.getHistoryTemplate()}
        </div>`;
      } else {
        return html``;
      }
    };
    let deleteEvent: ((item: FamilyResourceTag) => void) | null = null;
    if (this.activeFamily === this.favourites)
      deleteEvent = (item: FamilyResourceTag) => {
        this.deleteFavouriteItem(item);
      };
    if (this.activeFamily === this.history)
      deleteEvent = (item: FamilyResourceTag) => {
        this.deleteHistoryItem(item);
      };
    const hasFamilies = this.hasFamilies();
    const hasFamilyTileTemplate =
      this._filter === '' &&
      hasFamilies &&
      displayWithFamilyTiles &&
      this.activeFamily &&
      this.activeFamily.families.length > 0;
    const familyTileTemplate = hasFamilyTileTemplate
      ? html` <div class="row graphical-picker-list graphical-family-picker-list">
          ${this.activeFamily?.families
            .filter(nf => this.familyItemCount(nf) > 0)
            .map(fam => this.getFamilyTileTemplate(fam))}
        </div>`
      : html``;

    const sideBarTemplate = this.getSideBarTemplate();
    const classes =
      hasFamilies || this.options.show?.tree
        ? 'col-9 graphical-picker-list-wrapper'
        : 'col-12 graphical-picker-list-wrapper no-family';

    const visibleRowItems =
      this.allFrameLoading || !hasFamilyTileTemplate
        ? html` <div class="row graphical-picker-list">
            ${repeat(
              this.getVisibleItems(),
              item => item.objectReference,
              (item, _index) => this.itemTemplate(item, deleteEvent)
            )}
          </div>`
        : html``;

    const mainBodyTemplate = html`
      <div class="row">
        ${treeContent()}
        <div class=${classes}>${this.filterTemplate()} ${familyTileTemplate} ${visibleRowItems}</div>
      </div>
    `;

    return sideBarTemplate
      ? html` <div class="graphical-picker-body">
          <div class="row">
            <div class="col-10 graphical-picker-table">${mainBodyTemplate}</div>
            <div class="col-2 graphical-picker-thumbnail">${sideBarTemplate}</div>
          </div>
        </div>`
      : html` <div class="graphical-picker-body"><div class="graphical-picker-table">${mainBodyTemplate}</div></div>`;
  }

  protected getExtraFamilyTreeviewTemplateContent(): TemplateResult {
    if (!this.extraResult) return html``;
    return html` <div class="">
      <ol>
        ${this.createFamilyLI(this.extraResult.family, this.extraResult.family.name)}
      </ol>
    </div>`;
  }

  protected renderFooterTemplate(): boolean {
    return this.options.show?.selectButton != undefined || this.options.show?.cancelButton != undefined;
  }

  protected footerTemplate(): TemplateResult {
    const selectButton = this.options.show?.selectButton
      ? html` <button type="button" class="btn btn-primary" @click=${() => this.select()}>Select</button>`
      : html``;
    const cancelButton = this.options.show?.cancelButton
      ? html` <button type="button" class="btn btn-secondary" @click=${() => this.select(null)}>Cancel</button>`
      : html``;
    return html`${cancelButton}${selectButton}`;
  }

  protected modalSize(): string {
    return this.options.modalSize ?? 'modal-fullscreen';
  }

  protected closeButtonTemplate(): TemplateResult {
    const cancelClick = () => this.select(null);
    return html` <button type="button" class=${this.closeButtonCss()} @click=${cancelClick} aria-label="Close">
      ${this.closeButtonText()}
    </button>`;
  }

  protected async getTitle(): PromiseSnippet {
    const resName = this.options.resourceName ?? tlang`%%resource%%`;
    return this.options.title ?? tlang`${resName} Picker`;
  }

  protected select(forceSelection?: FamilyResourceTag | null) {
    //set the selected item for those waiting async on showModal
    this.selected = forceSelection === undefined ? this.selected : forceSelection;
    if (this.selected) this.selected.resourceClassId = this.getItemImageResourceClass(this.selected);

    //add to history if required
    if (this.selected !== undefined && this.selected !== null) {
      this.addHistoryItem(this.selected);
    }

    this.hideModal();
  }

  protected cancel() {
    this.select(null);
  }

  private loadFavourites() {
    const favStr = localStorage.getItem(this.getSettingName(storage.favourites));
    if (favStr) this.favourites = JSON.parse(favStr);
  }

  private loadHistoryItems() {
    const historyStr = localStorage.getItem(this.getSettingName(storage.history));
    if (historyStr) this.history = JSON.parse(historyStr);
  }

  private filterChangeEvent(e: Event) {
    //update the filter
    this.setFilter((e.target as HTMLInputElement).value);
  }

  private getSettingName(name: string): string {
    return `picker-${this.options.settingsName ?? this.options.resourceName}:${name}`;
  }

  private familyToString(resourceTag: ResourceFamily | null): string {
    if (!resourceTag) return '';
    return `${resourceTag.libId},${resourceTag.id},${resourceTag.name}`;
  }

  private familyItemCount(family: ResourceFamily): number {
    let count = 0;

    function iterate(resourceFamily: ResourceFamily) {
      count += resourceFamily.items.length;
      resourceFamily.families.forEach(f => iterate(f));
    }

    iterate(family);
    return count;
  }

  private findFamily(familyString: string): ResourceFamily | null {
    const data = familyString.split(',');
    const libid = parseInt(data[0]);
    const id = parseInt(data[1]);
    const name = data[2];

    if (name === this.names.favourites) return this.favourites;
    if (name === this.names.history) return this.history;

    let result: ResourceFamily | null = null;
    const iterate = (family: ResourceFamily): boolean => {
      if (family.name === name && family.id === id && family.libId == libid) {
        if (this.familyItemCount(family) > 0) result = family;
        return true;
      }
      return family.families.some(f => iterate(f));
    };

    let toplevel = this.extraResult?.family ?? this.lastResult?.family;
    if (!toplevel) return null;
    iterate(toplevel);
    if (result) return result;

    toplevel = this.lastResult?.family;
    if (!toplevel) return null;
    iterate(toplevel);
    return result;
  }

  private hasFamilies(): boolean {
    return (this.lastResult?.family.families ?? []).length > 0;
  }

  private itemHistoryTemplate(item: FamilyResourceTag): TemplateResult {
    const clickEvent = () => {
      this.clickEvent(item);
    };
    const deleteEvent = (e: Event) => {
      e.stopPropagation();
      e.preventDefault();
      this.deleteHistoryItem(item);
    };

    const imgSrc = this.comms.getResourceImageUrl(this.getItemImageResourceClass(item), item.libId ?? 0, item.id ?? 0);
    const img = this.allFrameLoading
      ? html`<img src=${imgSrc} class="card-img-top graphical-picker-img" alt="..." />`
      : html``;
    return html` <div
      class=" col-12 card frame-history-card"
      data-object-reference=${item.objectReference}
      data-code=${item.code}
      @click=${clickEvent}
      @dblclick=${clickEvent}
    >
      <div class="row py-2 px-0 card-body">
        <div class="col-2 pr-2">${img}</div>
        <div class="col-8 px-0">${item.code ?? item.description}</div>
        <button @click=${deleteEvent} type="button" class="col-2 pl-2 btn-close text-right" aria-label="Close"></button>
      </div>
    </div>`;
  }

  private clickEvent(item: FamilyResourceTag) {
    if (item.disabled) return;
    this.selected = item;
    if (this.options.pickImmediately || !this.options.show?.selectButton) {
      this.select();
    }
  }

  // the row template
  private itemTemplate(
    item: FamilyResourceTag,
    deleteEvent: ((item: FamilyResourceTag) => void) | null
  ): TemplateResult {
    const clickEvent = () => {
      this.clickEvent(item);
    };
    const addFavEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      this.addFavourite(item);
    };
    const delEvent = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      if (deleteEvent) deleteEvent(item);
    };

    const imgSrc = this.comms.getResourceImageUrl(this.getItemImageResourceClass(item), item.libId ?? 0, item.id ?? 0);
    const specialBtnTemplate: TemplateResult = !this.hasFamilies()
      ? html``
      : deleteEvent
        ? html` <button @click=${delEvent} class="btn-close"></button>`
        : this.isFavourite(item)
          ? html` <button @click=${addFavEvent} class="btn-close btn-heart-solid"></button>`
          : html` <button @click=${addFavEvent} class="btn-close btn-heart"></button>`;

    const img = this.allFrameLoading
      ? html`<img src=${imgSrc} class="card-img-top graphical-picker-img" alt="..." />`
      : html``;

    return !this.options.hideImages
      ? html`
                    <div role=${item.disabled ? '' : 'button'} class="col-2 graphical-picker-card-container">
                        <div class="card shadow" data-object-reference=${item.objectReference} data-code=${item.code}
                             @click=${clickEvent}>
                            <div class="card-body">
                                ${specialBtnTemplate}
                                ${img}
                                <p class="card-title fw-light">${item.code ?? ''}</>
                                <p class="card-subtitle fw-bolder ${item.disabled ? 'text-secondary' : ''}">${item.description}</p>
                            </div>
                        </div>
                    </div>`
      : html` <div role=${item.disabled ? '' : 'button'}>
          <div
            class="card shadow"
            data-object-reference=${item.objectReference}
            data-code=${item.code}
            @click=${clickEvent}
          >
            <div class="card-body">
              ${specialBtnTemplate}
              <span class="card-title fw-light me-2">${item.code ?? item.description}</span>
              <span class="card-subtitle fw-bolder ${item.disabled ? 'text-secondary' : ''}">${item.description}</span>
            </div>
          </div>
        </div>`;
  }

  private isFavourite(item: FamilyResourceTag): boolean {
    return this.favourites.items.some(x => x.objectReference === item.objectReference);
  }

  //public, but should never really need to be called
  private async refreshData(): Promise<ResourceFamilyResults | null> {
    const wait = new WaitPatientlyLoading();
    try {
      const result = await this.comms.getData();
      if (!result) await this.hideModal();
      this.lastResult = result;
      this.applyLastFamily();
    } catch (e) {
      await showError(e as Error);
    } finally {
      await wait.hideModal();
    }
    return this.lastResult;
  }

  private applyLastFamily() {
    const familyStr = localStorage.getItem(this.getSettingName(storage.family));
    if (familyStr) {
      const fam = this.findFamily(familyStr);
      if (fam) this.setActiveFamily(fam);
      else this.setActiveFamily(this.lastResult?.family ?? null);
    } else this.setActiveFamily(this.lastResult?.family ?? null);
  }

  private addHistoryItem(selection: FamilyResourceTag) {
    this.history.items = [
      selection,
      ...this.history.items.filter(x => x.objectReference !== selection.objectReference)
    ];
    if (this.history.items.length > 10) this.history.items.splice(10);
    localStorage.setItem(this.getSettingName(storage.history), JSON.stringify(this.history));
  }
}
