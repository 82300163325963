export enum DealerDeploymentOperationType {
  Production = 0,
  Test = 1
}
interface ResultBrandInfo {
  loginImageUrl: string;
  loginBrandLogo: string;
  footerBrandLogo: string;
  operationType: DealerDeploymentOperationType;
}

export function brandInfo(): ResultBrandInfo {
  return globalThis.dealerConfiguration.branding as ResultBrandInfo;
}

export function isTestSite(): boolean {
  const mocking = globalThis.dealerConfiguration.theme;
  if (mocking) return mocking === 'TEST';
  return (brandInfo().operationType ?? DealerDeploymentOperationType.Production) === DealerDeploymentOperationType.Test;
}

export function loginImageUrl(): string {
  return brandInfo().loginImageUrl;
}
export function loginBrandLogoUrl(): string {
  return brandInfo().loginBrandLogo;
}
export function footerBrandLogoUrl(): string {
  return brandInfo().footerBrandLogo;
}

export function injectThemeClasses() {
  const hasTestClasses = document.body.classList.contains('test-only-ui');
  if (isTestSite() && !hasTestClasses) document.body.classList.add('test-only-ui');
  if (!isTestSite() && hasTestClasses) document.body.classList.remove('test-only-ui');
  globalThis.dealerConfiguration.litApp.updateLogoTemplate();
  globalThis.dealerConfiguration.litApp.requestUpdate();
}

globalThis.dealerMockTestSite = () => {
  globalThis.dealerConfiguration.theme = 'TEST';
  injectThemeClasses();
};
globalThis.dealerMockProdSite = () => {
  globalThis.dealerConfiguration.theme = 'PROD';
  injectThemeClasses();
};
globalThis.dealerClearMockSite = () => {
  globalThis.dealerConfiguration.theme = undefined;
  injectThemeClasses();
};

globalThis.dealerApiServerBuildNumber = () => {
  globalThis.dealerConfiguration.apiBuildNumber ?? 0;
};

export function getDealerApiServerBuildNumber(): number {
  return globalThis.dealerConfiguration.apiBuildNumber ?? 0;
}
