// eslint-disable-next-line import/named
import { html } from 'lit';
import { QuoteItemType } from '../../api/dealer-api-interface-quote';
import { money } from '../../components/currency-formatter';

import {
  DynamicValueBinder,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../components/ui/databinding/data-tracker';
import { PromiseSnippet, PromiseTemplate } from '../../components/ui/events';

import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { DevelopmentError } from '../../development-error';
import { quoteItemContentType } from '../data/default-quote-item-content-type';
import { QuoteItemContainer } from '../data/quote-item-container';
import { newGuid } from '../../api/guid';
import { LineItemDialog, LineItemView } from './line-item-view';
import { QuoteContainerManager } from '../data/quote-container';
import { isSSI } from '../data/quote-helper-functions';
import { updateDealerFreehandPrice } from '../data/v6/helper-functions';
import { InformationDispatcher } from '../../components/ui/information-dispatcher';
import { SaveWorkflowModal } from '../../components/save-workflow';
import { V6SSIProcessor } from '../data/v6/v6-ssi-processor';

export class SpecialLineItemDialog extends LineItemDialog {
  interval: NodeJS.Timeout | null = null;

  constructor(quoteManager: QuoteContainerManager, quoteItemContainer: QuoteItemContainer, forceReadonly: boolean) {
    super(quoteManager, quoteItemContainer, forceReadonly);

    if (quoteItemContainer.item.itemType !== QuoteItemType.Basic)
      throw new DevelopmentError(
        `special-line-item-view, ${quoteItemContainer.item.description} is not a special line item.`
      );
    if (quoteItemContainer.item.quoteItemContentType !== quoteItemContentType.specialItem)
      throw new DevelopmentError(`quote item ${quoteItemContainer.item.title} is not a special line item`);

    const addDynamicMoney = (
      fieldName: string,
      getter: EventValueGetter,
      setter: EventValueSetter,
      readonly?: () => boolean
    ) => {
      this.dataTracker.addBinding(
        new DynamicValueBinder(FieldType.money, false, getter, setter, readonly),
        fieldName,
        fieldName,
        FieldType.money,
        false
      );
    };

    const item = this.quoteItemContainer.item;
    const price = this.quoteItemContainer.price;

    this.dataTracker.addObjectBinding(() => item, 'title', 'title', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'description', 'description', FieldType.string, false);
    this.dataTracker.addObjectBinding(() => item, 'quantity', 'quantity', FieldType.float, false);
    this.dataTracker.addObjectBinding(() => item, 'comment', 'comment', FieldType.string, false);

    //this.dataTracker.addObjectBinding(() => price, 'singleUnitCost', 'singleUnitCost', FieldType.money, false);

    addDynamicMoney(
      'singleUnitCost',
      () => this.quoteItemContainer.price.singleUnitCost,
      () => {
        console.log('singleUnitCost value set');
      },
      () => true
    );

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.money,
        false,
        () => {
          try {
            const qty = (this.dataTracker.getEditorValue('quantity') as number) ?? 0;
            const unitCost = (this.dataTracker.getEditorValue('singleUnitCost') as number) ?? 0;
            return money(qty * unitCost, 2);
          } catch {
            return price.quantityCost;
          }
        },
        () => {
          //Do nothing. This is set in the event with the supplier unit cost.
        },
        () => {
          return true;
        }
      ),
      'quantityCost',
      'quantityCost',
      FieldType.money,
      false
    );

    const triggerEvent = (e: Event) => {
      const elem = e.target as HTMLInputElement;
      const qtyCost = this.dataTracker.getEditorValue('quantityCost') as number;
      const unitCost = this.dataTracker.getEditorValue('singleUnitCost') as number;
      const qty = this.dataTracker.getEditorValue('quantity') as number;

      if (elem.id == this.dataTracker.binder.field('singleUnitCost')) {
        //Set the unit cost. This is also an item being sent to the supplier, so we need to set the supplier unit cost.
        this.quoteItemContainer.price.singleUnitCost = unitCost;
        this.quoteItemContainer.price.supplierGrossSingleUnitCost = unitCost;
      }

      if (money(qty * unitCost, 2) !== qtyCost) {
        this.render(); //no wait
      }
    };

    $(this.ui).on('keyup', 'input', (e: JQuery.TriggeredEvent) => {
      const elem = e.target as HTMLInputElement;
      const event = e.originalEvent as KeyboardEvent;
      console.log(`${elem.id} ${elem.value}`);
      if (
        elem.id == this.dataTracker.binder.field('quantity') ||
        elem.id == this.dataTracker.binder.field('singleUnitCost')
      )
        //   if (event.code === "Enter") {
        triggerEvent(event); //this.timedTrigger.triggerEarly(e);
      // }
    });
    $(this.ui).on('blur', 'input', (e: JQuery.TriggeredEvent) => {
      const elem = e.target as HTMLInputElement;
      if (
        elem.id == this.dataTracker.binder.field('quantity') ||
        elem.id == this.dataTracker.binder.field('singleUnitCost')
      )
        triggerEvent(e.originalEvent as Event); //this.timedTrigger.triggerEarly(e);
    });
  }

  protected async getTitle(): PromiseSnippet {
    return tlang`%%special-item%%`;
  }

  protected async internalSave() {
    updateDealerFreehandPrice(this.quoteItemContainer.item.quantity, this.quoteItemContainer.price);
    this.ok = true;
  }

  protected async getValidationErrors(): Promise<string[]> {
    const errors = await super.getValidationErrors();

    const quantity = this.dataBinding.getFloat('quantity') ?? 0;
    if (quantity <= 0) {
      errors.push(tlang`Please provide a quantity greater than 0`);
    }

    return errors;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.forceReadonly);
    return html`
      <form class="special-item-editor-container form-one-col">
        <div class="row">
          <div class="form-column">
            ${forms.textRequired('title', tlang`Title`, 120)} ${forms.textRequired('description', 'Reference', 120)}
            ${forms.float('quantity')} ${forms.money('singleUnitCost', 'Unit Cost', 2)}
            ${forms.moneyReadonly('quantityCost', 'Total Cost')} ${forms.note('comment', 'Comment', 500)}
          </div>
        </div>
      </form>
    `;
  }
}

export class SpecialLineItemView extends LineItemView {
  //true then this page is not valid, and should be assumed to be cancelled
  public async prepareEditor(): Promise<void> {
    if (this.quoteItemContainer) {
      this._readyToEdit = true;
    } else {
      this._readyToEdit = await this.createQuoteItem();
    }
  }
  public get hasPropertyDialog(): boolean {
    return true;
  }
  public get hasModalEditDialog(): boolean {
    if (this.quoteItemContainer && isSSI(this.quoteItemContainer.item)) return false;
    return true;
  }

  async createQuoteItem(): Promise<boolean> {
    const result = await this.quoteManager.createQuoteItem({
      isRestrictedToPowerUser: false,
      id: newGuid(),
      title: tlang`New %%special-item%%`,
      description: '',
      quantity: 1,
      comment: '',
      quoteItemContentType: quoteItemContentType.specialItem,
      externalProvider: null,
      buyInData: null,
      price: {
        sourceData: {},
        singleUnitCost: 0,
        supplierGrossSingleUnitCost: 0,
        supplierNettSingleUnitCost: 0,
        supplierPriceAdjustment: 0
      },
      thumbnail: null
    });
    if (result) {
      this.quoteItemContainer = result;
      return true;
    }
    return false;
  }

  public async executeModalEditDialog(): Promise<void> {
    if (!this.quoteItemContainer) return;
    const dlg = new SpecialLineItemDialog(this.quoteManager, this.quoteItemContainer, this.quoteManager.isReadonly());

    await dlg.showModal();
    if (dlg.ok) {
      if (!this.quoteManager.changedItem(this.quoteItemContainer.item.id)) return;
      const qm = this.quoteManager;
      const informationDispatcher = new InformationDispatcher();
      const setInfo = async (s: string, header = 'Preparing to save') => {
        await informationDispatcher.setInformation(`# ${header}

                + ${s}`);
      };
      await setInfo(tlang`getting updates from %%supplier%%`);
      const saveModal = new SaveWorkflowModal(
        tlang`Saving ${this.quoteItemContainer.item.title}`,
        informationDispatcher,
        3
      );
      const modalState = await saveModal.show();
      await modalState.onShow;
      try {
        await setInfo(tlang`Requesting Updates from %%supplier%%`);
        //process ssi with new information
        //build an ssi processor that will build the quote exluding the deleted item, so that we get data
        //based on its non-existence
        const ssiProcessor = new V6SSIProcessor(qm);
        const ssiInputs = await ssiProcessor.processSSI();
        await setInfo(tlang`%%supplier%% processing complete. 
                
                + sending updates to server`);

        this.quoteItemContainer = await this.quoteManager.saveAndUpdateQuoteItem(
          this.quoteItemContainer,
          '',
          ssiInputs
        );
      } finally {
        saveModal?.done();
        await saveModal?.hideModal();
      }
    }
  }
}
