import { tlang } from '@softtech/webmodule-components';

import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Branch } from '../../api/dealer-api-interface-franchisee';
import { FormInputSelectValue } from '../../components/FormInputView';
import { emptyGuid } from '../../api/guid';

@customElement('webmodule-franchisee-branch-creator')
export class WebModuleFranchiseeBranchCreator extends LitElement {
  @property()
  private _branches: Branch[] = [];
  private get selectedBranch(): Branch | null {
    return this._branches.find(x => x.id === this.value) ?? null;
  }
  public get branches(): Branch[] {
    return this._branches;
  }
  public set branches(value: Branch[]) {
    this._branches = value;
  }
  @property() readonly = false;
  @property()
  private _value = '';
  public get value() {
    return this._value;
  }
  public set value(value) {
    if (this.value !== value) {
      this._value = value;
      this.branchChangedEvent();
    }
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };
    //wm-be webmodule-brancheditor
    this.dispatchEvent(new CustomEvent(`wm-bc-${name}`, options));
  }
  branchChangedEvent() {
    this.dispatchCustom('changed', {
      value: this.value
    });
  }
  branchAddEvent() {
    this.dispatchCustom('add', {});
  }
  branchCancelAddEvent() {
    this.dispatchCustom('cancel-add', {});
  }
  private changeEvent = (e: Event) => {
    e.stopImmediatePropagation();
    this.value = (e.target as HTMLSelectElement).value;
  };
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('change', this.changeEvent);
  }
  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('change', this.changeEvent);
  }

  render() {
    const addBranchEvent = (_e: Event) => {
      this.branchAddEvent();
    };
    const cancelAddEvent = (_e: Event) => {
      this.branchCancelAddEvent();
    };
    const abandonNewTemplate =
      this.selectedBranch?.id === emptyGuid
        ? html` <button class="btn btn-primary" @click=${cancelAddEvent}>${tlang`Abandon new %%branch%%`}</button>`
        : html``;

    return html`<div class="row">
      <div class="col">
        <bs-form-select
          data-placeholder=${tlang`Select %%branch%%`}
          data-label=${tlang`Select %%branch%%`}
          ?disabled=${this.readonly}
          ?readonly=${this.readonly}
          .value=${this.value}
          .options=${this.branchToOptions()}
        >
        </bs-form-select>
      </div>
      <div class="col">
        <button class="btn btn-primary" @click=${addBranchEvent}>${tlang`Add %%branch%%`}</button>
        ${abandonNewTemplate}
      </div>
    </div> `;
  }
  branchToOptions(): FormInputSelectValue[] {
    return this.branches.map(x => {
      const sv: FormInputSelectValue = {
        disabled: false,
        text: x.name,
        value: x.id
      };
      return sv;
    });
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}
