import {
  PurchaseOrderBranchView,
  ResultGetPurchaseOrderDeliveryPipeline
} from '../../../api/dealer-api-interface-franchisee';
import { EventGetReference } from '../../../components/ui/events';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../../components/ui/datatable-view';
import { PurchaseOrderApi } from '../../../api/purchase-order-api';
import { getApiFactory } from '../../../api/api-injector';
import { tlang } from '@softtech/webmodule-components';
import { localDateTimeToServer, serverDateTimeToLocal } from '../../../components/datetime-converter';
import { resourcePurchaseOrder } from '../../../purchase-orders/ui/launcher';
import { DateTime } from 'luxon';
import { resolveURL } from '../../../components/ui/resource-resolver';

export interface PurchaseOrderSummaryTableOptions {
  purchaseOrderOwnerId: EventGetReference;
  assignedToUserId: EventGetReference;
}

export class PurchaseOrderPipelineTable extends DataTableWrapper<PurchaseOrderBranchView> {
  purchaseOrderApi: PurchaseOrderApi = getApiFactory().purchaseOrder();
  purchaseOrderOwnerId: EventGetReference;
  assignedToUserId: EventGetReference;
  cutoff: Date | null = null;

  constructor(options: PurchaseOrderSummaryTableOptions) {
    super();

    this.purchaseOrderOwnerId = options.purchaseOrderOwnerId;
    this.assignedToUserId = options.assignedToUserId;
  }

  public override afterRowCreated(_row: any, _data: PurchaseOrderBranchView, _dataIndex: number): void {
    const installDate = _data.supplierSystemDeliveryDate ?? _data.installationDate;
    const installationDate = installDate ? serverDateTimeToLocal(installDate) : null;

    if (installationDate == null || installationDate > DateTime.now().toJSDate()) return;

    $(_row).addClass('table-danger');
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PurchaseOrderBranchView>> {
    const serverDate = this.cutoff == null ? null : localDateTimeToServer(this.cutoff);

    const results = await this.purchaseOrderApi.getPurchaseOrderDeliveryPipeline({
      purchaseOrderOwnerId: await this.purchaseOrderOwnerId(),
      assignedToUserId: await this.assignedToUserId(),
      pageIndex: request.pageIndex,
      pageSize: request.pageSize,
      cutOffDate: serverDate
    });
    if (!results) {
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };
    }

    await this.doPreFetching(results);

    return results.purchaseOrdersBranchView;
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%purchase-order-abrev%% No.`,
        width: '90px',
        data: 'purchaseOrderNumber',
        orderable: false,
        className: 'purchase-order-number',
        render: (value: number, _type, row: PurchaseOrderBranchView) =>
          this.getPurchaseOrderLink(row, value == 0 ? 'N/A' : value.toString())
      },
      {
        title: tlang`%%purchase-order-abrev%% Title`,
        width: '100px',
        data: 'title',
        orderable: false,
        className: 'purchase-order-title',
        render: (value: string, _type: never, row: PurchaseOrderBranchView) => {
          return this.getPurchaseOrderLink(row, value);
        }
      },
      {
        title: tlang`MFG Ref.`,
        width: '90px',
        data: 'reference',
        orderable: false,
        className: 'mfg_reference',
        render: (value: string | null, _type, _row: PurchaseOrderBranchView) => {
          return value == null ? '' : value;
        }
      },
      {
        title: tlang`MFG Status`,
        width: '90px',
        data: 'supplierSystemStatus',
        orderable: false,
        className: 'mfg_status',
        render: (value: string | null, _type, _row: PurchaseOrderBranchView) => {
          return value == null ? '' : value;
        }
      },
      {
        title: tlang`Proposed Delivery`,
        width: '90px',
        data: 'installationDate',
        orderable: false,
        className: 'purchase-order-installation-date',
        render: (value: string | null, _type, _row: PurchaseOrderBranchView) => {
          return value == null ? '' : serverDateTimeToLocal(value).toLocaleDateString();
        }
      },
      {
        title: tlang`MFG Delivery`,
        width: '90px',
        data: 'supplierSystemDeliveryDate',
        orderable: false,
        className: 'purchase-order-mfg-delivery-date',
        render: (value: string | null, _type, _row: PurchaseOrderBranchView) => {
          return value == null ? '' : serverDateTimeToLocal(value).toLocaleDateString();
        }
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getDefaultSortFieldIndex(columns: any[]): number {
    return columns.findIndex(c => c.data === 'purchaseOrderNumber');
  }

  protected getPreFetched(_results: ResultGetPurchaseOrderDeliveryPipeline): Promise<void>[] {
    return [];
  }

  protected getPurchaseOrderLink(row: PurchaseOrderBranchView, value: string) {
    return `<a class="purchase-order-link" href="${resolveURL(resourcePurchaseOrder, row.id)}" data-purchaseorderid="${row.id}" >${this.htmlEncode(value)}</a>`;
  }

  protected async doPreFetching(results: ResultGetPurchaseOrderDeliveryPipeline) {
    await Promise.all(this.getPreFetched(results));
  }
}
