import { V6QuoteSupplierDefaultsView } from '../../../../quotes/v6/v6-quote-supplier-defaults-view';
import { V6FranchiseeQuoteProviderData } from '../../data/franchisee-quote-provider-data';

export class FranchiseeV6QuoteSupplierDefaultsView extends V6QuoteSupplierDefaultsView {
  protected getQuoteDefaultOptions(): unknown | null {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.defaultOptions ?? null;
  }
  protected getQuoteIGUs(): unknown /* V6QuoteIGU[] */ {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    return data.igu ?? [];
  }

  protected setQuoteDefaultOptions(options: unknown) {
    const data = this.quoteManager.quoteProviderData() as V6FranchiseeQuoteProviderData;
    data.defaultOptions = options;
  }
}
