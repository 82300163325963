// eslint-disable-next-line import/named
import { html, LitElement, nothing, TemplateResult } from 'lit';
import { money, moneyToTemplateResult } from '../../../components/currency-formatter';
import {
  DataTracker,
  DynamicValueBinder,
  EventValueGetter,
  EventValueSetter,
  FieldType
} from '../../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../../components/ui/databinding/databinding';
import { firstValidString, isEmptyOrSpace, isWithinRange, sameText } from '../../../components/ui/helper-functions';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';

import { QuoteItemContainer } from '../../../quotes/data/quote-item-container';
import { QuoteItemsView, QuoteItemsViewOptions } from '../../../quotes/views/quote-items-view';
import { QuoteItem, QuoteState } from '../../../api/dealer-api-interface-quote';
import { PromiseTemplate } from '../../../components/ui/events';
import { highestValidationRank, rankToValidationType } from '../../../v6config/validation-rank';
import {
  htmlEncode,
  isDefaultShipping,
  isFrame,
  isFreehand,
  isShipping,
  isSpecialItem,
  isSSI,
  quoteItemThumbSrc
} from '../../../quotes/data/quote-helper-functions';
import { userDataStore } from '../../common/current-user-data-store';
import {
  BranchQuoteSupportStatus,
  ResultGetSupplierPricingRule,
  TaxRate
} from '../../../api/dealer-api-interface-franchisee';
import { QuoteContainerManager } from '../../../quotes/data/quote-container';
import {
  getV6QuoteItemForContainer,
  getV6QuoteItemNFRCData,
  isQuoteFrameVersionCurrent,
  isV6
} from '../../../quotes/data/v6/helper-functions';
import {
  Frame,
  FrameBuyInItem,
  FrameValidationType,
  NFRCColumn,
  NFRCOptions,
  NFRCRowResult,
  NFRCRowValues,
  NFRCValue
} from '@softtech/webmodule-data-contracts';
import { NFRCCalculationResult, nfrcCalculator } from '../../../nfrc/nfrc-calculation';
import { getV6QuoteItemDisplayHeightFirst, getV6QuoteItemFrameDimensions } from '../../../v6config/v6-ui';
import { getBuyInDetailsTemplate, getFrameDetailsTemplate, V6FrameCache } from '../../../frames/ui';
import { isValidV6ConfigVersion, v6Util } from '../../../v6config/v6config';
import { DevelopmentError } from '../../../development-error';
import {
  lang,
  tlang,
  WebModuleLitTable,
  WebModuleLitTableColumnDef,
  WebModuleTableItemMove
} from '@softtech/webmodule-components';
import { supplierItemContentTypeDisplay } from '../../../quotes/data/supplier-quote-item-content-type';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { QuoteApi } from '../../../api/quote-api';
import { getApiFactory } from '../../../api/api-injector';
import { customElement, property, query, state } from 'lit/decorators.js';
import { Task } from '@lit/task';
import { quoteItemAction } from '../../../quotes/data/events';
import { consume } from '@lit/context';
import { ImagePreview, imagePreviewContext } from '../../../components/context/imagePreview';
import { fireQuickWarningToast } from '../../../toast-away';
import { getMarginLabel } from '../../../franchisee/view/payment-profile-view';

class NFRCDataCache {
  nfrcOptions: () => NFRCOptions;
  private nfrcColCache: NFRCRowResult[] = [];
  private nfrcRowDataCache: NFRCRowValues[] = [];

  constructor(nfrcOptions: () => NFRCOptions) {
    this.nfrcOptions = nfrcOptions;
  }

  public nfrcGetRowValues(id: string, v6QuoteItem: object): NFRCRowValues {
    let nfrcRowValues = this.nfrcRowDataCache.find(x => x.id === id);
    if (!nfrcRowValues) {
      const nfrcData = getV6QuoteItemNFRCData(this.nfrcOptions().calculationName, v6QuoteItem);
      nfrcRowValues = { id, nfrcData };
      this.nfrcRowDataCache.push(nfrcRowValues);
    }
    return nfrcRowValues;
  }

  public nfrcColumnValue(quoteItemContainer: QuoteItemContainer, publishedCode: string): number {
    let cacheItem = this.nfrcColCache.find(x => x.id == quoteItemContainer.item.id);
    if (!cacheItem) {
      const data = getV6QuoteItemForContainer(quoteItemContainer);
      const nfRCData = this.nfrcGetRowValues(quoteItemContainer.item.id, data);
      cacheItem = nfrcCalculator(this.nfrcOptions().calculationName).nfrcColumnCalculation(nfRCData);
      this.nfrcColCache.push(cacheItem);
    }
    isEmptyOrSpace;
    const codeValue = cacheItem.values.find(x => sameText(x.name, publishedCode));
    return codeValue?.value ?? NaN;
  }

  public nfrcGetResults(quoteContainerManager: QuoteContainerManager): NFRCCalculationResult {
    const input: NFRCRowValues[] = [];
    //this should have been called first to ensure loaded
    //quoteContainerManager.needsQuoteItems

    //scan and get cached or load cached data for each row item
    quoteContainerManager.container.items?.forEach(item => {
      const container = quoteContainerManager.quoteItemContainer(item.id);
      if (isFrame(item)) {
        const row: NFRCRowValues = this.nfrcGetRowValues(item.id, getV6QuoteItemForContainer(container));
        input.push(row);
      }
    });

    return nfrcCalculator(this.nfrcOptions().calculationName).nfrcCalculation(input);
  }

  public clear() {
    this.nfrcColCache = [];
    this.nfrcRowDataCache = [];
  }
}

@customElement('franchisee-quote-item-table')
export class FranchiseeQuoteItemTable extends LitElement {
  private frameDataCache: V6FrameCache = new V6FrameCache();

  quoteApi: QuoteApi = getApiFactory().quote();

  @query('#quote-items-table')
  table?: WebModuleLitTable;

  @state()
  private _data: QuoteItem[] = [];

  @state()
  private _columns: WebModuleLitTableColumnDef[] = [];

  @property({ attribute: false })
  public quoteManager!: FranchiseeQuoteContainerManager;

  @property({ attribute: false })
  public nfrcOptions?: NFRCDataCache;

  @consume({ context: imagePreviewContext })
  @property({ attribute: false })
  public imagePreview?: ImagePreview;

  dispatchCustom(values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };

    this.dispatchEvent(new CustomEvent(`wm-quote-item-action`, options));
  }

  protected render() {
    const keyEvent = (item: QuoteItem) => {
      return item.id;
    };

    const rowIdEvent = (item: QuoteItem) => {
      return item.id;
    };

    const itemRowClassEvent = (item: QuoteItem) => {
      if (!isSpecialItem(item)) return '';
      const bqs = this.quoteManager.branchQuoteSupport;
      const link = bqs.conversationLinks.find(x => x.quoteItemId === item.id);
      if (!link) return '';
      const support = bqs.items.find(x => x.conversationId === link.conversationId);
      if (!support) return '';

      return `quotesupport-status-${BranchQuoteSupportStatus[support.status].toLowerCase()}`;
    };

    const enableDragEvent = (item: QuoteItem): boolean => {
      return !(item && (isSSI(item) || isShipping(item)));
    };

    return html`
      ${this._getItemsTask.render({
        initial: () => html`initial...`,
        pending: () => html`<div>pending...</div>`,
        error: error => html`<div>${error}</div>`,
        complete: _value =>
          html` <webmodule-lit-table
            id="quote-items-table"
            class="quote-items-table"
            .rowClass=${'tr'}
            .colClass=${'column'}
            .keyevent=${keyEvent}
            .rowidevent=${rowIdEvent}
            .itemRowClassEvent=${itemRowClassEvent}
            .checkEnabledDragEvent=${enableDragEvent}
            .tablestyle="nestedtable"
            .columns=${this._columns}
            .pageLength="100"
            .data=${this._data}
            .clickrows=${false}
            @webmodule-table-item-move=${this._handleMove}
            enableDrag=${!this.quoteManager.isReadonly() ? 'true' : nothing}
          >
          </webmodule-lit-table>`
      })}
    `;
  }

  private async _handleMove(e: CustomEvent<WebModuleTableItemMove>) {
    const newData = this.moveArrayItem([...this._data], e.detail).map(x => x.commonId);

    if (await this.quoteManager.updateItemSortOrder(newData)) {
      await this.refreshData();
    }
  }

  private moveArrayItem<T>(array: T[], moveData: WebModuleTableItemMove): T[] {
    const { sourceIndex, targetIndex } = moveData;

    // Ensure the indices are within the bounds of the array
    if (
      sourceIndex < 0 ||
      sourceIndex >= array.length ||
      targetIndex < 0 ||
      targetIndex >= array.length ||
      sourceIndex === targetIndex
    ) {
      throw new Error('Invalid indices provided');
    }

    // Remove the item from the original position
    const item = array.splice(sourceIndex, 1)[0];

    // Insert the item at the new position
    array.splice(targetIndex, 0, item);

    return array;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private _getItemsTask = new Task(this, {
    task: async () => {
      this._columns = this.getColums();

      await this.refreshData();

      return true;
    },
    args: () => []
  });

  public async refreshData() {
    await this.quoteManager.needsQuoteItems(false);
    await this.quoteManager.needsQuoteSupport(true);
    await this.quoteManager.performItemSorting();
    this.frameDataCache.clear();

    if (this.quoteManager.container.items) {
      this._data = [...this.quoteManager.container.items];
    } else {
      this._data = [];
    }
    this.requestUpdate();
  }

  private async openItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.edit
    });
  }

  private async copyItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.copy
    });
  }

  private async deleteItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.delete
    });
  }

  private async propertiesItem(item: QuoteItem) {
    this.dispatchCustom({
      item: item,
      quoteAction: quoteItemAction.properties
    });
  }

  private getColums(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: 'Item #',
      classes: 'colpxmax-64 quote-item-no',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, index: number) => {
        const rowItem = item as QuoteItem;

        if (rowItem.isRestrictedToPowerUser) return html`${index + 1}. <span class="power-user"></span>`;
        return html`${index + 1}`;
      }
    });
    cols.push({
      title: 'Image',
      classes: 'colpxmax-64 quote-item-image',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const row = item as QuoteItem;

        const src =
          row.virtualThumbnailPath === ''
            ? quoteItemThumbSrc(row)
            : this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`);

        const imagePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();

          if (isFrame(row) || !isEmptyOrSpace(row.virtualThumbnailPath))
            this.imagePreview?.showImagePreview(
              this.quoteApi.api.fullUrl(`api/file/${row.virtualThumbnailPath}`),
              'quote-item-thumb'
            );
        };

        const hidePreview = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          this.imagePreview?.hideImagePreview();
        };

        return html` <div
          @mouseenter=${imagePreview}
          @mouseleave=${hidePreview}
          @touchstart=${imagePreview}
          @touchend=${hidePreview}
          @touchcancel=${hidePreview}
        >
          <img
            class="quote-item-thumbnail"
            alt="Thumbnail for quote item"
            src="${src}"
            style="width:48px; height:48px"
          />
        </div>`;
      }
    });
    cols.push({
      title: tlang`%%frame-title%% and Description`,
      classes: 'colpx-240 quote-item-title',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const clickEvent = async (e: Event) => {
          e.stopPropagation();
          e.preventDefault();

          await this.openItem(rowItem);
        };

        const title = isEmptyOrSpace(rowItem.title) ? tlang`%%frame-title%%` : rowItem.title;

        return html`<a class="quote-item-link" href="#" @click="${clickEvent}"
            >${htmlEncode(firstValidString(title, tlang`Untitled`))}</a
          >
          <span class="quote-item-description">${htmlEncode(rowItem.description)}</span>`;
      }
    });
    cols.push({
      title: 'Options',
      classes: 'colpx-160 quote-item-description',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        try {
          const container = this.quoteManager.quoteItemContainer(rowItem.id);

          return this.generateQuoteItemOptionsView(container, _table.isExpanded(rowItem) != undefined);
        } catch {
          return html``;
        }
      },
      click: (e: Event, table: WebModuleLitTable, item: unknown) => {
        e.stopPropagation();
        e.preventDefault();

        const rowItem = item as QuoteItem;

        const isShown = table.isExpanded(rowItem);

        if (isShown) table.remExtension(rowItem);
        else {
          table.addExtension(rowItem, this.getExtendedDetails(this.quoteManager.quoteItemContainer(rowItem.id)));
        }

        return true;
      }
    });
    cols.push({
      title: 'Qty',
      classes: 'colpxmax-48 quote-item-quantity',
      fieldName: 'quantity'
    });
    cols.push({
      title: 'Modified',
      classes: 'colpxmax-160 quote-item-modified',
      fieldName: 'lastModifiedDate',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const dt = new Date(rowItem.lastModifiedDate!);
        return html`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
      }
    });
    if (this.nfrcEnabled()) {
      const nfrcCols = this.nfrcDisplayColumns();
      for (let iNFRC = 0; iNFRC < nfrcCols.length; iNFRC++) {
        const nfrcCol = nfrcCols[iNFRC];
        cols.push({
          title: lang(nfrcCol.title),
          classes: 'colpxmax-98 dt-right nfrc-col',
          fieldName: 'xx',
          displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
            const rowItem = item as QuoteItem;

            let colValue = '';
            if (isFrame(rowItem)) {
              const colResult = this.nfrcColumnValue(this.quoteManager.quoteItemContainer(rowItem.id), nfrcCol.code);
              if (!isNaN(colResult)) colValue = colResult.toFixed(2);
            }
            return html`${colValue}`;
          }
        });
      }
    }
    cols.push({
      title: 'Price',
      classes: 'colpxmax-120 dt-right quote-item-price',
      fieldName: 'id',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        const price = this.quoteManager.quoteItemPrice(rowItem.id, _table.isExpanded(rowItem) != undefined);
        return moneyToTemplateResult(price);
      }
    });
    cols.push({
      title: '...',
      classes: 'colpxmax-48 item-menu',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, item: unknown, _index: number) => {
        const rowItem = item as QuoteItem;

        return this.ellipsisMenu(rowItem);
      }
    });

    return cols;
  }

  getExtendedDetails(quoteItemContainer: QuoteItemContainer, _class?: 'even' | 'odd') {
    if (isFrame(quoteItemContainer.item)) return this.getFrameExtendedDetails(quoteItemContainer, _class);
    else if (isSpecialItem(quoteItemContainer.item)) return this.getSpecialItemExtendedDetails(quoteItemContainer);

    return this.getOtherLineItemDetails(quoteItemContainer);
  }

  private getBuyInValidation(quoteItemContainer: QuoteItemContainer) {
    return this.quoteManager.getPriceValidation(quoteItemContainer.item.id);
  }

  private getSpecialItemExtendedDetails(quoteItemContainer: QuoteItemContainer) {
    const warningTemplate = (): TemplateResult => {
      if (!isEmptyOrSpace(quoteItemContainer.item.description)) return html``;

      return html`
        <ul class="list-group">
          <li class="list-group-item list-group-item-warning">
            <span class="me-3 fw-bold">${tlang`%%supplier%% Reference`}</span>
            ${tlang`The %%supplier%% must provide a reference`}
          </li>
        </ul>
      `;
    };

    return html` <div class="extended-options-line">
      ${warningTemplate()} ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;
  }

  private getOtherLineItemDetails(quoteItemContainer: QuoteItemContainer) {
    if (
      !(
        (isShipping(quoteItemContainer.item) ||
          isDefaultShipping(quoteItemContainer.item) ||
          isFreehand(quoteItemContainer.item)) &&
        !isEmptyOrSpace(quoteItemContainer.item.comment)
      )
    )
      return html``;

    return html` <div class="extended-options-line">${this.getNotesTemplate(quoteItemContainer)}</div>`;
  }

  private getFrameExtendedDetails(quoteItemContainer: QuoteItemContainer, classMap?: 'even' | 'odd'): TemplateResult {
    //only here if we are a frame and also a v6 frame
    if (!isV6(quoteItemContainer)) return html``;
    if (!isValidV6ConfigVersion()) {
      const message = tlang`%%supplier%% is OFFLINE`;
      return html`${message}`;
    }

    const data = this.getFrameData(quoteItemContainer);

    const validationsTemplate = (
      validationType: FrameValidationType,
      cssClass: string
    ): TemplateResult[] | TemplateResult => {
      const stuff = data?.validations?.filter(x => x.type === validationType) ?? [];
      if (stuff.length === 0) return html``;
      const rows = stuff.map(issue => {
        return html` <li class="list-group-item ${cssClass}">
          <span class="me-3 fw-bold">${issue.title}</span>${lang(issue.text ?? '')}
        </li>`;
      });
      return html`
        <ul class="list-group">
          ${rows}
        </ul>
      `;
    };

    const validationTemplates = html` <div class="extended-options-line">
      ${data ? getFrameDetailsTemplate(data, true) : undefined}
      ${validationsTemplate(FrameValidationType.critical, 'list-group-item-danger')}
      ${validationsTemplate(FrameValidationType.warning, 'list-group-item-warning')}
      ${validationsTemplate(FrameValidationType.information, 'list-group-item-info')}
      ${validationsTemplate(FrameValidationType.note, 'list-group-item-note')}
      ${this.getNotesTemplate(quoteItemContainer)}
    </div>`;

    const getBuyInRow = (buyInItem: FrameBuyInItem, heightFirst: null | boolean) => {
      const nfrcRows = () => {
        let s: TemplateResult[] = [];
        if (this.nfrcEnabled()) {
          const nfrcCols = this.nfrcDisplayColumns();
          for (let iNFRC = 0; iNFRC < nfrcCols.length; iNFRC++) {
            s.push(html`<div class="colpx-90 dt-right nfrc-col"></div>`);
          }
        }
        return s;
      };

      const buyIn = this.quoteManager.lookupBuyInData(
        quoteItemContainer,
        buyInItem.code,
        buyInItem.extraDetails?.['SuppCode'] ?? '',
        `Length=${buyInItem.extraDetails?.['Length'] ?? ''};Width=${buyInItem.extraDetails?.['Width'] ?? ''};Height=${buyInItem.extraDetails?.['Height'] ?? ''}`
      );

      const codeDisplay = () => {
        return html`(${buyInItem.extraDetails?.['SuppCode'] ?? ''} - ${buyInItem.code})`;
      };

      const priceValidations = () => {
        const validations = this.getBuyInValidation(quoteItemContainer);

        if (validations && validations.details && validations.details.length > 0)
          return validations.details.map(x => html`<br /><span class="text-danger">${x.message}</span>`);

        return html``;
      };

      const titleDisplay = (description: string | undefined) => {
        return html`${description ?? ''} ${codeDisplay()} ${priceValidations()}`;
      };

      return html`<div class="extended-options-line">
        <div class="tr ${classMap}">
          <div class="column colpxmax-64 quote-item-no"></div>
          <div class="column colpxmax-64 quote-item-image">
            ${supplierItemContentTypeDisplay(buyInItem.resourceType, false)}
          </div>
          <div class="column colpx-240 quote-item-title">${titleDisplay(buyIn?.description)}</div>
          <div class="column colpx-160 quote-item-description">${getBuyInDetailsTemplate(buyInItem, heightFirst)}</div>
          <div class="column colpxmax-48 quote-item-quantity">${buyInItem.quantity}</div>
          <div class="column colpxmax-160 quote-item-modified"></div>
          ${nfrcRows()}
          <div class="column colpxmax-120 dt-right quote-item-price">
            ${buyIn?.calculatedGross != undefined ? moneyToTemplateResult(buyIn?.calculatedGross) : ''}
          </div>
          <div class="column colpxmax-48 item-menu"></div>
        </div>
      </div>`;
    };

    const quoteItemData = getV6QuoteItemForContainer(quoteItemContainer);
    const heightFirst = getV6QuoteItemDisplayHeightFirst(quoteItemData);

    return html`${data?.buyIn?.map((item, _index) => getBuyInRow(item, heightFirst))} ${validationTemplates}`;
  }

  nfrcDisplayColumns(): NFRCColumn[] {
    return this.nfrcOptions?.nfrcOptions().columns ?? [];
  }

  nfrcEnabled() {
    return this.nfrcOptions?.nfrcOptions().enabled ?? false;
  }

  nfrcColumnValue(quoteItemContainer: QuoteItemContainer, publishedCode: string): number {
    return this.nfrcOptions?.nfrcColumnValue(quoteItemContainer, publishedCode) ?? NaN;
  }

  generateQuoteItemOptionsView(quoteItemContainer: QuoteItemContainer, childVisible: boolean) {
    const validationIndicator = () => {
      const state = this.getItemValidationType(quoteItemContainer);
      switch (state) {
        case FrameValidationType.note:
          return html`<i class="fa-solid fa-message text-primary validation-icon me-1"></i>`;
        case FrameValidationType.information:
          return html`<i class="fa-solid fa-circle-info text-primary validation-icon me-1"></i>`;
        case FrameValidationType.warning:
          return html`<i class="fa-solid fa-triangle-exclamation text-warning validation-icon me-1"></i>`;
        case FrameValidationType.critical:
          return html`<i class="fa-solid fa-circle-exclamation text-danger validation-icon me-1"></i>`;
        case FrameValidationType.outOfDate:
          return html`<i class="fa-solid fa-wrench text-danger me-1"></i>`;
      }
      return html``;
    };
    const notesIndicator = () => {
      if (quoteItemContainer.item.comment)
        return html`<i class="fa-solid fa-file-lines text-primary validation-icon me-1"></i>`;
      else return html``;
    };

    const supplierPriceAdjIndicator = () => {
      if (quoteItemContainer.price.supplierPriceAdjustment != 0)
        return html`<i class="fa-solid fa-tags text-black validation-icon me-1"></i>`;
      else return html``;
    };

    if (childVisible)
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"
          >${supplierPriceAdjIndicator()}${notesIndicator()}${validationIndicator()}<i class="fa-solid fa-caret-up"></i
        ></span>`;
    else
      return html`<span>${this.getOptionsOneLiner(quoteItemContainer)}</span
        ><span class="float-end options-dropdown"
          >${supplierPriceAdjIndicator()}${notesIndicator()}${validationIndicator()}<i
            class="fa-solid fa-caret-down"
          ></i
        ></span>`;
  }

  getItemValidationType(quoteItemContainer: QuoteItemContainer): FrameValidationType {
    //we are currently only supporting items that are for v6
    if (!isV6(quoteItemContainer)) return FrameValidationType.nothing;

    const data = this.getFrameData(quoteItemContainer);
    if (!data) return FrameValidationType.nothing;

    if (this.quoteManager.currentStateRequiresValidation) {
      if (!isQuoteFrameVersionCurrent(quoteItemContainer)) return FrameValidationType.outOfDate;

      if (!this.isQuoteItemBuyInPricesCurrent(quoteItemContainer)) {
        return FrameValidationType.outOfDate;
      }
    }
    // no validations on the frame, no point trying to find the highest ranked
    if (data.validations?.length == 0) {
      return FrameValidationType.nothing;
    }

    return rankToValidationType(highestValidationRank(data.validations));
  }

  getOptionsOneLiner(quoteItemContainer: QuoteItemContainer) {
    //We should only progress if item is a configuration from supplier.
    if (!isV6(quoteItemContainer)) return '';
    //We are only here if we are a v6 Frame
    const data = getV6QuoteItemForContainer(quoteItemContainer);
    return getV6QuoteItemFrameDimensions(data);
  }

  protected getNotesTemplate(quoteItemContainer: QuoteItemContainer) {
    if (!isEmptyOrSpace(quoteItemContainer.item.comment)) {
      return html` <ul class="list-group">
        <li class="list-group-item list-group-item-primary">
          <span class="me-3 fw-bold">Notes: </span>
          ${quoteItemContainer.item.comment}
        </li>
      </ul>`;
    }
    return html``;
  }

  private isQuoteItemBuyInPricesCurrent(quoteItemContainer: QuoteItemContainer) {
    if (this.quoteManager.priceValidation)
      return this.quoteManager.priceValidation?.findIndex(x => x.id == quoteItemContainer.item.id) < 0;
    else return true;
  }

  private getFrameData(quoteItemContainer: QuoteItemContainer): Frame | undefined {
    if (!isV6(quoteItemContainer) || !isValidV6ConfigVersion()) return undefined;

    return this.frameDataCache.getOrAdd(quoteItemContainer.item.id, () =>
      getV6QuoteItemForContainer(quoteItemContainer)
    );
  }

  ellipsisMenu(quoteItem: QuoteItem) {
    const clickOpen = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.openItem(quoteItem);
    };

    const clickCopy = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.copyItem(quoteItem);
    };

    const clickDelete = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.deleteItem(quoteItem);
    };

    const clickProperties = async (e: Event) => {
      e.stopPropagation();
      e.preventDefault();

      await this.propertiesItem(quoteItem);
    };

    // cannot delete if the quote is readonly, or the item is a shipping item
    const deleteMenu =
      this.quoteManager.isReadonly() || isShipping(quoteItem) || isSSI(quoteItem)
        ? html``
        : html`<button type="button" class="multi-action-btn btn-circle">
            <img @click=${clickDelete} class="icon action-delete" src="/assets/icons/bin.svg" />
          </button>`;
    const copyMenu =
      this.quoteManager.isReadonly() ||
      isFreehand(quoteItem) ||
      isShipping(quoteItem) ||
      isSpecialItem(quoteItem) ||
      isSSI(quoteItem)
        ? html``
        : html`<button type="button" class="multi-action-btn btn-circle ">
            <img @click=${clickCopy} class="icon action-copy" src="/assets/icons/copy.svg" />
          </button>`;

    const editMenu = isSSI(quoteItem)
      ? html``
      : html`<button type="button" class="multi-action-btn btn-circle ">
          <img @click=${clickOpen} class="icon action-open" src="/assets/icons/edit.svg" />
        </button>`;

    const propertiesMenu = html`<button type="button" class="multi-action-btn btn-circle ">
      <img @click=${clickProperties} class="icon action-properties" src="/assets/icons/adjust.svg" />
    </button>`;

    return html` <div class="multi-actions">
      <input type="checkbox" />
      <label>
        ${propertiesMenu} ${editMenu} ${copyMenu} ${deleteMenu}
        <span class="multi-action-btn btn-circle">
          <img class="icon" src="/assets/icons/close.svg" onclick="checkClosest(this,false)" />
        </span>
        <span class="icon">
          <img src="/assets/icons/ellipse.svg" onclick="checkClosest(this,true)" />
        </span>
      </label>
    </div>`;
  }
}

export class FranchiseeQuoteItemsView extends QuoteItemsView {
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  quoteApi: QuoteApi = getApiFactory().quote();
  supplierApi = getApiFactory().supplier();

  private _supplierPricingConfig?: ResultGetSupplierPricingRule;
  private nfrcOptions?: NFRCOptions;
  private _marginMin = 0;
  private _marginMax = 100;

  constructor(options: QuoteItemsViewOptions) {
    super(options);

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `quoteprice-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.quoteManager.container.quotePrice),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };
    const addDynamicMoney = (
      fieldName: string,
      getter: EventValueGetter,
      setter: EventValueSetter,
      readonly?: () => boolean
    ) => {
      this.dataTracker.addBinding(
        new DynamicValueBinder(FieldType.money, false, getter, setter, readonly),
        fieldName,
        fieldName,
        FieldType.money,
        false
      );
    };

    addField('termsAndConditions', FieldType.string, false, undefined, () => this.quoteManager.quote);

    // addField('dealerQuoteMargin', FieldType.int, true, undefined, () => this.quoteManager.container.quotePrice?.marginPercentage ?? 0);
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.int,
        true,
        () => this.quoteManager.container.quotePrice?.marginPercentage ?? 0,
        () => console.log('Readonly'),
        () => true
      ),
      'dealerQuoteMargin',
      'dealerQuoteMargin',
      FieldType.int,
      true
    );

    addDynamicMoney(
      'calculatedTaxAmount',
      () => this.quoteManager.quotePrice.calculatedTaxAmount,
      () => {
        console.log('calculatedTaxAmount value set');
      },
      () => true
    );
    addDynamicMoney(
      'calculatedGrossTotal',
      () => this.quoteManager.quotePrice.calculatedGrossTotal,
      () => {
        console.log('calculatedGrossTotal value set');
      },
      () => true
    );
    addDynamicMoney(
      'subTotal',
      () => this.quoteManager.quotePrice.calculatedLineItemTotal + this.quoteManager.quotePrice.priceAdjustment,
      () => {
        console.log('subTotal set event');
      },
      () => true
    );
    addDynamicMoney(
      'itemTotal',
      () => this.quoteManager.quotePrice.calculatedLineItemTotal,
      () => {
        console.log('subTotal set event');
      },
      () => true
    );
    addDynamicMoney(
      'priceAdjustment',
      () => this.quoteManager.quotePrice.priceAdjustment,
      () => {
        console.log('priceAdjustment value set');
      },
      () => true
    );

    //handle reprocessing of pricing information for priceadjustment and total adjustment
    const triggerEvent = (e: Event) => {
      const elem = e.target as HTMLInputElement;
      console.log(elem.value);
      if (elem.id == this.dataBinding.field('priceAdjustment')) {
        const p = this.quoteManager.quotePrice;
        const value = (this.dataTracker.getEditorValue('priceAdjustment') as number) ?? 0;
        if (p.priceAdjustment === value) return;
        p.priceAdjustment = money(value);
        p.calculatedLineItemTotal = this.quoteManager.quoteItemPriceTotal;
        p.calculatedNetTotal = this.quoteManager.quoteItemPriceTotal + p.priceAdjustment;
        p.calculatedTaxAmount = p.taxPercentage == 0 ? 0 : p.calculatedNetTotal * (p.taxPercentage / 100);
        p.calculatedGrossTotal = p.calculatedNetTotal + p.calculatedTaxAmount;
      } else if (elem.id == this.dataBinding.field('calculatedGrossTotal')) {
        const p = this.quoteManager.quotePrice;
        const finalTotal = (this.dataTracker.getEditorValue('calculatedGrossTotal') as number) ?? 0;
        if (p.calculatedGrossTotal === finalTotal) return;
        const valueLessTax = money(p.taxPercentage === 0 ? finalTotal : finalTotal / (1 + p.taxPercentage / 100));
        p.priceAdjustment = valueLessTax - this.quoteManager.quoteItemPriceTotal;

        this.recalculateQuotePrice();
        console.log(
          `Expected Total = ${finalTotal.toFixed(4)} and calculated total ${p.calculatedGrossTotal.toFixed(4)}`
        );
      }
      //we dont want to trigger the render from inside the events
      //calling this code. so we need to post it out.
      //rendering here can cause update issues with the internals of some edit controls
      setTimeout(async () => await this.render(), 50);
    };

    const triggerQuoteMarginEvent = async (e: Event) => {
      const elem = e.target as HTMLInputElement;
      console.log(elem.value);
      if (elem.id == this.dataBinding.field('dealerQuoteMargin')) {
        const p = this.quoteManager.quotePrice;
        const value = (this.dataTracker.getEditorValue('dealerQuoteMargin') as number) ?? 0;

        if (!isWithinRange(value, this._marginMin, this._marginMax)) {
          fireQuickWarningToast(tlang`Not a valid margin value`, 1500);
          this.dataTracker.setEditorValue('dealerQuoteMargin', this.quoteManager.quotePrice.marginPercentage);
          return;
        }

        if (p.marginPercentage === value) return;

        p.marginPercentage = value;

        await this.quoteManager.saveQuote(false);
      }

      //we dont want to trigger the render from inside the events
      //calling this code. so we need to post it out.
      //rendering here can cause update issues with the internals of some edit controls
      setTimeout(async () => {
        await this.table.refreshData();
        await this.render();
      }, 50);
    };

    //trigger financial changes on enter press or focus change
    $(this.ui).on('keydown', 'input', async (e: Event) => {
      const event = e as KeyboardEvent;
      const elem = e.target as HTMLInputElement;
      if (
        elem.id == this.dataBinding.field('priceAdjustment') ||
        elem.id == this.dataBinding.field('calculatedGrossTotal')
      )
        if (event.code === 'Enter') {
          triggerEvent(e); //this.timedTrigger.triggerEarly(e);
        }

      if (elem.id == this.dataBinding.field('dealerQuoteMargin'))
        if (event.code === 'Enter') {
          await triggerQuoteMarginEvent(e);
        }
    });
    $(this.ui).on('blur', 'input', async (e: Event) => {
      const elem = e.target as HTMLInputElement;
      if (
        elem.id == this.dataBinding.field('priceAdjustment') ||
        elem.id == this.dataBinding.field('calculatedGrossTotal')
      )
        triggerEvent(e); //this.timedTrigger.triggerEarly(e);

      if (elem.id == this.dataBinding.field('dealerQuoteMargin')) await triggerQuoteMarginEvent(e);
    });
  }

  private _nfrcDataCache?: NFRCDataCache;

  get nfrcDataCache(): NFRCDataCache {
    if (!this._nfrcDataCache)
      this._nfrcDataCache = new NFRCDataCache(() => {
        if (!this.nfrcOptions) throw new DevelopmentError('nfrcOptions are not initialized');
        return this.nfrcOptions;
      });
    return this._nfrcDataCache;
  }

  get table(): FranchiseeQuoteItemTable {
    return this._ui?.querySelector('#franchisee-quote-item-table') as FranchiseeQuoteItemTable;
  }

  protected get qcm(): FranchiseeQuoteContainerManager {
    return this.quoteManager as FranchiseeQuoteContainerManager;
  }

  public async refreshData(): Promise<void> {
    await this.getNFRCOptions();
    //this is a force reload of the data. This is not something that we want to do, if items are inuse
    //as we would end up with a bad loading of data.
    if (this.nfrcOptions?.enabled) this.nfrcDataCache.clear();

    await this.table.refreshData();
  }

  async afterConstruction(): Promise<void> {
    if (!this._supplierPricingConfig) {
      const result = await this.supplierApi.getSupplierPricingRule({
        supplierId: this.quoteManager.quote.supplierId
      });
      if (result) {
        const pricingRule = result.pricingRule;

        if (result.pricingRuleType.hasMinMax) {
          this._marginMax = pricingRule.maxValue;
          this._marginMin = pricingRule.minValue;
        }

        this._supplierPricingConfig = result;
      }
    }

    await super.afterConstruction();
  }

  public async prepareForSave() {
    await this.quoteManager.needsQuoteItems();
    this.dataTracker.applyChangeToValue();
  }

  //TODO - Tax Rates must be loaded and addTaxRow not hardcoded
  getTaxLabel(): string | undefined {
    const rate = userDataStore.taxRates.find(taxRate => taxRate.rate == this.quoteManager.quotePrice.taxPercentage);

    if (rate) {
      return tlang`${rate.name} ${rate.rate}%`;
    }

    return tlang`%%tax%% (${this.quoteManager.quotePrice.taxPercentage.toFixed(2)}%)`;
  }

  getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (this.quoteManager.quoteState == QuoteState.Active || this.quoteManager.quoteState == QuoteState.Draft)
      return errors;

    if (this.quoteManager.quoteState == QuoteState.Cancelled) {
      return [];
    }

    if (
      this.quoteManager.container.items &&
      this.quoteManager.container.items.filter(x => !isShipping(x)).length == 0
    ) {
      errors.push(
        tlang`There are no !!quote-item!! in this %%quote%%. A blank %%quote%% will not be issued to the %%client%%.`
      );
    }

    return errors;
  }

  protected async getNFRCOptions(): Promise<NFRCOptions> {
    const defaultOptions: NFRCOptions = {
      enabled: false,
      calculationName: '',
      columns: [],
      title: ''
    };
    if (!this.nfrcOptions)
      this.nfrcOptions = isValidV6ConfigVersion()
        ? await v6Util().getNFRCOptions(this.qcm.quote.supplierId)
        : defaultOptions;
    return this.nfrcOptions;
  }

  protected async template(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
    await this.getNFRCOptions();

    const addTaxRow = (taxRate: TaxRate): TemplateResult => {
      const taxChange = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.quoteManager.isReadonly()) return;

        this.quoteManager.quotePrice.taxPercentage = taxRate.rate;
        this.recalculateQuotePrice();
        this.render(); //no wait
      };

      return html` <li>
        <a class="dropdown-item" href="#" @click="${taxChange}">${tlang`${taxRate.name} ${taxRate.rate}%`}</a>
      </li>`;
    };

    const taxRates = userDataStore.taxRates.map(rate => addTaxRow(rate));
    const taxTemplate = this.quoteManager.isReadonly()
      ? html`${forms.moneyReadonly('calculatedTaxAmount', this.getTaxLabel())}`
      : html` <div class="tax-inline">
          <div class="dropdown">
            <button
              class="btn btn-secondary btn-tax dropdown-toggle "
              type="button"
              id="${this.dataTracker.binder.internalId + '-plus'}"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              ...
            </button>
            <ul class="dropdown-menu" aria-labelledby="${this.dataTracker.binder.internalId + '-plus'}">
              ${taxRates}
            </ul>
          </div>
          ${forms.moneyReadonly('calculatedTaxAmount', this.getTaxLabel())}
        </div>`;

    const nfrcTemplate = async (): Promise<TemplateResult> => {
      await this.quoteManager.needsQuoteItems();
      const localNFRCOptions = await this.getNFRCOptions();
      if (!localNFRCOptions.enabled) return html``;
      const data = this.nfrcDataCache.nfrcGetResults(this.quoteManager);
      const resultTemplate = (item: NFRCValue) => html`
        <div class="nfrc-col">
          <div class="nfrc-col-name">${item.name}</div>
          <div class="nfrc-col-value">${item.value.toFixed(2)}</div>
        </div>
      `;

      if (localNFRCOptions.enabled) {
        return html`
          <div class="nfrc-section">
            <div class="nfrc-label">${localNFRCOptions.title}</div>
            <div class="nfrc-result">${data.values.map(x => resultTemplate(x))}</div>
          </div>
        `;
      }
      return html``;
    };

    const dealerName = userDataStore.franchisee.name;
    const termsAndConditions = isEmptyOrSpace(dealerName)
      ? tlang`Dealer Terms and Conditions`
      : tlang`${dealerName} Terms and Conditions`;

    const actionQuoteItem = async (e: CustomEvent) => {
      const quoteItem = e.detail.item as QuoteItem;
      const action = e.detail.quoteAction;

      const qicm = this.quoteManager.quoteItemContainer(quoteItem.id);
      await this.eventRunQuoteItemActions?.(qicm, action);
    };

    return html`
      <franchisee-quote-item-table
        id="franchisee-quote-item-table"
        .quoteManager=${this.quoteManager as FranchiseeQuoteContainerManager}
        .nfrcOptions=${this.nfrcDataCache}
        @wm-quote-item-action=${actionQuoteItem}
      >
      </franchisee-quote-item-table>

      <form class="py-3 px-0 quote-price-summary form-two-col ">
        <div class="row">
          <div class="quote-items-terms">
            ${forms.note('termsAndConditions', termsAndConditions, 3000)} ${await nfrcTemplate()}
          </div>
          <div class="quote-items-summary">
            <div class="quote-items-summary-wrapper">
              ${forms.intRequired(
                'dealerQuoteMargin',
                getMarginLabel(this._marginMin, this._marginMax),
                this._marginMin,
                this._marginMax
              )}
              ${forms.moneyReadonly('itemTotal', tlang`Items Total`)}
              ${forms.money('priceAdjustment', tlang`Price Adjustment`)}
              ${forms.moneyReadonly('subTotal', tlang`Sub Total`)} ${taxTemplate}
              ${forms.money('calculatedGrossTotal', tlang`Total`)}
            </div>
          </div>
        </div>
      </form>
    `;
  }

  private recalculateQuotePrice() {
    const p = this.quoteManager.quotePrice;
    p.calculatedLineItemTotal = money(this.quoteManager.quoteItemPriceTotal);
    p.calculatedNetTotal = money(p.calculatedLineItemTotal + p.priceAdjustment);
    p.calculatedTaxAmount = money(p.taxPercentage == 0 ? 0 : p.calculatedNetTotal * (p.taxPercentage / 100));
    p.calculatedGrossTotal = money(p.calculatedNetTotal + p.calculatedTaxAmount);
  }
}
