import { customElement, property, state } from 'lit/decorators.js';
import { LitElementBase } from '../../components/litelement-base';
import {
  BranchQuoteSupport,
  BranchQuoteSupportItemType,
  BranchQuoteSupportStatus,
  ResultConversation,
  ViewConversationEntry
} from '../../api/dealer-api-interface-franchisee';
import { html, PropertyValueMap, TemplateResult } from 'lit';
import { getCurrentUser, tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { flagInSet, isEmptyOrSpace } from '../../components/ui/helper-functions';
import { lockUIandExecute } from '../../ui-lock';
import { ConversationEntryUpdateEvent, CreateConversationEntry } from './wm-conversation-entry';

import { repeat } from 'lit/directives/repeat.js';
import { getSupportStatusBadge } from './conversation-helper';
import { cache } from '../cache/cache-registry';
import { getQuoteNumberFormatted } from '../../quotes/data/quote-helper-functions';
import { newGuid } from '../../api/guid';
import { ConversationActionTemplate } from './conversation-types';
import { showValidations } from '../../components/ui/modal-validationhandler';

export interface ConversationActions {
  attachmentsOnlySwitch: ConversationActionTemplate;
  cancel: ConversationActionTemplate;
  reactivate: ConversationActionTemplate;
  resolve: ConversationActionTemplate;
  postToSupplier: ConversationActionTemplate;
  close: ConversationActionTemplate;
  addNote: ConversationActionTemplate;
}

@customElement('wmview-branchquote-conversation')
export class WebModuleBranchQuoteConversationView extends LitElementBase {
  actions?: ConversationActions;
  ActivateExternalEditor() {
    const currentUserId = getCurrentUser()?.id;
    const draftentry = this.results?.conversationEntries.find(x => x.systemUserId === currentUserId && x.draft);
    if (!draftentry) {
      if (this.alwaysShowNewNoteEditor) this.showNewNoteEditor = true;
    } else this.editConversationEntry(draftentry);
  }
  private _firstUpdated = false;
  protected notifyCreateEntryEditor() {
    if (!this._firstUpdated) return;
    if (this.useExternalEditing)
      this.dispatchCustom('new-entry', {
        enabled: this.createEntryEditor !== undefined,
        editor: this.createEntryEditor
      });
  }
  protected notifyEditEntryEditor() {
    if (!this._firstUpdated) return;
    if (this.useExternalEditing)
      this.dispatchCustom('edit-entry', {
        enabled: this.editEntryEditor !== undefined,
        entry: this.editEntry,
        editor: this.editEntryEditor
      });
  }
  buildCreateEntryEditor() {
    this.setEditEntryEditor(undefined, undefined);
    this.createEntryEditor = !this._showNewNoteEditor
      ? undefined
      : html`<wmview-conversation-entry-create
          .conversationEntryId=${newGuid()}
          .conversationId=${this.branchQuoteSupport?.id}
          id="addNoteEditor"
          .allowCancel=${!this.useExternalEditing}
          @wm-event-create=${this.eventCreateEntry}
          @wm-event-cancel=${this.eventCancelNewEntry}
        ></wmview-conversation-entry-create>`;
  }
  private _resultsLoaded = false;
  @state()
  private _results: ResultConversation | null = null;
  public get results(): ResultConversation | null {
    return this._results;
  }
  public set results(value: ResultConversation | null) {
    this._results = value;
    this._resultsLoaded = value !== null;
    this.activateEditors();
  }
  @property()
  private _alwaysShowNewNoteEditor = false;
  public get alwaysShowNewNoteEditor() {
    return this._alwaysShowNewNoteEditor;
  }
  public set alwaysShowNewNoteEditor(value) {
    this._alwaysShowNewNoteEditor = value;

    if (value) {
      if (this.useExternalEditing) {
        this.ActivateExternalEditor();
      } else this.showNewNoteEditor = true;
    } else {
      if (this.useExternalEditing) {
        this.createEntryEditor = undefined;
        this.setEditEntryEditor(undefined, undefined);
      }
    }
  }
  @property()
  private _showNewNoteEditor = false;
  public get showNewNoteEditor() {
    return this._showNewNoteEditor;
  }
  public set showNewNoteEditor(value) {
    if (value !== this._showNewNoteEditor) {
      this._showNewNoteEditor = value;
      if (this._showNewNoteEditor) {
        this.buildCreateEntryEditor();
      } else this.createEntryEditor = undefined;
    }
  }
  protected async showDraftSavedMessage() {
    //Not needed as per discussion with Shane
    //I suggest we use the normal toast notification but shane thinks it is to much
    //He'll talk to alexey as he wants to change the autosave message toast notification
  }
  protected eventUpdateEntry = async (e: CustomEvent<ConversationEntryUpdateEvent>) => {
    e.stopImmediatePropagation();
    const result = this.dispatchCustom('conversation-saved', {
      updateEvent: e.detail,
      showMessage: true,
      completionPromise: Promise.resolve(true)
    });
    await result.completionPromise;
    if (result.showMessage) await this.showDraftSavedMessage();
    this.requestUpdate(); // to refresh this conversation thread
  };
  public eventDeleteEntry = async (e: CustomEvent<ConversationEntryUpdateEvent>) => {
    if (this.results)
      this.results.conversationEntries = this.results.conversationEntries.filter(
        x => x.id !== e.detail.input.conversationEntryId
      );
    this.activateEditors();
    this.requestUpdate();
  };

  protected eventPostEntry = (e: CustomEvent<ConversationEntryUpdateEvent>) => {
    e.stopImmediatePropagation();
    this.activateEditors();
    this.dispatchCustom('conversation-saved', {
      updateEvent: e.detail,
      showMessage: true
    });
  };
  protected eventCancelEntry = (e: CustomEvent<ConversationEntryUpdateEvent>) => {
    e.stopImmediatePropagation();
    this.activateEditors();
  };

  public eventExternalEditConversation = async (e: CustomEvent<{ entry: ViewConversationEntry }>) => {
    if (this.showNewNoteEditor && this.createEntryEditor) {
      this.showNewNoteEditor = false;
    }
    this.editConversationEntry(e.detail.entry);
  };

  protected editConversationEntry(entry: ViewConversationEntry) {
    this.showNewNoteEditor = false;
    this.setEditEntryEditor(
      html`<wmview-conversation-entry-editor
        .conversationEntry=${entry}
        .conversationId=${this.branchQuoteSupport?.id}
        .showHeader=${false}
        id="editNoteEditor"
        .allowCancel=${!this.useExternalEditing || !entry.draft}
        @wm-event-update=${this.eventUpdateEntry}
        @wm-event-delete=${this.eventDeleteEntry}
        @wm-event-post=${this.eventPostEntry}
        @wm-event-cancel=${this.eventCancelEntry}
      ></wmview-conversation-entry-editor>`,
      entry
    );
  }

  @property() supplierName = '';
  @property() initialDisplayLead = 1;
  @property() initialDisplayTail = 3;
  @property() showSubject = true;
  @property() showButtons = true;
  @property() showHeader = true;
  @property() attachmentsMode = false;
  @property()
  private _useExternalEditing = false;
  public get useExternalEditing() {
    return this._useExternalEditing;
  }
  public set useExternalEditing(value) {
    this._useExternalEditing = value;
    if (value && this.alwaysShowNewNoteEditor) {
      this.ActivateExternalEditor();
    }
  }

  protected subjectEditorValue?: string;
  @property()
  emptyNote = tlang`There are no entries visible`;
  @state() displayCount = 4;
  @state() public quoteTitle?: TemplateResult;

  @property()
  private _branchQuoteSupport?: BranchQuoteSupport | undefined;
  public get branchQuoteSupport(): BranchQuoteSupport | undefined {
    return this._branchQuoteSupport;
  }

  public set branchQuoteSupport(value: BranchQuoteSupport | undefined) {
    if (this._branchQuoteSupport !== value) {
      this.results = null;
      this.subjectEditorValue = value?.subject;
      this._branchQuoteSupport = value;
      this.setEditEntryEditor(undefined, undefined);
      this.showNewNoteEditor = false;
      this.loadConversation(); // async delayed
    }
  }
  @state()
  private _createEntryEditor?: TemplateResult | undefined;
  public get createEntryEditor(): TemplateResult | undefined {
    return this._createEntryEditor;
  }
  public set createEntryEditor(value: TemplateResult | undefined) {
    this._createEntryEditor = value;
    this.notifyCreateEntryEditor();
    this.requestUpdate();
  }
  @state()
  private _editEntryEditor?: TemplateResult | undefined;
  public get editEntryEditor(): TemplateResult | undefined {
    return this._editEntryEditor;
  }
  private _editEntry: ViewConversationEntry | undefined;
  public get editEntry(): ViewConversationEntry | undefined {
    return this._editEntry;
  }

  public setEditEntryEditor(value: TemplateResult | undefined, entry?: ViewConversationEntry) {
    this._editEntryEditor = value;
    this._editEntry = entry;
    this.notifyEditEntryEditor();
    this.requestUpdate();
  }

  protected activateEditors() {
    this.setEditEntryEditor(undefined, undefined);
    this.showNewNoteEditor = false;
    if (!this._resultsLoaded) return;
    if (this.useExternalEditing && this.alwaysShowNewNoteEditor) {
      this.ActivateExternalEditor();
      if (this.editEntryEditor != undefined) {
        this.notifyEditEntryEditor();
      } else if (this.createEntryEditor != undefined) {
        this.notifyCreateEntryEditor();
      }
    } else {
      if (this.alwaysShowNewNoteEditor) this.showNewNoteEditor = true;
    }
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    this._firstUpdated = true;

    if (this.useExternalEditing && this.alwaysShowNewNoteEditor) {
      this.activateEditors();
    }

    if (this.useExternalEditing) this.requestUpdate();
  }

  private async changeState(status: BranchQuoteSupportStatus) {
    const validations: string[] = [];
    const result = this.dispatchCustom('changing', {
      branchQuoteSupport: this.branchQuoteSupport,
      status: status,
      allowChange: Promise.resolve(true),
      validations: validations
    });

    const canChange = await result.allowChange;
    if (result.validations.length > 0) {
      await showValidations(result.validations, () => tlang`Validation`);
      return;
    }

    if (!canChange) return;

    await lockUIandExecute(async () => {
      if (!this.branchQuoteSupport) return;
      const result = await getApiFactory()
        .franchisee()
        .updateBranchQuoteSupport({
          quoteItemIdToDetach: null,
          masterDocumentConversationId: null,
          branchQuoteSupportId: this.branchQuoteSupport.id,
          branchQuoteId: null,
          status: status,
          subject: this.subjectEditorValue ?? null,
          conversationEntries: []
        });
      this.branchQuoteSupport = result?.branchQuoteSupport;
      this.dispatchCustom('changed', {
        branchQuoteSupport: this.branchQuoteSupport
      });
    });
  }

  private async updateSubject(subject: string) {
    await lockUIandExecute(async () => {
      if (!this.branchQuoteSupport) return;
      const result = await getApiFactory().franchisee().updateBranchQuoteSupport({
        masterDocumentConversationId: null,
        quoteItemIdToDetach: null,
        branchQuoteSupportId: this.branchQuoteSupport.id,
        branchQuoteId: null,
        status: null,
        subject: subject,
        conversationEntries: []
      });
      if (result) {
        this.branchQuoteSupport = result?.branchQuoteSupport;
        this.dispatchCustom('changed', {
          branchQuoteSupport: this.branchQuoteSupport
        });
      }
      return result !== null;
    });
  }
  public eventCancelNewEntry = async (e: CustomEvent) => {
    e.stopImmediatePropagation();
    this.activateEditors();
  };
  public eventCreateEntry = async (e: CustomEvent<CreateConversationEntry>) => {
    const data = e.detail;
    const attachments = data.attachments;
    const users = data.users;
    const text = data.text ?? '';
    await lockUIandExecute(async () => {
      if (!this.branchQuoteSupport) return;
      const branchQuoteSupportId = isEmptyOrSpace(this.branchQuoteSupport.recordVersion)
        ? null
        : this.branchQuoteSupport.id;
      const result = await getApiFactory().franchisee().addConversationEntry({
        conversationEntryId: data.id,
        branchQuoteSupportId: branchQuoteSupportId,
        conversationId: this.branchQuoteSupport.id,
        text: text,
        draft: data.draft,
        users: users,
        attachments: attachments
      });
      if (result) {
        this.results?.conversationEntries.push(result.conversationEntry);

        const eventResult = this.dispatchCustom('conversation-created', {
          updateEvent: e.detail,
          showMessage: true,
          completionPromise: null
        });

        await eventResult.completionPromise;

        if (data.draft && eventResult.showMessage) await this.showDraftSavedMessage();
        if (data.draft && result && this.useExternalEditing) {
          this.editConversationEntry(result.conversationEntry);
        }
        this.activateEditors();
      }
    });
    this.requestUpdate();
  };
  public get hasChanged(): boolean {
    return this.branchQuoteSupport !== undefined && this.subjectEditorValue !== this.branchQuoteSupport?.subject;
  }
  public getValidationErrors(): string[] {
    const subject = this.subjectEditorValue ?? '';
    if (isEmptyOrSpace(subject)) {
      return [tlang`%%support-subject%% must have a value before exiting`];
    }
    return [];
  }
  public async save() {
    return await this.updateSubject(this.subjectEditorValue ?? '');
  }
  public eventClose = async (_e: Event) => {
    if (this.hasChanged) {
      const validations = this.getValidationErrors();
      if (validations.length > 0) {
        await showValidations(validations);
        return;
      }
      await this.save();
    }
    this.dispatchCustom('close', {});
  };
  protected eventAttachmentMode = async (e: CustomEvent<{ checked: boolean }>) => {
    this.attachmentsMode = e.detail.checked;
  };

  public eventCancel = async (_e: Event) => {
    await this.changeState(BranchQuoteSupportStatus.Cancelled);
  };
  public eventResolve = async (_e: Event) => {
    await this.changeState(BranchQuoteSupportStatus.Resolved);
  };
  public eventReactivate = async (_e: Event) => {
    await this.changeState(BranchQuoteSupportStatus.ReActivated);
  };

  public eventActivate = async (_e: Event) => {
    await this.changeState(BranchQuoteSupportStatus.New);
  };

  protected eventAddNote = async (_e: Event) => {
    this.showNewNoteEditor = true;
  };

  protected get canReactivate() {
    return (
      !this.attributeTrue('readonly') &&
      this.branchQuoteSupport &&
      flagInSet(this.branchQuoteSupport.status, BranchQuoteSupportStatus.Resolved | BranchQuoteSupportStatus.Cancelled)
    );
  }

  protected get isReadonly() {
    return (
      this.readonly ||
      this.attributeTrue('readonly') ||
      !this.branchQuoteSupport ||
      flagInSet(this.branchQuoteSupport.status, BranchQuoteSupportStatus.Resolved | BranchQuoteSupportStatus.Cancelled)
    );
  }

  async loadConversation() {
    if (!this.branchQuoteSupport) {
      this.results = null;
      return;
    }

    const results = await getApiFactory().franchisee().getConversation({
      conversationId: this.branchQuoteSupport.id,
      count: 0, //take all
      fetchAllAttachments: false,
      fetchAllUsers: false,
      maxRecordVersion: null,
      sortAscending: true,
      startIndex: 0
    });
    if (results) await cache().userProfile.preFetch(results?.conversationEntries.map(x => x.systemUserId));

    const quoteSummary = (await cache().quote.getData(this.branchQuoteSupport.branchQuoteId))?.quoteSummary;
    this.quoteTitle = html`<h6>${tlang`Created by ${getQuoteNumberFormatted(quoteSummary)}`}</h6>`;
    this.results = results;
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  render() {
    const header = this.getFullHeaderTemplate(this.showSubject, this.showButtons, this.showHeader);

    return html`<div class="conversation">
      ${header}${this.conversationTemplates()} ${this.addNoteTemplate()} ${this.footerTemplate()}
    </div>`;
  }
  protected eventSubjectChanged = (e: Event) => {
    this.subjectEditorValue = (e.target as HTMLInputElement).value;
  };
  public getFullHeaderTemplate(showSubject: boolean, showButtons: boolean, showHeader: boolean) {
    const quoteTitle = this.showHeader && this.quoteTitle ? html`<div>${this.quoteTitle}</div>` : html``;
    const subject = showSubject
      ? html`<div class="d-flex conversation-subject-wrapper align-items-center">
          <label class="conversation-subject-label me-3">${tlang`%%support-subject%%:`}</label>
          <input
            id="subject"
            class="conversation-subject"
            type="text"
            maxlength="100"
            @change=${this.eventSubjectChanged}
            @input=${this.eventSubjectChanged}
            .value=${this.branchQuoteSupport?.subject ?? ''}
          />
        </div>`
      : html``;
    const subjectButtons = showButtons
      ? html`<div class="d-flex conversation-subject-actions ms-3">
          ${this.buttonsTemplates()}${getSupportStatusBadge(this.branchQuoteSupport?.status)}
        </div>`
      : html``;
    const header = showHeader
      ? html`${quoteTitle}
          <div class="conversation-header mb-3">${subject} ${subjectButtons}</div>`
      : html``;
    return header;
  }

  footerTemplate(): unknown {
    const addNote =
      !this.isReadonly && !this.useExternalEditing
        ? html`<button class="btn btn-primary" @click=${this.eventAddNote}>${tlang`Add Note`}</button>`
        : html``;

    return !this.showNewNoteEditor && !this.useExternalEditing
      ? html`<div class="conversation-list-actions">${addNote}</div>`
      : html``;
  }
  addNoteTemplate(): unknown {
    return !this.useExternalEditing && this.showNewNoteEditor ? this.createEntryEditor : html``;
  }
  get showEditor() {
    return !this.useExternalEditing && this.showNewNoteEditor;
  }

  hasDownloads(entry: ViewConversationEntry) {
    return entry.attachments.some(x => !x.embedded);
  }
  conversationTemplates(): unknown {
    const userId = getCurrentUser()?.id;

    const data = this.results?.conversationEntries ?? [];
    const entries = this.attachmentsMode
      ? [
          ...data.filter(item => !item.draft && this.hasDownloads(item)),
          ...data.filter(item => item.draft && item.systemUserId === userId && this.hasDownloads(item))
        ]
      : [...data.filter(item => !item.draft), ...data.filter(item => item.draft && item.systemUserId === userId)];

    const results: any[] = [];
    const repeatRange = (start: number, end?: number) => {
      return repeat(
        entries.slice(start, end),
        entry => entry.id,
        entry =>
          html`<div class="conversation-messages">
            <wmview-conversation-entry-view
              .inlineEditing=${!this.useExternalEditing}
              .attachmentsOnly=${this.attachmentsMode}
              .readonly=${this.isReadonly}
              @wm-event-delete=${this.eventDeleteEntry}
              @wm-event-request-edit=${this.eventExternalEditConversation}
              .conversationEntry=${entry}
            ></wmview-conversation-entry-view>
          </div> `
      );
    };

    if (entries.length === 0 && !this.showEditor) return html`<h1 class="text-muted">${this.emptyNote}</h1>`;
    if (
      this.attachmentsMode ||
      this.initialDisplayLead + this.initialDisplayTail >= entries.length ||
      this.displayCount >= entries.length
    )
      return repeatRange(0);
    const leading = this.displayCount - this.initialDisplayTail;
    results.push(repeatRange(0, leading));
    if (this.displayCount < entries.length) {
      results.push(this.showMore(entries.length));
      results.push(repeatRange(entries.length - this.initialDisplayTail));
    }
    return results;
  }
  showMore(count: number): any {
    const showSize = 3;
    const available = count - this.displayCount;
    const showMore = Math.min(showSize, available);
    const eventShowMore = (e: Event) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.displayCount = Math.min(count, this.displayCount + showMore);
    };
    const eventShowAll = (e: Event) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.displayCount = count;
    };
    if (showMore <= 0) return html``;

    const showAll =
      available > showSize
        ? html`<a class="ms-5" href="#" @click=${eventShowAll}>${tlang`Show all ${count} messages`}</a>`
        : html``;
    return html`
      <div class="row">
        <div class="col d-flex justify-content-center border-top border-bottom border-secondary p-3">
          <a href="#" @click=${eventShowMore}>${tlang`Show ${showMore} more messages`}</a>${showAll}
        </div>
      </div>
    `;
  }

  actionsTemplate(): ConversationActions {
    const result: ConversationActions = {
      addNote: (hide?: boolean) => {
        const disabled = !(!this.isReadonly && !this.useExternalEditing);
        return disabled && hide
          ? html``
          : html`<button ?disabled=${disabled} class="btn btn-primary" @click=${this.eventAddNote}>
              ${tlang`Add Note`}
            </button>`;
      },
      attachmentsOnlySwitch: () => {
        const attachmentsTitle = tlang`Attachments Only`;

        return html`<wm-button-switch
          .checked=${this.attachmentsMode}
          .caption=${attachmentsTitle}
          @wm-event-changed=${this.eventAttachmentMode}
        ></wm-button-switch>`;
      },
      cancel: (hide?: boolean) => {
        const disabled = !(
          this.branchQuoteSupport &&
          !this.isReadonly &&
          this.branchQuoteSupport.status != BranchQuoteSupportStatus.Draft &&
          this.branchQuoteSupport.type !== BranchQuoteSupportItemType.QuoteReview &&
          //   !claims.isAgent &&
          !flagInSet(
            this.branchQuoteSupport.status,
            BranchQuoteSupportStatus.Cancelled | BranchQuoteSupportStatus.Resolved
          )
        );
        return disabled && hide
          ? html``
          : html`<button ?disabled=${disabled} class="btn btn-secondary" @click=${this.eventCancel}>
              ${tlang`Cancel Ticket`}
            </button>`;
      },
      close: () => {
        return html`<button class="btn no-btn ms-1 text-primary pe-0" @click=${this.eventClose}>
          <i class="fa-solid fa-circle-left ms-1"></i>
          ${tlang`Back to %%quote%% !!support-request!!`}
        </button>`;
      },
      postToSupplier: (hide?: boolean) => {
        const disabled = !(
          this.branchQuoteSupport &&
          !this.isReadonly &&
          this.branchQuoteSupport.type !== BranchQuoteSupportItemType.QuoteReview &&
          //   !claims.isAgent &&
          flagInSet(this.branchQuoteSupport.status, BranchQuoteSupportStatus.Draft)
        );

        return hide && disabled
          ? html``
          : html`<button ?disabled=${disabled} class="btn btn-primary" @click=${this.eventActivate}>
              ${tlang`Issue Ticket`}
            </button>`;
      },
      reactivate: (hide?: boolean) => {
        const enabled =
          this.branchQuoteSupport &&
          !this.readonly &&
          this.canReactivate &&
          this.branchQuoteSupport.type !== BranchQuoteSupportItemType.QuoteReview;

        return hide && !enabled
          ? html``
          : html`<button ?disabled=${!enabled} class="btn btn-primary" @click=${this.eventReactivate}>
              ${tlang`Reactivate Ticket`}
            </button>`;
      },
      resolve: (hide?: boolean) => {
        const disabled = !(
          this.branchQuoteSupport &&
          !this.isReadonly &&
          this.branchQuoteSupport.status != BranchQuoteSupportStatus.Draft &&
          this.branchQuoteSupport.type !== BranchQuoteSupportItemType.QuoteReview &&
          !flagInSet(
            this.branchQuoteSupport.status,
            BranchQuoteSupportStatus.Cancelled | BranchQuoteSupportStatus.Resolved
          )
        );
        return hide && disabled
          ? html``
          : html`<button ?disabled=${disabled} class="btn btn-primary" @click=${this.eventResolve}>
              ${tlang`Resolve Ticket`}
            </button>`;
      }
    };

    return result;
  }
  buttonsTemplates(): TemplateResult[] {
    const result: TemplateResult[] = [];
    if (!this.actions) this.actions = this.actionsTemplate();
    if (!this.branchQuoteSupport) return result;
    if (this.showNewNoteEditor) return result;

    result.push(this.actions.attachmentsOnlySwitch());
    result.push(this.actions.close());
    result.push(this.actions.cancel(true));
    result.push(this.actions.resolve(true));
    result.push(this.actions.reactivate(true));
    return result;
  }
}
