import { tlang } from '@softtech/webmodule-components';
import { PromiseTemplate, Snippet } from '@softtech/webmodule-data-contracts';
import { html } from 'lit-html';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import {
  PaymentProfileDetailView,
  PaymentProfileDetailViewOptions
} from '../../../franchisee/view/payment-profile-detail-view';
import { QuoteSupplier, getQuoteSuppliers, isMultiSupplier } from '../../../quotes/quote-service';
import { emptyGuid } from '../../../api/guid';
import { DataEntryPageControlView } from '../../../components/ui/data-entry-screen-base';
import { FieldType } from '../../../components/ui/databinding/data-tracker';
import { getApiFactory } from '../../../api/api-injector';
import { isEmptyOrSpace, validId } from '../../../components/ui/helper-functions';
import { getMarginLabel } from "../../../franchisee/view/payment-profile-view";

export class FranchiseePaymentProfileDetailView extends PaymentProfileDetailView {
  protected supplierApi = getApiFactory().supplier();
  protected suppliers: QuoteSupplier[] = [];

  constructor(owner: DataEntryPageControlView, options: PaymentProfileDetailViewOptions) {
    super(owner, options);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.paymentProfile),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('supplierId');
  }

  public async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    this.suppliers = await getQuoteSuppliers();
    if (!isMultiSupplier() && this.isNew()) {
      this.paymentProfile.supplierId = this.suppliers.length == 1 ? this.suppliers[0].supplierId : emptyGuid;
      this.paymentProfileManager.resetBackup();
    }
    await this.render();
  }

  protected isNew(): boolean {
    return this.paymentProfile.id == emptyGuid;
  }

  protected getCaption(): Snippet {
    return tlang``;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    if (isMultiSupplier()) {
      this.suppliers.unshift({ description: '', supplierId: emptyGuid });
    }

    const supplierConverter = (qs: QuoteSupplier) => {
      return {
        text: tlang`${qs.description}`,
        value: qs.supplierId,
        disabled: false
      };
    };

    const isSupplierSelectorReadOnly = this.paymentProfile.supplierId != emptyGuid && !this.isNew();
    const supplierSelectorControl = isMultiSupplier()
      ? html` <bs-form-select
          data-id=${this.dataBinding.field('supplierId')}
          data-placeholder=${tlang`%%supplier%%`}
          data-label=${tlang`%%supplier%%`}
          ?readonly=${isSupplierSelectorReadOnly}
          ?disabled=${isSupplierSelectorReadOnly}
          .value=${this.paymentProfile.supplierId}
          .options=${this.suppliers.map(supplierConverter)}
          @wm-select-changed=${_ => this.onSupplierSelected(_.detail)}
        >
        </bs-form-select>`
      : forms.textHidden('supplierId');

    const marginTemplate = await this.getMarginTemplate(this.paymentProfile.supplierId, forms);

    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${forms.textRequired('name', tlang`%%payment-profile%%`, 100)} ${supplierSelectorControl} 
            ${marginTemplate}
            ${forms.note('quoteTerms', tlang`%%quote%% Terms and Conditions`, 5000)}
          </div>
        </div>
      </form>
    `;
  }

  protected async getMarginTemplate(supplierId: string, forms: FormInputAssistant): PromiseTemplate {
    if (validId(supplierId)) {
      const result = await this.supplierApi.getSupplierPricingRule({
        supplierId: supplierId
      });
      if (result) {
        const pricingRule = result.pricingRule;
        if (this.isNew()) {
          this.paymentProfile.margin = pricingRule.minValue;
        }

        if (result.pricingRuleType.hasMinMax) {
          this.marginMax = pricingRule.maxValue;
          this.marginMin = pricingRule.minValue;
          // const label
          // return html`${forms.floatRequired('margin', tlang`Margin %`, pricingRule.minValue, pricingRule.maxValue)}`;
        }
      }
    }
    // this.marginMax = 100;
    // this.marginMin = 0;

    return html`${forms.floatRequired('margin', getMarginLabel(this.marginMin, this.marginMax), this.marginMin, this.marginMax)}`;
  }

  protected onSupplierSelected(supplierId: string) {
    this.paymentProfile.supplierId = supplierId;

    this.render();
  }

  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    if (isEmptyOrSpace(this.paymentProfile.supplierId)) {
      errors.push(tlang`Please Select a %%supplier%%.`);
    }

    return errors;
  }
}
