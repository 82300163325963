import { ModalPickerDialog, ModalPickerDialogOptions } from '../../components/ui/modal-picker-base';
import { PaymentProfileSummary } from '../../api/dealer-api-interface-franchisee';
import { PaymentProfileApi } from '../../api/payment-profile-api';
// eslint-disable-next-line import/named
import { TemplateResult, html } from 'lit';
import { PaymentProfileSummaryPickerBrowser } from './payment-profile-list-table';
import { NullPromise } from '../../null-promise';
import { tlang } from '@softtech/webmodule-components';
import { PromiseTemplate } from '../../components/ui/events';
import { singleSupplierId } from '../../quotes/quote-service';

export interface PaymentProfilePickerOptions extends ModalPickerDialogOptions<PaymentProfileSummary> {
  api: PaymentProfileApi;
  supplierId: string | null;
}

export async function PaymentProfilePicker(api: PaymentProfileApi): NullPromise<PaymentProfileSummary> {
  return new Promise<PaymentProfileSummary | null>((resolve, reject) => {
    const picker = new PaymentProfileBrowserPicker({
      onSelect: (selected: PaymentProfileSummary | null) => {
        resolve(selected);
      },
      onCancel: () => reject(),
      title: tlang`Select a %%payment-profile%%`,
      api: api,
      supplierId: singleSupplierId()
    });
    picker.showModal();
  });
}

export class PaymentProfileBrowserPicker extends ModalPickerDialog<PaymentProfileSummary> {
  private comms: PaymentProfileSummaryPickerBrowser;

  constructor(options: PaymentProfilePickerOptions) {
    super(options);

    this.comms = this.paymentProfilePickerBrowserFactory(options);
  }

  protected paymentProfilePickerBrowserFactory(
    options: PaymentProfilePickerOptions
  ): PaymentProfileSummaryPickerBrowser {
    return new PaymentProfileSummaryPickerBrowser({
      title: () => options.title ?? '',
      onPaymentProfileSelected: paymentProfileSummary => this.select(paymentProfileSummary),
      supplierId: options.supplierId
    });
  }

  protected async bodyTemplate(): PromiseTemplate {
    this.comms.render();
    return html`${this.comms.ui}`;
  }

  protected footerTemplate(): TemplateResult | null {
    return html`<button @click=${() => this.hideModal()} class="btn btn-secondary">${tlang`Close`}</button>`;
  }
}
