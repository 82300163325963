import { html, TemplateResult } from 'lit';
import { emptyGuid } from '../../api/guid';
import { tlang } from '@softtech/webmodule-components';
import { resolveURL } from './resource-resolver';

export function validId(value: string | null | undefined): boolean {
  return !isEmptyOrSpace(value);
}

export function sameText(a: string, ...b: string[]): boolean {
  for (let i = 0; i < b.length; i++) {
    if (a.toLowerCase() === b[i].toLowerCase()) return true;
  }
  return false;
}

export function isEmptyOrSpace(value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === '' || value.trim() == emptyGuid;
}

export function joinWithCommaAnd(array: string[]) {
  if (array && array.length > 1) {
    let last = array.pop();
    return `${array.join(', ')} and ${last}`;
  } else {
    return array[0] || '';
  }
}

export function isValidEmail(mail: string): boolean {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

export function concatNotes(...args) {
  return args.filter(str => str && !isEmptyOrSpace(str.toString())).join('\r\n----------\r\n');
}

function displayBoolean(yes: string, no: string, value?: boolean) {
  return value ? yes : no;
}

export function displayYesNo(value?: boolean) {
  return displayBoolean(tlang`Yes`, tlang`No`, value);
}

export function bsFormRadioGroupYesNoOptions() {
  const value = JSON.stringify([
    { value: 'Yes', text: 'Yes' },
    { value: 'No', text: 'No' }
  ]);
  return value;
}

export function firstValidString(...args) {
  for (let i = 0; i < args.length; i++) if (!isEmptyOrSpace(args[i])) return args[i];
  return '';
}

export interface ClosePageAction {
  url: string;
  template: TemplateResult;
}

export function closePageAction(
  primaryType: string,
  primaryId?: string | null,
  primaryFragment?: string | null,
  fallbackType?: string,
  fallbackId?: string,
  fallbackFragment?: string | null
): ClosePageAction {
  const route =
    primaryId && validId(primaryId)
      ? resolveURL(primaryType, primaryId, primaryFragment)
      : resolveURL(fallbackType ?? primaryType, fallbackId ?? '', fallbackFragment);
  return {
    template: html` <a href="${route}" class="form-close-action float-end btn-close"></a>`,
    url: route
  };
}

export function flagInSet(flag: number, flagSet: number): boolean {
  return (flag & flagSet) !== 0;
}

export function isFlagInFilterSet(flag: number, flagSet?: number | null): boolean {
  return flagSet === undefined || flagSet === null || flagInSet(flag, flagSet);
}

export function isWithinRange(value: number, min: number = 0, max?: number): boolean {
  if (max === undefined) {
    max = 100;
  }
  return value >= min && value <= max && (max !== 100 || value < 100);
}

/**
 * NOTE: This is a work-around what appears to be a bug in Chrome :https://bugs.chromium.org/p/chromium/issues/detail?id=920069
 */
export function getOffset(element: HTMLElement, parent: HTMLElement) {
  return {
    top: Math.round(element.getBoundingClientRect().top - parent.getBoundingClientRect().top),
    left: Math.round(element.getBoundingClientRect().left - parent.getBoundingClientRect().left)
  };
}

/** Scrolls an element into view of its container. If the element is already in view, nothing will happen. */
export function scrollIntoView(
    element: HTMLElement,
    container: HTMLElement,
    direction: 'horizontal' | 'vertical' | 'both' = 'vertical',
    behavior: 'smooth' | 'auto' = 'smooth'
) {
  const offset = getOffset(element, container);
  const offsetTop = offset.top + container.scrollTop;
  const offsetLeft = offset.left + container.scrollLeft;
  const minX = container.scrollLeft;
  const maxX = container.scrollLeft + container.offsetWidth;
  const minY = container.scrollTop;
  const maxY = container.scrollTop + container.offsetHeight;

  if (direction === 'horizontal' || direction === 'both') {
    if (offsetLeft < minX) {
      container.scrollTo({ left: offsetLeft, behavior });
    } else if (offsetLeft + element.clientWidth > maxX) {
      container.scrollTo({ left: offsetLeft - container.offsetWidth + element.clientWidth, behavior });
    }
  }

  if (direction === 'vertical' || direction === 'both') {
    if (offsetTop < minY) {
      container.scrollTo({ top: offsetTop, behavior });
    } else if (offsetTop + element.clientHeight > maxY) {
      container.scrollTo({ top: offsetTop - container.offsetHeight + element.clientHeight, behavior });
    }
  }
}