// eslint-disable-next-line import/named
import { html } from 'lit';
import { ProjectContainerManager } from '../data/project-container';
import { EventBooleanAsync, PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { ViewBase } from '../../components/ui/view-base';

export interface ProjectDocumentViewOptions {
  projectManager: ProjectContainerManager;
  canClose: EventBooleanAsync;
}

export class ProjectDocumentView extends ViewBase {
  protected readonly projectManager: ProjectContainerManager;
  protected canClose: EventBooleanAsync;

  constructor(options: ProjectDocumentViewOptions) {
    super();
    this.projectManager = options.projectManager;
    this.canClose = options.canClose;
  }

  public async invalidate(): Promise<void> {
    await this.render();
  }

  public async refreshData(): Promise<void> {
    throw new Error('No override provided for refresh data on resource view.');
  }

  public async buttonMenu(): PromiseSnippet {
    return html``;
  }

  protected async template(): PromiseTemplate {
    return html``;
  }
}
