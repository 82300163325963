// eslint-disable-next-line import/named
import { html } from 'lit';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { DataTracker, DynamicValueBinder, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import { StockManager } from '../data/stock-manager';
import { DataTableWrapper, RequestPage, ResultPaginated } from '../../components/ui/datatable-view';
import { StockItem } from '../../api/dealer-api-interface-franchisee';
import { isAutoSaving } from '../../components/save-workflow';
import { fireQuickSuccessToast } from '../../toast-away';
import { supplierItemContentTypeDisplay } from '../../quotes/data/supplier-quote-item-content-type';

interface FranchiseeStockOptions {
  stockManager: StockManager;
}

interface FranchiseeStockTableOptions extends FranchiseeStockOptions {
  formInputAssistant?: FormInputAssistant;
}

export class FranchiseeStockTable extends DataTableWrapper<StockItem> {
  stockManager: StockManager;
  formInputAssistant?: FormInputAssistant;

  filter?: string;

  constructor(options?: FranchiseeStockTableOptions) {
    super();

    const resolveOptions: FranchiseeStockTableOptions = {
      stockManager: new StockManager(null),
      formInputAssistant: undefined,
      ...options
    };

    this.stockManager = resolveOptions.stockManager;
    this.formInputAssistant = resolveOptions.formInputAssistant;
  }

  verticalHeight(): string | undefined {
    return undefined;
  }

  usePaging(): boolean {
    return false;
  }

  useInfo(): boolean {
    return false;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  ordering(): boolean {
    return false;
  }

  enableFiltering(): boolean {
    return true;
  }

  updateFilter(_searchTerm: string | null) {
    if (_searchTerm) this.filter = _searchTerm;
    else {
      this.filter = undefined;
      this.stockManager.refreshItemsFlag = true;
    }
  }

  public getRowGroup(): any {
    return {
      startRender: (rows, group: string, _level: number) => {
        if (rows.length == 0) return;

        const groupN = parseInt(group);

        return supplierItemContentTypeDisplay(groupN, true, true);
      },
      dataSrc: 'itemContentType'
    };
  }

  override async getRowsFromServer(_request: RequestPage): Promise<ResultPaginated<StockItem>> {
    await this.stockManager.needsStockItems(this.filter);

    const items = this.stockManager.items;

    if (!items)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: 2000,
        results: []
      };

    return {
      count: items.length,
      pageCount: 1,
      pageIndex: 0,
      pageSize: items.length,
      results: items.sort((a, b) => {
        return a.itemContentType == b.itemContentType ? 0 : a.itemContentType > b.itemContentType ? 1 : -1;
      })
    };
  }

  getColumns(): any[] {
    const cols: any[] = [];
    cols.push({
      title: 'Code',
      width: '50px',
      data: 'code',
      className: 'stock-item-code',
      render: (_data: any, _type: any, row: StockItem, _meta: any) => {
        // const field = `stock_code_${row.id}`;
        // return `
        //   <bs-form-input-no-label
        //     value="${this.formInputAssistant?.dataTracker.getObjectDisplayValue(field)}"
        //     type="text" data-id="${field}">
        //   </bs-form-input-no-label>
        // `;
        return row.code;
      }
    });

    cols.push({
      title: 'Description',
      width: '200px',
      data: 'description',
      className: 'stock-item-description',
      render: (_data: any, _type: any, row: StockItem, _meta: any) => {
        const field = `stock_description_${row.id}`;
        return `
          <bs-form-input-no-label
            value="${this.formInputAssistant?.dataTracker.getObjectDisplayValue(field)}"
            type="text" data-id="${field}">
          </bs-form-input-no-label>
        `;

        // return row.description;
      }
    });

    cols.push({
      title: 'Cost Per Unit',
      width: '50px',
      data: 'singleUnitCost',
      className: 'stock-item-singleUnitCost',
      render: (_data: any, _type: any, row: StockItem, _meta: any) => {
        const field = `stock_unit_cost_${row.id}`;
        return `
          <bs-form-money data-id="${field}" dp="2"
          value="${this.formInputAssistant?.dataTracker.getObjectDisplayValue(field)}">
          </bs-form-money>
        `;
      }
    });

    return cols;
  }

  protected enableTableClass(): string {
    return 'franchisee-stock-table';
  }
}

export class FranchiseeStockView extends PageControlTabWithIndependantSaving {
  public ShowTab = false;
  protected table: FranchiseeStockTable;
  private stockManager: StockManager;
  private readonly dataBinding: DataBinding;
  private readonly dataTracker: DataTracker;

  constructor(options?: FranchiseeStockOptions) {
    super();

    const resolveOptions: Required<FranchiseeStockOptions> = {
      stockManager: new StockManager(null),
      ...options
    };

    this.stockManager = resolveOptions.stockManager;

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}`;
    });

    this.dataBinding.allowMissingElements = true;
    this.dataTracker = new DataTracker(this.dataBinding);

    this.table = this.stockTableFactory({
      formInputAssistant: new FormInputAssistant(this.dataTracker),
      ...resolveOptions
    });
  }

  override async afterConstruction(): Promise<void> {
    await this.stockManager.needsStockItems();

    this.ShowTab = this.stockManager.showTab;

    if (this.stockManager.items)
      for (let i = 0; i < this.stockManager.items.length; i++) {
        this.addStockBinding(this.stockManager.items[i].id);
      }
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    if (!this.stockManager.reportValidation()) {
      this.stockManager.getValidation().map(x => errors.push(`Code (${x.code}) - ${x.message}`));
    }

    return errors;
  }

  public async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return this.stockManager.changed();
  }

  public resetEditControls() {
    if (this.dataTracker.modified) {
      this.dataTracker.resetEditorValue();
    }
  }

  async onEnter(): Promise<void> {
    await this.render();
    await this.table.refreshData();
    await this.table.render();
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('Saving Franchisee Stock');

    const result = await this.stockManager.saveStockItems();

    if (!result) return false;

    if (!isAutoSaving()) fireQuickSuccessToast(tlang`!!stock!! saved`);

    return true;
  }

  protected getCaption(): Snippet {
    return tlang`!!stock!!`;
  }

  protected async bodyTemplate(): PromiseTemplate {
    return html` <div>
      <form id="stockForm" class="form-one-col">
        <h2>${tlang`!!stock!!`}:</h2>
        <div class="row" @bs-form-input-blur="${e => this.editVal(e)}">${this.table.ui}</div>
      </form>
    </div>`;
  }

  protected stockTableFactory(options?: FranchiseeStockTableOptions) {
    return new FranchiseeStockTable(options);
  }

  private editVal(e: CustomEvent) {
    this.dataTracker.setEditorValue(e.detail.id, e.detail.value);
  }

  // stock_description

  private addStockBinding(itemId: string) {
    const fieldSingleUnitCost = `stock_unit_cost_${itemId}`;
    const fielddescription = `stock_description_${itemId}`;

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.money,
        true,
        () => {
          const si = this.stockManager.getStockItem(itemId);

          if (!si) return null;

          return si.singleUnitCost == null ? null : (si.singleUnitCost as number);
        },
        value => {
          this.stockManager.updateStockItem(itemId, undefined, value as number);
        },
        () => false
      ),
      fieldSingleUnitCost,
      fieldSingleUnitCost,
      FieldType.money,
      true
    );

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.string,
        true,
        () => {
          const si = this.stockManager.getStockItem(itemId);

          if (!si) return null;

          return si.description == null ? null : (si.description as string);
        },
        value => {
          this.stockManager.updateStockItem(itemId, value as string, undefined);
        },
        () => false
      ),
      fielddescription,
      fielddescription,
      FieldType.string,
      false
    );
  }
}
