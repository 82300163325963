// eslint-disable-next-line import/named
import { html } from 'lit';
import { getApiFactory } from '../../../api/api-injector';
import { Client } from '../../../api/dealer-api-interface-client';
import {
  BranchQuote,
  FranchiseeClient,
  ResultGetSupplierQuoteSettings
} from '../../../api/dealer-api-interface-franchisee';
import { QuoteState, QuoteType } from '../../../api/dealer-api-interface-quote';
import { emptyGuid } from '../../../api/guid';
import { PaymentProfileApi } from '../../../api/payment-profile-api';
import { ClientPicker } from '../../../clients/views/client-picker';
import { ContactPicker } from '../../../clients/views/contact-picker';
import { FieldType } from '../../../components/ui/databinding/data-tracker';
import { isEmptyOrSpace, validId } from '../../../components/ui/helper-functions';
import { FormInputAssistant } from '../../../components/ui/templateresult/form-input-assistant';
import { tlang } from '@softtech/webmodule-components';
import { QuoteDetailView, QuoteDetailViewOptions } from '../../../quotes/views/quote-detail-view';
import { fireQuickWarningToast } from '../../../toast-away';
import { DevelopmentError, showDevelopmentError } from '../../../development-error';
import { lockUIandExecute } from '../../../ui-lock';
import { FranchiseeClientView } from '../../clients/views/franchisee-client-view';
import { FranchiseeQuoteContainer } from '../data/franchisee-quote-container';
import { constructAsync } from '../../../async-constructor';
import { userDataStore } from '../../common/current-user-data-store';
import { createNewClient } from '../../clients/data/franchisee-client-container';
import { DataCacheGeneric } from '../../../cache/generic-data-cache';
import { cache } from '../../cache/cache-registry';
import { PromiseTemplate } from '../../../components/ui/events';
import { PaymentProfileCacheData } from '../../cache/cache-data';
import { serverDateTimeToLocalRFC3339 } from '../../../components/datetime-converter';
import { getInternalId } from '../../../components/ui/databinding/databinding';
import {
  FranchiseeQuoteAddressManager,
  FranchiseeQuoteAddressManagerType
} from '../data/franchisee-quote-address-manager';
import { FranchiseeQuoteContainerManager } from '../data/franchisee-quote-manager';
import { emptyAddress, isAddressNullOrEmpty, singleLineAddress } from '../../../components/ui/maps/map-helpers';
import { clientContainerManagerFactory } from '../../clients/client-ui-adaptor';
import { AddressOption, WebModuleAddressEditor } from '../../../components/ui/address-editor';
import { clone } from '../../../components/clone';
import { SupplierApi } from '../../../api/supplier-api';
import { checkValidations } from '../../../components/ui/data-entry-screen-helpers';
import { FranchiseePaymentProfilePicker } from '../../franchisee/view/franchisee-payment-profile-picker';
import { getQuoteSupplierDisplayName } from '../../../quotes/data/quoteSupplierProvider';
import { getQuoteTypeMap } from '../../../quotes/data/quote-helper-functions';
import { TenantLoginPublicInfo } from '../../../api/dealer-api-interface-user';
import { getBranchUserCache } from '../../cache/dealer-branch-user-cache';
import { DataCache } from '../../../cache/data-cache';
import { AskConfirmation, buttonsYesNo } from "../../../components/ui/modal-confirmation";

export class FranchiseeQuoteDetailView extends QuoteDetailView {
  contactCache: DataCacheGeneric = cache().contact;
  clientCache = cache().client;
  primaryContactCache = cache().primaryContact;
  prlCache = cache().projectResourceLink;
  projectCache = cache().project;
  paymentProfileCache: DataCache<PaymentProfileCacheData> = cache().paymentProfile;
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  supplierApi: SupplierApi = getApiFactory().supplier();
  internalId: string;
  clientPickerDisplay = '';
  contactPickerDisplay = '';
  clientTypePickerDisplay = '';
  branchUserCache = getBranchUserCache();
  supplierQuoteConfig?: ResultGetSupplierQuoteSettings;
  addressManager: FranchiseeQuoteAddressManager;

  private updatePricingMargin?: boolean;

  get validityPeriod(): number | undefined {
    return this.supplierQuoteConfig?.validityPeriod;
  }

  get leadTime(): number | null {
    return this.supplierQuoteConfig?.leadPeriod ?? null;
  }

  constructor(options: QuoteDetailViewOptions) {
    super(options);
    this.internalId = getInternalId();

    this.addressManager = new FranchiseeQuoteAddressManager(this.quoteManager as FranchiseeQuoteContainerManager);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.branchQuote),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('clientId');
    addField('contactId');
    addField('clientTypeId');
    addField('assignedToUserId', FieldType.string, false, undefined, () => this.quote);
  }

  async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    await this.branchUserCache.prefetch();
    await this.quoteManager.needsQuote();
    await this.branchUserCache.addUsersToBranch(this.branchQuote!.branchId, [
      this.quote.assignedToUserId,
      this.quote.creationUserId,
      this.quote.lastModifiedUserId
    ]);
  }

  protected get branchQuote(): BranchQuote | null {
    if (!(this.quoteManager.container instanceof FranchiseeQuoteContainer))
      throw new DevelopmentError('FranchiseeQuoteDetailView requires a container type FranchiseeQuoteContainer');
    return (this.quoteManager.container as FranchiseeQuoteContainer).branchQuote;
  }

  public async prepareForSave(): Promise<void> {
    if (!this.branchQuote) {
      await showDevelopmentError(`BranchQuote is null`);
      return;
    }
    const oldClientTypeId = this.branchQuote.clientTypeId;
    const oldQuoteType = this.quote.quoteType;

    this.dataTracker.applyChangeToValue();

    this.quote.quoteCustomerId = this.branchQuote.clientId ?? emptyGuid;

    //If there was no change in client type or quote type, we can exit. Otherwise update terms and margin if applicable.
    if (oldClientTypeId == this.branchQuote.clientTypeId && oldQuoteType == this.quote.quoteType) return;

    const paymentProfile = await this.paymentProfileCache.get(this.branchQuote?.clientTypeId);

    if (!isEmptyOrSpace(this.branchQuote.clientTypeId)) {
      if (!paymentProfile || !paymentProfile?.data) {
        await showDevelopmentError(`PaymentProfile is null. cache entry "${this.branchQuote.clientTypeId}"`);
        return;
      }

      const profile = paymentProfile.data as PaymentProfileCacheData;

      if (this.updatePricingMargin == true)
      {
        if (profile.paymentProfile.supplierId === this.quote.supplierId) {
          //Update Margins when client type changed.
          if (oldClientTypeId != this.branchQuote.clientTypeId) {
            const price = this.quoteManager.quotePrice;
            price.marginPercentage = profile.paymentProfile.margin;
          }
        }

        //Update Terms
        if (this.quote.quoteType == QuoteType.Quote)
          this.quoteManager.quote.termsAndConditions = profile.paymentProfile.quoteTerms;
        else if (this.quote.quoteType == QuoteType.Estimate)
          this.quoteManager.quote.termsAndConditions = profile.paymentProfile.estimateTerms;
        else this.quoteManager.quote.termsAndConditions = null;

        this.updatePricingMargin = undefined;
      }
    }
  }

  async resetEditControls() {
    super.resetEditControls();
    await this.loadPickerDisplaysFromCache();

    await this.render();
  }

  async loadOrRefresh(): Promise<void> {
    await super.loadOrRefresh();
    //need quote items to check the client type state
    await this.loadPickerDisplaysFromCache();

    if (!this.supplierQuoteConfig) {
      const _supplierQuoteConfig = await this.supplierApi.getSupplierQuoteConfig({
        supplierId: this.quote.supplierId
      });
      if (_supplierQuoteConfig) {
        this.supplierQuoteConfig = _supplierQuoteConfig;
      }
    }

    await this.addressManager.loadAddresses();
    const addressChanged = this.addressManager.updateCurrentQuoteAddress();
    if (addressChanged) {
      this.showCheckShippingNoteWarning();
    }
    await this.render();
  }

  getValidationErrorsForState(state: QuoteState): string[] {
    const errors = super.getValidationErrorsForState(state);

    if (!this.branchQuote) {
      throw new DevelopmentError('BranchQuote is null');
    }

    if (state == QuoteState.Active) {
      const clientId = this.branchQuote.clientId;
      const contactId = this.branchQuote.contactId;

      if (isEmptyOrSpace(clientId)) errors.push(tlang`Please select a %%client%%`);

      if (isEmptyOrSpace(contactId)) errors.push(tlang`Please select a %%contact%%`);
    }

    if (state == QuoteState.Cancelled) {
      return [];
    }

    if (state == QuoteState.IssuePending) {
      if (isAddressNullOrEmpty(this.quote.shippingAddress)) errors.push(tlang`Please select or enter a valid address`);
    }

    const clientTypeId = this.branchQuote.clientTypeId;

    if (isEmptyOrSpace(clientTypeId)) errors.push(tlang`Please select a %%payment-profile%% for accurate pricing`);

    return errors;
  }

  async createNewClient() {
    const title = tlang`Add %%client%%`;
    const container = await createNewClient(userDataStore.clientOwnerId);
    if (!container) {
      await showDevelopmentError('Client could not be created');
      return;
    }

    const qm = clientContainerManagerFactory(container);

    const qv = await constructAsync(
      new FranchiseeClientView({
        viewTitle: () => title,
        clientContainerManager: qm
      })
    );
    await qv.showModal();
    const client = qv.view?.clientContainer.client;
    if (client && validId(client.id)) {
      this.selectClient(client);
    }
  }

  protected async template(): PromiseTemplate {
    const allowCreateClient = !this.quoteManager.isReadonly() && this.quoteManager.quoteState == QuoteState.Draft;
    const forms = new FormInputAssistant(this.dataTracker, this.quoteManager.isReadonly());
    if (allowCreateClient) forms.classes.push({ id: 'clientId', classes: 'shortstop-edit' });

    const clientPicker =
      this.quoteManager.quoteState == QuoteState.Draft
        ? forms.picker('clientId', this.clientPickerDisplay, async () => await this.selectClient(), tlang`%%client%%`)
        : forms.pickerReadonly('clientId', this.clientPickerDisplay, tlang`%%client%%`);
    const contactPicker = forms.picker(
      'contactId',
      this.contactPickerDisplay,
      async () => await this.selectContact(),
      tlang`%%contact%%`
    );

    const clientTypePicker = forms.pickerRequired(
      'clientTypeId',
      this.clientTypePickerDisplay,
      async () => await this.selectClientType(),
      tlang`%%payment-profile%%`
    );

    const createClientEvent = async () => lockUIandExecute(async () => await this.createNewClient());
    const createClientTemplate = allowCreateClient
      ? html` <button
          @click=${createClientEvent}
          class="btn btn-primary shortstop-edit"
          type="button"
          id=${forms.id('create-client')}
        >
          ${tlang`New`}
        </button>`
      : html``;

    const validUntilToolTipText = tlang`The system calculates the expiry date based on the default validity period
            (in ‘settings’) from the current date (e.g. today + 28 days), every day until the date you actually issue the %%quote%%.`;

    const installationDateToolTipText = tlang`Ensure that you consider the supplier’s scheduling, ordering, fabrication & shipping lead
            times before committing to a proposed delivery date to the client. If in doubt, contact the supplier for confirmation.`;

    const validityDisplay = () => {
      let valDisplay = !this.validityPeriod ? '' : `${this.validityPeriod} day${this.validityPeriod == 1 ? '' : 's'}`;
      let valLabel = tlang`Validity Period`;
      let controlType = 'text';
      let controlToolTip: string | null = validUntilToolTipText;

      if (this.quote.validUntilDate) {
        controlType = 'datetime-local';
        valLabel = tlang`'Valid To' Date`;
        valDisplay = serverDateTimeToLocalRFC3339(this.quote.validUntilDate);
        controlToolTip = null;
      }

      return html`
        <bs-form-input
          data-id=${'quote-validityDisplay-' + this.dataTracker.binder.internalId}
          type=${controlType}
          ?readonly=${true}
          value=${valDisplay}
          data-label=${valLabel}
          toolTip=${controlToolTip}
        >
        </bs-form-input>
      `;
    };

    const shippingOptionEntry = (type: FranchiseeQuoteAddressManagerType, allowDisabled = true) => {
      return {
        value: this.addressManager.getAddressTagByType(type),
        text: `${this.addressManager.getAddressLabelByTag(this.addressManager.getAddressTagByType(type))}
                    ${tlang`${'ref:addressLabel'}Address`}`,
        disabled: allowDisabled && isAddressNullOrEmpty(this.addressManager.addressByType(type))
      };
    };

    const shippingOptions = () => {
      const addOptions = [
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.branch),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.client),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.project),
        shippingOptionEntry(FranchiseeQuoteAddressManagerType.other, false)
      ];

      const disableWillCall = !(this.supplierQuoteConfig && this.supplierQuoteConfig.willCallLocations
          && this.supplierQuoteConfig.willCallLocations.length > 0) ?? true;

      addOptions.push({
        value: this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.willCall),
        text: `${this.addressManager.getAddressLabelByTag(this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.willCall))}
                    ${tlang`${'ref:addressLabel'}Address`}`,
        disabled: disableWillCall
      });

      return JSON.stringify(addOptions);
    };

    const addressSource =
      this.quote.shippingAddress?.source ??
      this.addressManager.getAddressTagByType(FranchiseeQuoteAddressManagerType.other);

    const addrSelector = html`<bs-form-radio-group
      slot="subtitle"
      data-label=${tlang`Delivery Address`}
      data-id=${this.dataBinding.field('source')}
      ?required=${false}
      ?readonly=${this.quoteManager.isReadonly()}
      .value=${addressSource}
      .options=${shippingOptions()}
      @radio-changed=${_ => this.setDeliveryAddress(_.detail)}
    >
    </bs-form-radio-group>`;

    const addressPickerList = addressSource
    && addressSource == "franchisee-address-type:willcall"
        && this.supplierQuoteConfig?.willCallLocations
        ? this.supplierQuoteConfig.willCallLocations.map(x => <AddressOption>{...x.willCallAddress,
          display: `${x.title} - ${x.type} - ${singleLineAddress(x.willCallAddress)}`}) : null;

    const addressTemplate1 = html`<webmodule-address-editor
      .subheader=${addrSelector}
      .preSelectAddressList="${addressPickerList}"
      @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as WebModuleAddressEditor)}
      id="physicalAddressEditor"
      .address=${this.quote.shippingAddress}
      .shippingNotes=${this.quote.shippingNotes}
      .title=${tlang`Deliver from ${await getQuoteSupplierDisplayName(this.quote.supplierId)} to`}
      .readonly=${this.quoteManager.isReadonly()}
      .isDefaultShippingVisible=${false}
    >
    </webmodule-address-editor>`;

    const dealerName = userDataStore.franchisee.name;
    const dealerOrderNo = isEmptyOrSpace(dealerName) ? tlang`%%franchisee%% Order No.` : tlang`${dealerName} Order No.`;

    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    minDate.setDate(minDate.getDate() + (this.leadTime ?? 0) * 7);

    const users = this.branchUserCache.getBranchUsers(this.branchQuote?.branchId ?? emptyGuid);
    const userToOption = (u: TenantLoginPublicInfo) => ({ text: u.friendlyName ?? '', value: u.id, disabled: false });

    let typesToHide: number[] | undefined = undefined;

    if (this.supplierQuoteConfig && this.supplierQuoteConfig.availableQuoteTypes)
      typesToHide = Object.values(QuoteType)
        .filter(value => !isNaN(Number(value)))
        .map(Number)
        .filter(x => !this.supplierQuoteConfig?.availableQuoteTypes.includes(x));

    return html`
      <form class="py-3 px-0 frm-quote-details form-two-col" novalidate>
        <div class="row">
          <div>
            ${forms.text('title', tlang`Title`, 200)} ${clientPicker} ${createClientTemplate} ${contactPicker}
            ${clientTypePicker} ${forms.money('budget', tlang`Budget`)}
            ${forms.note('description', tlang`Description`, 2950)}
          </div>
          <div>
            ${forms.text('customQuoteNumber', dealerOrderNo, 200)}
            ${forms.radioGroupHideValues('quoteType', QuoteType, tlang`Type`, typesToHide, getQuoteTypeMap())}
            ${forms.arraySelect('assignedToUserId', users, userToOption, tlang`Author`)}
            ${forms.dateReadonly('dateCreated', tlang`Created`)}
            ${forms.dateReadonly('lastModifiedDate', tlang`Last Modified`)} ${validityDisplay()}
            ${forms.date(
              'installationDate',
              tlang`Proposed Delivery`,
              'date',
              installationDateToolTipText,
              minDate.getTime()
            )}
          </div>
        </div>
        ${addressTemplate1}
      </form>
    `;
  }

  addressModified(addressEditor: WebModuleAddressEditor) {
    if (addressEditor.address) {
      this.quote.shippingAddress = clone(addressEditor.address);
      this.quote.shippingNotes = addressEditor.shippingNotes ?? '';
    } else {
      this.quote.shippingAddress = emptyAddress();
      this.quote.shippingNotes = '';
    }
  }

  protected setAddressByType(type: FranchiseeQuoteAddressManagerType) {
    this.addressManager.updateAddress(type);
  }

  protected async setDeliveryAddress(tag: string) {
    this.setAddressByType(this.addressManager.getAddressTypeByTag(tag));
    await this.render();
  }

  private async loadPickerDisplaysFromCache() {
    if (!this.branchQuote) {
      this.clientPickerDisplay = '';
      this.contactPickerDisplay = '';
      this.clientTypePickerDisplay = '';
    } else {
      const clientData = await this.clientCache.get(this.branchQuote.clientId);
      this.clientPickerDisplay = clientData?.displayValue ?? '';

      this.contactPickerDisplay = (await this.contactCache.get(this.branchQuote.contactId))?.displayValue ?? '';
      this.clientTypePickerDisplay =
        (await this.paymentProfileCache.get(this.branchQuote.clientTypeId))?.displayValue ?? '';
    }
  }

  private async selectClient(client?: Client) {
    const selectedClient = client?.id ?? (await ClientPicker())?.id;

    if (!selectedClient) {
      return;
    }

    this.dataBinding.setValue('clientId', selectedClient);
    const clientCacheItem = await this.clientCache.get(selectedClient);
    const clientData = clientCacheItem?.data;
    if (!clientData) return;
    this.clientPickerDisplay = clientCacheItem?.displayValue ?? '';
    const franchiseeClient = clientData.franchiseeClient as FranchiseeClient;

    const primaryContact = await this.primaryContactCache.getData(selectedClient);
    this.dataBinding.setValue('contactId', primaryContact?.contactId ?? emptyGuid);
    const contact = await this.contactCache.get(primaryContact?.contactId ?? emptyGuid);
    this.contactPickerDisplay = contact?.displayValue ?? '';

    const defaultPaymentProfile = await this.paymentProfileCache.get(franchiseeClient.paymentProfileId);

    if (defaultPaymentProfile && defaultPaymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      await this.updateClientTypeAndPickerDisplay(defaultPaymentProfile.id);
    }

    this.updateClientAddress(clientData.client);

    await this.render();
  }

  private async selectContact() {
    const selectedClient = this.dataBinding.getValue('clientId');

    if (selectedClient == emptyGuid) {
      this.dataBinding.setValue('contactId', emptyGuid);
      this.contactPickerDisplay = '';

      fireQuickWarningToast(tlang`Please select a %%client%% first.`, 1000);
    } else {
      const selectedContact = await ContactPicker(selectedClient);

      if (selectedContact) {
        this.dataBinding.setValue('contactId', selectedContact.id);
        this.contactPickerDisplay = selectedContact.name ?? '';
      }
    }

    await this.render();
  }

  private async selectClientType() {
    const selectedClientType = await FranchiseePaymentProfilePicker(this.paymentProfileApi, this.quote.supplierId);

    if (selectedClientType) {
      await this.updateClientTypeAndPickerDisplay(selectedClientType.id);
      await this.render();
    }
  }

  private async updateClientTypeAndPickerDisplay(id: string) {
    const paymentProfile = await this.paymentProfileCache.get(id);

    if (paymentProfile && paymentProfile.id !== this.dataBinding.getValue('clientTypeId')) {
      this.dataBinding.setValue('clientTypeId', paymentProfile.id);
      this.clientTypePickerDisplay = paymentProfile.displayValue;

      const t = this.branchQuote?.clientTypeId;
      console.log(t)

      if (this.branchQuote?.clientTypeId != emptyGuid && await AskConfirmation(
          tlang`Would you like to update the Margin and T&C’s on the Quote Summary page? This will alter the Quote Price.`,
          buttonsYesNo(), undefined, "Update Margin and Terms & Conditions"))
      {
        this.updatePricingMargin = true;

        fireQuickWarningToast(
            tlang`The ‘%%payment-profile%%’ change will inherit its’ associated Margin and Terms & Conditions, please review details on the !!quote-item!! tab.`
        );
      }
      else if(this.branchQuote?.clientTypeId == emptyGuid)
      {
        this.updatePricingMargin = true;
      }
    }
  }

  private updateClientAddress(client: Client) {
    const existingAddressType = this.addressManager.getAddressTypeByTag(this.quote.shippingAddress?.source);
    this.addressManager.setClient(client);

    const isClientAddressEmpty = isAddressNullOrEmpty(client.physicalAddress);

    if (
      !isClientAddressEmpty &&
      client.shipToPhysicalAddress &&
      existingAddressType != FranchiseeQuoteAddressManagerType.project
    ) {
      this.setAddressByType(FranchiseeQuoteAddressManagerType.client);
      if (existingAddressType != FranchiseeQuoteAddressManagerType.other) {
        this.showCheckShippingNoteWarning();
      }
    }
  }

  protected showCheckShippingNoteWarning() {
    fireQuickWarningToast(tlang`The currently selected delivery address has been updated.
      Please review the !!shipping-note!! to ensure that they are still valid.`);
  }

  async valid(state: QuoteState): Promise<boolean> {
    const errors = this.getValidationErrorsForState(state);
    return await checkValidations(errors);
  }
}
