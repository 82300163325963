// eslint-disable-next-line import/named
import { html, TemplateResult } from 'lit';
import { PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { ModalDialog } from '../../components/ui/modal-base';
import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from '../../components/ui/modal-confirmation';
import { WaitPatiently } from '../../components/ui/modal-spinner';
import { lang, tlang } from '@softtech/webmodule-components';
import { QuoteContainerManager } from '../data/quote-container';
import { QuoteItemContainer } from '../data/quote-item-container';
import { processQuoteItemState } from '../data/quote-helper-functions';
import { Message } from '../../components/messages';
import { getSupplierVersion } from '../data/v6/helper-functions';
import { asMarkdownTemplate } from '../../components/markdown';
import { showDevelopmentError } from '../../development-error';

/**
 *
 * @param quoteManager the quote that we want to alter the quote item defaults on
 * @param defaults the current accurate quote defaults as a baseline
 */
export async function validateOutOfDateQuoteItems(
  quoteManager: QuoteContainerManager,
  handler: OutOfDateQuoteItemHandler
) {
  await new ValidateOutOfDDateQuoteItemsModal(quoteManager, handler).showModal();
}

/**
 * a list of properties that we need to apply to a quote item before doing an update.
 */
interface ItemToProcess {
  quoteItemId: string;
}

/**
 * tracking item for keeping a record if which items are selected to update
 */
interface UITargetItemChecked {
  targetItem: QuoteItemContainer;
  checked: boolean;
}

/**
 * ui tracking to render and note the quote items that are updated, and what the status of them was
 */
export interface QuoteItemProcessingCompleteItem {
  quoteItemContainer: QuoteItemContainer;
  //TODO-Add an expansion view to show any validation
  validation: Message[];
  error: string;
  oldPrice: number;
  newPrice: number;
  completionStatus: string;
}

/**
 * helper interface to keep the properties together for processing
 */
interface DataProcessingInformation {
  //the client has started processing
  processing: boolean;
  //sucessfully completed processing
  processingComplete: boolean;
  //cancel was requested
  cancelling: boolean;
  //the list of items to process for this job
  itemsToProcess: ItemToProcess[];
  //the current item being processed
  currentItemToProcess: ItemToProcess | null;
  //the current state of the current item
  currentItemState: string;
  //list of completed items
  completedItems: QuoteItemProcessingCompleteItem[] | null;
}
export interface OutOfDateQuoteItemHandler {
  finalProcessing: (qm: QuoteContainerManager) => Promise<boolean>;
  isOutOfDate(qic: QuoteItemContainer): boolean;
  isValidatingItem(qic: QuoteItemContainer): boolean;
  displayInfo(qic: QuoteItemContainer): string;
  processItemEvent: (
    qm: QuoteContainerManager,
    qic: QuoteItemContainer,
    completeItem: QuoteItemProcessingCompleteItem,
    progress: (caption: string) => Promise<void>
  ) => Promise<void>;
  displayInfoTitle: string;
}

class ValidateOutOfDDateQuoteItemsModal extends ModalDialog {
  currentProcess: Promise<void> | null = null;
  processingInfo: DataProcessingInformation = {
    processing: false,
    cancelling: false,
    itemsToProcess: [],
    currentItemToProcess: null,
    currentItemState: '',
    completedItems: [],
    processingComplete: false
  };
  targetChecked: UITargetItemChecked[] = [];
  handler: OutOfDateQuoteItemHandler;
  quoteManager: QuoteContainerManager;
  outOfDateItems: QuoteItemContainer[] = [];
  runningFinalProcessing = false;
  constructor(quoteManager: QuoteContainerManager, handler: OutOfDateQuoteItemHandler) {
    super();
    this.handler = handler;
    this.quoteManager = quoteManager;
    if (quoteManager.container.items)
      for (let i = 0; i < quoteManager.container.items.length; i++) {
        const item = quoteManager.quoteItemContainer(quoteManager.container.items[i].id);
        if (this.handler.isValidatingItem(item) && this.isOutOfDate(item)) {
          this.outOfDateItems.push(item);
          this.quoteItemChecked(item, true);
        }
      }
  }

  isOutOfDate(item: QuoteItemContainer): boolean {
    return this.quoteManager.isBuyInDataLocked
      ? this.handler.isOutOfDate(item)
      : this.handler.isOutOfDate(item) || !this.isBuyInPriceCurrent(item);
  }

  private isBuyInPriceCurrent(qic: QuoteItemContainer): boolean {
    if (this.quoteManager.priceValidation && this.quoteManager.priceValidation.length != 0) {
      return this.quoteManager.priceValidation.findIndex(x => x.id == qic.item.id) < 0;
    }

    return true;
  }

  get allChecked(): boolean {
    return (
      this.outOfDateItems.filter(x => this.targetChecked.find(y => y.targetItem.item.id === x.item.id)?.checked)
        .length === this.outOfDateItems.length
    );
  }

  /**
   *
   * @param sourceItem the source property
   * @returns a template that displays all targets that dont match, that can be checked to change
   */
  outOfDateItemsTemplate(sourceItem: QuoteItemContainer[]): TemplateResult {
    const checkedTemplate = (qic: QuoteItemContainer): TemplateResult => {
      const checked = this.quoteItemChecked(qic);
      const clickEvent = (value: boolean) => {
        this.quoteItemChecked(qic, value);
        this.render(); //no wait
      };
      return checked
        ? html`<i @click=${() => clickEvent(false)} class="fa-regular fa-square-check"></i>`
        : html`<i @click=${() => clickEvent(true)} class="fa-regular fa-square"></i>`;
    };

    const itemTemplate = (qic: QuoteItemContainer) => html`
      <div class="row defaults-stripped-row non-matching">
        <div class="col-1 checkbox-icon">${checkedTemplate(qic)}</div>
        <div class="col-1 col-position">${this.quoteManager.itemPosition(qic.item.id)}</div>
        <div class="col-5 col-title">${qic.item.title}</div>
        <div class="col-5 col-value">${this.displayVal(this.getQuoteItemDisplayValue(qic), '')}</div>
      </div>
    `;
    const allCheckedTemplate = () => {
      const checked = this.allChecked;
      const clickEvent = (value: boolean) => {
        this.outOfDateItems.forEach(x => this.quoteItemChecked(x, value));
        this.render(); //no wait
      };
      return checked
        ? html`<i @click=${() => clickEvent(false)} class="checkbox-icon-img fa-regular fa-square-check me-2"></i
            >${tlang`All`}`
        : html`<i @click=${() => clickEvent(true)} class="checkbox-icon-img fa-regular fa-square me-2"></i
            >${tlang`All`}`;
    };

    const contentTemplate = html`
      <div class="ms-2 me-5 default-group-items-table">
        <div class="row defaults-stripped-row-header">
          <div class="col-1 checkbox-icon">${allCheckedTemplate()}</div>
          <div class="col-1">${tlang`#`}</div>
          <div class="col-5">${tlang`Title`}</div>
          <div class="col-5">${this.handler.displayInfoTitle}</div>
        </div>
        <hr class="dropdown-divider" />
        ${sourceItem.map(x => itemTemplate(x))}
      </div>
    `;

    return html` <div class="default-group-items-list mismatching-items">${contentTemplate}</div>`;
  }
  getQuoteItemDisplayValue(qic: QuoteItemContainer): string | null | undefined {
    return this.handler.displayInfo(qic);
  }

  async startProcessing(): Promise<void> {
    this.processingInfo.itemsToProcess = [];
    this.targetChecked.filter(x => x.checked).map(x => this.getItemToProcess(x.targetItem));

    if (this.processingInfo.itemsToProcess.length > 0) {
      this.processingInfo.itemsToProcess.sort((a, b) => {
        //reverse sort so that popping off the queue takes the first index
        const aVal = this.quoteManager.itemPosition(a.quoteItemId) ?? -1;
        const bVal = this.quoteManager.itemPosition(b.quoteItemId) ?? -1;
        return bVal - aVal;
      });
      this.processingInfo.cancelling = false;
      this.processingInfo.processing = true;
      this.currentProcess = this.createProcessPromise();
      try {
        await this.currentProcess;
      } catch (e) {
        await showDevelopmentError(e as Error);
      }
    } else {
      this.processingInfo.cancelling = false;
      this.processingInfo.processing = false;
      this.currentProcess = null;
    }
  }

  createProcessPromise(): Promise<void> {
    return new Promise<void>(resolve => {
      this.processAllItems().then(() => resolve());
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async processAllItems(): Promise<void> {
    while (this.processingInfo.itemsToProcess.length > 0 && !this.processingInfo.cancelling) {
      await this.processNextItem();
    }
    this.runningFinalProcessing = true;
    try {
      await this.render();
      await this.finalProcessing();
    } finally {
      this.runningFinalProcessing = false;
    }
    this.currentProcess = null;
    this.processingInfo.processing = false;
    this.processingInfo.processingComplete = true;
    await this.render();
  }
  async finalProcessing() {
    return await this.handler.finalProcessing(this.quoteManager);
  }

  /**
   * process a quote item with artificial delays to throttle.
   * @returns
   */
  async processNextItem(): Promise<void> {
    this.processingInfo.currentItemToProcess = this.processingInfo.itemsToProcess.pop() ?? null;
    if (!this.processingInfo.currentItemToProcess) return;
    const item = this.processingInfo.currentItemToProcess;

    //get the quote item that we need to update as a forced refresh of the live data
    //due to updates, the data we have may no longer be valid due to server updates
    const itemContainer = this.quoteManager.quoteItemContainer(item.quoteItemId);

    //prepare the completed item for the ui
    const completeItem: QuoteItemProcessingCompleteItem = {
      quoteItemContainer: itemContainer,
      oldPrice: itemContainer.price.quantityCost,
      newPrice: 0,
      validation: [],
      error: '',
      completionStatus: processQuoteItemState.errors
    };

    try {
      await this.render();
      //we want to stop people throttling the system by adding some delays
      await this.sleep(500);

      await this.handler.processItemEvent(this.quoteManager, itemContainer, completeItem, async (caption: string) => {
        this.processingInfo.currentItemState = caption;
        await this.render();
      });
      this.processingInfo.currentItemState = processQuoteItemState.completed;
      await this.render();

      await this.sleep(1500);
      if (this.processingInfo.currentItemState == processQuoteItemState.completed)
        if (completeItem.completionStatus !== processQuoteItemState.errors)
          completeItem.completionStatus = processQuoteItemState.completed;
    } finally {
      this.processingInfo.completedItems?.push(completeItem);
      this.processingInfo.currentItemToProcess = null;
      await this.render();
    }
  }

  protected modalSize(): string {
    return 'modal-xl';
  }

  protected modalClasses(): string {
    return 'modal-dialog modal-dialog-scrollable';
  }

  /**
   *
   * @param targetQuoteItem the property link we are checking or updating
   * @param value the checked state of the item
   * @returns the state after executing
   */
  protected quoteItemChecked(targetQuoteItem: QuoteItemContainer, value?: boolean): boolean {
    let ps = this.targetChecked.find(x => x.targetItem.item.id === targetQuoteItem.item.id);
    if (!ps) {
      ps = {
        targetItem: targetQuoteItem,
        checked: value ?? true
      };
      this.targetChecked.push(ps);
    }
    if (value != undefined) {
      ps.checked = value;
    }
    return ps.checked;
  }

  /**
   *
   * @returns true if we can close the dialog
   */
  protected async canCancelOperations(): Promise<boolean> {
    if (!this.processingInfo.processing) return true;

    if (this.processingInfo.cancelling) {
      await this.currentProcess;
      return true;
    }
    if (
      await AskConfirmation(
        tlang`All updates may not have completed. Do you want to cancel update?`,
        confirmationButtons[ConfirmationButtonType.yesNo]
      )
    ) {
      //trigger the cancellation
      this.processingInfo.cancelling = true;
      const waiting = new WaitPatiently(
        () => tlang`Cancelling Operations`,
        () => tlang`Please wait while the processes clean up`
      );
      try {
        //wait for the cancel to complete
        await this.currentProcess;
      } finally {
        await waiting.hideModal();
      }
      return true;
    } else return false;
  }

  async canClose(): Promise<boolean> {
    return await this.canCancelOperations();
  }

  /**
   *
   * @returns the template for displaying the active item being updated.
   */
  protected currentItemTemplate(): TemplateResult {
    if (!this.processingInfo.currentItemToProcess) {
      if (this.runningFinalProcessing)
        return html`
            <h2>${tlang`Final Processing`}</h4>
                <div class="row defaults-stripped-row-header m-0">
                    <div class="col-1">
                        #
                    </div>
                    <div class="col-8">
                        ${tlang`Title`}
                    </div>
                    <div class="col-3">
                        ${tlang`Status`}
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">${tlang`Status`}</span>
                        </div>
                    </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row defaults-stripped-row">
                    <div class="col-1">
                    </div>
                    <div class="col-8">
                    </div>
                    <div class="col-3">
                        ${tlang`Final Processing`}
                    </div>
                </div>`;

      return html``;
    }
    const item = this.processingInfo.currentItemToProcess;
    const itemContainer = this.quoteManager.quoteItemContainer(item.quoteItemId);
    return html`
            <h2>${tlang`Processing %%frame%%`}</h4>
                <div class="row defaults-stripped-row-header m-0">
                    <div class="col-1">
                        #
                    </div>
                    <div class="col-8">
                        ${tlang`Title`}
                    </div>
                    <div class="col-3">
                        ${tlang`Status`}
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">${tlang`Status`}</span>
                        </div>
                    </div>
                </div>
                <hr class="dropdown-divider">
                <div class="row defaults-stripped-row">
                    <div class="col-1">
                        ${this.quoteManager.itemPosition(itemContainer.item.id)}
                    </div>
                    <div class="col-8">
                        ${itemContainer.item.title}
                    </div>
                    <div class="col-3">
                        ${lang(this.processingInfo.currentItemState)}
                    </div>
                </div>
        `;
  }

  /**
   *
   * @returns the template for all the items which have been completed successfully or otherwise
   */
  protected completedItemsTemplate(): TemplateResult {
    const itemTemplate = (item: QuoteItemProcessingCompleteItem) => {
      //display the status in a color
      const statusClass =
        item.completionStatus == processQuoteItemState.completed ? 'fw-bold text-primary' : 'fw-bold text-danger';

      //if the price changed, show an icon to indicate this
      const priceVarianceTemplate = () => {
        if (item.newPrice != item.oldPrice) return html`<i class="fa-solid fa-circle-dollar text-info"></i>`;
        else return html``;
      };

      //if any errors occured, indicate visually what happened.
      const errorTemplate = () => {
        if (item.completionStatus != processQuoteItemState.completed || item.error !== '')
          return html`<i class="fa-solid fa-circle-exclamation text-danger"></i>`;
        else return html``;
      };
      //if there are any validation issues after the change, show a warning.
      //maybe have a click button to expand and show the issues, but they can also go open the item
      const validationTemplate = () => {
        if (item.validation.length > 0) return html`<i class="fa-solid fa-triangle-exclamation text-info"></i>`;
        else return html``;
      };
      return html` <div class="row defaults-stripped-row">
        <div class="col-1">${this.quoteManager.itemPosition(item.quoteItemContainer.item.id)}</div>
        <div class="col-8">${item.quoteItemContainer.item.title}</div>
        <div class="col-3">
          <span class=${statusClass}>${lang(item.completionStatus)}</span>
          ${priceVarianceTemplate()} ${errorTemplate()} ${validationTemplate()}
        </div>
      </div>`;
    };

    if (!this.processingInfo.completedItems || this.processingInfo.completedItems.length === 0) return html``;
    const title = this.processingInfo.processingComplete ? tlang`All !!frame!! Processed` : tlang`Completed !!frame!!`;

    return html`
      <h4 class="text-primary mb-2">${title}</h4>
      ${this.processingInfo.completedItems.map(item => itemTemplate(item))}
    `;
  }

  /**
   *
   * @returns render the template that is the life status update of the processing and will stay there at completion
   */
  protected processingTemplate(): TemplateResult {
    if (!(this.processingInfo.processing || this.processingInfo.processingComplete)) return html``;
    return html`
      <div class="container border border-secondary bg-light mb-3 sticky-top defaults-verification-progress-wrapper">
        ${this.currentItemTemplate()} ${this.completedItemsTemplate()}
      </div>
    `;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const itemTemplates = this.outOfDateItemsTemplate(this.outOfDateItems);
    const version = getSupplierVersion(this.quoteManager.quote.supplierId);
    const explanation = tlang`${'ref:outOfDateFrameExplanation:markdown'}
                ## Information
                ### Current %%supplier%% version: **${version}**
                the !!frame!! shown need to be validated and re-saved because
                the configuration service and/or pricing has been updated since these items were created

                Please check each %%frame%% carefully after validation to ensure prices and configuration are still
                as expected. Prices may vary because of %%supplier%% price updates, but it could also be sign of an unexpected change
                in the structure of the %%frame%%`;

    return html` ${this.processingTemplate()}
      <div class="alert alert-info m-3" role="alert">${asMarkdownTemplate(explanation)}</div>
      ${itemTemplates}`;
  }

  protected async getTitle(): PromiseSnippet {
    return tlang`Validate Out of Date !!frame!!`;
  }

  protected footerTemplate(): TemplateResult {
    const validateButton = !this.processingInfo.processingComplete
      ? html`<button
          @click=${() => this.startProcessing()}
          class="btn btn-primary"
          ?disabled=${this.processingInfo.processing}
        >
          ${tlang`Validate`}
        </button>`
      : html``;

    return html`
      <button @click=${() => this.hideModal()} class="btn btn-secondary" ?disabled=${this.processingInfo.processing}>
        ${tlang`Close`}
      </button>
      ${validateButton}
    `;
  }

  private displayVal(main: string | null | undefined, next: string | null | undefined) {
    if (!isEmptyOrSpace(main)) return main;
    if (!isEmptyOrSpace(next)) return next;
    return '';
  }

  private getItemToProcess(qic: QuoteItemContainer): ItemToProcess {
    let item = this.processingInfo.itemsToProcess.find(x => x.quoteItemId === qic.item.id);
    if (!item) {
      item = {
        quoteItemId: qic.item.id
      };
      this.processingInfo.itemsToProcess.push(item);
    }
    return item;
  }
}
