// eslint-disable-next-line import/named
import { html } from 'lit';
import { Quote } from '../../api/dealer-api-interface-quote';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import { ViewBase } from '../../components/ui/view-base';
import { QuoteContainerManager } from '../data/quote-container';

export interface QuoteSupplierDefaultsViewOptions {
  quoteManager: QuoteContainerManager;
  areItemsOpen: () => boolean;
}

export class QuoteSupplierDefaultsView extends ViewBase {
  quoteManager: QuoteContainerManager;
  areItemsOpen: () => boolean;

  constructor(options: QuoteSupplierDefaultsViewOptions) {
    super();
    this.quoteManager = options.quoteManager;
    this.areItemsOpen = options.areItemsOpen;
  }
  async afterConstruction(): Promise<void> {
    await this.quoteManager.needsQuote();
  }

  async prepareForSave() {
    //nothing to do
  }

  buttonMenu(): Snippet {
    return html``;
  }

  get supplierId(): string {
    return this.quoteManager.quote.supplierId;
  }
  get quote(): Quote {
    return this.quoteManager.quote;
  }
  protected async template(): PromiseTemplate {
    return html``;
  }

  public async refresh(): Promise<void> {
    //override;
  }
}
