// eslint-disable-next-line import/named
import { html } from 'lit';
import { claimIdentity, getApiToken, tlang, userSecurity } from '@softtech/webmodule-components';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../components/ui/events';
import {
  BaseLoginModalEditProperties,
  ILicenseServerUIStaffPageProvider,
  ILoginClaimManager,
  IStaffPage,
  ViewBaseLogin,
  licenseServerUIBootLoader
} from '../data/licenseserver-integration';
import { getApiFactory } from '../../api/api-injector';
import { Branch } from '../../api/dealer-api-interface-franchisee';
import { guidEquals } from '../../api/guid';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';

const claimName_branchId = claimIdentity.branchId;
const claimName_AllBranch = claimIdentity.allBranches;
const claimName_BranchManager = 'franchisee-branchadmin';

function trueClaim(claims: { [key: string]: string }, name: string): boolean {
  return claims[name] === 'true';
}

function isBasicUser(login: ViewBaseLogin): boolean {
  return (
    !trueClaim(login.deploymentClaims, claimName_BranchManager) &&
    !trueClaim(login.deploymentClaims, claimIdentity.admin) &&
    !trueClaim(login.baseLogin.claims, claimName_BranchManager) &&
    !trueClaim(login.baseLogin.claims, claimIdentity.admin)
  );
}

export class FranchiseeStaffView extends PageControlTabWithIndependantSaving {
  branches: Branch[] = [];

  buildBranchProperty(): BaseLoginModalEditProperties {
    const branchTemplate = (branchid: string) =>
      this.branches.map(b => {
        return html`<option ?selected=${b.id === branchid} value=${b.id}>${b.name}</option>`;
      });

    let branchValue: string | undefined = '';
    const changeEvent = (e: Event) => (branchValue = (e.currentTarget as HTMLSelectElement).value);

    const branchEditor: BaseLoginModalEditProperties = {
      inputId: 'branchId',
      visible: this.isAdmin || this.isFullBranchManager,
      editControl: (claimManager: ILoginClaimManager) => {
        const defaultBranchId = !this.isAdmin && this.isSingleBranchManager ? this.userBranchId : this.branches[0].id;

        if (!branchValue) branchValue = claimManager.getClaim(claimName_branchId) ?? defaultBranchId;
        return html`<select @change=${changeEvent} id="branchId" class="form-select">
          ${branchTemplate(claimManager.getClaim(claimName_branchId) ?? this.branches[0].id)}
        </select>`;
      },
      label: tlang`%%branch%%`,
      propertyId: 1,
      save: (claimManager: ILoginClaimManager) => {
        if (!this.isAdmin && this.isSingleBranchManager) {
          claimManager.setClaim(claimName_branchId, this.userBranchId);
          claimManager.setClaim(claimName_AllBranch, 'false');
        }
        if (branchValue) claimManager.setClaim(claimName_branchId, branchValue);
        else throw new Error(tlang`please select a %%branch%%`);
      }
    };
    return branchEditor;
  }
  buildAllBranchProperty(): BaseLoginModalEditProperties {
    let branchValue: string | undefined = '';
    const changeEvent = (e: Event) => (branchValue = (e.currentTarget as HTMLSelectElement).value);

    const branchEditor: BaseLoginModalEditProperties = {
      inputId: 'allbranchAccess',
      visible: this.isAdmin,
      editControl: (claimManager: ILoginClaimManager) => {
        if (!branchValue) branchValue = !this.isAdmin ? '' : claimManager.getClaim(claimName_AllBranch) ?? '';

        return html`<select @change=${changeEvent} id="allbranchAccess" class="form-select">
          <option ?selected=${branchValue !== 'true'} value="">${tlang`Single %%branch%% access`}</option>
          <option ?selected=${branchValue === 'true'} value="true">${tlang`All !!branch!! access`}</option>
        </select>`;
      },
      label: tlang`%%branch%% Access`,
      propertyId: 2,
      save: (claimManager: ILoginClaimManager) => {
        if (this.isAdmin)
          if (branchValue !== undefined) claimManager.setClaim(claimName_AllBranch, branchValue);
          else throw new Error(tlang`please select a %%branch%% access level`);
      }
    };
    return branchEditor;
  }

  get canAddUser() {
    return this.isAdmin || this.isBranchManager;
  }

  get isSupplier(): boolean {
    return userSecurity().claimIsTrue(claimIdentity.supplier);
  }

  get isAdmin(): boolean {
    return userSecurity().claimIsTrue(claimIdentity.admin) || this.isSupplier;
  }
  get isBranchManager(): boolean {
    return userSecurity().claimIsTrue(claimName_BranchManager);
  }
  get isSingleBranchManager(): boolean {
    return userSecurity().claimIsTrue(claimName_BranchManager) && !userSecurity().claimIsTrue(claimName_AllBranch);
  }
  get isFullBranchManager(): boolean {
    return userSecurity().claimIsTrue(claimName_BranchManager) && userSecurity().claimIsTrue(claimName_AllBranch);
  }
  get userBranchId(): string {
    return this.branchId(userSecurity().claim(claimName_branchId));
  }
  buildModalProperties(): BaseLoginModalEditProperties[] {
    const results: BaseLoginModalEditProperties[] = [];
    if (this.isAdmin || this.isFullBranchManager) results.push(this.buildBranchProperty());
    if (userSecurity().isAdmin()) results.push(this.buildAllBranchProperty());
    return results;
  }
  getBranchName(_login: ViewBaseLogin) {
    const branchId = this.branchId(_login.deploymentClaims[claimName_branchId]);
    return this.branches.find(x => x.id == branchId)?.name ?? tlang`Main`;
  }
  staffPageProvider: ILicenseServerUIStaffPageProvider = {
    credentials: {
      host: globalThis.dealerConfiguration.licenseServerHost,
      token: getApiToken()
    },
    canEditUser: (viewLogin: ViewBaseLogin): boolean => {
      //full admin can edit anyone
      if (this.isAdmin) return true;
      //if admin, and only admin, we can see everything.
      //if admin, but also branch-manager, reduce the scope
      if (this.isFullBranchManager) {
        return isBasicUser(viewLogin);
      }
      if (this.isSingleBranchManager) {
        return (
          isBasicUser(viewLogin) &&
          guidEquals(this.userBranchId, this.branchId(viewLogin.deploymentClaims[claimIdentity.branchId]))
        );
      }
      return false;
    },
    includeBranchManager: true,
    userFilter: (viewLogin: ViewBaseLogin): boolean => {
      if (this.isAdmin || this.isFullBranchManager) return true;
      //if admin, and only admin, we can see everything.
      //if admin, but also branch-manager, reduce the scope
      //branch manager can manage staff from their branch, or all branches if they have all branches
      return (
        guidEquals(this.branchId(viewLogin.deploymentClaims[claimName_branchId]), this.userBranchId) ||
        !isBasicUser(viewLogin)
      );
    },
    getColumnProperties: () => {
      console.log('get column properties');
      return [
        {
          displayValue: (login: ViewBaseLogin) => {
            const allStr = login.deploymentClaims[claimName_AllBranch] === 'true' ? ' (All)' : '';
            return html`<span>${this.getBranchName(login)}${allStr}</span>`;
          },
          title: tlang`%%branch%%`
        }
      ];
    },
    getModalProperties: () => this.buildModalProperties(),
    onAfterSave: async (login: ViewBaseLogin) => {
      console.log(`login saved ${login.baseLogin.userName}`);
    },
    onBeforeSave: async (login: ViewBaseLogin) => {
      console.log(`login saving ${login.baseLogin.userName}`);
      return true;
    },
    uiChanged: () => this.render()
  };
  userConsole?: IStaffPage;
  constructor() {
    super();
    this.pageFragment = 'staff';
    console.log(JSON.stringify(this.staffPageProvider.getColumnProperties?.()));
  }
  get defaultBranchId(): string {
    return this.branches[0].id;
  }
  branchId(id?: string | null): string {
    return isEmptyOrSpace(id) ? this.defaultBranchId : id ?? '';
  }
  async reloadData() {
    this.branches = (await getApiFactory().franchisee().getBranches())?.branches ?? [];
    const licUI = await licenseServerUIBootLoader();
    this.userConsole = licUI?.getStaffPage(this.staffPageProvider);
    await this.render();
    await this.refreshParent();
  }
  buttonMenu(): Snippet {
    const buttons = this.userConsole?.actions().map(x => {
      return html`<button class="btn btn-primary" ?disabled=${!x.enabled || !this.canAddUser} @click=${x.event}>
        ${x.label}
      </button>`;
    });
    return html`${buttons}`;
  }
  public internalDataChanged(): boolean {
    return false;
  }

  protected async internalSaveData(): Promise<boolean> {
    //nothing to do
    return true;
  }
  async onEnter(): Promise<void> {
    await this.reloadData();
    await this.render();
  }
  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Staff`;
  }
  async prepareForSave(): Promise<void> {
    //
  }
  protected async bodyTemplate(): PromiseTemplate {
    return this.userConsole?.template() ?? html``;
  }
}
