// eslint-disable-next-line import/named
import { html } from 'lit';
import { emptyGuid } from '../../api/guid';
import { ClientApi } from '../../api/client-api';
import { DataBinding, getInternalId } from '../../components/ui/databinding/databinding';
import { Contact } from '../../api/dealer-api-interface-client';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { ClientPicker } from './client-picker';
import { tlang } from '@softtech/webmodule-components';
import { isEmptyOrSpace, isValidEmail, validId } from '../../components/ui/helper-functions';
import { getApiFactory } from '../../api/api-injector';
import { ContactContainerManager } from '../data/contact-container-manager';
import { cache } from '../../dealer-franchisee/cache/cache-registry';
import { DataEntryPageControlView, PageControlChildTab } from '../../components/ui/data-entry-screen-base';
import { PromiseTemplate, Snippet } from '../../components/ui/events';

export interface ContactDetailViewOptions {
  contactManager: ContactContainerManager;
}

export class ContactDetailView extends PageControlChildTab {
  contactManager: ContactContainerManager;
  clientApi: ClientApi = getApiFactory().client();
  internalId: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;

  clientPickerDisplay = '';

  constructor(owner: DataEntryPageControlView, options: ContactDetailViewOptions) {
    super(owner);

    this.internalId = getInternalId();

    this.contactManager = options.contactManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input: string, internalId: string) => `contact-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    //Think we can refactor this
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.contact),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('clientId');
    addField('name');
    addField('title');
    addField('email');
    addField('mobile');
  }

  async onEnter(): Promise<void> {
    await this.loadOrRefresh();
  }

  protected getCaption(): Snippet {
    return tlang`%%contact%% Info`;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected get contact(): Contact {
    return this.contactManager.contact;
  }

  protected get isPrimary(): boolean {
    return this.contactManager.container.isPrimary;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.contactManager.needsContact();
    this.clientPickerDisplay = (await cache().client.get(this.contact.clientId))?.displayValue ?? '';
    await this.render();
  }

  public async prepareForSave() {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    const newPrimaryContactMsg = tlang`This %%contact%% will be the new primary %%contact%% for the %%client%%`;
    const removePrimaryContactMsg = tlang`You are about to remove this %%contact%% as the primary %%contact%% for the %%client%%`;
    const isPrimaryWarning =
      this.isPrimary == this.contactManager.backup.isPrimary
        ? html``
        : this.isPrimary
          ? html`<span class="text-warning">${newPrimaryContactMsg}</span>`
          : html`<span class="text-danger">${removePrimaryContactMsg}</span>`;

    const clientIdPicker = validId(this.contact.clientId)
      ? forms.pickerReadonly('clientId', this.getClientPickerDisplay(), tlang`%%client%%`)
      : forms.pickerRequired(
          'clientId',
          this.getClientPickerDisplay(),
          async () => await this.selectClient(),
          tlang`%%client%%`
        );
    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${clientIdPicker} ${forms.textRequired('name', tlang`Name`, 100)} ${forms.text('title', tlang`Role`, 50)}
            ${forms.textRequired('email', tlang`Email`, 100)} ${forms.text('mobile', tlang`Mobile`, 30)}

            <bs-form-checkbox
              data-label=${tlang`Primary %%contact%%`}
              data-id=${`contact-is-primary-${this.internalId}`}
              .checked=${this.isPrimary}
              ?readonly=${this.contactManager.backup.isPrimary}
              @checkbox-changed=${_ => this.changeIsPrimary(_.detail)}
            >
            </bs-form-checkbox>

            ${isPrimaryWarning}
          </div>
        </div>
      </form>
    `;
  }

  private getClientPickerDisplay() {
    return this.clientPickerDisplay;
  }

  private changeIsPrimary(value: boolean) {
    this.contactManager.container.isPrimary = value;
    this.render(); //no wait
  }

  public getValidationErrors() {
    const errors: string[] = [];

    const name = this.dataBinding.getValue('name');
    const email = this.dataBinding.getValue('email');
    const clientId = this.dataBinding.getValue('clientId');

    if (isEmptyOrSpace(clientId) || clientId == emptyGuid) errors.push(tlang`Please select a %%client%%`);

    if (isEmptyOrSpace(name)) errors.push(tlang`Please provide a %%contact%% Name`);

    if (isEmptyOrSpace(email)) errors.push(tlang`Please provide an Email`);
    else if (!isValidEmail(email)) errors.push(tlang`Please provide a valid Email`);

    return errors;
  }

  private async selectClient() {
    const selectedClient = await ClientPicker();

    if (selectedClient) {
      this.dataBinding.setValue('clientId', selectedClient.id);
      this.clientPickerDisplay = selectedClient.name;
      await this.render();
    }
  }
}
