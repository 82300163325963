/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BlobApi } from '../../api/blob-api';
import {
  BinaryDataWrapper,
  ExternalProviderDefinition,
  InitialPriceDefinition,
  InputCreateQuoteItem,
  InputQuoteState,
  InputUpdateQuoteItem,
  InputUpdateQuoteItems,
  InputUpdateQuoteSetState,
  InputUpdateQuoteStateChangeReason,
  Quote,
  QuoteItem,
  QuoteItemBuyInData,
  QuoteItemPrice,
  QuoteItemProviderData,
  QuoteItemRowAction,
  QuotePresentation,
  QuotePrice,
  QuoteSetSibling,
  QuoteState,
  QuoteStateChangeReason,
  ResultFullQuotePrice,
  ResultGetQuote,
  ResultQuoteItem,
  UpdateQuoteItem
} from '../../api/dealer-api-interface-quote';
import { newGuid } from '../../api/guid';
import { QuoteApi } from '../../api/quote-api';
import { base64ToObject, objectToBase64 } from '../../blob/converters';
import { clone, compare } from '../../components/clone';
import { money } from '../../components/currency-formatter';
import { isAutoSaving, saveWithIndicator } from '../../components/save-workflow';
import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../null-promise';
import { fireQuickSuccessToast } from '../../toast-away';
import { DevelopmentError, showDevelopmentError } from '../../development-error';
import { QuoteItemContainer } from './quote-item-container';
import { createQuoteProviderData, QuoteProviderData, validateAndUpgradeQuoteProviderData } from './quote-provider-data';
import { EventNotify } from '../../components/ui/events';
import { getQuoteSupplierDisplayName, quoteSupplierProvider } from './quoteSupplierProvider';
import { QuoteFrameBuyInItem } from '@softtech/webmodule-data-contracts';
import { StockLookupView } from '../../api/dealer-api-interface-franchisee';
import { localDateTimeToServer } from '../../components/datetime-converter';
import {
  canAppearOnSupplierOrder,
  getQuoteNumberSuffix,
  isFrame,
  isShipping,
  isSSI,
  mustAppearOnPurchaseOrder
} from './quote-helper-functions';
import { flagInSet } from '../../components/ui/helper-functions';
import { defaultQuoteStateReasons } from './quotestate-reasons';
import { LockResourceResult } from '../../components/resource-lock-info';
import { v6ConfigSupplierOptions } from '../../v6config/v6config';
import { QuoteTechnicalReviewDialog } from './quote-technical-review-confirmation';
import { runEventNotify } from '../../components/array-helper';

export interface ValidationDetails {
  code: string;
  message: string;
}

export interface PriceValidation {
  id: string;
  details: ValidationDetails[];
}

export interface StockLookupViewExtra extends StockLookupView {
  lookup: string;
  marginPercentage: number | null;
  calculatedGross: number | null;
  // description: string;
}

export interface ParamCreateQuoteItem {
  id: string | null;
  title: string | null;
  description: string | null;
  quantity: number | null;
  comment: string | null;
  externalProvider: ExternalProviderDefinition | null;
  buyInData: BinaryDataWrapper | null;
  quoteItemContentType: number;
  thumbnail: string | null;
  price: InitialPriceDefinition | null;
  isRestrictedToPowerUser: boolean | null;
  thumbnailExtension?: string;
}

export class QuoteContainer {
  quoteId: string;
  quote: Quote | null;
  quotePrice: QuotePrice | null;
  quoteProviderData: QuoteProviderData | null = null;
  quotePresentation: QuotePresentation | null = null;
  siblings: QuoteSetSibling[] | null = null;
  items: QuoteItem[] | null;
  itemsData: QuoteItemProviderData[] | null;
  itemsBuyInData: QuoteItemBuyInData[] | null;
  itemPrices: QuoteItemPrice[] | null;
  isNewQuote: boolean;

  constructor(
    quoteId: string,
    quote: Quote | null,
    quotePrice: QuotePrice | null,
    quotePresentation: QuotePresentation | null,
    siblings: QuoteSetSibling[] | null,
    items: QuoteItem[] | null,
    itemsData: QuoteItemProviderData[] | null,
    itemsBuyInData: QuoteItemBuyInData[] | null,
    itemPrices: QuoteItemPrice[] | null,
    quoteProviderData: QuoteProviderData | null = null,
    isNewQuote = false
  ) {
    this.quote = quote;
    this.quotePrice = quotePrice;
    this.quotePresentation = quotePresentation;
    this.siblings = siblings;
    this.items = items;
    this.itemsData = itemsData;
    this.itemsBuyInData = itemsBuyInData;
    this.itemPrices = itemPrices;
    this.quoteId = quoteId;
    this.quoteProviderData = quoteProviderData;
    this.isNewQuote = isNewQuote;
  }
}

export interface IQuoteValidator {
  valid: (state: QuoteState) => Promise<boolean>;
}

// a manager that is used to find and collect quote item information
// and keep a comparison backup
export class QuoteContainerManager {
  backup: QuoteContainer;
  container: QuoteContainer;
  api: QuoteApi;
  afterSave: EventNotify[] = [];
  blobApi: BlobApi;
  numberSeed: string | null = null;
  lockInfo?: LockResourceResult;
  priceValidation: PriceValidation[] | null = null;
  quoteValidator?: IQuoteValidator;
  protected stateChangeReason: InputUpdateQuoteStateChangeReason | null = null;
  /**
   * create and return a container for quote item data references
   * @param quoteItemId the quote item to return
   * @param useBackup if true, return the backup copy, if false the working copy
   * @returns a quoteItemContainer with the data parts combined
   */
  private itemContainerCache: QuoteItemContainer[] = [];
  private itemContainerCacheBak: QuoteItemContainer[] = [];

  constructor(original: QuoteContainer, quoteApi: QuoteApi, blobApi: BlobApi) {
    this.api = quoteApi;
    this.blobApi = blobApi;
    if (original.quote && original.quote.id !== original.quoteId)
      throw new Error(`invalid argument Quote ID must match quoteId`);
    this.container = original;
    this.backup = this.clone(this.container);
  }

  get isV6(): boolean {
    return this.container.quote?.serviceProvider == quoteSupplierProvider.v6;
  }
  public get quoteState(): QuoteState {
    if (globalThis?.dealerQuoteState !== undefined) return globalThis?.dealerQuoteState;
    return this.quote.state;
  }
  public set quoteState(value: QuoteState) {
    this.quote.state = value;
  }

  get siblings() {
    return this.container.siblings;
  }

  public get hasFrames(): boolean {
    const items = this.container.items;
    if (!items) return false;

    for (let i = 0; i < items.length; i++) {
      if (isFrame(items[i])) return true;
    }
    return false;
  }

  /**
   * the quoteId for this managed container
   */
  get quoteId(): string {
    return this.container.quoteId;
  }

  get quoteItemPriceTotal(): number {
    let total = 0;
    //if we dont sum this to 2dp, then the UI wont always add up if someone checking.
    //the server will be doing the real calculations anyway
    this.container.itemPrices?.forEach(x => (total += money(x.calculatedGrossSellingPrice, 2)));
    return total;
  }

  public get quoteTitle(): string {
    if (this.quote.quoteNumber != 0) return tlang`#${getQuoteNumberSuffix(this.quote)} - ${this.quote.title}`;
    else return tlang`Draft - ${this.quote.title}`;
  }

  /**
   * returns the quote object after needsQuote is called, or throws an error if the quote is unavailable.
   */
  get quote(): Quote {
    if (!this.container.quote) {
      throw new Error('Quote is null');
    }
    return this.container.quote;
  }

  /**
   * returns the quote price object after needsQuote is called, or throws an error if the quote price is unavailable.
   */
  get quotePrice(): QuotePrice {
    if (!this.container.quotePrice) {
      throw new Error('QuotePrice is null');
    }
    return this.container.quotePrice;
  }

  get supplierName(): string {
    throw new Error('please overrride this method');
  }

  private _lastSaveSuccessful = true;

  public get lastSaveSuccessful(): boolean {
    return this._lastSaveSuccessful;
  }

  public get isSupplierQuote(): boolean {
    if (!this.container.items) throw new DevelopmentError('call needsQuoteItems first');

    const items = this.container.items.filter(item => canAppearOnSupplierOrder(item)) ?? [];

    // if we end up with free hand items and/or shipping only, we should not create a purchase order
    const shouldCreatePO = items.some(item => mustAppearOnPurchaseOrder(item));
    return shouldCreatePO;
  }

  public static async createQuoteServiceProviderData(
    supplierType: string,
    supplierId: string
  ): NullPromise<QuoteProviderData> {
    return await createQuoteProviderData(supplierType, supplierId);
  }

  public async isQuoteValid(state: QuoteState): Promise<boolean> {
    if (!this.quoteValidator) return true;

    return await this.quoteValidator.valid(state);
  }

  public addPriceValidation(id: string, code: string, message: string) {
    this.priceValidation ??= [];

    let validation = this.priceValidation.find(x => x.id == id);

    if (!validation) {
      validation = new (class implements PriceValidation {
        details: ValidationDetails[] = [];
        id = id;
      })();

      this.priceValidation.push(validation);
    }

    if (validation.details.findIndex(x => x.code == code) < 0) validation.details.push({ code, message });
  }

  public getPriceValidation(id: string) {
    return this.priceValidation?.find(x => x.id == id);
  }

  isReadonly(): boolean {
    return this.internalIsReadonly() || this.isLockedFromUse();
  }
  public forceLockout = false;
  isLockedFromUse(): boolean {
    return this.forceLockout || !(this.lockInfo?.isLockOwner ?? true);
  }

  /**
   *
   * @param id the quoteItemId
   */
  public itemPosition(id?: string): number | undefined {
    if (id === undefined) return undefined;
    //TODO - implement a quote item position tracker, managed at the quote level and use this for rendering
    return (this.container.items?.findIndex(x => x.id === id) ?? -1) + 1;
  }

  /**
   * Simple wrapper around structuredClone
   * @param item an object of any basic type to clone
   * @returns
   */
  clone<ItemType>(item: ItemType): ItemType {
    return clone(item);
  }

  quoteItemPrice(id: string, removeBuyInCost = false): number {
    const price = this.container.itemPrices?.find(x => x.id == id);
    if (price == undefined) return 0;

    let linePrice = price.calculatedGrossSellingPrice;

    if (removeBuyInCost) {
      const quoteItemBuyInData = this.container.itemsBuyInData?.find(x => x.id == id);

      if (quoteItemBuyInData != undefined) {
        const buyIns = base64ToObject<StockLookupViewExtra[]>(quoteItemBuyInData.buyInData);

        const sum =
          buyIns?.reduce((accumulator, currentObject) => {
            return accumulator + (currentObject.calculatedGross ?? 0);
          }, 0) ?? 0;

        linePrice = linePrice - sum;
      }
    }

    return linePrice;
  }

  /**
   * this will ensure at an async level that the quote propery is valid, before accessing the property synchronously
   * @returns true if the quote property is now valid
   */
  async needsQuote(): Promise<boolean> {
    if (!this.container.quote) {
      const result = await this.api.getQuote({ quoteId: this.quoteId });
      if (result) {
        await this.resetQuote(result.quote, result.quotePrice, result.quotePresentation, result.siblings);
      } else return false;
    }

    //This should only execute if the container is constructed with the quote passed in and the provider data not,
    //meaning that the quote is fetched outside the container and passed in.
    //This will fetch the data for the service provider. We are not using the value(s) from this.container.quote.serviceProvider
    //as it may be out of date.
    if (this.container.quote && !this.container.quoteProviderData) {
      this.container.quoteProviderData = await createQuoteProviderData(
        this.container.quote.serviceProvider,
        this.container.quote.supplierId
      );
    }

    return true;
  }

  async getQuoteReasons(_quoteState: QuoteState): NullPromise<QuoteStateChangeReason[]> {
    return [];
  }

  async getFreshQuoteCopy(): NullPromise<ResultGetQuote> {
    return await this.api.getQuote({ quoteId: this.quoteId });
  }

  public generateQuoteNumberOnSave() {
    this.numberSeed = this.quote.quoteOwnerId;
  }

  async quoteStateChange(state: QuoteState): Promise<boolean> {
    const originalState = this.quoteState;
    const originalAllowSupplierApproval = this.quote.supplierAuthorizedToIssueOrderOnQuoteApproval;
    const seed = this.numberSeed;
    const qn = this.quote.quoteNumber;

    const restoreQuoteState = () => {
      this.quote.supplierAuthorizedToIssueOrderOnQuoteApproval = originalAllowSupplierApproval;
      this.quoteState = originalState;
      this.quote.quoteNumber = qn;
      this.numberSeed = seed;
      this.stateChangeReason = null;
    };

    const addSiblingStates = async (input: InputQuoteState[], rollback?: boolean) => {
      if (state === QuoteState.Accepted && !this.quote.supplierApproved) {
        //if we are here, we need to locate the rejected reason, and then reject any quotes
        //that are active or issued at the same time as accepting this quote
        const inputRejection = await this.getRejectedReasonInput();
        this.siblings
          ?.filter(x => flagInSet(x.state, QuoteState.Active | QuoteState.IssuePending | QuoteState.Issued))
          .forEach(s => {
            input.push({
              quoteId: s.quoteId,
              state: rollback ? s.state : QuoteState.Rejected,
              stateChangeReason: rollback ? null : inputRejection
            });
          });
      }
    };

    const performSave = async (rollback?: boolean) => {
      if (
        flagInSet(
          originalState,
          QuoteState.Draft | QuoteState.Active | QuoteState.IssuePending | QuoteState.SupplierReviewPending
        ) ||
        (originalState === QuoteState.Issued && !this.quote.supplierApproved)
      ) {
        let siblingUpdate: InputQuoteState[] | null = [];
        if (this.quoteState == QuoteState.Accepted && !this.quote.supplierApproved) {
          await addSiblingStates(siblingUpdate, rollback);
          if (siblingUpdate.length === 0) siblingUpdate = null;
          if (!(await this.performSupplierMayApproveQuoteProcess())) return false;
        }

        //we need to use saveQuote here because the quote may have other things changed and edited.
        return await this.saveQuote(rollback, siblingUpdate);
      } else {
        const input: InputUpdateQuoteSetState = {
          quoteState: [
            {
              quoteId: this.quote.id,
              state: this.quoteState,
              stateChangeReason: null
            }
          ]
        };
        await addSiblingStates(input.quoteState, rollback);
        return await this.saveQuoteSetState(input);
      }
    };

    if (!(await this.quoteStateBeforeChange(state, originalState))) return false;

    this.quoteState = state;
    try {
      if (!(await performSave())) {
        restoreQuoteState();
        return false;
      }

      if (!(await this.quoteStateAfterChange(state, originalState))) {
        restoreQuoteState();
        await performSave(true);
        return false;
      }
    } catch (e) {
      restoreQuoteState();
      await performSave(true);
      throw e;
    }
    return true;
  }

  async performSupplierMayApproveQuoteProcess(): Promise<boolean> {
    const supplierName = await getQuoteSupplierDisplayName(this.quote.supplierId);
    const v6Settings = v6ConfigSupplierOptions(this.quote.supplierId);
    const poNumberRequired = v6Settings.isPurchaseOrderNumberMandatory;
    const forceIssueOrder = v6Settings.isPurchaseOrderIssuedImmediately;
    const dialog = new QuoteTechnicalReviewDialog(
      poNumberRequired,
      forceIssueOrder,
      supplierName,
      this.quote.customQuoteNumber ?? ''
    );
    await dialog.showModal();
    if (!dialog.ok) return false;

    this.quote.supplierAuthorizedToIssueOrderOnQuoteApproval = dialog.allowSupplierToIssue;
    this.quote.customQuoteNumber = dialog.orderNumber;
    return true;
  }

  async saveQuoteSetState(input: InputUpdateQuoteSetState) {
    const result = await this.api.updateQuoteSetState(input);
    if (!result) return false;
    const thisState = result.items.find(x => x.quoteId === this.quote.id);
    if (!thisState) throw new DevelopmentError('return from Update QuoteState does not include this quote');
    this.quoteState === thisState.state;
    this.quote.supplierApproved = thisState.supplierApproved;
    this.quote.recordVersion = thisState.recordVersion;
    this.backup.quote = clone(this.quote);
    result.items.forEach(thisState => {
      const sib = this.siblings?.find(x => x.quoteId === thisState.quoteId);
      if (sib) {
        sib.state = thisState.state;
        sib.recordVersion = thisState.recordVersion;
        sib.supplierApproved = thisState.supplierApproved;
      }
    });
    this.backup.siblings = clone(this.siblings?.filter(x => x.quoteId !== this.quote.id) ?? null);
    await this.doAfterSave();
    return true;
  }

  async getRejectedReasonInput() {
    const stateReasons = await this.getQuoteReasons(QuoteState.Rejected);
    const rejection = stateReasons?.find(x => x.reason === defaultQuoteStateReasons.superceeded) ?? null;
    return rejection
      ? {
          comment: defaultQuoteStateReasons.superceeded,
          stateChangeReason: rejection
        }
      : null;
  }

  /**
   * this is called to send the current quote and quote price objects to update the server.
   * on sucessful update, the internal quote and price objects will be replaced with the new copies
   * from the server
   * when updating prices we also refresh all pricing for all items at the same time that may have been
   * reliant on the pricing.
   * calling this may result in some quoteItemContainers holding outdated objects that need replacement
   */
  async saveQuote(silently?: boolean, siblings?: InputQuoteState[] | null): Promise<boolean> {
    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return false;
    }
    this.quote.serviceProviderData = objectToBase64(this.container.quoteProviderData);
    const result = await this.api.updateQuote({
      siblingQuoteState: siblings ?? null,
      quote: this.quote,
      quotePrice: this.quotePrice,
      numberSeed: this.numberSeed,
      stateChangeReason: this.stateChangeReason,
      quotePresentation: this.container.quotePresentation
    });
    if (result) {
      //quote update doesn't alter presentation, so we keep what we have
      await this.resetQuote(
        result.quote!, //never null when the input was not null
        result.resultFullQuotePrice!.quotePrice!,
        result.quotePresentation,
        result.siblings! //as above
      );
      this.updateItemPrices(result.resultFullQuotePrice!.itemPrices);
      this.updateItemBuyIns(result.resultFullQuotePrice!.itemBuyIns);
      await this.validateBuyInPrices();

      if (!silently) fireQuickSuccessToast(tlang`Quote Saved "${this.quoteTitle}"`);
      await this.doAfterSave();
      return true;
    }
    return false;
  }

  /**
   * this will find the backup of a quote item and related data, and replace the items with a clone
   * of the backup. this will make any quoteItemContainers to this id stale and need replacing
   *
   * @param id the quote item id to replace
   * @returns a reference to the fresh copy of the data
   */
  restoreQuoteItemFromBackup(id: string | undefined): QuoteItemContainer | null {
    if (!id) return null;
    const backup = this.quoteItemContainer(id, true);
    this.replaceQuoteItem(backup, false);
    this.removeContainerCache(id);
    return this.quoteItemContainer(id);
  }

  changedItem(id: string | undefined): boolean {
    if (!id) return false;
    const backup = this.quoteItemContainer(id, true);
    const current = this.quoteItemContainer(id, false);
    const o = current.dataObject;
    current.dataObject = backup.dataObject;

    const changed = !compare(backup, current);
    current.dataObject = o;
    return changed;
  }

  changedItemQuantity(id: string | undefined): boolean {
    if (!id) return false;
    const backup = this.quoteItemContainer(id, true);
    const current = this.quoteItemContainer(id, false);

    return backup.item.quantity != current.item.quantity;
  }

  async performItemSorting() {
    if (!this.container.items) await this.needsQuoteItems();
    else this.container.items = await this.internalSortItemsAsList();
  }

  /**
   * Updates the sort order of items in the container's quote presentation.
   *
   * @param {string[]} sortedOrder - The new sorted order of items.
   * @returns {Promise<boolean>} - A promise that resolves to true if the sort order was updated successfully, and false otherwise.
   */
  async updateItemSortOrder(sortedOrder: string[]) {
    if (!this.container.quotePresentation) return false;

    if (this.container.quotePresentation.itemDisplayOrder.length !== sortedOrder.length) return false;

    const sorted1 = this.container.quotePresentation.itemDisplayOrder.slice().sort();
    const sorted2 = sortedOrder.slice().sort();

    for (let i = 0; i < sorted1.length; i++) {
      if (sorted1[i] !== sorted2[i]) {
        return false;
      }
    }

    this.container.quotePresentation.itemDisplayOrder = sortedOrder;
    return await this.saveQuote();
  }

  /**
   * this should be called any time before accessing the quote items list, which may yet be unpopulated.
   * calling this makes all references to data invalid so it should not be called while there are active
   * items being used or edited.
   * @param forceRefresh force a reload of the data even if not required.
   * @returns true if suceeded
   */
  async needsQuoteItems(forceRefresh = false): Promise<boolean> {
    if (!this.container.items || forceRefresh) {
      this.itemContainerCache = [];
      this.itemContainerCacheBak = [];
      const result = await this.api.getQuoteItemsSummary({
        quoteId: this.quoteId
      });
      if (result) {
        const sortedItems: QuoteItem[] = await this.internalSortItemsAsList(result.items);

        this.container.items = sortedItems;
        this.container.itemsData = result.data;
        this.container.itemsBuyInData = result.quoteItemBuyInData;
        this.container.itemPrices = result.prices;

        await this.validateBuyInPrices();

        this.backup.items = this.clone(sortedItems);
        this.backup.itemsData = this.clone(result.data);
        this.backup.itemsBuyInData = this.clone(result.quoteItemBuyInData);
        this.backup.itemPrices = this.clone(result.prices);
      } else return false;
    }
    return true;
  }

  quoteProviderData(): QuoteProviderData | null {
    return this.container.quoteProviderData;
  }

  quoteItemContainerCache(quoteItemId: string, useBackup = false): QuoteItemContainer {
    const cache = useBackup ? this.itemContainerCacheBak : this.itemContainerCache;
    let result = cache.find(x => x.item.id === quoteItemId);
    if (!result) {
      const container = useBackup ? this.backup : this.container;
      const item = container.items?.find(x => x.id === quoteItemId);
      const data = container.itemsData?.find(x => x.id == quoteItemId);
      const buyInData = container.itemsBuyInData?.find(x => x.id == quoteItemId);
      const price = container.itemPrices?.find(x => x.id == quoteItemId);
      if (!item || !price) throw new Error(`${quoteItemId} is not a valid quote item id`);
      result = {
        item: item,
        data: data ?? null,
        buyInData: buyInData?.buyInData ?? null,
        price: price
      };
      cache.push(result);
    }

    return result;
  }

  quoteItemContainer(quoteItemId: string, useBackup = false): QuoteItemContainer {
    return this.quoteItemContainerCache(quoteItemId, useBackup);
  }

  /**
   * Create a new quote item and add it to this quote instance. will ensure the quote items list is valid first before adding
   *
   * @param input parameters for the new quote item
   * @returns a valid object on success
   */
  async createQuoteItem(input: ParamCreateQuoteItem): NullPromise<QuoteItemContainer> {
    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return null;
    }

    const newId = input.id ?? newGuid();
    const virtualThumbnailPath = input.thumbnail
      ? this.api.createQuoteItemThumbnailPath(this.quoteId, newId, input.thumbnailExtension ?? '.svg')
      : '';
    //ensure we have a correct listing before adding more items
    await this.needsQuoteItems();
    const inputParam: InputCreateQuoteItem = {
      quoteId: this.quoteId,
      quoteItemId: newId,
      virtualThumbnailPath: virtualThumbnailPath,
      ...input
    };
    const result = await this.api.createQuoteItem(inputParam);
    if (result) {
      await this.addQuoteItem(
        result.quoteItem,
        result.quoteItemPrice,
        result.quoteItemProviderData,
        result.quoteItemBuyInData
      );

      await this.resetQuote(result.quote, result.resultFullQuotePrice!.quotePrice, result.quotePresentation, null);
      if (result.resultFullQuotePrice!.itemPrices) this.updateItemPrices(result.resultFullQuotePrice!.itemPrices);
      if (result.resultFullQuotePrice!.itemBuyIns) this.updateItemBuyIns(result.resultFullQuotePrice!.itemBuyIns);

      if (input.thumbnail) {
        this.postImageBlob('', virtualThumbnailPath, input.thumbnail);
      }
      await this.validateBuyInPricesForItem(result.quoteItem.id);
      await this.doAfterSave();
      return this.quoteItemContainer(result.quoteItem.id);
    }
    return null;
  }

  /**
   * update a quote item on the server and its thumbnail svg image
   * after update refreshes the quote and quote price object, and all item prices
   * @param quoteItemContainer
   * @param thumbnail
   * @returns the new refreshed copy for the quoteitem
   */
  async createQuoteItemEx(
    quoteItemContainer: QuoteItemContainer,
    thumbnail: string,
    extraItems?: InputUpdateQuoteItems,
    thumbnailExtension?: string
  ): Promise<QuoteItemContainer> {
    this._lastSaveSuccessful = false;

    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return quoteItemContainer;
    }

    //if thumbnail is an empty string, then we are not changing anything
    let currentImagePath = '';
    let newImagePath = '';
    if (thumbnail !== '') {
      currentImagePath = quoteItemContainer.item.virtualThumbnailPath ?? '';
      //generate a new image path to refer to when saving the quote item
      newImagePath = this.api.createQuoteItemThumbnailPath(
        this.quoteId,
        quoteItemContainer.item.id,
        thumbnailExtension ?? '.svg'
      );
      quoteItemContainer.item.virtualThumbnailPath = newImagePath;
    }

    this._lastSaveSuccessful = await this.procesItemUpdateBatch(
      quoteItemContainer,
      QuoteItemRowAction.Insert,
      extraItems,
      async () => {
        if (thumbnail !== '')
          if (isAutoSaving()) await this.postImageBlob(currentImagePath, newImagePath, thumbnail);
          else this.postImageBlob(currentImagePath, newImagePath, thumbnail);
      }
    );

    return this.quoteItemContainer(quoteItemContainer.item.id);
  }

  public async copyQuoteItem(quoteItemContainer: QuoteItemContainer): NullPromise<QuoteItemContainer> {
    let value: QuoteItemContainer | null = null;
    try {
      if (!(await this.copyQuoteItemBefore(quoteItemContainer))) return null;

      value = await this.copyQuoteItemExecute(quoteItemContainer);
      if (!value) return null;
      if (!(await this.copyQuoteItemAfter(quoteItemContainer, value))) {
        try {
          if (value) await this.deleteQuoteItem(value);
        } catch (e) {
          throw new DevelopmentError(`trying to delete quoteitem after failed copy. ${(e as object).toString()}`);
        }
        return null;
      }
      return value;
    } finally {
      await this.copyQuoteItemFinally(quoteItemContainer, value);
    }
  }

  async processResultItem(item: ResultQuoteItem) {
    if (!item.quoteItem) return;
    const newContainer: QuoteItemContainer = {
      item: item.quoteItem!,
      data: item.quoteItemProviderData!,
      price: item.quoteItemPrice!,
      buyInData: item.quoteItemBuyInData
    };
    if (this.removeContainerCache(newContainer.item.id)) {
      this.updateItemContainer(newContainer, false);
      this.updateItemContainer(newContainer, true);
    } else {
      if (item.quoteItem && item.quoteItemPrice)
        await this.addQuoteItem(
          item.quoteItem,
          item.quoteItemPrice,
          item.quoteItemProviderData,
          item.quoteItemBuyInData
        );
    }
    await this.validateBuyInPricesForItem(item.quoteItem.id);
  }

  async internalUpdateQuoteItems(primaryItem: UpdateQuoteItem | null, extraItems: InputUpdateQuoteItems) {
    const inputItems = clone(extraItems);
    if (primaryItem) inputItems.items.push(primaryItem);
    const result = await this.api.updateQuoteItems(inputItems);
    //remove deleted items from local cache
    inputItems.items.forEach(element => {
      if (element.action === QuoteItemRowAction.Delete && element.input.quoteItem)
        this.internalDeleteQuoteItemReference(element.input.quoteItem.id);
    });
    return result;
  }

  async procesItemUpdateBatch(
    quoteItemContainer: QuoteItemContainer | null,
    action: QuoteItemRowAction,
    extraItems?: InputUpdateQuoteItems,
    postUpdateEvent?: () => Promise<void>
  ) {
    const updateItemInput: InputUpdateQuoteItem | null = quoteItemContainer
      ? {
          quoteItemId: quoteItemContainer.item.id,
          quoteItem: quoteItemContainer.item,
          quoteItemProviderData: quoteItemContainer.data,
          quoteItemBuyInData: { data: quoteItemContainer.buyInData },
          quoteItemPrice: quoteItemContainer.price
        }
      : null;

    const result = await this.internalUpdateQuoteItems(
      updateItemInput
        ? {
            action: action,
            input: updateItemInput
          }
        : null,
      extraItems ?? { items: [] }
    );
    const resultQuote = result?.quote;
    const resultFullQuotePrice = result?.resultFullQuotePrice ?? undefined;
    const resultItems = result?.items ?? [];
    const resultQuotePresentation = result?.quotePresentation ?? undefined;

    if (resultFullQuotePrice && resultQuote) {
      await postUpdateEvent?.();

      await this.afterItemUpdateProcessing(resultItems, resultFullQuotePrice, resultQuote, resultQuotePresentation);

      return true;
    }
    return false;
  }
  cloneItemContainer(qic: QuoteItemContainer): QuoteItemContainer {
    return {
      buyInData: clone(qic.buyInData),
      data: clone(qic.data),
      item: clone(qic.item),
      price: clone(qic.price)
    };
  }

  /**
   * update a quote item on the server and its thumbnail svg image
   * after update refreshes the quote and quote price object, and all item prices
   * @param quoteItemContainer
   * @param thumbnail
   * @returns the new refreshed copy for the quoteitem
   */
  async saveAndUpdateQuoteItem(
    quoteItemContainer: QuoteItemContainer,
    thumbnail: string,
    extraItems?: InputUpdateQuoteItems,
    thumbnailExtension?: string,
    forceSave?: boolean
  ): Promise<QuoteItemContainer> {
    this._lastSaveSuccessful = false;

    if (!forceSave && this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return quoteItemContainer;
    }

    //if thumbnail is an empty string, then we are not changing anything
    let currentImagePath = '';
    let newImagePath = '';
    if (thumbnail !== '') {
      currentImagePath = quoteItemContainer.item.virtualThumbnailPath ?? '';
      //generate a new image path to refer to when saving the quote item
      newImagePath = this.api.createQuoteItemThumbnailPath(
        this.quoteId,
        quoteItemContainer.item.id,
        thumbnailExtension ?? '.svg'
      );
      quoteItemContainer.item.virtualThumbnailPath = newImagePath;
    }

    this._lastSaveSuccessful = await this.procesItemUpdateBatch(
      quoteItemContainer,
      QuoteItemRowAction.Update,
      extraItems,
      async () => {
        if (thumbnail !== '')
          if (isAutoSaving()) await this.postImageBlob(currentImagePath, newImagePath, thumbnail);
          else this.postImageBlob(currentImagePath, newImagePath, thumbnail);
      }
    );

    return this.quoteItemContainer(quoteItemContainer.item.id);
  }

  async saveAndUpdateQuoteItems(extraItems: InputUpdateQuoteItems): Promise<QuoteItemContainer[]> {
    this._lastSaveSuccessful = false;

    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return [];
    }

    this._lastSaveSuccessful = await this.procesItemUpdateBatch(null, QuoteItemRowAction.Update, extraItems);

    const result: QuoteItemContainer[] = [];
    extraItems.items.forEach(element => {
      if (element.input.quoteItem) result.push(this.quoteItemContainer(element.input.quoteItem?.id));
    });
    return result;
  }

  async afterItemUpdateProcessing(
    resultItems: ResultQuoteItem[],
    resultFullQuotePrice: ResultFullQuotePrice,
    resultQuote: Quote,
    resultQuotePresentation?: QuotePresentation
  ) {
    for (let iExtra = 0; iExtra < resultItems.length; iExtra++) await this.processResultItem(resultItems[iExtra]);

    //saving item doesn't change presentation layer, pass in null
    await this.resetQuote(resultQuote, resultFullQuotePrice.quotePrice, resultQuotePresentation ?? null, null);

    if (resultFullQuotePrice?.itemPrices) this.updateItemPrices(resultFullQuotePrice.itemPrices);
    if (resultFullQuotePrice!.itemBuyIns) this.updateItemBuyIns(resultFullQuotePrice.itemBuyIns);

    await this.validateBuyInPrices();
    await this.doAfterSave();
  }

  public async deleteQuoteItem(
    quoteItemContainer: QuoteItemContainer,
    extraItems?: InputUpdateQuoteItems
  ): Promise<boolean> {
    this._lastSaveSuccessful = false;
    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return false;
    }

    await this.needsQuoteItems();
    this._lastSaveSuccessful = await this.procesItemUpdateBatch(
      quoteItemContainer,
      QuoteItemRowAction.Delete,
      extraItems
    );

    if (this._lastSaveSuccessful) await this.doAfterDeleteQuoteItem(quoteItemContainer);
    return this._lastSaveSuccessful;
  }

  removeContainerCache(id: string) {
    let idx = this.itemContainerCache.findIndex(x => x.item.id === id);
    if (idx >= 0) this.itemContainerCache.splice(idx, 1);
    const found = idx >= 0;
    idx = this.itemContainerCacheBak.findIndex(x => x.item.id === id);
    if (idx >= 0) this.itemContainerCacheBak.splice(idx, 1);
    return found;
  }

  async saveAndUpdateQuoteItemWithIndicator(
    quoteItemContainer: QuoteItemContainer,
    thumbnail: string
  ): Promise<QuoteItemContainer> {
    if (!this.changedItem(quoteItemContainer.item.id)) {
      this._lastSaveSuccessful = true;
      return quoteItemContainer;
    }

    let result: QuoteItemContainer = quoteItemContainer;
    this._lastSaveSuccessful = await saveWithIndicator(async () => {
      if (!quoteItemContainer) return false;
      try {
        const newContainer = await this.saveAndUpdateQuoteItem(quoteItemContainer, thumbnail);
        if (newContainer.item.recordVersion !== quoteItemContainer.item.recordVersion) {
          result = newContainer;
          return true;
        }
      } catch {
        return false;
      }
      return false;
    });
    return result;
  }

  async saveAndUpdateQuoteItemPrice(quoteItemPrice: QuoteItemPrice): Promise<QuoteItemContainer> {
    if (this.isReadonly()) {
      await showDevelopmentError('Trying to save readonly  quote ');
      return this.quoteItemContainer(quoteItemPrice.id);
    }

    const updateBuyInItem = (buyInData: string, useBackup: boolean) => {
      const container = useBackup ? this.backup : this.container;
      const idxBuyInData = container.itemsBuyInData?.findIndex(x => x.id == quoteItemPrice.id) ?? -1;

      if (container.itemsBuyInData) {
        if (idxBuyInData > -1 && !buyInData) container.itemsBuyInData?.splice(idxBuyInData, 1);
        else if (idxBuyInData > -1 && buyInData) container.itemsBuyInData[idxBuyInData].buyInData = buyInData;
        else if (idxBuyInData < 0 && buyInData)
          container.itemsBuyInData.push({
            buyInData: buyInData,
            dateCreated: localDateTimeToServer(new Date()),
            id: quoteItemPrice.id,
            recordVersion: ''
          });
      }
    };

    const result = await this.api.updateQuoteItem({
      quoteItemId: quoteItemPrice.id,
      quoteItem: null,
      quoteItemProviderData: null,
      quoteItemBuyInData: null,
      quoteItemPrice: quoteItemPrice
    });
    if (result && result.quoteItemPrice && result.resultFullQuotePrice) {
      if (result.quoteItemBuyInData) {
        const newBuyInData = result.quoteItemBuyInData;
        updateBuyInItem(newBuyInData, false);
        updateBuyInItem(newBuyInData, true);
      }

      await this.afterItemUpdateProcessing([result], result.resultFullQuotePrice, result.quote, undefined);

      const container = this.quoteItemContainer(quoteItemPrice.id);
      fireQuickSuccessToast(tlang`Item Price Updated "${container?.item.title}"`);
      return container;
    }

    return this.quoteItemContainer(quoteItemPrice.id);
  }

  /**
   * replaces all item price objects with a fresh set from the server.
   * @param prices a new set of item prices
   */
  updateItemPrices(prices: QuoteItemPrice[]) {
    const update = (list?: QuoteItemPrice[] | null) => {
      list?.forEach(itemPrice => {
        const newPrice = prices.find(x => x.id === itemPrice.id);
        if (newPrice) Object.assign(itemPrice, newPrice);
      });
    };
    update(this.container.itemPrices);
    update(this.backup.itemPrices);
  }

  updateItemBuyIns(buyIns: QuoteItemBuyInData[]) {
    const update = (list?: QuoteItemBuyInData[] | null) => {
      list?.forEach(itemBuyIn => {
        const newBuyIn = buyIns.find(x => x.id === itemBuyIn.id);
        if (newBuyIn) Object.assign(itemBuyIn, newBuyIn);
      });
    };
    update(this.container.itemsBuyInData);
    update(this.backup.itemsBuyInData);
  }

  quoteChanged(): boolean {
    return !compare(this.backup.quote, this.container.quote);
  }

  changed(): boolean {
    return !compare(this.backup, this.container);
  }

  public async makeCopy(): NullPromise<QuoteContainer> {
    return null;
  }

  public async makeAlternative(_forSupplierReview = false): NullPromise<QuoteContainer> {
    return null;
  }

  public async deleteQuote(): Promise<boolean> {
    return false;
  }

  public async getBuyInCosts(_buyInItems?: QuoteFrameBuyInItem[]): Promise<StockLookupViewExtra[] | undefined> {
    return undefined;
  }

  public lookupBuyInData(
    quoteItemContainer: QuoteItemContainer,
    code: string,
    supplierCode: string,
    lookup: string
  ): StockLookupViewExtra | undefined {
    if (!quoteItemContainer.buyInData) return;

    const buyIns = base64ToObject<StockLookupViewExtra[]>(quoteItemContainer.buyInData);

    if (!buyIns) return;

    return buyIns.find(x => x.code == code && x.supplierCode == supplierCode && x.lookup == lookup);
  }

  protected async quoteStateBeforeChange(_state: QuoteState, _original: QuoteState): Promise<boolean> {
    return true;
  }

  protected async quoteStateAfterChange(_state: QuoteState, _original: QuoteState): Promise<boolean> {
    return true;
  }

  protected async copyQuoteItemBefore(_quoteItemContainer: QuoteItemContainer): Promise<boolean> {
    return true;
  }

  protected async copyQuoteItemAfter(
    _quoteItemContainer: QuoteItemContainer,
    _newQuoteItemContainer: QuoteItemContainer
  ): Promise<boolean> {
    return true;
  }

  protected async copyQuoteItemFinally(
    _quoteItemContainer: QuoteItemContainer,
    _newQuoteItemContainer: QuoteItemContainer | null
  ): Promise<void> {
    //
  }

  protected async copyQuoteItemExecute(quoteItemContainer: QuoteItemContainer): NullPromise<QuoteItemContainer> {
    const result = await this.api.duplicateQuoteItem({
      quoteItemId: quoteItemContainer.item.id
    });
    if (result) {
      await this.addQuoteItem(
        result.quoteItem,
        result.quoteItemPrice,
        result.quoteItemProviderData,
        result.quoteItemBuyInData?.buyInData ?? null
      );
      await this.resetQuote(result.quote, result.resultFullQuotePrice.quotePrice, result.quotePresentation, null);
      await this.validateBuyInPrices();
      await this.doAfterSave();
      return this.quoteItemContainer(result.quoteItem.id);
    }
    return null;
  }

  protected doAfterDeleteQuoteItem(_quoteItemContainer: QuoteItemContainer) {
    //DoNothing
  }

  protected internalIsReadonly(): boolean {
    return false;
  }

  /**
   * replaces the backups and originals of the quote objects with this new set of objects to become the master
   * @param quote
   * @param quotePrice
   * @param quotePresentation
   */
  protected async resetQuote(
    quote: Quote,
    quotePrice: QuotePrice,
    quotePresentation: QuotePresentation | null,
    siblings: QuoteSetSibling[] | null
  ) {
    if (this.container.quote) Object.assign(this.container.quote, quote);
    else this.container.quote = quote;

    const providerData =
      base64ToObject<QuoteProviderData>(quote.serviceProviderData) ??
      (await createQuoteProviderData(quote.serviceProvider, quote.supplierId));
    this.container.quoteProviderData = await validateAndUpgradeQuoteProviderData(providerData);
    if (quotePresentation) this.container.quotePresentation = quotePresentation;
    else if (!this.container.quotePresentation) throw new DevelopmentError('Missing QuotePresentation'); //should only occur on updates

    if (this.container.quotePrice) Object.assign(this.container.quotePrice, quotePrice);
    else this.container.quotePrice = quotePrice;

    if (siblings) this.container.siblings = siblings;

    this.backup.quote = this.clone(quote);
    this.backup.quoteProviderData = this.clone(this.container.quoteProviderData);
    this.backup.quotePrice = this.clone(quotePrice);

    //we dont pass in siblings if we didn't update the quote directly
    if (siblings) this.backup.siblings = this.clone(siblings);

    if (quotePresentation) this.backup.quotePresentation = this.clone(quotePresentation);
  }

  /**
   * execute all bound events after any save operation to allow for re-rendering and refreshing of state
   */
  protected async doAfterSave(): Promise<void> {
    await runEventNotify(this.afterSave);
  }

  protected async validateBuyInPricesForItem(id: string) {
    if (this.isBuyInDataLocked) return;
    const buyInData = this.container.itemsBuyInData?.find(x => x.id == id);

    const validationIssueIndex = this.priceValidation?.findIndex(x => x.id == id) ?? -1;

    if (validationIssueIndex > -1) this.priceValidation?.splice(validationIssueIndex, 1);

    if (buyInData) {
      await this.reportBuyInValidation(buyInData);
    }
  }

  protected async validateBuyInPrices() {
    if (this.isBuyInDataLocked) return;
    this.priceValidation = null;
    this.container.itemsBuyInData?.map(async x => {
      await this.reportBuyInValidation(x);
    });
  }

  protected async addQuoteItem(
    quoteItem: QuoteItem,
    quoteItemPrice: QuoteItemPrice,
    quoteItemProviderData: QuoteItemProviderData | null,
    quoteItemBuyInData: string | null
  ) {
    this.container.items?.push(quoteItem);
    if (quoteItemProviderData) this.container.itemsData?.push(quoteItemProviderData);
    if (quoteItemBuyInData)
      this.container.itemsBuyInData?.push({
        id: quoteItem.id,
        buyInData: quoteItemBuyInData,
        dateCreated: localDateTimeToServer(new Date()),
        recordVersion: ''
      });
    this.container.itemPrices?.push(quoteItemPrice);

    this.backup.items?.push(this.clone(quoteItem));
    if (quoteItemProviderData) this.backup.itemsData?.push(this.clone(quoteItemProviderData));
    if (quoteItemBuyInData)
      this.backup.itemsBuyInData?.push({
        id: quoteItem.id,
        buyInData: quoteItemBuyInData,
        dateCreated: localDateTimeToServer(new Date()),
        recordVersion: ''
      });
    this.backup.itemPrices?.push(this.clone(quoteItemPrice));

    const sortedItems = await this.internalSortItemsAsList();
    this.container.items = sortedItems;
    this.backup.items = clone(sortedItems);
  }

  /**
   * perform an insert of text based blob data such as svg on the server
   * @param oldItemPath an old name to delete when uploading
   * @param newItemPath a new name path to use for this insert
   * @param imageData text based data such as svg
   */
  protected async postImageBlob(oldItemPath: string, newItemPath: string, imageData: string | null) {
    if (!imageData) return;
    const base64Index = imageData.indexOf('base64,');
    const data = base64Index >= 0 ? imageData.substring(imageData.indexOf(',') + 1) : btoa(imageData);

    await this.blobApi.updateFileByVirtualPath({
      oldVirtualPath: oldItemPath,
      newVirtualPath: newItemPath,
      data: data
    });
  }

  private getAdjustedPresentationOrder(items: QuoteItem[]): string[] {
    const presentationItems: string[] = [];
    const itemsToMoveToEnd: QuoteItem[] = [];
    const order = this.container.quotePresentation?.itemDisplayOrder ?? [];
    order.forEach(commonId => {
      const item = items.find(x => x.commonId === commonId);
      if (item && (isShipping(item) || isSSI(item))) itemsToMoveToEnd.push(item);
      else presentationItems.push(commonId);
    });
    items
      .filter(x => !order.includes(x.commonId))
      .forEach(item => {
        presentationItems.push(item.commonId);
      });
    itemsToMoveToEnd.forEach(item => {
      if (!isShipping(item)) presentationItems.push(item.commonId);
    });
    itemsToMoveToEnd.forEach(item => {
      if (isShipping(item)) presentationItems.push(item.commonId);
    });
    return presentationItems;
  }

  private async internalSortItemsAsList(items?: QuoteItem[]) {
    const sortingItems = items ?? this.container.items ?? [];
    if (!this.container.quotePresentation) {
      await showDevelopmentError('QuotePresentation missing, cannot sort items');
      return sortingItems;
    }
    const sortedItems: QuoteItem[] = [];
    const displayOrder = this.getAdjustedPresentationOrder(sortingItems);
    this.container.quotePresentation.itemDisplayOrder = displayOrder;

    //create a list ordered by the quote presentation
    displayOrder.forEach(commonId => {
      const item = sortingItems.find(item => item.commonId === commonId);
      if (item) {
        sortedItems.push(item);
      }
    });
    if (sortedItems.length !== sortingItems.length) {
      sortedItems.push(...sortingItems.filter(item => !displayOrder.includes(item.commonId)));
    }
    return sortedItems;
  }

  private updateItemContainer(updatedItemContainer: QuoteItemContainer, useBackup: boolean) {
    const container = useBackup ? this.backup : this.container;
    const idxItem = container.items?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;

    let idxData = -1;
    if (updatedItemContainer.data)
      idxData = container.itemsData?.findIndex(x => x.id === updatedItemContainer.data?.id) ?? -1;

    let idxBuyInData = -1;
    if (updatedItemContainer.buyInData)
      idxBuyInData = container.itemsBuyInData?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;

    const idxPrice = container.itemPrices?.findIndex(x => x.id === updatedItemContainer.price.id) ?? -1;

    if (idxItem >= 0 && container.items)
      container.items[idxItem] = useBackup ? this.clone(updatedItemContainer.item) : updatedItemContainer.item;
    if (idxData >= 0 && container.itemsData && updatedItemContainer.data)
      container.itemsData[idxData] = useBackup ? this.clone(updatedItemContainer.data) : updatedItemContainer.data;
    if (idxPrice >= 0 && container.itemPrices)
      container.itemPrices[idxPrice] = useBackup ? this.clone(updatedItemContainer.price) : updatedItemContainer.price;

    if (container.itemsBuyInData) {
      if (idxBuyInData > -1 && !updatedItemContainer.buyInData) container.itemsBuyInData?.splice(idxBuyInData, 1);
      else if (idxBuyInData > -1 && updatedItemContainer.buyInData)
        container.itemsBuyInData[idxBuyInData].buyInData = updatedItemContainer.buyInData;
      else if (idxBuyInData < 0 && updatedItemContainer.buyInData)
        container.itemsBuyInData.push({
          buyInData: updatedItemContainer.buyInData,
          dateCreated: localDateTimeToServer(new Date()),
          id: updatedItemContainer.item.id,
          recordVersion: ''
        });
    }
  }

  public get isBuyInDataLocked(): boolean {
    return !flagInSet(this.quoteState, QuoteState.Draft | QuoteState.Active);
  }
  private async reportBuyInValidation(itemBuyInData: QuoteItemBuyInData) {
    if (this.isBuyInDataLocked) return;

    const metaDataForItem = base64ToObject<StockLookupViewExtra[]>(itemBuyInData.buyInData);

    if (metaDataForItem) {
      const buyins = await this.getBuyInCosts(
        metaDataForItem.map(y => {
          return {
            code: y.code,
            partCode: '',
            extraDetails: {
              LibCode: y.libCode,
              SuppCode: y.supplierCode,
              SupplierId: y.supplierId
            },
            quantity: 1,
            resourceType: 0,
            decription: ''
          };
        })
      );

      //Once V6 returns the libcode, we need to filter on that as well
      metaDataForItem.map(y => {
        const serverVersion = buyins?.find(
          z => z.code == y.code && y.supplierCode == z.supplierCode && y.supplierId == z.supplierId
        );

        // Check for missing costs
        if (y.cost == null || !serverVersion) {
          this.addPriceValidation(itemBuyInData.id, y.code, 'Missing cost price');
        }

        //Check for catalogue updates updates
        if (y.version != serverVersion?.version ?? '') {
          this.addPriceValidation(itemBuyInData.id, y.code, 'Buy-in catalogue has changed');
        }
      });
    }
  }

  private internalDeleteQuoteItemReference(quoteItemId: string) {
    function remove(list: { id: string }[]) {
      const idx = list.findIndex(x => x.id === quoteItemId) ?? -1;
      if (idx >= 0) list.splice(idx, 1);
    }

    if (this.container.items) remove(this.container.items);
    if (this.container.itemPrices) remove(this.container.itemPrices);
    if (this.container.itemsData) remove(this.container.itemsData);
    if (this.container.itemsBuyInData) remove(this.container.itemsBuyInData);

    if (this.backup.items) remove(this.backup.items);
    if (this.backup.itemPrices) remove(this.backup.itemPrices);
    if (this.backup.itemsData) remove(this.backup.itemsData);
    if (this.backup.itemsBuyInData) remove(this.backup.itemsBuyInData);
    this.removeContainerCache(quoteItemId);
  }

  /**
   * find and replace the data elements of a quoteItem with a new refreshed set of data
   * uses a clone of the data passed in
   * @param updatedItemContainer
   * @param useBackup
   */
  private replaceQuoteItem(updatedItemContainer: QuoteItemContainer, useBackup: boolean) {
    const container = useBackup ? this.backup : this.container;
    const idxItem = container.items?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;

    let idxData = -1;
    if (updatedItemContainer.data)
      idxData = container.itemsData?.findIndex(x => x.id === updatedItemContainer.data?.id) ?? -1;

    const idxPrice = container.itemPrices?.findIndex(x => x.id == updatedItemContainer.price.id) ?? -1;
    if (idxItem >= 0 && container.items) container.items[idxItem] = this.clone(updatedItemContainer.item);
    if (updatedItemContainer.data && idxData >= 0 && container.itemsData)
      container.itemsData[idxData] = this.clone(updatedItemContainer.data);

    let idxBuyInData = -1;
    if (updatedItemContainer.buyInData)
      idxBuyInData = container.itemsBuyInData?.findIndex(x => x.id === updatedItemContainer.item.id) ?? -1;

    if (container.itemsBuyInData) {
      if (idxBuyInData > -1 && !updatedItemContainer.buyInData) container.itemsBuyInData?.splice(idxBuyInData, 1);
      else if (idxBuyInData > -1 && updatedItemContainer.buyInData)
        container.itemsBuyInData[idxBuyInData].buyInData = updatedItemContainer.buyInData;
      else if (idxBuyInData < 0 && updatedItemContainer.buyInData)
        container.itemsBuyInData.push({
          buyInData: updatedItemContainer.buyInData,
          dateCreated: localDateTimeToServer(new Date()),
          id: updatedItemContainer.item.id,
          recordVersion: ''
        });
    }

    if (idxPrice >= 0 && container.itemPrices) container.itemPrices[idxPrice] = this.clone(updatedItemContainer.price);
  }
}
