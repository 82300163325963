// noinspection HtmlUnknownAttribute
import {
  MenuIconOption,
  PageButtonLocation,
  PageControl,
  PageControlOptions,
  PageManager
} from '../../components/ui/page-control';
import { EventSnippet, PromiseSnippet, PromiseTemplate } from '../../components/ui/events';
import { ProjectApi } from '../../api/project-api';
import {
  ProjectState,
  ProjectStateChangeReason,
  ViewProjectResourceReference
} from '../../api/dealer-api-interface-project';
import { ProjectResourceView } from './project-resource-view';
import { ProjectDetailView } from './project-detail-view';
import { ProjectContainer, ProjectContainerManager } from '../data/project-container';
// eslint-disable-next-line import/named
import { tlang } from '@softtech/webmodule-components';
import { getApiFactory } from '../../api/api-injector';
import { isAutoSaving } from '../../components/save-workflow';
import { DataCacheGeneric } from '../../cache/generic-data-cache';
import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from '../../components/ui/modal-confirmation';
import { DataCache } from '../../cache/data-cache';
import { QuoteCacheData } from '../../dealer-franchisee/cache/cache-data';
import { DataEntryPageControlView, ModalViewBase } from '../../components/ui/data-entry-screen-base';
import { DataEntryOwner } from '../../components/ui/DataEntryOwner';
import { constructAsync } from '../../async-constructor';
import { html } from 'lit';
import { ProjectDocumentView } from './project-document-view';
import { NullPromise } from '../../null-promise';
import { getConfirmationReasonFor } from '../../components/ui/modal-confirmation-reason';

export interface ProjectViewOptions {
  projectContainerManager: ProjectContainerManager;
  quoteCache: DataCache<QuoteCacheData>;
  purchaseOrderCache: DataCacheGeneric;
  title: EventSnippet;
}

// Project view encloses the entire project edit and view process
// contains detail view
// resources view
// shipping view.
export class ProjectDataEntryView extends DataEntryPageControlView {
  private readonly discardChangesText = tlang`Discard Changes`;
  projectApi: ProjectApi = getApiFactory().project();
  quoteCache: DataCache<QuoteCacheData>;
  purchaseOrderCache: DataCacheGeneric;
  protected readonly projectContainerManager: ProjectContainerManager;
  protected title: EventSnippet;
  protected readonly detailView: ProjectDetailView;
  private readonly resourceView: ProjectResourceView;
  private readonly documentsView: ProjectDocumentView;
  intervalStateChange?: NodeJS.Timeout;

  constructor(options: ProjectViewOptions, owner: DataEntryOwner) {
    super(owner);
    this.title = options.title;
    this.quoteCache = options.quoteCache;
    this.purchaseOrderCache = options.purchaseOrderCache;

    this.projectContainerManager = options.projectContainerManager;

    this.detailView = this.projectDetailViewFactory(options);
    this.resourceView = this.projectResourceViewFactory();
    this.documentsView = this.projectDocumentsViewFactory();

    this.projectContainerManager.afterSave.push(async () => {
      await this.detailView.render();

      // await this.resourceView.loadOrRefresh();
      // this.resourceView.render();

      await this.render();
    });
  }

  get projectContainer(): ProjectContainer {
    return this.projectContainerManager.container;
  }

  public async afterConstruction() {
    await this.projectContainerManager.needsProject();
    await this.detailView.afterConstruction();
    await this.detailView.render();
    await super.afterConstruction();
    this.buildProjectActionMenu();
    
    if (await this.resourceView.hasPendingResources()) this.waitForStateChange();
  }

  async openResourceItem(resourceItem: ViewProjectResourceReference): Promise<void> {
    alert(`Open Resource ${resourceItem.typeOf.toString()}`);
  }

  public async canLoseFocus(): Promise<boolean> {
    return true;
  }

  public override isReadonly(): boolean {
    return this.projectContainerManager.isReadonly();
  }

  public override async prepareForSave(): Promise<void> {
    if (this.isReadonly()) return;

    await this.detailView.prepareForSave();
  }

  public override internalDataChanged(): boolean {
    return this.projectContainerManager.changed();
  }

  public override getDataDictionaryName(): string {
    return '%%project%%';
  }

  public async getTitle(): PromiseSnippet {
    return html`${this.title(this.projectContainer.project)}`;
  }

  protected override getValidationErrors() {
    const errors = [...this.detailView.getValidationErrors()];
    return errors;
  }

  protected createPageControl(): PageControl {
    // build static pages for each of the configured table settings
    const getInitialPageManagers = (): PageManager[] => {
      const pages: PageManager[] = [];
      pages.push(this.createDetailPage());
      pages.push(this.createResourcePage());
      pages.push(this.createDocumentPage());
      return pages;
    };
    const options: PageControlOptions = {
      defaultTabIndex: 0,
      menuIcons: undefined,
      pageInitializer: () => getInitialPageManagers()
    };
    const pc = new PageControl(options);
    pc.pageButtonLocation = PageButtonLocation.right;
    return pc;
  }

  protected createResourcePage(): PageManager {
    return {
      caption: () => tlang`Documents`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.resourceView.invalidate();
      },
      content: () => {
        return this.resourceView.ui;
      },
      buttonMenu: async (): PromiseSnippet => {
        return await this.resourceView.buttonMenu();
      },
      data: this.resourceView,
      pageFragment: 'resources'
    };
  }

  protected createDocumentPage(): PageManager {
    return {
      caption: () => tlang`Reports`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.documentsView.invalidate();
      },
      content: () => {
        return this.documentsView.ui;
      },
      buttonMenu: async () => {
        return await this.documentsView.buttonMenu();
      },
      data: this.documentsView,
      pageFragment: 'documents'
    };
  }

  protected projectResourceViewFactory() {
    return new ProjectResourceView({
      projectManager: this.projectContainerManager,
      quoteCache: this.quoteCache,
      purchaseOrderCache: this.purchaseOrderCache,
      canClose: async () => {
        if (await this.canClose()) {
          await this.tryClose();
          return true;
        }

        return false;
      }
    });
  }

  protected projectDocumentsViewFactory() {
    return new ProjectDocumentView({
      projectManager: this.projectContainerManager,
      canClose: async () => {
        if (await this.canClose()) {
          await this.tryClose();
          return true;
        }

        return false;
      }
    });
  }

  protected projectDetailViewFactory(_options: ProjectViewOptions) {
    return new ProjectDetailView({
      projectManager: this.projectContainerManager
    });
  }

  protected override async bodyTemplate(): PromiseTemplate {
    this.buildProjectActionMenu();
    return super.bodyTemplate();
  }

  protected async setProjectState(projectState: ProjectState): Promise<boolean> {
    try {
      if (projectState == ProjectState.Completed) {
        if (
          await AskConfirmation(
            tlang`Do you want to complete this %%project%%?`,
            confirmationButtons[ConfirmationButtonType.yesNo]
          )
        ) {
          return await this.internalSetProjectState(projectState);
        }
      } else if (projectState == ProjectState.Cancelled) {
        const cancelProjectText = tlang`Cancel %%project%%`;
        const cancelProjectMessage = tlang`Please select the reason for cancelling the %%project%%`;
        const cancelProjectReasonLabel = tlang`Cancellation Reason`;
        const hasReason = await this.tryGetUserProvidedStateChangeReason(
          projectState,
          cancelProjectText,
          cancelProjectMessage,
          cancelProjectReasonLabel,
          cancelProjectText
        );
        if (projectState == ProjectState.Cancelled && hasReason) {
          return await this.internalSetProjectState(projectState);
        }
      }
      return false;
    } finally {
      await this.render();
    }
  }

  protected async internalSetProjectState(projectState: ProjectState): Promise<boolean> {
    this.projectContainerManager.project.state = projectState;
    const saveResult = await this.internalSaveData();
    this.buildProjectActionMenu();
    return saveResult;
  }

  protected async internalCreateQuote(): Promise<boolean> {
    // implemented in subclass
    return false;
  }

  protected async canCreateQuote(): Promise<boolean> {
    // implemented in subclass
    return false;
  }

  /**
   * inherited
   * @returns
   */
  protected override async internalSaveData(): Promise<boolean> {
    //this will populate the managed quote with data from the UI
    return await this.projectContainerManager.saveProject(isAutoSaving());
  }

  protected async tryGetUserProvidedStateChangeReason(
    projectState: ProjectState,
    modalTitle: string,
    modalMessage: string,
    selectReasonLabel: string,
    confirmButtonText: string
  ): Promise<boolean> {
    const stateReasons = await this.getProjectReasons(projectState);
    if (stateReasons) {
      const reasonResult = await getConfirmationReasonFor(
        stateReasons,
        modalTitle,
        modalMessage,
        selectReasonLabel,
        confirmButtonText
      );
      if (reasonResult.result && reasonResult.reason) {
        const selectedReason = reasonResult.reason as ProjectStateChangeReason;
        this.projectContainerManager.stateChangeReason = {
          comment: reasonResult.comment ?? '',
          stateChangeReason: selectedReason
        };
        return true;
      }
      return false;
    }
    return true;
  }

  protected async getProjectReasons(_projectState: ProjectState): NullPromise<ProjectStateChangeReason[]> {
    // implemented in subclass
    return null;
  }

  private buildProjectActionMenu() {
    const menuIcons: MenuIconOption[] = [];

    const cancel = {
      caption: () => tlang`Cancel`,
      event: async () => {
        return await this.setProjectState(ProjectState.Cancelled);
      },
      classList: 'btn btn-dark'
    };
    const complete = {
      caption: () => tlang`Complete`,
      event: async () => {
        return await this.setProjectState(ProjectState.Completed);
      }
    };

    const save = {
      event: async () => {
        if (this.isReadonly()) return false;

        return this.internalSaveData();
      },
      caption: () => tlang`Save`
    };

    const status: MenuIconOption = {
      caption: () => tlang`Status`,
      childEvents: []
    };

    const addQuote = {
      event: async () => {
        if (this.isReadonly()) return false;

        await this.prepareForSave();

        if (await this.canCreateQuote()) return await this.internalCreateQuote();

        return false;
      },
      caption: () => tlang`Add %%quote%%`
    };

    if (!isAutoSaving()) menuIcons.push(save);

    if (!this.projectContainerManager.isReadonly()) {
      status.childEvents = [complete, cancel];
      menuIcons.push(status);
      menuIcons.push(addQuote);
    }

    this.pageControl.setMenuIcons(menuIcons);
  }

  private createDetailPage(): PageManager {
    return {
      caption: () => tlang`Details`,
      canClose: () => Promise.resolve(false),
      canLeave: async () => await this.allowPageSwitch(),
      hasDelete: () => false,
      onEnter: async () => {
        await this.detailView.loadOrRefresh();
      },
      content: () => {
        return this.detailView.ui;
      },
      buttonMenu: async () => {
        if (this.isLockedFromUse || this.isReadonly()) return html``;
        return html` <button @click=${() => this.abandonAndClose()} class="btn secondary-btn">
          ${this.discardChangesText}
        </button>`;
      },
      data: this.detailView,
      pageFragment: 'details',
      pageButtonLocation: PageButtonLocation.left
    };
  }

  private get isLockedFromUse(): boolean {
    return this.projectContainerManager.isLockedFromUse;
  }

  waitForStateChange() {
    this.intervalStateChange = setInterval(async () => {
      if (!(await this.resourceView.hasPendingResources())) {
        clearInterval(this.intervalStateChange);
        this.intervalStateChange = undefined;
        return;
      }
      await this.resourceView.updateResources();
      if (!(await this.resourceView.hasPendingResources())) {
        this.ui.dispatchEvent(
          new CustomEvent('resource-changed', {
            bubbles: true,
            composed: true,
            detail: {}
          })
        );
      }
    }, 60000);
  }

  override async dispose(): Promise<void> {
    await super.dispose();
    if (this.intervalStateChange) {
      clearInterval(this.intervalStateChange);
      this.intervalStateChange = undefined;
    }
  }
}

export class ProjectView extends ModalViewBase {
  view: ProjectDataEntryView | null = null;
  options: ProjectViewOptions;

  constructor(options: ProjectViewOptions) {
    super();
    this.options = options;
  }

  /**
   * inherited
   * @returns
   */
  override async canClose(): Promise<boolean> {
    return (await this.view?.canClose()) ?? true;
  }

  override async afterConstruction(): Promise<void> {
    this.view = await constructAsync(this.createView());
    this.view.onRender = async () => await this.render();
    await this.view.render();
  }

  /**
   * inherited
   * @returns
   */
  protected override async getTitle(): PromiseSnippet {
    return (await this.view?.getTitle()) ?? '';
  }

  /**
   * inherited
   * @returns
   */
  protected override modalSize(): string {
    return 'modal-fullscreen';
  }

  protected createView(): ProjectDataEntryView {
    return new ProjectDataEntryView(this.options, this);
  }

  protected override async bodyTemplate(): PromiseTemplate {
    return html`${this.view?.ui}`;
  }
}
