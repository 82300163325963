import { WebModuleQuoteSupportInboxView } from './conversation/wm-branchquote-support';
import { WebModuleBranchQuoteSupportNew } from './conversation/wm-branchquote-support-new';
import { WebModuleBranchQuoteConversationView } from './conversation/wm-branchquote-conversation';
import {
  WebModuleConversationEntryCreate,
  WebModuleConversationEntryEditorView,
  WebModuleConversationEntryView,
  WebModuleConversationUsersView
} from './conversation/wm-conversation-entry';
import { WebModuleFranchiseeBranchCreator } from '../franchisee/components/franchisee-branch-creator';
import { WebModuleFranchiseeBranchEditor } from '../franchisee/components/franchisee-branch-editor';
import { WebModuleFranchiseeBranchesEditor } from '../franchisee/components/franchisee-branches-editor';
import { WebModuleConversationEntryDropZone } from './conversation/wm-conversation-entry-dropzone';
import { WebModuleFranchiseeETOEditor } from './quotes/views/franchisee-engineered-to-order';
import { WebModuleBranchQuoteConversationEditor } from './conversation/wm-branchquote-support-editor';
import { DashboardFilter } from './dashboard/widget/dashboard-filter';
import { GeneratedPurchaseOrdersWidget } from './dashboard/widget/generated-purchase-orders-widget';
import { GeneratedQuotesWidget } from './dashboard/widget/issued-quotes-widget';
import { BranchMonthlyDeliveryPipeline } from './dashboard/widget/branch-monthly-delivery-pipeline';
import { BranchMonthlyRevenue } from './dashboard/widget/branch-monthly-revenue';
import { BranchMonthlyRevenuePipeLine } from './dashboard/widget/branch-monthly-revenue-pipeline';
import { SalesSuccessWidget } from './dashboard/widget/sales-success-widget';

export const franchiseeComponentRegistry = {
  branchCreator: WebModuleFranchiseeBranchCreator,
  branchEditor: WebModuleFranchiseeBranchEditor,
  branchesEditor: WebModuleFranchiseeBranchesEditor,
  supportEditor: WebModuleBranchQuoteConversationView,
  supportInboxView: WebModuleQuoteSupportInboxView,
  conversationEntryView: WebModuleConversationEntryView,
  conversationEntryEditor: WebModuleConversationEntryEditorView,
  conversationEntryCreate: WebModuleConversationEntryCreate,
  conversationUsers: WebModuleConversationUsersView,
  newSupportView: WebModuleBranchQuoteSupportNew,
  conversationEntryDropZone: WebModuleConversationEntryDropZone,
  webModuleFranchiseeETOEditor: WebModuleFranchiseeETOEditor,
  webModuleBranchQuoteConversationEditor: WebModuleBranchQuoteConversationEditor,
  dashboardFilterControls: DashboardFilter,
  dashboardBranchMonthlyDeliveryPipeline: BranchMonthlyDeliveryPipeline,
  dashboardBranchMonthlyRevenue: BranchMonthlyRevenue,
  dashboardBranchMonthlyRevenuePipeLine: BranchMonthlyRevenuePipeLine,
  dashboardSalesSuccessWidget: SalesSuccessWidget,
  dashboardGeneratedPurchaseOrderWidgets: GeneratedPurchaseOrdersWidget,
  dashboardGeneratedQuotesWidget: GeneratedQuotesWidget
};
