import { EventNotify } from '../components/ui/events';

import {
  ApiCommunications,
  NullPromise,
  ServiceResponse,
  ServiceResponseInvalid,
  ServiceResponseType
} from '@softtech/webmodule-data-contracts';
import { setLastApiError } from './api-communications';
import { ValidationError } from './validation-error';
import { FileResponse } from './api-type-base-interface';
import { getUserLock } from '../dealer-franchisee/common/optimistic-user-lock';
import { serverDateTimeToLocalDateTime } from '../components/datetime-converter';
import { userDataStore } from '../dealer-franchisee/common/current-user-data-store';
import { NullMultiPromise } from '../multicast-promise';
import {
  AuthenticateModal,
  getApiToken,
  setApiToken,
  setCurrentUser,
  getCurrentUser,
  jsonRequest,
  strDuplicateLogin,
  strNetworkUnavailable,
  tlang,
  setUserClaims,
  ResultTenantLogin
} from '@softtech/webmodule-components';

import { authenticationOptions, getApi } from './api-injector';

import { goStaticURL } from '../components/ui/resource-resolver';
import { information } from '../components/ui/modal-option';
import { clearCurrentUserFromSession, saveCurrentUserIntoSession } from '../components/user-session-verifier';
import { isCynclyStaff } from '../components/currentuser-claims';

export type ServiceResponseHandler = (response: ServiceResponseInvalid | ValidationError[]) => Promise<void>;
export type ValidationErrorHandler = (errors: ValidationError[]) => Promise<void>;

export let dealerTokenProvider: () => string = getUserLock;
export function setDealerTokenProvider(provider: () => string) {
  dealerTokenProvider = provider;
}

const enableVerboseLogging = false;
export class DealerApiCommunications implements ApiCommunications {
  private redirectToLoginPage: EventNotify;
  private endpoint: string;
  private invalidErrorResponseHandler?: ServiceResponseHandler;

  constructor(endpoint: string, responseHandler: ServiceResponseHandler, redirectToLoginPage: EventNotify) {
    this.endpoint = endpoint;
    if (this.endpoint == '') this.endpoint = globalThis.dealerConfiguration.apiHost;
    this.invalidErrorResponseHandler = responseHandler;
    this.redirectToLoginPage = redirectToLoginPage;
  }

  public static async verifyUserAndSetSecurityClaims(verifyUserPath?: string) {
    verifyUserPath = verifyUserPath ?? 'api/LicenseServer/verifyuser';
    const comms = new DealerApiCommunications(
      '',
      async () => {
        // ignore errors
      },
      () => {
        //
      }
    );
    const token = comms.getToken();
    if (!getCurrentUser() || token === '') return false;

    const r = await comms.tryFetch(`${comms.endpoint}/${verifyUserPath}`, token, {});
    if (!r) return false;
    if (r.status === 200) {
      const data = await r.json();
      const claims = data.claims as { [key: string]: string };
      setUserClaims(claims);
    }
    return r != null && r.status === 200;
  }

  fullUrl(path: string): string {
    return `${this.endpoint}/${path}`;
  }

  public async postFileDownload(path: string, data?: any): Promise<FileResponse | null> {
    let response: ServiceResponse<FileResponse> | null = null;
    const token = this.getToken();

    try {
      // Default options are marked with *
      const httpresponse = await fetch(`${this.endpoint}/${path}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });

      if (!(httpresponse.status == 200 || httpresponse.status == 206)) {
        const text = await httpresponse.text();
        response = {
          responseType: ServiceResponseType.Error,
          responseError: { message: text },
          responseTypeCaption: 'Error',
          result: null
        };
        await this.handleError(response);
        return null;
      } else {
        const _headers: any = {};
        if (httpresponse.headers && httpresponse.headers.forEach) {
          httpresponse.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        const contentDisposition = httpresponse.headers ? httpresponse.headers.get('content-disposition') : undefined;
        const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
        const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;

        setLastApiError(null);

        return httpresponse.blob().then(blob => {
          return {
            fileName: fileName,
            data: blob,
            status: httpresponse.status,
            headers: _headers
          };
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        await this.handleError({
          responseType: ServiceResponseType.Error,
          responseError: {
            message: `${e.name} ${e.message} ${e.cause}`,
            stackTrace: e.stack
          },
          responseTypeCaption: 'Error',
          result: null
        });
        return null;
      } else {
        await this.handleError({
          responseType: ServiceResponseType.Error,
          responseError: { message: `${e}` },
          responseTypeCaption: 'Error',
          result: null
        });
        return null;
      }
    }
  }

  public async tryFetch(url, token, data, retryCount = 3): NullPromise<Response> {
    let retry = retryCount > 0 ? 0 : -1;
    while (retry++ <= retryCount) {
      try {
        const httpresponse = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${token}`,
            'Authorization-Client': dealerTokenProvider()
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return httpresponse;
      } catch (e) {
        console.error(e);
        await new Promise(resolve => setTimeout(resolve, retry * 1000));
      }
    }
    return null;
  }
  protected async apiRequest<T>(
    url: string,
    data: any,
    authenticate?: boolean,
    skipShowResponseError?: boolean
  ): Promise<ServiceResponse<T>> {
    let response: ServiceResponse<T> | null = null;
    const doAuthenticate = authenticate ?? true;
    const token = doAuthenticate ? this.getToken() : '';
    if (doAuthenticate && token === '') {
      const r: ServiceResponse<T> = {
        responseType: ServiceResponseType.UnAuthenticated,
        responseTypeCaption: 'UnAuthenticated',
        responseError: null,
        result: null
      };
      return Promise.resolve(r);
    }

    try {
      // Default options are marked with *
      const httpresponse = await this.tryFetch(url, token, data);

      if (!httpresponse || (httpresponse?.status ?? 1) !== 200) {
        const str404 = `Api Mismatch: "${url}" unavailable. Please upgrade the server`;
        let text = (await httpresponse?.text()) ?? strNetworkUnavailable;
        if (httpresponse?.status == 412) {
          text = strDuplicateLogin;
        }

        const msg =
          httpresponse?.status == 412
            ? text
            : httpresponse?.status == 404
              ? str404
              : `${httpresponse?.status ?? 1}:  ${text}`;
        response = {
          responseType: ServiceResponseType.Error,
          responseError: { message: msg },
          responseTypeCaption: 'Error',
          result: null
        };
        await this.handleError(response);
        return response;
      } else {
        const data = await httpresponse.json();
        response = data as ServiceResponse<T>;

        //Set or clear last error, which can be analyzed by the caller of this routine if null was returned
        //higher up the chain.
        if (response.responseType === ServiceResponseType.Ok) setLastApiError(null);
        else setLastApiError(response);

        //All unexpected errors are passed through to the global handler for
        //presentation
        if (!skipShowResponseError) await this.handleError(response);

        return response;
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e);
        response = {
          responseType: ServiceResponseType.Error,
          responseError: {
            message: `url:${url}
                                  body:${JSON.stringify(data)}
                    ${e.name} ${e.message} ${e.cause}`,
            stackTrace: e.stack
          },
          responseTypeCaption: 'Error',
          result: null
        };
        await this.handleError(response);
        return response;
      } else {
        response = {
          responseType: ServiceResponseType.Error,
          responseError: { message: `${e}` },
          responseTypeCaption: 'Error',
          result: null
        };
        await this.handleError(response);
        return response;
      }
    }
  }

  getToken(): string {
    return getApiToken();
  }

  public postRaw<T>(path: string, data: any, authenticate?: boolean, baseUrl?: string): Promise<ServiceResponse<T>> {
    if (!data) data = {};
    return this.apiRequest<T>(`${baseUrl ?? this.endpoint}/${path}`, data, authenticate ?? true);
  }

  //the authentication workflow attempts to make a call as a passthrough. if a failure is based on being unauthenticated
  //then it will attempt to authentication invisibly to the caller and if succeeded retry the attempt for a clean workflow.
  //Any messages that expect to get validation errors back should pass in a local validation callback handler.
  private async authenticationWorkflow<T>(callback: () => Promise<ServiceResponse<T>>): NullPromise<T> {
    let complete = false;
    while (!complete) {
      //run the actual api call as passed in
      const serviceResponse = await callback();

      //unauthorized calls are handled via the callback.
      if (serviceResponse.responseType == ServiceResponseType.UnAuthorized) {
        //for now, we will let the unauthorized state be globally propagated
        if (this.invalidErrorResponseHandler) {
          await this.invalidErrorResponseHandler(serviceResponse);
        }
        return null;
      } else if (serviceResponse.responseType == ServiceResponseType.UnAuthenticated) {
        //run an asynchronous callback that can perform a login, and if it works
        //loop and try again.
        if (await this.performAuthenticationProcess()) {
          // noinspection UnnecessaryContinueJS -- Added for clarity
          continue;
        } else {
          //throw the unauthenticated error back as a global presentation
          if (this.invalidErrorResponseHandler) {
            await this.invalidErrorResponseHandler(serviceResponse);
          }
          return null;
        }
      } else {
        if (serviceResponse.responseType === ServiceResponseType.Ok) {
          complete = true;
          return serviceResponse.result;
        } else if (serviceResponse.responseType === ServiceResponseType.ValidationFailure) {
          //perform special error handling if there were validation errors coming back, which should
          //only be used for validating data saves.
          const errors = (serviceResponse as unknown as ServiceResponse<ValidationError[]>).result ?? [];
          //call the validation handler if there is one.
          if (this.invalidErrorResponseHandler) {
            //pass the errors to the global handler, this is unexpected,
            //but better than them being lost.
            await this.invalidErrorResponseHandler(errors);
          }
        }
        return null;
      }
    }
    return null;
  }

  private _authenticate = new NullMultiPromise<ResultTenantLogin>(async () => {
    const modal: AuthenticateModal = new AuthenticateModal(authenticationOptions());
    await modal.showModal();
    return modal.result;
  }, true);

  public async performAuthenticationProcess(): Promise<boolean> {
    try {
      setApiToken(null);
      const result: ResultTenantLogin | null = await this._authenticate.run();
      if (getApiToken() == '') {
        //double testing as 2 asyncs can be stacking this.
        if (result && result.authenticationToken !== '' && result?.authenticationToken) {
          setApiToken(result.authenticationToken);
          result.publicInfo.Is2FAEnabled = result.requires2FA;
          saveCurrentUserIntoSession(result.publicInfo);
          await setCurrentUser(result.publicInfo);
          return true;
        }
      } else return true;
    } catch {
      setTimeout(() => {
        this.redirectToLoginPage();
      }, 5);
      return false;
    }
    return false;
  }

  //only use this if a response Handler assigned
  public async post<T>(path: string, data?: any, skipShowResponseError?: boolean): Promise<T | null> {
    if (enableVerboseLogging) {
      console.log(`SEND MSG TO PATH ${path}`);
      console.log(`${JSON.stringify(data)}`);
    }
    if (!data) data = {};
    const retVal = await this.authenticationWorkflow<T>(() =>
      this.apiRequest<T>(`${this.endpoint}/${path}`, data, undefined, skipShowResponseError)
    );
    if (enableVerboseLogging) {
      console.log(`RETURN`);
      console.log(`${JSON.stringify(retVal)}`);
    }
    return retVal;
  }

  private async handleError<T>(response: ServiceResponse<T>): Promise<void> {
    if (
      response.responseType == ServiceResponseType.Error ||
      response.responseType == ServiceResponseType.ModifyNotFound ||
      response.responseType == ServiceResponseType.TransientDbError
    )
      if (this.invalidErrorResponseHandler) {
        await this.invalidErrorResponseHandler(response);
      }
  }
}

export async function refreshUserToken(updateNote?: (message: string) => void) {
  let user = getCurrentUser();
  if (user) {
    const hours = Math.abs(serverDateTimeToLocalDateTime(user.tokenDateExpires).diffNow('hours').hours);
    if (hours < 3) {
      updateNote?.('Revalidating User Authentication');
      const result = await jsonRequest<ResultTenantLogin>(
        'api/Login/TenantExtendToken',
        {
          dealerDeploymentId: globalThis.dealerConfiguration.dealerDeploymentId,
          token: getApiToken()
        },
        globalThis.dealerConfiguration.licenseServerHost
      );
      if (result.status == 200 && result.value) {
        setApiToken(result.value.authenticationToken);
        await setCurrentUser(result.value.publicInfo);
      } else {
        //better to reset now, than  in the middle of some work.
        await setCurrentUser(null);
        return null;
      }
    }
    user = getCurrentUser(); // refresh incase it was updated.
    return user;
  }
  return null;
}
export async function patVerification(patToken: string): Promise<ResultTenantLogin | undefined> {
  const result = await jsonRequest<ResultTenantLogin>(
    `api/Access/ResolvePAT`,
    { PAT: patToken },
    globalThis.dealerConfiguration.licenseServerHost
  );
  if (result.status === 200 && result.value) {
    return result.value;
  }
  return undefined;
}

export async function checkPATTokenOnURL() {
  sessionStorage.removeItem('dealer-pat-login');
  //check if the URL contains a temporary Personal access token.
  const patToken = new URLSearchParams(window.location.search).get('PAT');
  if (patToken) {
    sessionStorage.setItem('dealer-pat-login', 'true');
    try {
      //erase any user information
      await setCurrentUser(null);
    } finally {
      sessionStorage.removeItem('dealer-pat-login');
    }
    const loginData = await patVerification(patToken);
    if (loginData) {
      setApiToken(loginData.authenticationToken);
      saveCurrentUserIntoSession(loginData.publicInfo);
      await setCurrentUser(loginData.publicInfo);
      localStorage.setItem('PAT-in-use', 'true');

      const title = (loginData.publicInfo as any).tenantName
        ? `${(loginData.publicInfo as any).tenantName}`
        : tlang`Support Access Granted`;
      if (!isCynclyStaff())
        await information(
          tlang`You have been granted temporary access to this system. Please logout when your use is complete`,
          title
        );
      return true;
    } else {
      goStaticURL('/login');
    }
  }
  return false;
}
export async function performInitialLoginAttempt() {
  clearCurrentUserFromSession();
  if (getCurrentUser() != null) await setCurrentUser(null);
  else {
    await getApi().performAuthenticationProcess();
    if (getCurrentUser() == null) {
      //We Must have at least one valid login to progress to the main application point.
      goStaticURL('');
      location.href = '';
    }
  }
  saveCurrentUserIntoSession();
  return true;
}

export function startTokenRefreshTimer() {
  //create a smart interval, only repeats after the async work is done.
  const tokenCheckInterval = 1000 * 60 * 60 * 2; //every 2 hours
  const checkToken = () => {
    setTimeout(async () => {
      await refreshUserToken(undefined);
      checkToken();
    }, tokenCheckInterval);
  };
  checkToken();
  //every 2 hours if we are online try to refresh our token
}
export async function performPageLoadLoginVerifications(updateNote: (message: string) => void) {
  // if we resolved a PAT token, then we just force switched authentication and can exit
  if (await checkPATTokenOnURL()) return true;

  //This is going to go to the api and verify the user token is valid
  //and if it is, it will also set the security roles of the user
  const valid = await DealerApiCommunications.verifyUserAndSetSecurityClaims('api/LicenseServer/verifyuser');
  if (!valid) {
    await performInitialLoginAttempt();
    return getCurrentUser() !== null;
  } else {
    const user = await refreshUserToken(updateNote);
    if (user) {
      updateNote('Fetching User Settings');
      await userDataStore.loadCoreDetails();

      const usingPAT = localStorage.getItem('PAT-in-use') !== null;
      if (usingPAT) {
        const title = (user as any).tenantName ? `${(user as any).tenantName}` : tlang`Support Access Granted`;
        if (!isCynclyStaff())
          await information(
            tlang`You are accessing this system using a temporary access login. please logoff if this is unintentional`,
            title
          );
      } else {
        startTokenRefreshTimer();
      }
      return true;
    } else return false;
  }
}

(function () {
  globalThis.dealerVerifyUser = async (updateNote: (message: string) => void) => {
    return await performPageLoadLoginVerifications(updateNote);
  };
})();
