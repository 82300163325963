// eslint-disable-next-line import/named
import { html } from 'lit';
import { DataBinding, getInternalId } from '../../components/ui/databinding/databinding';
import { PaymentProfileApi } from '../../api/payment-profile-api';
import { PaymentProfile } from '../../api/dealer-api-interface-franchisee';
import { tlang } from '@softtech/webmodule-components';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { getApiFactory } from '../../api/api-injector';
import { PaymentProfileContainerManager } from '../data/payment-profile-container-manager';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { EventVoidAsync, PromiseTemplate, Snippet } from '../../components/ui/events';
import { DataEntryPageControlView, PageControlChildTab } from '../../components/ui/data-entry-screen-base';
import { getQuoteSupplierDisplayName } from '../../quotes/data/quoteSupplierProvider';

export interface PaymentProfileDetailViewOptions {
  paymentProfileManager: PaymentProfileContainerManager;
}

export class PaymentProfileDetailView extends PageControlChildTab {
  paymentProfileManager: PaymentProfileContainerManager;
  paymentProfileApi: PaymentProfileApi = getApiFactory().paymentProfile();
  internalId: string;
  dataBinding: DataBinding;
  dataTracker: DataTracker;
  eventRefreshPage: EventVoidAsync | null = null;
  marginMax: number = 100;
  marginMin: number = 0;
  private _supplierName: string = '';

  constructor(owner: DataEntryPageControlView, options: PaymentProfileDetailViewOptions) {
    super(owner);
    this.internalId = getInternalId();

    this.paymentProfileManager = options.paymentProfileManager;

    this.dataBinding = new DataBinding(
      this.ui,
      undefined,
      (input, internalId) => `payment-profile-${input}-${internalId}`
    );
    this.dataTracker = new DataTracker(this.dataBinding);

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.paymentProfile),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('name');
    addField('margin', FieldType.float, true);
    addField('quoteTerms');
  }

  async onEnter(): Promise<void> {
    await this.loadOrRefresh();
  }

  protected getCaption(): Snippet {
    return tlang`%%payment-profile%% Info`;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  protected get paymentProfile(): PaymentProfile {
    return this.paymentProfileManager.paymentProfile;
  }

  public async loadOrRefresh(): Promise<void> {
    await this.paymentProfileManager.needsPaymentProfile();
    this._supplierName = await getQuoteSupplierDisplayName(this.paymentProfileManager.paymentProfile.supplierId);
    await this.render();
  }

  public async prepareForSave() {
    if (this.dataTracker.modified) this.dataTracker.applyChangeToValue();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);

    return html`
      <form class="frm-client-details form-one-col">
        <div class="row">
          <div>
            ${forms.textRequired('name', tlang`%%payment-profile%%`, 100)}
            ${forms.floatRequired('margin', tlang`Margin %`)}
            ${forms.note('quoteTerms', tlang`%%quote%% Terms and Conditions`, 5000)}
            ${forms.note('estimateTerms', tlang`%%estimate%% Terms and Conditions`, 5000)}
          </div>
        </div>
      </form>
    `;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];

    if (isEmptyOrSpace(this.paymentProfile?.name)) errors.push(tlang`Please provide a %%payment-profile%%`);

    if (
      this.paymentProfile.margin === undefined ||
      isNaN(this.paymentProfile.margin) ||
      this.paymentProfile.margin < this.marginMin ||
      this.paymentProfile.margin > this.marginMax
    ) {
      errors.push(
        tlang`${'ref:WI218755-supplier-margin-restriction-msg'}${this._supplierName} has restricted the margins between ${this.marginMin}%-${this.marginMax}%, please set a valid value.`
      );
    }
    return errors;
  }
}
