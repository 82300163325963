import { getApiFactory } from '../../../api/api-injector';
import { Client } from '../../../api/dealer-api-interface-client';
import { Branch } from '../../../api/dealer-api-interface-franchisee';
import { Project } from '../../../api/dealer-api-interface-project';
import { Address } from '../../../api/dealer-api-interface-quote';
import { clone } from '../../../components/clone';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { isAddressNullOrEmpty } from '../../../components/ui/maps/map-helpers';
import { DevelopmentError } from '../../../development-error';
import { tlang } from '@softtech/webmodule-components';
import { NullPromise } from '../../../null-promise';
import { cache } from '../../cache/cache-registry';
import { userDataStore } from '../../common/current-user-data-store';
import { FranchiseeQuoteContainerManager } from './franchisee-quote-manager';

export enum FranchiseeQuoteAddressManagerType {
  branch = 1,
  client = 2,
  project = 3,
  other = 4,
  willCall = 5
}

export class FranchiseeQuoteAddressManager {
  private readonly quoteManager: FranchiseeQuoteContainerManager;
  private readonly prlCache = cache().projectResourceLink;
  private readonly clientCache = cache().client;

  private readonly projectAddressTag: string = 'franchisee-address-type:project';
  private readonly clientAddressTag: string = 'franchisee-address-type:client';
  private readonly branchAddressTag: string = 'franchisee-address-type:branch';
  private readonly otherAddressTag: string = 'franchisee-address-type:other';
  private readonly willCallAddressTag: string = 'franchisee-address-type:willcall';

  private addresses: Map<FranchiseeQuoteAddressManagerType, Address | null> = new Map<
    FranchiseeQuoteAddressManagerType,
    Address | null
  >();
  private shippingNotes: Map<FranchiseeQuoteAddressManagerType, string | null> = new Map<
    FranchiseeQuoteAddressManagerType,
    string | null
  >();

  private addressTags: Map<FranchiseeQuoteAddressManagerType, string> = new Map<
    FranchiseeQuoteAddressManagerType,
    string
  >();

  private project: Project | null = null;
  private hasDefaultBranchAddress = false;
  private hasDefaultClientAddress = false;
  private hasDefaultProjectAddress = false;

  public get address(): Address | null {
    return this.quoteManager.quote.shippingAddress;
  }
  public set address(value: Address | null) {
    this.quoteManager.quote.shippingAddress = clone(value);
  }

  constructor(quoteManager: FranchiseeQuoteContainerManager) {
    this.quoteManager = quoteManager;

    this.addressTags.set(FranchiseeQuoteAddressManagerType.branch, this.branchAddressTag);
    this.addressTags.set(FranchiseeQuoteAddressManagerType.client, this.clientAddressTag);
    this.addressTags.set(FranchiseeQuoteAddressManagerType.project, this.projectAddressTag);
    this.addressTags.set(FranchiseeQuoteAddressManagerType.other, this.otherAddressTag);
    this.addressTags.set(FranchiseeQuoteAddressManagerType.willCall, this.willCallAddressTag);

    this.addresses.set(FranchiseeQuoteAddressManagerType.branch, null);
    this.addresses.set(FranchiseeQuoteAddressManagerType.client, null);
    this.addresses.set(FranchiseeQuoteAddressManagerType.project, null);
    this.addresses.set(FranchiseeQuoteAddressManagerType.other, null);
    this.addresses.set(FranchiseeQuoteAddressManagerType.willCall, null);

    this.shippingNotes.set(FranchiseeQuoteAddressManagerType.branch, null);
    this.shippingNotes.set(FranchiseeQuoteAddressManagerType.client, null);
    this.shippingNotes.set(FranchiseeQuoteAddressManagerType.project, null);
    this.shippingNotes.set(FranchiseeQuoteAddressManagerType.other, null);
    this.shippingNotes.set(FranchiseeQuoteAddressManagerType.willCall, null);
  }

  public setAddressTypeToDefault() {
    this.addressByType(this.defaultAddressType);
  }

  private setInternalAddress(
    type: FranchiseeQuoteAddressManagerType,
    address: Address | null,
    shippingNotes: string | null
  ) {
    const newAddress = clone(address);
    if (newAddress) {
      newAddress.source = this.addressTags.get(type) ?? null;
      if (!newAddress.source) throw new DevelopmentError('Invalid address map');
    }
    this.addresses.set(type, newAddress);
    this.shippingNotes.set(type, shippingNotes);
  }
  /**
   * Loads the client, project and franchisee address objects. This should only be called once per page.
   */
  private _loaded = false;
  public async loadAddresses() {
    if (this._loaded) return;
    this._loaded = true;
    const client = await this.getClient();
    const project = await this.getProject();
    const branch = await this.getFranchiseeBranch();

    if (branch) {
      this.setInternalAddress(FranchiseeQuoteAddressManagerType.branch, branch.physicalAddress, branch.shippingNotes);
      this.hasDefaultBranchAddress =
        branch.physicalAddressAsDefaultShipping && !isAddressNullOrEmpty(branch.physicalAddress);
    }

    if (client) {
      this.setClient(client);
    }

    if (project) {
      this.setInternalAddress(FranchiseeQuoteAddressManagerType.project, project.defaultAddress, project.shippingNotes);
      this.hasDefaultProjectAddress = project.shipToDefaultAddress && !isAddressNullOrEmpty(project.defaultAddress);
    }
    this.setInternalAddress(FranchiseeQuoteAddressManagerType.other, FranchiseeQuoteAddressManager.blankAddressWithSource(this.otherAddressTag), null);
    this.setInternalAddress(FranchiseeQuoteAddressManagerType.willCall, FranchiseeQuoteAddressManager.blankAddressWithSource(this.willCallAddressTag), null);
  }

  /**
   * Manually sets the specified client to be used by the address manager.
   * @param client
   */
  public setClient(client: Client) {
    this.setInternalAddress(FranchiseeQuoteAddressManagerType.client, client.physicalAddress, client.shippingNotes);
    this.hasDefaultClientAddress = client.shipToPhysicalAddress && !isAddressNullOrEmpty(client.physicalAddress);
  }

  /**
   * Checks the current quote address, and updates it by checking its source tag if required.
   * This is to ensure that the Quote object always has the most recent address upon opening.
   * If the quote has no address, it attempts to automatically assign one based on franchisee, client and project settings.
   * The address is only updated if the quote is in an editable state.
   * @returns True if the address has been updated, false otherwise.
   */
  public updateCurrentQuoteAddress(): boolean {
    if (this.quoteManager.isReadonly()) {
      return false;
    }

    const quoteAddress = this.quoteManager.quote.shippingAddress;
    if (quoteAddress && !isEmptyOrSpace(quoteAddress.source)) {
      if (quoteAddress.source !== this.otherAddressTag && quoteAddress.source !== this.willCallAddressTag) {
        this.internalUpdateAddress(this.getAddressTypeByTag(quoteAddress.source ?? ''), false);
        if (!compareAddress(quoteAddress, this.quoteManager.quote.shippingAddress!)) {
          return true;
        }
      }
    } else {
      this.updateAddress(this.defaultAddressType);
    }
    return false;
  }

  public addressByType(type: FranchiseeQuoteAddressManagerType): Address | null {
    return clone(this.addresses.get(type)) ?? null;
  }

  public shippingNotesByType(type: FranchiseeQuoteAddressManagerType): string | null {
    return this.shippingNotes.get(type) ?? null;
  }

  public updateAddress(type: FranchiseeQuoteAddressManagerType) {
    this.internalUpdateAddress(type, true);
  }

  private internalUpdateAddress(type: FranchiseeQuoteAddressManagerType, updateShippingNotes: boolean) {
    this.updateQuoteAddress(this.addressByType(type), this.shippingNotesByType(type), updateShippingNotes);
  }

  private updateQuoteAddress(newAddress: Address | null, shippingNotes: string | null, updateShippingNotes: boolean) {
    if (newAddress) {
      if (isEmptyOrSpace(newAddress.source)) throw new DevelopmentError('Address source must have a value');
      this.quoteManager.quote.shippingAddress = clone(newAddress);
      this.address = this.quoteManager.quote.shippingAddress;
    }
    if (updateShippingNotes) {
      this.quoteManager.quote.shippingNotes = shippingNotes;
    }
  }

  /**
   * Indicates if the address is readonly. An address will be readonly only if it is a predefined
   * project, client or franchisee address.
   */
  public isCurrentAddressReadonly(): boolean {
    try {
      return this.address?.source != this.otherAddressTag;
    } catch {
      return false;
    }
  }

  public static blankAddressWithSource(source: string): Address {
    return {
      line1: '',
      line2: null,
      line3: null,
      line4: null,
      locality: null,
      postcode: '',
      region: null,
      country: null,
      longitude: null,
      latitude: null,
      source: source
    };
  }

  public get defaultAddressType() {
    if (this.hasDefaultProjectAddress) return FranchiseeQuoteAddressManagerType.project;
    if (this.hasDefaultClientAddress) return FranchiseeQuoteAddressManagerType.client;
    if (this.hasDefaultBranchAddress) return FranchiseeQuoteAddressManagerType.branch;
    return FranchiseeQuoteAddressManagerType.other;
  }

  public getAddressTagByType(type: FranchiseeQuoteAddressManagerType) {
    return this.addressTags.get(type);
  }

  public getAddressTypeByTag(tag?: string | null): FranchiseeQuoteAddressManagerType {
    switch (tag) {
      case this.branchAddressTag:
        return FranchiseeQuoteAddressManagerType.branch;
      case this.clientAddressTag:
        return FranchiseeQuoteAddressManagerType.client;
      case this.projectAddressTag:
        return FranchiseeQuoteAddressManagerType.project;
      case this.willCallAddressTag:
        return FranchiseeQuoteAddressManagerType.willCall;
      case this.otherAddressTag:
      case '':
      case null:
        return FranchiseeQuoteAddressManagerType.other;
    }
    throw new DevelopmentError(`invalid address tag ${tag}`);
  }

  public getAddressLabelByTag(addressSource?: string | null): string {
    console.log(addressSource);
    switch (addressSource) {
      case this.projectAddressTag:
        return tlang`%%project%%`;
      case this.clientAddressTag:
        return tlang`%%client%%`;
      case this.branchAddressTag:
        return tlang`%%franchisee%%`;
      case this.otherAddressTag:
        return tlang`${'ref:addressTypeOther'}Other`;
      case this.willCallAddressTag:
        return tlang`${'ref:addressTypeWillCall'}Will Call`;
      default:
        return '';
    }
  }

  protected async getClient(): NullPromise<Client> {
    if (this.quoteManager) {
      const clientData = await this.clientCache.getData(this.quoteManager.branchQuote.clientId);
      if (clientData) {
        return clientData.client;
      }
    }
    return null;
  }

  protected async getProject(): NullPromise<Project> {
    if (this.quoteManager) {
      const data = await this.prlCache.getData(this.quoteManager.quoteId);
      const projectId = data?.projectId;
      if (projectId !== this.project?.id) {
        this.project = null;
      }
      if (!this.project && projectId) {
        const projectResult = await getApiFactory().project().getProject({ projectId: projectId });
        if (projectResult) {
          this.project = projectResult.project;
        }
      }
      return this.project;
    }
    return null;
  }

  protected async getFranchiseeBranch(): NullPromise<Branch> {
    //TODO removing this line, forcing a datastore refresh as its just not needed. Franchisee is set and forget
    //and this is only an issue in testing and development where we are messing around
    //this can be done when we have proper push notifications
    await userDataStore.loadCoreDetails();
    const userbranch = userDataStore.defaultBranch;
    return userbranch;
  }
}

/**
 * Compares two different addresses to see if they are equal.
 * This function accounts for the fact that some fields in the address interface are nullable, and allows null to equal an empty string.
 * @param address1 The first address to be checked for equality.
 * @param address2 The second address to be checked for equality.
 * @returns true if the addresses are equal, false otherwise.
 */
export function compareAddress(address1: Address, address2: Address): boolean {
  return (
    address1.line1 === address2.line1 &&
    (address1.line2 ?? '') === (address2.line2 ?? '') &&
    (address1.line3 ?? '') === (address2.line3 ?? '') &&
    (address1.line4 ?? '') === (address2.line4 ?? '') &&
    address1.postcode === address2.postcode &&
    (address1.locality ?? '') === (address2.locality ?? '') &&
    (address1.region ?? '') === (address2.region ?? '') &&
    (address1.country ?? '') === (address2.country ?? '') &&
    (address1.latitude ?? '') === (address2.latitude ?? '') &&
    (address1.longitude ?? '') === (address2.longitude ?? '') &&
    (address1.source ?? '') === (address2.source ?? '')
  );
}
